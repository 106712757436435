import { useState } from 'react';
import SearchBar from '../../SelectionPanel/SearchBar';
import classNames from 'classnames';
import elementPanelStyles from '../ElementPanel/styles.module.scss';
import blockStyles from '../ElementPanel/ElementBlockSource/styles.module.scss';
import styles from './styles.module.scss';
import useDocumentFieldDrag, {
  DocumentFieldDragOperation
} from '../../CustomDragLayer/hooks/useDocumentFieldDrag';
import { escapeSpecialChars } from '../../../utils/domOperations';

type DocumentFieldProps = {
  field: any;
};

const DocumentField = ({ field }: DocumentFieldProps) => {
  const [, drag] = useDocumentFieldDrag(() => ({
    operation: DocumentFieldDragOperation.New,
    type: field.type,
    label: field.icon,
    opts: {
      preview: {
        width: field.width,
        height: field.height,
        anchor: 'center' // makes precise placement easier
      }
    }
  }));

  return (
    <div
      key={field.key}
      className={classNames(styles.fieldContainer, blockStyles.fieldContainer)}
    >
      <div ref={drag} className={classNames(styles.field, blockStyles.field)}>
        <div className={blockStyles.icon}>{field.icon}</div>
        <div className={blockStyles.label}>{field.label}</div>
      </div>
    </div>
  );
};

type Field = {
  type: string;
  label: string;
  Icon: any;
  width?: number;
  height?: number;
};

type Props = {
  fields: { [key: string]: Field };
};

const DocumentFieldPanel = ({ fields: _fields }: Props) => {
  const [search, setSearch] = useState('');

  const allFields = Object.values(_fields).reduce((acc, field) => {
    return {
      ...acc,
      [field.type]: {
        key: field.type,
        type: field.type,
        label: field.label,
        icon: <field.Icon />,
        width: field.width,
        height: field.height
      }
    };
  }, {});

  const fields = Object.values(allFields).filter(
    (field: any) =>
      field.label
        .toLowerCase()
        .search(escapeSpecialChars(search).toLowerCase()) > -1
  );

  return (
    <>
      <div className={elementPanelStyles.header}>
        <SearchBar
          searchValue={search}
          onSearch={setSearch}
          searchCount={fields.length}
        />
      </div>
      <div className={classNames(styles.panel, styles.blockView)}>
        {fields.map((field: any) => (
          <DocumentField key={field.key} field={field} />
        ))}
      </div>
    </>
  );
};

export default DocumentFieldPanel;
