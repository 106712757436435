import {
  assetUpdateOperations,
  elementOperation,
  elementTypeToAsset,
  getAsset
} from '../../../../utils/themes';
import {
  getStepPropFromElementType,
  getUndoRedoPayload
} from '../../../../utils/step';
import { objectRemove } from '../../../../utils/core';
import { UNDO_TITLES, UNDO_TYPES } from '../../../../utils/constants';
import { v4 as uuidv4 } from 'uuid';
import useFeatheryRedux from '../../../../redux';
import produce from 'immer';
import { FIELD_TYPES } from '../../../../utils/elements';
import { Viewport } from '../../../RenderingEngine/GridInGrid/engine';
import { useParams } from 'react-router-dom';
import useAssetInUseUpdater from '../../../../utils/useAssetInUseUpdater';
import { useAppSelector } from '../../../../hooks';

const propsToNotCopy = [
  'id',
  'hide_ifs',
  'show_logic',
  'servar',
  'source_asset',
  'position'
];

const servarPropsToIgnore = [
  'key',
  'id',
  'repeated',
  'repeat_trigger',
  'has_data'
];

export const useAssetUpdater = (element: any, handleUpdates: any) => {
  const {
    formBuilder: {
      setTheme,
      setWorkingSteps,
      setPanelDataWithUndoRedo,
      addThemeToUndoStack
    }
  } = useFeatheryRedux();

  const theme = useAppSelector((state) => state.formBuilder.theme);
  const workingSteps = useAppSelector(
    (state) => state.formBuilder.workingSteps
  );
  const activeStepId = useAppSelector(
    (state) => state.formBuilder.activeStepId
  );
  const { elementType } = useAppSelector(
    (state) => state.formBuilder.focusedElement
  );

  const { formId } = useParams<{ formId: string }>();
  const { updatePanelThemeAssetUse } = useAssetInUseUpdater();

  if (!element) {
    return {};
  }

  const { id: elementId } = element;
  const assetsThemeProp = elementTypeToAsset(elementType);
  const elementStepProp = getStepPropFromElementType(elementType);

  const validateAsset = (newAsset: { key: string }) => {
    const newName = newAsset.key.trim().toLowerCase();
    const otherAssetNames = theme[assetsThemeProp].map(
      (asset: { key: string }) => asset.key.toLowerCase()
    );
    if (newName === 'primary' || otherAssetNames.includes(newName)) {
      throw new Error('This name is already in use');
    }
  };

  const formatAsset = (assetName: any) => {
    const asset = {
      id: uuidv4(),
      key: assetName.trim(),
      ...objectRemove(element, propsToNotCopy)
    };

    // If the asset we're creating is an asset for a servar field (as opposed to a basic element)
    // We need to also include the servar properties we want to copy
    // So we can differentiate between assets for different fields in theme.servar_field_assets
    // And so we have reasonable defaults for future uses of this asset
    if (FIELD_TYPES.includes(elementType)) {
      (asset as any).servar = objectRemove(element.servar, servarPropsToIgnore);
    }

    // Validate the asset
    validateAsset(asset);

    return asset;
  };

  const createAsset = (newAsset: any) => {
    const newTheme = produce(theme, (draft: any) => {
      draft[assetsThemeProp].push(newAsset);
    });

    setTheme({
      theme: newTheme
    });

    const newWorkingSteps = produce(workingSteps, (draft: any) => {
      const elementIndex = draft[activeStepId][elementStepProp].findIndex(
        (el: any) => el.id === elementId
      );
      const oldElement = draft[activeStepId][elementStepProp][elementIndex];
      draft[activeStepId][elementStepProp][elementIndex] = {
        ...objectRemove(oldElement, ['styles', 'mobile_styles']),
        properties: {
          text_formatted: element.properties.text_formatted
            ? element.properties.text_formatted.map((chunk: any) => ({
                ...chunk,
                attributes: {}
              }))
            : []
        },
        source_asset: newAsset.id
      };
    });

    setWorkingSteps({
      workingSteps: newWorkingSteps,
      changedStepKeys: [activeStepId]
    });

    setPanelDataWithUndoRedo(
      getUndoRedoPayload({
        elementType,
        oldSteps: workingSteps,
        newSteps: newWorkingSteps,
        stepId: activeStepId,
        changedStepKeys: [activeStepId]
      })
    );

    // Set the asset in use for this form so that the new asset can't be deleted (unless references are removed)
    // Causes issues if published with dangling source_asset references due to a delete.
    updatePanelThemeAssetUse(formId, theme.id, newWorkingSteps);

    addThemeToUndoStack({
      id: theme.id,
      oldValue: theme,
      newValue: newTheme,
      title: UNDO_TITLES.THEME,
      type: UNDO_TYPES.THEME
    });
  };

  const updateAsset = () => {
    const asset = getAsset(theme, elementType, element.source_asset);
    const hasTextStyles = Boolean(element.properties?.text_formatted?.length);
    const newTextFormatted = hasTextStyles
      ? produce(asset.properties.text_formatted, (draft: any) => {
          draft.forEach((op: any) => {
            op.attributes = {
              ...op.attributes,
              ...element.properties.text_formatted[0].attributes
            };
          });
        })
      : null;

    const operations = assetUpdateOperations({
      viewport: Viewport.Desktop,
      elementType,
      asset,
      newProps: hasTextStyles
        ? {
            text_formatted: newTextFormatted
          }
        : {},
      newStyle: element.styles
    });

    // If element has text styles, clear the element text styles
    if (hasTextStyles) {
      const elTextFormatted = element.properties.text_formatted.map(
        (chunk: any) => ({
          ...chunk,
          attributes: {}
        })
      );

      operations.push(
        elementOperation({
          viewport: Viewport.Desktop,
          propUpdate: { properties: { text_formatted: elTextFormatted } }
        })
      );
    }

    operations.push(
      ...assetUpdateOperations({
        viewport: Viewport.Mobile,
        elementType,
        asset,
        newStyle: element.mobile_styles
      })
    );

    handleUpdates(operations);
  };

  return {
    createAsset,
    updateAsset,
    formatAsset
  };
};
