import { useEffect, useState } from 'react';
import useFeatheryRedux from '../redux';
import '../style/dialog-form.css';
import { Negative, Positive } from './Core/Button';
import Label from './Dialog/Label';
import Dialog from './Dialog';
import { DropdownField, InlineTooltip, TextField } from './Core';
import styles from './Dialog/styles.module.scss';
import { useAppSelector } from '../hooks';

interface abTestFormData {
  key: string;
  variant_a: string;
  variant_b: string;
  winner: string;
  live_panel: string;
}

export default function ABTestEditModal({ mode, abTest, isOpen, close }: any) {
  const { createABTest, editABTest, deleteABTest } = useFeatheryRedux();
  const panelsData = useAppSelector((state) => state.panels.panels);

  const [error, setError] = useState(false);
  const [formData, setFormData] = useState<abTestFormData>({
    key: '',
    variant_a: '',
    variant_b: '',
    winner: 'active',
    live_panel: ''
  });

  useEffect(() => {
    if (!isOpen) return;

    setError(false);
    if (mode === 'edit' && abTest) {
      setFormData({
        key: abTest.key,
        variant_a: abTest.variant_a.id,
        variant_b: abTest.variant_b.id,
        winner: abTest.winner,
        live_panel: abTest.live_panel
      });
    }
  }, [isOpen, mode, abTest]);

  const handleChange = (key: any, val: any) => {
    setFormData((formData) => ({
      ...formData,
      [key]: val
    }));
  };

  const formatData = () => {
    const { key, variant_a: variantA, variant_b: variantB, winner } = formData;
    if (key.trim().length < 1) {
      return [false, 'You need to enter a key'];
    } else if (!variantA || !variantB) {
      return [
        false,
        'You need to select two forms to be variants for this test'
      ];
    }

    return [
      true,
      {
        ...formData,
        ...(winner === 'active' ? {} : { winner })
      }
    ];
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const validity = formatData();
    if (!validity[0]) {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'string | boolean | { winner: any... Remove this comment to see the full error message
      setError(validity[1]);
      return;
    }
    try {
      const data = validity[1];
      if (mode === 'edit') (data as any).abTestId = abTest.id;
      await (mode === 'create' ? createABTest(data) : editABTest(data));
      close();
    } catch (error) {
      setError((error as any).message);
    }
  };

  const handleDelete = async (e: any) => {
    e.preventDefault();
    try {
      await deleteABTest({ abTestId: abTest.id });
      close();
    } catch (error) {
      setError((error as any).message);
    }
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={close}
      title={mode === 'create' ? 'Create an A/B Test' : 'Edit A/B Test'}
      size='sm'
    >
      {mode === 'edit' && !abTest ? null : (
        <>
          <Label>Test ID</Label>
          <TextField
            value={formData.key}
            placeholder='my-test-id'
            onComplete={(id: any) => handleChange('key', id)}
            className={styles.input}
          />
          <Label>Form Variant A</Label>
          <DropdownField
            className={styles.input}
            disabled={mode === 'edit'}
            onChange={(event: any) =>
              handleChange('variant_a', event.target.value)
            }
            selected={formData.variant_a}
            options={[
              { value: '', display: 'Select Form' },
              ...Object.values(panelsData).map(({ id, key }) => ({
                value: id,
                display: key
              }))
            ]}
          />
          <Label>Form Variant B</Label>
          <DropdownField
            className={styles.input}
            disabled={mode === 'edit'}
            onChange={(event: any) =>
              handleChange('variant_b', event.target.value)
            }
            selected={formData.variant_b}
            options={[
              { value: '', display: 'Select Form' },
              ...Object.values(panelsData).map(({ id, key }) => ({
                value: id,
                display: key
              }))
            ]}
          />
          {mode === 'edit' && (
            <>
              <Label>
                {abTest.winner === 'active'
                  ? 'Pick Winner and End Test'
                  : 'Winner ( Not editable )'}
              </Label>
              <DropdownField
                className={styles.input}
                disabled={abTest.winner !== 'active'}
                onChange={(event: any) =>
                  handleChange('winner', event.target.value)
                }
                selected={formData.winner}
                options={[
                  { value: 'active', display: 'Still Running' },
                  { value: 'variant_a', display: 'Variant A' },
                  { value: 'variant_b', display: 'Variant B' }
                ]}
              />
              {formData.winner !== 'active' && (
                <>
                  <Label>
                    <>
                      Live Form
                      <InlineTooltip
                        text='The winning form will inherit the live form URL. If neither is specified, no links will be updated.'
                        style={{ display: 'inline' }}
                      />
                    </>
                  </Label>
                  <DropdownField
                    className={styles.input}
                    disabled={abTest.winner !== 'active'}
                    onChange={(event: any) =>
                      handleChange('live_panel', event.target.value)
                    }
                    selected={formData.live_panel}
                    options={[
                      {
                        value: '',
                        display: 'Neither'
                      },
                      {
                        value: 'variant_a',
                        display: abTest?.variant_a.key
                      },
                      {
                        value: 'variant_b',
                        display: abTest?.variant_b.key
                      }
                    ]}
                  />
                </>
              )}
            </>
          )}
          <div className='dialog-form-action text-center'>
            {mode === 'edit' && (
              <Negative onClick={handleDelete}>Delete Test</Negative>
            )}
            <Positive onClick={handleSubmit}>
              {mode === 'create' ? 'Create' : 'Save'}
            </Positive>
          </div>
          {error && <div>{error}</div>}
        </>
      )}
    </Dialog>
  );
}
