import { useEffect, useMemo, useState } from 'react';
import { NavBar } from '../components/NavBar';
import { PageProps } from './types';
import { JSONEditor } from '../../../JSONEditor';
import { DEFAULT_STATE } from '../../../../pages/FormLogicDetailPage/LogicRuleDetail/components/RuleBuilder/components/RuleAction/ConnectToAPIAction/constants';
import RadioInput from '../../../Core/RadioInput';
import styles from './styles.module.scss';
import {
  CONFIGURE_REQUEST_PAGE,
  MAP_RESPONSE_DATA_PAGE,
  TEST_REQUEST_PAGE
} from '../constants';
import { searchForFields } from '../utils';
import {
  KeyValueInput,
  ValueComponentProps
} from '../components/KeyValueInput';
import useField from '../../../../utils/useField';
import { FieldValueInput } from '../../../../pages/FormLogicDetailPage/LogicRuleDetail/components/RuleBuilder/components/RuleAction/SetFieldAction/components/FieldValue';
import { useSyncedRefState } from '../../../../hooks/useSyncedRefState';
import { INTEGRATIONS_TOKEN_DICT } from './utils';

function removeAppTokens(obj: any) {
  return Object.keys(obj).reduce((newObj: any, key) => {
    if (!INTEGRATIONS_TOKEN_DICT[key]) {
      newObj[key] = obj[key];
    }
    return newObj;
  }, {});
}

const validateState = (state: any, options: any) => {
  const { targets = Object.keys(DEFAULT_STATE), setErrors = null } = options;
  const errors: { [key: string]: string } = {};

  // Reset errors of targets
  if (setErrors) {
    setErrors((s: any) => {
      return {
        ...s,
        ...targets.reduce((acc: any, key: string) => {
          acc[key] = '';
          return acc;
        }, {})
      };
    });
  }

  if (targets.includes('responseStructure')) {
    if (!state.responseStructure) {
      errors['responseStructure'] = 'Please enter a response body.';
    }

    const validJSONString = state.responseStructure.replace(
      /(\r\n|\n|\r)/gm,
      ''
    );

    try {
      JSON.parse(validJSONString);
    } catch (e) {
      errors['responseStructure'] = 'The response body is not valid JSON.';
    }
  }

  if (Object.keys(errors).length > 0) {
    if (setErrors) {
      setErrors((s: any) => {
        return {
          ...s,
          ...errors
        };
      });
    }

    return false;
  }

  return true;
};

export const MapResponseStructurePage = (props: PageProps) => {
  const {
    state,
    onSubmit = () => {},
    onBack = () => {},
    onChange = () => {},
    goto = () => {},
    setState = () => {},
    onComplete = () => {}
  } = props;

  const [, setErrors] = useState<any>({});
  const [mode, setMode] = useState(state.responseStructure ? 'manual' : 'auto');
  const getTestParameters = useSyncedRefState(state.testParameters);
  const getField = useField(true);

  const hasFields = useMemo(() => {
    const urlFields = searchForFields(state.url);
    const headerFields = searchForFields(JSON.stringify(state.headers));
    const paramFields = searchForFields(JSON.stringify(state.params));
    const bodyFields = searchForFields(state.body);

    return (
      [...urlFields, ...headerFields, ...paramFields, ...bodyFields].length > 0
    );
  }, [state.url, state.headers, state.params, state.body]);

  useEffect(() => {
    const currentParameters = getTestParameters();
    const urlFields = searchForFields(state.url);
    const headerFields = searchForFields(JSON.stringify(state.headers));
    const paramFields = searchForFields(JSON.stringify(state.params));
    const bodyFields = searchForFields(state.body);

    const allFieldValues = [
      ...urlFields,
      ...headerFields,
      ...paramFields,
      ...bodyFields
    ].reduce(
      (acc, key) => ({
        ...acc,
        [key]: ''
      }),
      {}
    );

    const newParameters = Object.entries({
      ...allFieldValues,
      ...currentParameters
    }).reduce((acc, [key, value]) => {
      if (key in allFieldValues) {
        return {
          ...acc,
          [key]: value
        };
      }

      return acc;
    }, {});

    onChange('testParameters', newParameters);
  }, [state.url, state.headers, state.params, state.body]);

  const FieldValueComponent = useMemo(() => {
    const _FieldValueComponent = (props: ValueComponentProps) => {
      const { keyValue } = props;
      const field = getField(keyValue as string);

      return <FieldValueInput {...props} field={field} />;
    };

    return _FieldValueComponent;
  }, [getField]);

  const handleSubmit = () => {
    if (mode === 'manual' && validateState(state, { setErrors })) {
      onSubmit(MAP_RESPONSE_DATA_PAGE);
    } else {
      goto(TEST_REQUEST_PAGE);
    }
  };

  const handleSkip = () => {
    setState({
      responseStructure: '{}',
      responseMapping: {}
    });

    onComplete();
  };

  return (
    <div className={styles.page}>
      <div className={styles.content}>
        <div className={styles.field}>
          <RadioInput
            selected={mode}
            onChange={(val: string) => setMode(val)}
            options={[
              {
                value: 'auto',
                display: 'Test request and pull sample response'
              },
              { value: 'manual', display: 'Manually build response structure' }
            ]}
          />
        </div>
        {mode === 'auto' && hasFields && (
          <div className={styles.field}>
            <div className={styles.label}>Field values</div>
            <KeyValueInput
              value={removeAppTokens(state.testParameters)}
              onChange={(fieldValues: any) =>
                onChange('testParameters', fieldValues)
              }
              ValueComponent={FieldValueComponent}
              editableKeys={false}
              hideAddButton
              hideDeleteButton
            />
          </div>
        )}
        {mode === 'manual' && (
          <div className={styles.field}>
            <div className={styles.label}>Sample response body</div>
            <JSONEditor
              height='300px'
              code={state.responseStructure}
              placeholder='// Enter a sample JSON response from your API'
              onChange={(code?: string) => {
                onChange('responseStructure', code || '');
                validateState(
                  { ...state, responseStructure: code || '' },
                  { targets: ['responseStructure'], setErrors }
                );
              }}
            />
          </div>
        )}
      </div>
      <NavBar
        next
        back
        secondary
        text={{
          secondary: 'Skip'
        }}
        onClick={(btn: string) => {
          if (btn === 'next') handleSubmit();
          else if (btn === 'back') onBack(CONFIGURE_REQUEST_PAGE);
          else if (btn === 'secondary') handleSkip();
        }}
      />
    </div>
  );
};
