import { useParams } from 'react-router-dom';
import FormBuilderPage from '../FormBuilderPage';
import FormFlowPage from '../FormFlowPage';
import FormDesignerUtils from './FormDesignerUtils';
import FormLogicPage from '../FormLogicPage';
import FormLogicDetailPage from '../FormLogicDetailPage';
import { UUID_REGEX } from '../../components/RenderingEngine/utils';

const FormDesigner = ({ mode = 'builder' }) => {
  const { stepId, ruleId } = useParams<{ stepId: string; ruleId: string }>();

  let componentPage;
  if (mode === 'logic') {
    if (new RegExp(UUID_REGEX).test(ruleId)) {
      componentPage = <FormLogicDetailPage />;
    } else {
      componentPage = <FormLogicPage />;
    }
  } else {
    componentPage = <FormBuilderPage />;
    if (stepId === 'flow') {
      mode = 'flow';
      componentPage = <FormFlowPage />;
    }
  }

  return (
    <>
      <FormDesignerUtils mode={mode} />
      {componentPage}
    </>
  );
};

export default FormDesigner;
