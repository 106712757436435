import {
  DropdownMultiField,
  InlineTooltip,
  TextField
} from '../../../components/Core';
import Label from '../../../components/Dialog/Label';
import styles from '../styles.module.scss';
import '../../../style/dialog-form.css';
import VariantGroup from './VariantGroup';

export default function VariantProperties({
  extractionId = null,
  formData,
  handleChange,
  fileOptions,
  hiddenFieldOptions,
  usedFields,
  fieldProperties,
  hiddenFieldProperties,
  variant
}: any) {
  const updateQuestionGroupFields = (
    groupId: string,
    entityIndex: number,
    newFieldId: string | null
  ) => {
    // Make a copy of current group_variants
    const updatedGroupVariants = [...(variant.group_variants || [])];

    // Find if there's already an variant for this question group
    const existingVariantIndex = updatedGroupVariants.findIndex(
      (go) => go.question_group_id === groupId
    );

    if (existingVariantIndex === -1) {
      // If no variant exists for this group, create a new one
      const matchingGroup = formData.question_groups.find(
        (g: any) => g.id === groupId
      );
      if (matchingGroup) {
        // Create new field_ids array with nulls except for the updated field
        const newFieldIds = Array(matchingGroup.entities.length).fill(null);
        newFieldIds[entityIndex] = newFieldId;

        // Add new group variant
        updatedGroupVariants.push({
          id: crypto.randomUUID(), // Or use uuidv4() if available
          question_group_id: groupId,
          field_ids: newFieldIds
        });
      }
    } else {
      // If variant exists, update the specific field
      const existingVariant = updatedGroupVariants[existingVariantIndex];
      const newFieldIds = [...existingVariant.field_ids];

      // Ensure array is long enough
      while (newFieldIds.length <= entityIndex) {
        newFieldIds.push(null);
      }

      // Update the specific field
      newFieldIds[entityIndex] = newFieldId;

      // Update the group variant
      updatedGroupVariants[existingVariantIndex] = {
        ...existingVariant,
        field_ids: newFieldIds
      };
    }

    // Return just the group_variants property to be updated
    handleChange({ group_variants: updatedGroupVariants });
  };

  return (
    <>
      <Label>Name</Label>
      <TextField
        value={variant.key}
        placeholder='Variant'
        onComplete={(newKey: string) => handleChange({ key: newKey })}
        className={styles.modalInput}
        required
      />
      <div className={styles.labelContainer}>
        <Label>Uploaded File Sources</Label>
        <InlineTooltip text='The extraction will run on files stored in these fields' />
      </div>
      <DropdownMultiField
        selected={variant.file_sources}
        options={[...fileOptions, ...hiddenFieldOptions].filter(
          (option) =>
            formData.file_sources.includes(option.value) ||
            !usedFields.includes(option.value)
        )}
        onChange={(items: { value: string }[]) =>
          handleChange({ file_sources: items.map((item) => item.value) })
        }
        className={styles.modalInput}
        required
      />
      {formData.question_groups.map((group: any, index: number) => {
        return (
          <VariantGroup
            key={group.id}
            extractionId={extractionId}
            index={index}
            group={group}
            fieldProperties={fieldProperties}
            hiddenFieldProperties={hiddenFieldProperties}
            file_type={formData.file_type}
            onUpdate={(entityIndex, newFieldId) =>
              updateQuestionGroupFields(group.id, entityIndex, newFieldId)
            }
            variant_group={variant.group_variants?.find(
              (i: any) => i.question_group_id === group.id
            )}
          />
        );
      })}
    </>
  );
}
