import type { RuleBuilderState, RuleBuilderUpdater } from './types';
import { memo, useEffect, useMemo, useReducer } from 'react';
import { useContextSelector } from 'use-context-selector';
import { reducer } from './reducer';
import { StateContext, UpdaterContext, initialState } from './state';
import { connect } from './utils';
import { actions } from './actions';
import { RuleEngine } from './RuleEngine';
import { LogicRule } from '../../../../../FormLogicPage/LogicRuleList';
import { isEmptyRule } from '../utils';
import useFeatheryRedux from '../../../../../../redux';
import { UNDO_TYPES } from '../../../../../../utils/constants';
import { useParams } from 'react-router-dom';

export function useRuleBuilderState<T>(fn: (value: RuleBuilderState) => T): T {
  return useContextSelector(StateContext, fn);
}

export function useRuleBuilderUpdater<T>(
  fn: (value: RuleBuilderUpdater) => T
): T {
  return useContextSelector(UpdaterContext, fn);
}

type RuleProviderProps = React.PropsWithChildren<{
  rule: LogicRule;
  dsl: IRuleDSL;
  mode?: RuleBuilderMode;
}>;

const RuleProvider = ({
  rule,
  dsl,
  mode = 'view',
  children
}: RuleProviderProps) => {
  const { formId } = useParams<{ formId: string }>();

  const {
    formBuilder: { setRuleBuilderDSL, filterUndoRedoStacksByType }
  } = useFeatheryRedux();

  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    dsl,
    prevDSL: dsl,
    originalDSL: dsl,
    isNew: isEmptyRule(rule)
  });

  const connectFns = useMemo(() => connect(dispatch), []);

  const stateContextValue: RuleBuilderState = useMemo(
    () => ({ ...state }),
    [state]
  );

  const updaterContextValue: RuleBuilderUpdater = useMemo(
    () => connectFns(actions) as RuleBuilderUpdater,
    [connectFns]
  );

  // Sync the Redux store with the DSL
  useEffect(() => {
    if (!formId) return;

    setRuleBuilderDSL(dsl);
    return () => {
      // Filter the Undo/Redo stacks to remove the Rule Builder changes before unmounting
      filterUndoRedoStacksByType(UNDO_TYPES.RULE_BUILDER);
      setRuleBuilderDSL(null);
    };
  }, []);

  return (
    <StateContext.Provider value={stateContextValue}>
      <UpdaterContext.Provider value={updaterContextValue}>
        <RuleEngine rule={rule} dsl={dsl} mode={mode} />
        {children}
      </UpdaterContext.Provider>
    </StateContext.Provider>
  );
};

export default memo(RuleProvider);
