/* eslint-disable react-hooks/exhaustive-deps */

import { memo, useEffect, useMemo, useState } from 'react';

import HeaderFilterResultsTable from '../../components/HeaderFilterResultsTable';
import styles from './styles.module.scss';
import pageStyles from '../styles.module.scss';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../hooks';
import { capitalizeFirstLetter, formatDate } from '../../utils/format';
import useFeatheryRedux from '../../redux';
import DocumentNavigation from '../../components/NavBar/DocumentNavigation';
import { DeleteEnvelopeModal } from '../../components/Modals';
import { renderFile } from '../FormResultsDetailPage/utils';
import useGetJwt from '../../utils/useGetJwt';
import { parseAndThrowError } from '../../redux/utils';
import { downloadFile } from '../../utils/browserUtils';
import DocumentAutofillButton from './DocumentAutofillButton';
import { getSignUrl } from '../../utils/documents';

function DocumentResultsPage() {
  const { documentId } = useParams<{ documentId: string }>();
  const doc = useAppSelector((state) => state.documents.documents[documentId]);
  const docIsPDF = doc?.type === 'pdf';
  const docHasDisplayFile = doc.display_file != null;

  const { getEnvelopes } = useFeatheryRedux();

  useEffect(() => {
    getEnvelopes({ documentId });
  }, [documentId]);

  const [envelopeToDelete, setEnvelopeToDelete] = useState<any>(null);
  const envelopes = useAppSelector((state) => state.documents.envelopes);
  const [dataToRender, hasSigner] = useMemo(() => {
    let hasSigner = false;
    const data = Object.values(envelopes);
    const toRender = data.map((d: any) => {
      if (!hasSigner) hasSigner = !!d.signer;

      const signable = d.signer && ['pdf', 'docx'].includes(d.type);
      let status = 'Sent';
      if (d.signed || !signable) {
        status = 'Completed';
      } else if (d.expired) {
        status = 'Expired';
      } else if (d.viewed) {
        status = 'Viewed';
      }

      let document;
      if (signable && ['Sent', 'Viewed'].includes(status)) {
        document = (
          <a href={getSignUrl(d)} target='_blank' rel='noreferrer'>
            Sign Here
          </a>
        );
      } else if (d.file) {
        document = renderFile(['View File'], [d.file]);
      } else {
        // if the document is signed but file is null,
        // there must have been a backend error somewhere
        document = <span className={styles.missingDocument}>No document</span>;
      }

      return {
        ...d,
        updated_at_pretty: formatDate(d.updated_at),
        invite_method: capitalizeFirstLetter(d.invite_method),
        status,
        document
      };
    });
    return [toRender, hasSigner];
  }, [envelopes]);

  const getJwt = useGetJwt();
  const {
    toasts: { addErrorToast }
  } = useFeatheryRedux();
  const downloadLog = async (envelopeId: string) => {
    const token = getJwt();
    await FeatheryAPI.downloadEnvelopeLog(token, envelopeId)
      .then(async (res: any) => {
        if (res.status === 200) {
          const blob = await res.blob();
          downloadFile('signature_log.pdf', blob);
        } else {
          const result = await res.json();
          parseAndThrowError(result, 'Your download could not be started');
        }
      })
      .catch((e: any) => {
        addErrorToast({ text: e.toString() });
      });
  };

  return (
    <>
      <DocumentNavigation
        activeItem='envelopes'
        submitButton={
          !docIsPDF && !docHasDisplayFile ? (
            <DocumentAutofillButton />
          ) : undefined
        }
      />
      <div className={styles.documentResultsPage}>
        <div className={pageStyles.pageHeadRow}>
          <h1 className='page-head'>Envelopes</h1>
        </div>
        <HeaderFilterResultsTable
          data={dataToRender}
          columns={getDocumentColumns(hasSigner)}
          defaultSort={{ order: -1, key: 'updated_at' }}
          useSearch
          name='Envelope'
          onDownload={(doc: any) => downloadLog(doc.id)}
          downloadName='Audit Log'
          onDelete={(doc: any) => setEnvelopeToDelete(doc)}
        />
      </div>
      <DeleteEnvelopeModal
        close={() => setEnvelopeToDelete(null)}
        show={Boolean(envelopeToDelete)}
        envelopeId={envelopeToDelete?.id}
        signer={envelopeToDelete?.signer}
      />
    </>
  );
}

function getDocumentColumns(hasSigner: boolean) {
  const columns = [
    { key: 'status', name: 'Status' },
    { key: 'document', name: 'Document' },
    {
      key: 'updated_at_pretty',
      name: 'Last Updated',
      sortBy: 'updated_at'
    },
    { key: 'id', name: 'ID' }
  ];
  if (hasSigner) {
    columns.unshift({ key: 'signer', name: 'Signer' });
  }
  return columns;
}

export default memo(DocumentResultsPage);
