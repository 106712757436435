import { InlineTooltip } from '../../../components/Core';
import type { FieldProperties } from '../ExtractionCreateModal/types';
import Label from '../../../components/Dialog/Label';
import classNames from 'classnames';
import styles from './styles.module.scss';
import useIntegrations from '../../../components/FormIntegrations/useIntegrations';
import { AI_INTEGRATION_TYPES } from '../../../components/AIIntegrations/types';
import { DocumentQuestionGroup } from './DocumentQuestionGroup';
import { CSVQuestionGroup } from './CSVQuestionGroup';

type Label = {
  label: string;
  value: string;
};

type Props = {
  extractionId?: string;
  index: number;
  group: any;
  fieldProperties: Record<string, FieldProperties>;
  hiddenFieldProperties: Record<string, Partial<FieldProperties>>;
  file_type: string;
  onUpdate: (entityIndex: number, newFieldId: string | null) => void;
  variant_group?: null | {
    question_group_id: string;
    field_ids: string[];
  };
};

function VariantGroup(props: Props) {
  const {
    extractionId,
    index,
    group,
    fieldProperties,
    hiddenFieldProperties,
    file_type,
    onUpdate,
    variant_group
  } = props;

  const integrations = useIntegrations({
    all: true,
    extractionId,
    entityType: 'ai'
  });

  const isEmailIntegActive = !!integrations[AI_INTEGRATION_TYPES.EMAIL_INBOX];
  const label = group.entities.map((entity: any) => entity.name).join(', ');

  return (
    <div
      key={`question-group-${index + 1}`}
      className={classNames(
        styles.questionGroupSection,
        styles.card,
        styles.white
      )}
    >
      <QuestionGroupHeader label={label} index={index} disabled />
      {file_type === 'image' ? (
        <DocumentQuestionGroup
          group={group}
          onUpdate={onUpdate}
          isEmailIntegActive={isEmailIntegActive}
          index={index}
          fieldProperties={fieldProperties}
          hiddenFieldProperties={hiddenFieldProperties}
          display_only
          variant_group={variant_group}
        />
      ) : (
        <CSVQuestionGroup
          group={group}
          onUpdate={onUpdate}
          index={index}
          fieldProperties={fieldProperties}
          hiddenFieldProperties={hiddenFieldProperties}
          display_only
          variant_group={variant_group}
        />
      )}
    </div>
  );
}

export default VariantGroup;

function QuestionGroupHeader({ label, index }: any) {
  return (
    <div className={styles.header}>
      <div className={styles.labelContainer}>
        <Label className={styles.label}>{label || `Query ${index + 1}`}</Label>
        <InlineTooltip text='The query will be used by AI to find answer(s) from each document, and the answer(s) will be saved into the field(s)' />
      </div>
    </div>
  );
}
