import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import {
  AddeparSettingsSidebar,
  ALL_INTEGRATIONS_MAP,
  AlloySettingsSidebar,
  APIKeySidebar,
  ArgyleSettingsSidebar,
  BoxSettingsSidebar,
  EgnyteSettingsSidebar,
  EmailSettingsSidebar,
  EMoneySettingsSidebar,
  FirebaseSettingsSidebar,
  FlinksSettingsSidebar,
  HubspotSettingsSidebar,
  INTEGRATION_TAGS,
  IntegrationCard,
  INTEGRATIONS,
  OrionSettingsSidebar,
  PlaidSettingsSidebar,
  PostgresSettingsSidebar,
  PREMIUM_INTEGRATIONS,
  QuikSettingsSidebar,
  RedtailSettingsSidebar,
  SchwabSettingsSidebar,
  SFTPSettingsSidebar,
  SlackSettingsSidebar,
  STANDARD_INTEGRATIONS,
  StripeSettingsSidebar,
  StytchSettingsSidebar,
  TelesignSettingsSidebar,
  TrustedFormSettingsSidebar,
  WebhookSettingsSidebar,
  ZapierSettingsSidebar
} from '../components/FormIntegrations';
import useFilterTag from '../components/FormIntegrations/components/useFilterTag';

import FormNavigation from '../components/NavBar/FormNavigation';

import styles from './styles.module.scss';
import useIntegrations from '../components/FormIntegrations/useIntegrations';
import {
  PREMIUM_INTEGRATIONS_ORDER,
  STANDARD_INTEGRATION_ORDER
} from '../components/FormIntegrations/types';
import SegmentSettingsSidebar from '../components/FormIntegrations/SegmentSettingsSidebar';
import RolloutSidebar from '../components/FormIntegrations/RolloutSidebar';
import { SearchIcon } from '../components/Icons';
import classNames from 'classnames';
import GoogleTagManagerSettingsSidebar from '../components/FormIntegrations/GoogleTagManagerSettingsSidebar';
import DisabledIntegrationSidebar from '../components/FormIntegrations/DisabledIntegrationSidebar';
import RudderStackSettingsSidebar from '../components/FormIntegrations/RudderStackSettingsSidebar';
import AmplitudeSettingsSidebar from '../components/FormIntegrations/AmplitudeSettingsSidebar';
import SMSOTPSettingsSidebar from '../components/FormIntegrations/SMSOTPSettingsSidebar';
import CalendlySettingsSidebar from '../components/FormIntegrations/CalendlySettingsSidebar';
import PersonaSettingsSidebar from '../components/FormIntegrations/PersonaSettingsSidebar';
import SMSMessageSettingsSidebar from '../components/FormIntegrations/SMSMessageSettingsSidebar';
import { useAppSelector } from '../hooks';
import PowerAutomateSidebar from '../components/FormIntegrations/PowerAutomateSidebar';
import { ROLLOUT_APPS } from '../components/FormIntegrations/RolloutSidebar/components/constants';
import EmailOTPSettingsSidebar from '../components/FormIntegrations/EmailOTPSettingsSidebar';

function FormIntegrationsPage() {
  const { formId } = useParams<{ formId: string }>();

  const [searchStr, setSearchStr] = useState('');
  const [visibleSidebar, setVisibleSidebar] = useState('');

  const org = useAppSelector((state) => state.accounts.organization);

  const { filterTag, filterPills } = useFilterTag(
    [
      ...STANDARD_INTEGRATIONS,
      ...(org?.whitelabel ? [] : PREMIUM_INTEGRATIONS)
    ],
    Object.values(INTEGRATION_TAGS)
  );

  const integrations = useIntegrations({ all: true, panelId: formId });

  // Supporting passing a url param to preselect an integration/sidebar.  Needed for Stytch over quota CTA.
  const { search } = useLocation();
  useEffect(() => {
    const sidebar = new URLSearchParams(search).get('sidebar');
    sidebar && setVisibleSidebar(sidebar);
  }, [search]);

  let sidebarContent;
  if (visibleSidebar in ROLLOUT_APPS) {
    sidebarContent = (
      <RolloutSidebar
        key={visibleSidebar}
        integrationType={visibleSidebar}
        rolloutApp={ROLLOUT_APPS[visibleSidebar]}
      />
    );
  } else {
    switch (visibleSidebar) {
      case INTEGRATIONS.EXPERIAN:
      case INTEGRATIONS.EQUIFAX:
      case INTEGRATIONS.ID_VERIFICATION:
      case INTEGRATIONS.SAP_CONCUR:
      case INTEGRATIONS.MORNINGSTAR:
      case INTEGRATIONS.MONEYGUIDE:
      case INTEGRATIONS.TAMARAC:
      case INTEGRATIONS.WEALTHBOX:
      case INTEGRATIONS.MULESOFT:
      case INTEGRATIONS.LEXISNEXIS:
      case INTEGRATIONS.FIDELITY:
      case INTEGRATIONS.PERSHING:
      case INTEGRATIONS.VANGUARD:
      case INTEGRATIONS.INVERITE:
      case INTEGRATIONS.APPLIED_EPIC:
      case INTEGRATIONS.GUIDEWIRE:
      case INTEGRATIONS.SAGITTA:
      case INTEGRATIONS.AMS360:
      case INTEGRATIONS.EZLYNX:
      case INTEGRATIONS.HAWKSOFT:
      case INTEGRATIONS.QQ_CATALYST:
      case INTEGRATIONS.NOVIDEA:
      case INTEGRATIONS.PL_RATING:
      case INTEGRATIONS.QUOTE_RUSH:
      case INTEGRATIONS.TURBORATER:
      case INTEGRATIONS.IBQ_RATER:
      case INTEGRATIONS.TARMIKA:
      case INTEGRATIONS.AGENCY_ZOOM:
      case INTEGRATIONS.ZYWAVE:
      case INTEGRATIONS.FISERV:
      case INTEGRATIONS.LOANPRO:
      case INTEGRATIONS.TEMENOS:
      case INTEGRATIONS.OSCILAR:
      case INTEGRATIONS.VERISK:
        sidebarContent = (
          <DisabledIntegrationSidebar integrationType={visibleSidebar} />
        );
        break;
      case INTEGRATIONS.EMONEY:
        sidebarContent = <EMoneySettingsSidebar />;
        break;
      case INTEGRATIONS.BOX:
        sidebarContent = <BoxSettingsSidebar />;
        break;
      case INTEGRATIONS.SCHWAB:
        sidebarContent = <SchwabSettingsSidebar />;
        break;
      case INTEGRATIONS.POWER_AUTOMATE:
        sidebarContent = <PowerAutomateSidebar />;
        break;
      case INTEGRATIONS.EGNYTE:
        sidebarContent = <EgnyteSettingsSidebar />;
        break;
      case INTEGRATIONS.SFTP:
        sidebarContent = <SFTPSettingsSidebar />;
        break;
      case INTEGRATIONS.FIREBASE:
        sidebarContent = <FirebaseSettingsSidebar />;
        break;
      case INTEGRATIONS.HUBSPOT_LEGACY:
        sidebarContent = <HubspotSettingsSidebar />;
        break;
      case INTEGRATIONS.SMS_MESSAGE:
        sidebarContent = <SMSMessageSettingsSidebar />;
        break;
      case INTEGRATIONS.SMS_OTP:
        sidebarContent = <SMSOTPSettingsSidebar />;
        break;
      case INTEGRATIONS.EMAIL_OTP:
        sidebarContent = <EmailOTPSettingsSidebar />;
        break;
      case INTEGRATIONS.STYTCH:
        sidebarContent = <StytchSettingsSidebar />;
        break;
      case INTEGRATIONS.STRIPE:
        sidebarContent = <StripeSettingsSidebar />;
        break;
      case INTEGRATIONS.TELESIGN:
        sidebarContent = <TelesignSettingsSidebar />;
        break;
      case INTEGRATIONS.ZAPIER:
        sidebarContent = <ZapierSettingsSidebar />;
        break;
      case INTEGRATIONS.GOOGLE_ANALYTICS:
        sidebarContent = (
          <APIKeySidebar
            key={INTEGRATIONS.GOOGLE_ANALYTICS}
            integrationInfo={
              ALL_INTEGRATIONS_MAP[INTEGRATIONS.GOOGLE_ANALYTICS]
            }
            label='Measurement ID*'
            placeholder='G-XXXXXXXXXX'
          />
        );
        break;
      case INTEGRATIONS.INTERCOM_EMBEDDED:
        sidebarContent = (
          <APIKeySidebar
            key={INTEGRATIONS.INTERCOM_EMBEDDED}
            integrationInfo={
              ALL_INTEGRATIONS_MAP[INTEGRATIONS.INTERCOM_EMBEDDED]
            }
            label='Workspace ID*'
            placeholder='XXXXXXXX'
          />
        );
        break;
      case INTEGRATIONS.HEAP:
        sidebarContent = (
          <APIKeySidebar
            key={INTEGRATIONS.HEAP}
            integrationInfo={ALL_INTEGRATIONS_MAP[INTEGRATIONS.HEAP]}
            label='App ID*'
          />
        );
        break;
      case INTEGRATIONS.AMPLITUDE:
        sidebarContent = <AmplitudeSettingsSidebar />;
        break;
      case INTEGRATIONS.MIXPANEL:
        sidebarContent = (
          <APIKeySidebar
            key={INTEGRATIONS.MIXPANEL}
            integrationInfo={ALL_INTEGRATIONS_MAP[INTEGRATIONS.MIXPANEL]}
            label='Project Token'
          />
        );
        break;
      case INTEGRATIONS.CALENDLY:
        sidebarContent = <CalendlySettingsSidebar />;
        break;
      case INTEGRATIONS.RUDDERSTACK:
        sidebarContent = <RudderStackSettingsSidebar />;
        break;
      case INTEGRATIONS.GOOGLE_TAG_MANAGER:
        sidebarContent = <GoogleTagManagerSettingsSidebar />;
        break;
      case INTEGRATIONS.SEGMENT:
        sidebarContent = <SegmentSettingsSidebar />;
        break;
      case INTEGRATIONS.TRUSTEDFORM:
        sidebarContent = <TrustedFormSettingsSidebar />;
        break;
      case INTEGRATIONS.PLAID:
        sidebarContent = <PlaidSettingsSidebar />;
        break;
      case INTEGRATIONS.PERSONA:
        sidebarContent = <PersonaSettingsSidebar />;
        break;
      case INTEGRATIONS.ARGYLE:
        sidebarContent = <ArgyleSettingsSidebar />;
        break;
      case INTEGRATIONS.ALLOY:
        sidebarContent = <AlloySettingsSidebar />;
        break;
      case INTEGRATIONS.FLINKS:
        sidebarContent = <FlinksSettingsSidebar />;
        break;
      case INTEGRATIONS.SLACK_LEGACY:
        sidebarContent = <SlackSettingsSidebar />;
        break;
      case INTEGRATIONS.EMAIL:
        sidebarContent = <EmailSettingsSidebar />;
        break;
      case INTEGRATIONS.WEBHOOK:
        sidebarContent = <WebhookSettingsSidebar />;
        break;
      case INTEGRATIONS.POSTGRES:
        sidebarContent = <PostgresSettingsSidebar />;
        break;
      case INTEGRATIONS.QUIK:
        sidebarContent = <QuikSettingsSidebar />;
        break;
      case INTEGRATIONS.ORION:
        sidebarContent = <OrionSettingsSidebar />;
        break;
      case INTEGRATIONS.REDTAIL:
        sidebarContent = <RedtailSettingsSidebar />;
        break;
      case INTEGRATIONS.ADDEPAR:
        sidebarContent = <AddeparSettingsSidebar />;
        break;
      default:
        sidebarContent = null;
    }
  }

  const premiumToRender = org?.whitelabel
    ? []
    : renderIntegrations(
        PREMIUM_INTEGRATIONS.sort((a: any, b: any) => {
          const aInteg = integrations[a.key];
          const bInteg = integrations[b.key];
          if ((aInteg && bInteg) || (!aInteg && !bInteg)) {
            return PREMIUM_INTEGRATIONS_ORDER[a.key] >
              PREMIUM_INTEGRATIONS_ORDER[b.key]
              ? 1
              : -1;
          } else return aInteg ? -1 : 1;
        }),
        visibleSidebar,
        setVisibleSidebar,
        filterTag,
        searchStr,
        org
      );
  return (
    <>
      <FormNavigation activeItem='integrations' />
      <div className={styles.integrationsContainer}>
        <div className={styles.leftContainer}>
          <h1 className={classNames('page-head', styles.headerTitle)}>
            Integrations
          </h1>
          {filterPills}
        </div>
        <div className={styles.integrationsScrollContainer}>
          <div className={classNames('search-wrap', styles.searchAdjust)}>
            <SearchIcon className='search-icon' />
            <input
              type='text'
              className='inp list-view-search'
              value={searchStr}
              placeholder='Search'
              onChange={(e) => setSearchStr(e.target.value)}
            />
          </div>
          <div className={styles.integrationCardsContainer}>
            {renderIntegrations(
              STANDARD_INTEGRATIONS.filter(
                (integ: any) =>
                  // Only show legacy integrations for people who already have it active
                  ![
                    INTEGRATIONS.HUBSPOT_LEGACY,
                    INTEGRATIONS.SLACK_LEGACY,
                    INTEGRATIONS.PDF_LEGACY
                  ].includes(integ.key) || integrations[integ.key]
              ).sort((a: any, b: any) => {
                const aInteg = integrations[a.key];
                const bInteg = integrations[b.key];
                if ((aInteg && bInteg) || (!aInteg && !bInteg)) {
                  return STANDARD_INTEGRATION_ORDER[a.key] >
                    STANDARD_INTEGRATION_ORDER[b.key]
                    ? 1
                    : -1;
                } else return aInteg ? -1 : 1;
              }),
              visibleSidebar,
              setVisibleSidebar,
              filterTag,
              searchStr,
              org
            )}
            {premiumToRender.length > 0 && (
              <div className={styles.integrationsSubHeader}>
                <div className={styles.integrationsSubHeaderLabel}>
                  Premium Integrations
                </div>
                <span className={styles.integrationsHeaderSubtitle}>
                  <a
                    href='https://www.feathery.io/demo'
                    target='_blank'
                    rel='noreferrer'
                  >
                    Reach out to sales for live access
                  </a>
                </span>
              </div>
            )}
            {premiumToRender}
          </div>
        </div>
        {sidebarContent && (
          <div className={styles.sidebar}>
            <div className={styles.sidebarOverflow}>{sidebarContent}</div>
          </div>
        )}
      </div>
    </>
  );
}

const renderIntegrations = (
  integrations: typeof STANDARD_INTEGRATIONS,
  visibleSidebar: any,
  setVisibleSidebar: any,
  filterTag: string,
  searchStr: string,
  org: any
) => {
  return integrations
    .filter(
      (integ) =>
        (org.hipaaIntegrationWhitelist || !integ.hideForHIPAA) &&
        (filterTag === 'All' || integ.tags.includes(filterTag)) &&
        (!searchStr ||
          integ.title.toLowerCase().includes(searchStr.toLowerCase()))
    )
    .map((integ) => (
      // eslint-disable-next-line react/jsx-key
      <IntegrationCard
        integrationType={integ.key}
        isSelected={visibleSidebar === integ.key}
        onClick={() => setVisibleSidebar(integ.key)}
        {...integ}
      />
    ));
};

export default FormIntegrationsPage;
