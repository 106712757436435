import {
  DropdownMultiField,
  InlineTooltip,
  TextField
} from '../../../components/Core';
import SettingsContainer from '../../../components/SettingsContainer';
import { useAppSelector } from '../../../hooks';

const NotificationsSection = ({ title, formData, updateData }: any) => {
  const accounts = useAppSelector(
    (state) => state.accounts.organization?.team_accounts ?? []
  );

  const optionEmails = accounts.map((account: any) => account.email);
  formData.signature_alertees.forEach((alertee: string) => {
    if (!optionEmails.includes(alertee)) optionEmails.push(alertee);
  });

  return (
    <SettingsContainer title={title}>
      <div className='field-group'>
        <label>
          <p className='form-label'>
            Signature Notification Emails
            <InlineTooltip text='Emails to notify when documents to sign have been sent, viewed, or signed. This may also be a field wrapped in double curly braces (e.g. {{FieldID}}), which will automatically populate an email if one exists.' />
          </p>
          <DropdownMultiField
            placeholder='notify1@company.com'
            selected={formData.signature_alertees}
            options={optionEmails}
            creatable
            onChange={(items: { value: string }[]) =>
              updateData(
                'signature_alertees',
                items.map((item) => item.value)
              )
            }
          />
        </label>
      </div>
      <div className='field-group'>
        <label>
          <p className='form-label'>
            Custom Sender Email (Optional)
            <InlineTooltip text='Customize the email that signature notifications are sent from. The email can be pulled from a field value by specifying the field ID, e.g. {{FieldId}}. You MUST own the sender email.' />
          </p>
          <TextField
            type='email'
            placeholder='noreply@mail.feathery.io'
            value={formData.signature_alert_email}
            onComplete={(val: string) =>
              updateData('signature_alert_email', val)
            }
          />
        </label>
      </div>
      <div className='field-group'>
        <label>
          <p className='form-label'>
            Slack Integration
            <InlineTooltip text='Add a Slack incoming webhook to get notifications for signed documents and daily reports on outstanding signatures' />
          </p>
          <TextField
            type='url'
            placeholder='https://hooks.slack.com/services/...'
            value={formData.slack_alert_hook}
            onComplete={(val: string) => updateData('slack_alert_hook', val)}
          />
        </label>
      </div>
      <div className='field-group'>
        <label>
          <p className='form-label'>
            Signature Invite Email Customization
            <InlineTooltip text='Customize the email that is sent out to users who you invite to sign a document. You can include the following variables: {{TEAM_NAME}}, {{SENDER_EMAIL}}, {{DOCUMENT_NAME}}, {{INVITE_MESSAGE}}, {{ENVELOPE_LINK}}' />
          </p>
          <TextField
            type='textarea'
            placeholder='HTML or Text'
            value={formData.email_templates.invite_template ?? ''}
            onComplete={(val: string) =>
              updateData('email_templates', {
                ...formData.email_templates,
                invite_template: val
              })
            }
            style={{ height: '200px' }}
          />
        </label>
      </div>
      <div className='field-group'>
        <label>
          <p className='form-label'>
            Signature Completion Email Customization
            <InlineTooltip text='Customize the email that is sent out to users who have signed a document. You can include the following variables: {{TEAM_NAME}}, {{DOCUMENT_NAME}}' />
          </p>
          <TextField
            type='textarea'
            placeholder='HTML or Text'
            value={formData.email_templates.signed_template ?? ''}
            onComplete={(val: string) =>
              updateData('email_templates', {
                ...formData.email_templates,
                signed_template: val
              })
            }
            style={{ height: '200px' }}
          />
        </label>
      </div>
    </SettingsContainer>
  );
};

export default NotificationsSection;
