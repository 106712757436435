import { useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import UserResultsTable from '../../components/UserResultsTable';
import classNames from 'classnames';
import styles from './styles.module.scss';
import useFeatheryRedux from '../../redux';
import { LinkText } from '../../utils/domOperations';
import { AIExtractionRun } from '../AIExtractionRunPage/types';
import { capitalizeFirstLetter } from '../../utils/format';
import AINavigation from '../../components/NavBar/AINavigation';
import { Tooltip } from '../../components/Core/Tooltip/Tooltip';
import { useAppSelector } from '../../hooks';
import { imageExtensions } from '../../utils/documents';
import useGetJwt from '../../utils/useGetJwt';

type URLParams = {
  formId: string;
  extractionId: string;
};

export default function AIExtractionResultsPage() {
  const { extractionId } = useParams<URLParams>();
  const extraction = useAppSelector((state) =>
    state.ai.extractions.find((extraction) => extraction.id === extractionId)
  );

  const history = useHistory();
  const getJwt = useGetJwt();

  const [loadError, setLoadError] = useState('');
  const [refetch, setRefetch] = useState(false);

  const { getAIExtractionRuns, deleteAIExtractionRun } = useFeatheryRedux();

  const fileInput = useRef<any>(null);
  const activeEnv = useRef('');

  const handleFetchData = (payload: { [key: string]: any }) => {
    return getAIExtractionRuns({
      ...payload,
      extractionId
    });
  };

  const handleSelectResult = (run: any) => {
    if (run.success === null) return;
    history.push(`/ai/${extractionId}/results/${run.id}`);
  };

  const handleLoadError = (error: string) => {
    setLoadError(error);
  };

  const onFileChange = async (event: any) => {
    const file = event.target.files[0];
    if (!file) return;

    const token = getJwt();
    const primary = activeEnv.current === 'Production' ? 'true' : 'false';
    await FeatheryAPI.createAIExtractionRun(token, extractionId, file, primary);
    setTimeout(() => setRefetch(!refetch), 2500);
  };

  let allowedFileTypes: string[] = [];
  if (extraction.file_type === 'image')
    allowedFileTypes = [...imageExtensions, 'pdf', 'docx'];
  else if (extraction.file_type === 'csv')
    allowedFileTypes = ['csv', 'xlsx', 'xls', 'xlsm'];
  return (
    <>
      <AINavigation activeItem='results' error={loadError} />
      <div className={styles.aiExtractionsListPage}>
        <div className={styles.section}>
          <input
            ref={fileInput}
            type='file'
            onChange={onFileChange}
            required={false}
            accept={allowedFileTypes.join(',')}
            style={{ visibility: 'hidden' }}
          />
          <UserResultsTable
            extractionId={extractionId}
            onFetchData={handleFetchData}
            onSelectResult={handleSelectResult}
            onDelete={(run: AIExtractionRun) =>
              deleteAIExtractionRun({ runId: run.id })
            }
            onCreate={(env: string) => {
              activeEnv.current = env;
              fileInput.current?.click();
            }}
            refetch={refetch}
            onLoadError={handleLoadError}
            hideTitle
            hideUploadSubmissions
            hideEditTableView
            padding={false}
            resultColumns={[
              { key: 'id', name: 'Run ID' },
              {
                key: 'file_sources',
                name: 'Files',
                noSort: true,
                renderCell: (data) => {
                  if (
                    data.file_sources?.length &&
                    data.file_sources?.[0]?.url
                  ) {
                    return data.file_sources.map((source: any) => (
                      <LinkText
                        key={source.field_id}
                        link={source.url}
                        text={source.path.split('/').at(-1)}
                      />
                    ));
                  } else if (data.email_content) {
                    return `Email - ${data.email_content.subject}`;
                  } else {
                    return 'Unknown Source';
                  }
                }
              },
              {
                key: 'error_message',
                name: 'Status',
                renderCell: (data) => {
                  let status, message;
                  if (data.success === null) status = 'processing';
                  else if (data.is_user_error) {
                    status = 'invalid';
                    message = 'Invalid document';
                  } else if (!data.success) status = 'error';
                  else if (!data.approved) status = 'pending';
                  else {
                    status = 'approved';
                    if (
                      data.fetching_bounding_boxes &&
                      data.bounding_box_success === null
                    )
                      message = 'Fetching bounding boxes';
                    if (data.approver) message = `Approved - ${data.approver}`;
                  }
                  let component = (
                    <div className={classNames(styles.status, styles[status])}>
                      {message || capitalizeFirstLetter(status)}
                    </div>
                  );
                  if (['invalid', 'error'].includes(status)) {
                    const errMessage =
                      data.error_message ||
                      data.bounding_box_error_message ||
                      'An error occurred';
                    component = (
                      <Tooltip content={errMessage}>{component}</Tooltip>
                    );
                  }
                  return component;
                }
              },
              { key: 'updated_at', name: 'Last Updated' }
            ]}
          />
        </div>
      </div>
    </>
  );
}
