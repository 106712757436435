import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const getBillingMetrics = createAsyncThunk(
  'metrics/getBillingMetrics',
  async ({ token }: { token: string }) => {
    const response = await FeatheryAPI.getBillingMetrics(token);
    if (response.status === 200) {
      return await response.json();
    } else {
      throw new Error('Could not fetch billing metrics');
    }
  }
);

const getPanelMetrics: any = createAsyncThunk(
  'metrics/getPanelMetrics',
  async ({
    token,
    ...data
  }: {
    token: string;
    panelId: string;
    startTime: string;
    endTime: string;
  }) => {
    const response = await FeatheryAPI.getPanelMetrics(
      token,
      data.panelId,
      data.startTime,
      data.endTime
    );
    if (response.status === 200) {
      return await response.json();
    } else {
      throw new Error('Could not fetch form metrics');
    }
  }
);

const getPanelAPIConnectorErrorLogs: any = createAsyncThunk(
  'metrics/getPanelAPIConnectorErrorLogs',
  async ({ token, ...data }: { token: string; panelId: string }) => {
    const response = await FeatheryAPI.getPanelAPIConnectorErrorLogs(
      token,
      data.panelId
    );
    if (response.status === 200) {
      return await response.json();
    } else {
      throw new Error('Could not fetch form API connector error logs');
    }
  }
);

const submissionsThisMonth: Record<string, number> = {};

const metricsSlice = createSlice({
  name: 'metrics',
  initialState: {
    billingMetrics: {
      active_users_this_month: '',
      submissions_this_month: submissionsThisMonth
    },
    panels: {} as Record<string, any>
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getBillingMetrics.fulfilled, (state, action) => {
      state.billingMetrics = action.payload;
    });
    builder.addCase(getPanelMetrics.fulfilled, (state, action) => {
      state.panels[action.meta.arg.panelId] = action.payload;
    });
  }
});

export default metricsSlice.reducer;
export { getBillingMetrics, getPanelMetrics, getPanelAPIConnectorErrorLogs };
