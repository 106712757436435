import { useHistory, useParams } from 'react-router-dom';
import LogicRuleDetail from './LogicRuleDetail';
import { useAppSelector } from '../../hooks';
import useFeatheryRedux from '../../redux';
import { UNDO_TITLES, UNDO_TYPES } from '../../utils/constants';
import { objectPick, objectRemove } from '../../utils/core';
import { LogoLoader } from '../../components/Core';
import { LogicRule } from '../FormLogicPage/LogicRuleList';

export default function FormLogicDetailPage() {
  const { formId } = useParams<{ formId: string }>();
  const history = useHistory();

  const workingSteps = useAppSelector((s) => s.formBuilder.workingSteps);
  const workingLogicRules = useAppSelector(
    (s) => s.formBuilder.workingLogicRules
  );
  const workingLogicRulesInitialized = useAppSelector(
    (s) => s.formBuilder.workingLogicRulesInitialized
  );

  const {
    formBuilder: { setPanelDataWithUndoRedo, filterUndoRedoStacksByType }
  } = useFeatheryRedux();

  const onRuleUpdate = (rule: LogicRule) => {
    setPanelDataWithUndoRedo({
      id: rule.id,
      oldValue: {},
      newValue: {},
      workingSteps: { ...workingSteps },
      oldRulesValue: objectPick(workingLogicRules, [rule.id]),
      newRulesValue: { [rule.id]: rule },
      workingLogicRules: { ...workingLogicRules, [rule.id]: rule },
      title: UNDO_TITLES.LOGIC_RULES,
      type: UNDO_TYPES.LOGIC_RULES
    });
  };

  const onRuleDelete = (rule: LogicRule, redirect = false) => {
    setPanelDataWithUndoRedo({
      id: rule.id,
      oldValue: {},
      newValue: {},
      workingSteps: { ...workingSteps },
      oldRulesValue: objectPick(workingLogicRules, [rule.id]),
      newRulesValue: {},
      workingLogicRules: objectRemove(workingLogicRules, [rule.id]),
      title: UNDO_TITLES.DELETE_LOGIC_RULE,
      type: UNDO_TYPES.LOGIC_RULES
    });
    if (redirect) history.push(`/forms/${formId}/logic`);
  };

  const onCodeEditorSwitch = () =>
    filterUndoRedoStacksByType(UNDO_TYPES.RULE_BUILDER);

  return !workingLogicRulesInitialized ? (
    <LogoLoader />
  ) : (
    <LogicRuleDetail
      steps={workingSteps}
      rules={workingLogicRules}
      onRuleUpdate={onRuleUpdate}
      onRuleDelete={onRuleDelete}
      onBack={() => history.push(`/forms/${formId}/logic`)}
      onCodeEditorSwitch={onCodeEditorSwitch}
    />
  );
}
