import { RuleAction, RuleOperand } from '../../context/RuleDSL';
import {
  CALENDLY_ACTION,
  CONNECT_TO_API,
  NAVIGATE_TO_STEP_ACTION,
  OPEN_URL_ACTION,
  SET_ACTION,
  SET_ERROR_ACTION,
  SMS_CODE_ACTION,
  EMAIL_CODE_ACTION,
  TELESIGN_VOICE_ACTION,
  EMAIL_ACTION,
  TELESIGN_SMS_ACTION,
  AI_EXTRACTION_ACTION,
  RUN_INTEGRATION_ACTION
} from './constants';

const sanitize = (action: RuleAction) => {
  const json = action.toJSON();

  return {
    name: json.name,
    parameters: [...json.parameters] as ParametersTuple
  };
};

const initSetAction = () => {
  const action = new RuleAction();

  action.name = SET_ACTION;
  action.inputMode = 'value';
  action.setParameters([new RuleOperand(), new RuleOperand()]);

  return sanitize(action);
};

const initNavigateToStepAction = () => {
  const action = new RuleAction();

  action.name = NAVIGATE_TO_STEP_ACTION;
  action.setParameters([new RuleOperand()]);

  action.parameters[0].type = 'value';

  return sanitize(action);
};

const initOpenURLAction = () => {
  const action = new RuleAction();

  action.name = OPEN_URL_ACTION;
  action.setParameters([new RuleOperand(), new RuleOperand()]);

  action.parameters[0].type = 'value';
  const targetParam = action.parameters[1] as IRuleOperand;
  targetParam.type = 'value';
  targetParam.value = '_blank';
  targetParam.meta = {
    display: 'New Tab'
  };

  return sanitize(action);
};

const initConnectToAPIAction = () => {
  const action = new RuleAction();

  action.name = CONNECT_TO_API;
  action.setParameters([new RuleOperand()]);

  action.parameters[0].type = 'value';

  return sanitize(action);
};

const initSetErrorAction = () => {
  const action = new RuleAction();

  action.name = SET_ERROR_ACTION;
  action.setParameters([new RuleOperand(), new RuleOperand()]);

  action.parameters[0].type = 'field';
  (action.parameters[1] as IRuleOperand).type = 'value';

  return sanitize(action);
};

const initCalendlyAction = () => {
  const action = new RuleAction();

  action.name = CALENDLY_ACTION;
  action.setParameters([new RuleOperand()]);

  action.parameters[0].type = 'value';
  action.parameters[0].value = '';

  return sanitize(action);
};

const initSmsCodeAction = () => {
  const action = new RuleAction();

  action.name = SMS_CODE_ACTION;
  action.setParameters([new RuleOperand()]);

  action.parameters[0].type = 'field';

  return sanitize(action);
};

const initEmailCodeAction = () => {
  const action = new RuleAction();

  action.name = EMAIL_CODE_ACTION;
  action.setParameters([new RuleOperand()]);

  action.parameters[0].type = 'field';

  return sanitize(action);
};

const initTelesignVoiceAction = () => {
  const action = new RuleAction();

  action.name = TELESIGN_VOICE_ACTION;
  action.setParameters([new RuleOperand()]);

  action.parameters[0].type = 'field';

  return sanitize(action);
};

const initTelesignSmsAction = () => {
  const action = new RuleAction();

  action.name = TELESIGN_SMS_ACTION;
  action.setParameters([new RuleOperand()]);

  action.parameters[0].type = 'field';

  return sanitize(action);
};

const initEmailAction = () => {
  const action = new RuleAction();

  action.name = EMAIL_ACTION;
  action.setParameters([new RuleOperand()]);

  action.parameters[0].type = 'value';

  return sanitize(action);
};

const initRunAIExtraction = () => {
  const action = new RuleAction();

  action.name = AI_EXTRACTION_ACTION;
  action.setParameters([
    new RuleOperand(),
    new RuleOperand(),
    new RuleOperand()
  ]);

  action.parameters[0].type = 'value';
  action.parameters[0].value = '';

  (action.parameters[1] as RuleOperand).type = 'value';
  (action.parameters[1] as RuleOperand).value = 'true';

  (action.parameters[2] as RuleOperand).type = 'value';
  (action.parameters[2] as RuleOperand).value = '';

  return sanitize(action);
};

const initRunIntegrationAction = () => {
  const action = new RuleAction();

  action.name = RUN_INTEGRATION_ACTION;
  action.setParameters([new RuleOperand(), new RuleOperand()]);

  action.parameters[0].type = 'value';
  action.parameters[0].value = '';

  (action.parameters[1] as RuleOperand).type = 'value';
  (action.parameters[1] as RuleOperand).value = JSON.stringify({
    waitForCompletion: false
  });

  return sanitize(action);
};

export const initializeAction = (actionName: string) => {
  switch (actionName) {
    case SET_ACTION:
      return initSetAction();
    case NAVIGATE_TO_STEP_ACTION:
      return initNavigateToStepAction();
    case OPEN_URL_ACTION:
      return initOpenURLAction();
    case CONNECT_TO_API:
      return initConnectToAPIAction();
    case SET_ERROR_ACTION:
      return initSetErrorAction();
    case CALENDLY_ACTION:
      return initCalendlyAction();
    case SMS_CODE_ACTION:
      return initSmsCodeAction();
    case EMAIL_CODE_ACTION:
      return initEmailCodeAction();
    case TELESIGN_VOICE_ACTION:
      return initTelesignVoiceAction();
    case TELESIGN_SMS_ACTION:
      return initTelesignSmsAction();
    case EMAIL_ACTION:
      return initEmailAction();
    case AI_EXTRACTION_ACTION:
      return initRunAIExtraction();
    case RUN_INTEGRATION_ACTION:
      return initRunIntegrationAction();
    default:
      return initSetAction();
  }
};
