import { LogicRule } from '../../../../FormLogicPage/LogicRuleList';
import {
  ACTION_DETAILS,
  PRIVATE_ACTIONS
} from './components/RuleAction/constants';

export const isCondition = (segment: any) => {
  return segment.operator && ['and', 'or'].includes(segment.operator);
};

export const isExpression = (segment: any) => {
  return segment.operator && !isCondition(segment);
};

export const isOperand = (segment: any) => {
  return 'type' in segment && ['field', 'value'].includes(segment.type);
};

export const isEmptyRule = (rule: LogicRule) => {
  return (
    rule.mode === 'rule_builder' && rule.code === '// Javascript rule code'
  );
};

export const isEmptyDSL = (dsl?: IRuleDSL) => {
  if (!dsl || !dsl.branches || !dsl.branches.length) {
    return true;
  }

  return false;
};

export const getPrivateActions = (dsl?: IRuleDSL) => {
  const privateActions: string[] = [];
  if (!dsl) return '';

  for (const branch of dsl.branches) {
    for (const clause of branch.clauses) {
      for (const action of clause.actions) {
        if (PRIVATE_ACTIONS.includes(action.name)) {
          privateActions.push((ACTION_DETAILS as any)[action.name].name);
        }
      }
    }
  }
  return privateActions.join(', ');
};
