import { memo, useEffect, useState } from 'react';
import useFeatheryRedux from '../../redux';
import styles from './styles.module.scss';
import { Prompt, useParams } from 'react-router-dom';
import { useAppSelector } from '../../hooks';
import { PUBLISH_STATUS } from '../../redux/utils';
import DocumentNavigation from '../../components/NavBar/DocumentNavigation';
import { objectPick } from '../../utils/core';
import SideNavigation from '../../components/SideNavigation';
import DocumentPropertiesSection from './sections/DocumentPropertiesSection';
import NotificationsSection from './sections/NotificationsSection';
import SignaturePropertiesSection from './sections/SignaturePropertiesSection';
import { PublishButton } from '../../components/Core/Button';
import { NON_SIGNABLE_TYPES } from '../../utils/documents';

function DocumentSettingsPage() {
  const { documentId } = useParams<{ documentId: string }>();
  const doc = useAppSelector((state) => state.documents.documents[documentId]);
  const {
    editDocument,
    toasts: { addInfoToast, addErrorToast }
  } = useFeatheryRedux();

  const [publishStatus, setPublishStatus] = useState(PUBLISH_STATUS.FULFILLED);
  const [formData, setFormData] = useState<Record<string, any>>({
    verify_email: true,
    key: '',
    tag_metadata: [],
    signature_alertees: [],
    signature_alert_email: '',
    email_templates: {},
    slack_alert_hook: '',
    signature_save_field: '',
    expire_days: null
  });

  useEffect(() => {
    if (doc) setFormData({ ...objectPick(doc, Object.keys(formData)) });
  }, [doc]);

  const updateData = (property: string, value: any) => {
    if (value !== formData[property]) {
      setFormData((formData) => ({
        ...formData,
        [property]: value
      }));
      setPublishStatus(PUBLISH_STATUS.ACTIVE);
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setPublishStatus(PUBLISH_STATUS.LOADING);
    try {
      await editDocument({ documentId, ...formData });
      addInfoToast('Your settings have been saved.');
      setPublishStatus(PUBLISH_STATUS.FULFILLED);
    } catch (error: any) {
      addErrorToast({ title: error.message });
      setPublishStatus(PUBLISH_STATUS.ACTIVE);
    }
  };

  const handleBlockedNavigation = (nextLocation: any) => {
    const validSettingsPathRegex = new RegExp(
      `^/documents/${documentId}/settings/?$`
    );
    if (validSettingsPathRegex.test(nextLocation.pathname)) return true;
    return 'You are about to leave without saving your settings. Are you sure you want to continue?';
  };

  return (
    <>
      <Prompt
        when={publishStatus === PUBLISH_STATUS.ACTIVE}
        message={handleBlockedNavigation}
      />
      <DocumentNavigation
        activeItem='settings'
        submitButton={
          <PublishButton
            status={publishStatus}
            onPublish={handleSubmit}
            saveFlag
          />
        }
      />
      <div className={styles.documentSettingsPage}>
        <SideNavigation title='Settings'>
          <DocumentPropertiesSection
            title='Properties'
            formData={formData}
            updateData={updateData}
          />
          {!NON_SIGNABLE_TYPES.includes(doc.type) && (
            <SignaturePropertiesSection
              title='Signature Flow'
              formData={formData}
              updateData={updateData}
            />
          )}
          {!NON_SIGNABLE_TYPES.includes(doc.type) && (
            <NotificationsSection
              title='Notifications'
              formData={formData}
              updateData={updateData}
            />
          )}
        </SideNavigation>
      </div>
    </>
  );
}

export default memo(DocumentSettingsPage);
