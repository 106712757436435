import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const fetchVancoToken = createAsyncThunk(
  'customers/fetchVancoToken',
  async ({ token }: { token: string }) => {
    const response = await FeatheryAPI.fetchVancoToken(token);

    if (response.status === 404) {
      return {};
    } else if (response.status === 200) {
      return await response.json();
    }

    throw new Error('Could not fetch Vanco token');
  }
);

const customersSlice = createSlice({
  name: 'customers',
  initialState: {},
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchVancoToken.fulfilled, (state, action) => {
      (state as any).vancoToken = action.payload.token;
    });
  }
});

export default customersSlice.reducer;
export { fetchVancoToken };
