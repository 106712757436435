import ElementListPanel from '../../Panels/ElementPanel/ElementListPanel';
import { onDropWrapper } from '../GridInGrid/components/utils';
import { DEFAULT_CATEGORIES } from '../../Panels/ElementPanel';
import { ElevatedMenu } from '../../Core';
import React, { useEffect, useRef, useState } from 'react';
import { useStore } from 'react-redux';
import useFeatheryRedux from '../../../redux';
import useIntegrations from '../../FormIntegrations/useIntegrations';
import { INTEGRATIONS } from '../../FormIntegrations';
import { updateAllowedFields } from '../../SelectionPanel';
import {
  stopReactEventPropagation,
  useGlobalMouseDownToggle
} from '../../Core/util';
import { useParams } from 'react-router-dom';
import PlusButton from '../GridInGrid/components/PlusButton';
import { ControlReference, SideControlReference } from './ControlLayer';
import { useAppSelector } from '../../../hooks';

const PlusMenu = ({
  control,
  buttonColor,
  onToggle
}: {
  control: ControlReference | SideControlReference;
  buttonColor: string;
  onToggle: (show: boolean) => void;
}) => {
  if (!control) return null;

  const plusMenuRef = useRef<HTMLDivElement>(null);

  const node = control.canvasReference.node;

  if (!node) return null;

  const nodeAction = 'side' in control ? `add${control['side']}` : `setElement`;

  const {
    formBuilder,
    toasts: { addToast }
  } = useFeatheryRedux();
  const store = useStore();

  const { formId } = useParams<{ formId: string }>();
  const integrations = useIntegrations({
    types: [INTEGRATIONS.FIREBASE, INTEGRATIONS.STYTCH, INTEGRATIONS.STRIPE],
    panelId: formId
  });
  const elementEntries = useAppSelector((state) => state.elements);

  // Initially load all elements, then filter if corresponding integrations aren't enabled
  const [elements, setElements] = useState(elementEntries);

  useEffect(() => {
    const newElements = { ...elementEntries };
    setElements(updateAllowedFields(newElements, integrations));
  }, [integrations, elementEntries]);

  const theme = useAppSelector((state) => state.formBuilder.theme);
  const reduxActions = useFeatheryRedux();
  const { setTheme } = reduxActions.themeBuilder;

  // const fieldMenuRef = useRef<HTMLDivElement>(null);
  const [showMenu, setShowMenu] = useGlobalMouseDownToggle([plusMenuRef], {
    onCloseCb: () => onToggle(false)
  });
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });

  return (
    <>
      <PlusButton
        color={buttonColor}
        touchSize={16}
        onMouseDown={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
          e.preventDefault();
          stopReactEventPropagation(e);
          setShowMenu(true);
          onToggle(true);
          setMenuPosition({
            x: e.clientX,
            y: e.clientY
          });
        }}
      />
      <ElevatedMenu
        bestFit
        key={`${node.id}-center-plus-menu`}
        ref={plusMenuRef}
        position={menuPosition}
        show={showMenu}
        styles={{
          overflow: 'hidden',
          cursor: 'pointer',
          boxShadow: '0 3px 15px #d3d3d3',
          borderRadius: '16px',
          userSelect: 'none'
        }}
      >
        <ElementListPanel
          elements={elements}
          mode={'click'}
          theme={theme}
          setTheme={setTheme}
          onElementMouseDown={(
            elementType: string,
            assetMeta: any | undefined
          ) => {
            onDropWrapper(
              node,
              nodeAction,
              {
                elementType: elementType,
                node: null,
                operation: 'new',
                assetId: assetMeta?.id
              },
              store,
              formBuilder,
              addToast
            );
            onToggle(false);
            setShowMenu(false);
          }}
          categories={DEFAULT_CATEGORIES(elements)}
          context={'builder'}
          style={{
            width: '200px',
            height: '300px',
            padding: '5px 0'
          }}
        />
      </ElevatedMenu>
    </>
  );
};

export default PlusMenu;
