import {
  CollapsibleSection,
  DropdownField,
  DropdownMultiField,
  InlineTooltip,
  NumberInput,
  Switch,
  TextField
} from '../../../components/Core';
import {
  defaultQuestionGroup,
  getDefaultEntity,
  questionGroupTemplates
} from '../utils';
import produce from 'immer';
import Label from '../../../components/Dialog/Label';
import QuestionGroup from './QuestionGroup';
import styles from '../styles.module.scss';
import '../../../style/dialog-form.css';
import useFeatheryRedux from '../../../redux';
import { useSyncedRefState } from '../../../hooks/useSyncedRefState';

export default function ExtractionProperties({
  extractionId = null,
  formData,
  handleChange,
  accounts,
  fileOptions,
  hiddenFieldOptions,
  usedFields,
  fieldProperties,
  hiddenFieldProperties
}: any) {
  const {
    toasts: { addErrorToast }
  } = useFeatheryRedux();

  const getQuestionGroups = useSyncedRefState(formData.question_groups);
  const getVariants = useSyncedRefState(formData.variants);

  const removeQuestionGroup = (index: number) => {
    const groups = getQuestionGroups();
    const group_id = groups[index].id;
    const newGroups = produce(groups, (draft: any[]) => {
      if (draft.length === 1) {
        draft[0] = defaultQuestionGroup(formData.file_type);
        return;
      } else {
        draft.splice(index, 1);
      }
    });

    // remove any group_variants attached to this group
    const newVariants = produce(getVariants(), (draft: any[]) => {
      for (let i = 0; i < draft.length; i++) {
        if (draft[i].group_variants) {
          draft[i].group_variants = draft[i].group_variants.filter(
            (groupVariant: any) => groupVariant.question_group_id !== group_id
          );
        }
      }
    });

    handleChange({
      question_groups: newGroups,
      variants: newVariants
    });
  };

  const addQuestionGroup = () => {
    handleChange({
      question_groups: [
        ...getQuestionGroups(),
        defaultQuestionGroup(formData.file_type)
      ]
    });
  };

  const updateQuestionGroup = (
    index: number,
    key: string,
    val: any,
    entityIndex?: number,
    operation?: 'add' | 'remove'
  ) => {
    const newData: Record<string, any> = {};

    const groups = getQuestionGroups();
    const groupId = groups[index].id;

    const newGroups = produce(groups, (draft: any[]) => {
      draft[index][key] = val;

      if (key === 'question_type') {
        draft[index].field_ids = [];
        draft[index].entities = [getDefaultEntity()];
        draft[index].criteria = '';
        draft[index].toggle_page_question = false;
        draft[index].run_email_mode = 'document_only';
      } else if (key === 'entities') {
        draft[index].field_ids = draft[index][key].map(
          (e: any) => e.field_id ?? null
        );
      }
    });
    newData.question_groups = newGroups;

    const seenFieldIds = new Set<string>();
    const hasDuplicate = Object.values(newGroups).some((group) => {
      return group.field_ids.some((fieldId: string | null) => {
        if (!fieldId) return false;
        if (seenFieldIds.has(fieldId)) return true;
        seenFieldIds.add(fieldId);
        return false;
      });
    });

    if (hasDuplicate) {
      addErrorToast({ title: 'Field is already used!' });
      return;
    }

    if (entityIndex != null && operation) {
      // Update variants to match the field changes
      const newVariants = produce(getVariants(), (draft: any[]) => {
        draft.forEach((variant) => {
          const groupVariantIndex = variant.group_variants?.findIndex(
            (group: any) => group.question_group_id === groupId
          );

          if (groupVariantIndex !== -1 && groupVariantIndex != null) {
            const groupVariant = variant.group_variants[groupVariantIndex];

            if (key === 'question_type') {
              // Reset field_ids
              groupVariant.field_ids = [];
            } else if (key === 'entities') {
              if (operation === 'add' && entityIndex !== undefined) {
                // add null at index
                groupVariant.field_ids.splice(entityIndex, 0, null);
              } else if (operation === 'remove' && entityIndex !== undefined) {
                // remove field at index
                groupVariant.field_ids.splice(entityIndex, 1);
              }
            }

            // remove groupVariant if it's only nulls
            if (groupVariant.field_ids.filter(Boolean).length === 0)
              variant.group_variants.splice(groupVariantIndex, 1);
          }
        });
      });

      newData.variants = newVariants;
    }

    handleChange(newData);
  };

  const moveQuestionGroupUp = (index: number) => {
    const newGroups = produce(getQuestionGroups(), (draft: any) => {
      if (index > 0) {
        [draft[index - 1], draft[index]] = [draft[index], draft[index - 1]];
      }
    });

    handleChange({ question_groups: newGroups });
  };

  const moveQuestionGroupDown = (index: number) => {
    const newGroups = produce(getQuestionGroups(), (draft: any) => {
      if (index < draft.length - 1) {
        [draft[index], draft[index + 1]] = [draft[index + 1], draft[index]];
      }
    });

    handleChange({ question_groups: newGroups });
  };

  return (
    <>
      <Label>Name</Label>
      <TextField
        value={formData.key}
        placeholder='My Extraction'
        onComplete={(newKey: string) => handleChange({ key: newKey })}
        className={styles.modalInput}
        required
      />
      <div className={styles.labelContainer}>
        <Label>Reviewers</Label>
        <InlineTooltip text='Reviewers will be notified via email to review & approve new extraction runs.' />
      </div>
      <DropdownMultiField
        selected={formData.reviewers}
        options={accounts.map((account: any) => ({
          label: account.email,
          value: account.id
        }))}
        onChange={(items: { value: string }[]) =>
          handleChange({ reviewers: items.map((item) => item.value) })
        }
        className={styles.modalInput}
      />
      <div className={styles.labelContainer}>
        <Label>Template</Label>
        <InlineTooltip text='Leverage query templates and optimized processing based on the type of document being processed.' />
      </div>
      <DropdownField
        selected={formData.extraction_type}
        options={[
          { value: '', display: 'Custom' },
          { value: 'holdings', display: 'Security Holdings Statement' },
          {
            value: 'bank_transactions',
            display: 'Bank Transactions Statement'
          },
          { value: 'acord', display: 'ACORD Forms', disabled: true },
          {
            value: 'supplemental_insurance_application',
            display: 'Supplemental Insurance Application',
            disabled: true
          },
          {
            value: 'patient_referral',
            display: 'Patient Referral Form',
            disabled: true
          },
          {
            value: 'quarterly_fund_investment_report',
            display: 'Quarterly Fund Investment Report',
            disabled: true
          },
          {
            value: 'financial_investment_statements',
            display: 'Financial Investment Statements',
            disabled: true
          },
          {
            value: 'capital_account_investment_report',
            display: 'Capital Account Investment Report',
            disabled: true
          }
        ]}
        onChange={(event: any) => {
          const newData: Record<string, any> = {};
          const val = event.target.value;
          if (val in questionGroupTemplates) {
            // @ts-ignore
            newData.question_groups = questionGroupTemplates[val]();
          } else {
            const oldVal = formData.extraction_type ?? '';
            if (oldVal in questionGroupTemplates) {
              newData.question_groups = [
                defaultQuestionGroup(formData.file_type)
              ];
            }
          }

          newData.extraction_type = val;
          if (val) newData.file_type = 'image';
          handleChange(newData);
        }}
        className={styles.modalInput}
      />
      {!formData.extraction_type && (
        <>
          <div className={styles.labelContainer}>
            <Label>File Format</Label>
            <InlineTooltip text='The type of files being processed by this extraction' />
          </div>
          <DropdownField
            selected={formData.file_type}
            options={[
              { value: 'image', display: 'Documents or images' },
              { value: 'csv', display: 'Spreadsheets or CSVs' }
            ]}
            onChange={(event: any) =>
              handleChange({
                file_type: event.target.value,
                question_groups: [defaultQuestionGroup(event.target.value)],
                page_filter:
                  event.target.value !== 'image' ? null : formData.page_filter,
                file_filter_query:
                  event.target.value !== 'image'
                    ? ''
                    : formData.file_filter_query // Clear filter if not image extraction
              })
            }
            className={styles.modalInput}
            required
          />
        </>
      )}
      <div className={styles.labelContainer}>
        <Label>File Upload Source</Label>
        <InlineTooltip text='The extraction will run on files stored in these fields' />
      </div>
      <DropdownMultiField
        selected={formData.file_sources}
        options={[...fileOptions, ...hiddenFieldOptions].filter(
          (option) =>
            formData.file_sources.includes(option.value) ||
            !usedFields.includes(option.value)
        )}
        onChange={(items: { value: string }[]) =>
          handleChange({ file_sources: items.map((item) => item.value) })
        }
        className={styles.modalInput}
        required
      />
      <CollapsibleSection
        title='Filter & Merge Options'
        expanded={false}
        separator={false}
        customClasses={{
          content: styles.mergeFilterSection,
          title: styles.mergeFilterTitle,
          header: styles.mergeFilterHeader,
          expandIndicator: styles.mergeFilterIndicator
        }}
      >
        {formData.file_type === 'image' && (
          <>
            <div
              className={styles.labelContainer}
              style={{ marginTop: '10px' }}
            >
              <Label>Filter Files</Label>
              <InlineTooltip text='Describe the uploaded files that should be processed by this extraction. Uploaded files that do not match the description will be ignored.' />
            </div>
            <TextField
              value={formData.file_filter_query}
              placeholder='Bank statements'
              onComplete={(newFilter: string) =>
                handleChange({ file_filter_query: newFilter })
              }
              className={styles.modalInput}
            />
            <div className={styles.labelContainer}>
              <Label>Limit Page Count</Label>
              <InlineTooltip text="Only process the first N pages of each file. If `Merge Files` is enabled, this runs on each file before they're merged." />
            </div>
            <NumberInput
              classNames={{ root: styles.modalInput }}
              min={1}
              placeholder={3}
              value={formData.page_filter}
              onComplete={({ value }: any) => {
                handleChange({ page_filter: value });
              }}
            />
          </>
        )}
        <div className={styles.labelContainer}>
          <Label>Merge Files</Label>
          <InlineTooltip text='The extraction will run once on the combined files rather than separately on each.' />
        </div>
        <Switch
          id='extraction-merge-uploaded'
          checked={formData.merge_files || false}
          onCheckedChange={(checked) => {
            handleChange({ merge_files: checked });
          }}
          className={styles.switch}
        />
      </CollapsibleSection>
      {formData.question_groups.map((group: any, index: number) => {
        return (
          <QuestionGroup
            key={group.id}
            extractionId={extractionId}
            index={index}
            group={group}
            fieldProperties={fieldProperties}
            hiddenFieldProperties={hiddenFieldProperties}
            isLast={index === formData.question_groups.length - 1}
            file_type={formData.file_type}
            onRemove={() => removeQuestionGroup(index)}
            onMoveUp={() => moveQuestionGroupUp(index)}
            onMoveDown={() => moveQuestionGroupDown(index)}
            onUpdate={(...props) => updateQuestionGroup(index, ...props)}
          />
        );
      })}
      <div onClick={addQuestionGroup} className={styles.addGroupButton}>
        Add Query
      </div>
    </>
  );
}
