import {
  VancoSearchConfig,
  VancoSearchParams,
  VancoSearchResponse,
  VancoSearchResult
} from './types';

/**
 * Mock implementation of the Vanco search API
 * @param config Service configuration
 * @param params Search parameters
 * @param options Additional options for mock behavior
 * @returns Promise resolving to mock search results
 */
export async function mockSearchVancoProducts(
  config: VancoSearchConfig,
  params: VancoSearchParams,
  options: { mockDelay?: number; totalResultsCount?: number } = {}
): Promise<VancoSearchResponse> {
  const { searchTerm, pageNumber, pageSize } = params;
  const mockDelay = options.mockDelay || 450;
  const totalResultsCount = options.totalResultsCount || 25;

  // Network delay
  await new Promise((resolve) => setTimeout(resolve, mockDelay));

  // Return empty results for empty search
  if (!searchTerm) {
    return {
      data: [],
      resultsCount: 0,
      'page[number]': pageNumber,
      'page[size]': pageSize
    };
  }

  // Simulate error for specific search term
  if (searchTerm.toLocaleLowerCase().includes('error')) {
    throw new Error('Simulated error for testing');
  }

  const allResults = generateMockResults(searchTerm, totalResultsCount);

  const start = (pageNumber - 1) * pageSize;
  const paginatedResults = allResults.slice(start, start + pageSize);

  return {
    data: paginatedResults,
    resultsCount: allResults.length,
    'page[number]': pageNumber,
    'page[size]': pageSize
  };
}

/**
 * Generate an array of mock search results
 * @param searchTerm Current search term to include in results
 * @param count Number of results to generate
 * @returns Array of mock search results
 */
function generateMockResults(
  searchTerm: string,
  count: number
): VancoSearchResult[] {
  if (searchTerm.toLocaleLowerCase().includes('none')) return [];
  return Array.from({ length: count }, (_, index) => {
    const iconUrl = `https://api.dicebear.com/9.x/icons/svg?seed=seed_${index}&size=32`;

    return {
      id: `mock-result-${index + 1}`,
      type: 'search-result',
      attributes: {
        title: `${searchTerm ? `Result for "${searchTerm}"` : 'Mock Result'} #${
          index + 1
        }`,
        description: `${
          searchTerm ? `Matching the term "${searchTerm}".` : 'Mock Description'
        }`,
        thumbnail: iconUrl,
        'render-url': iconUrl
      }
    };
  });
}

export default mockSearchVancoProducts;
