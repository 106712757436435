import { memo, useMemo, useState } from 'react';

import Dialog from '../../Dialog';
import FieldSelector, {
  SelectedFieldData
} from '../../Modals/FieldSelectorWithModal/FieldSelector';
import { AnnotatedField } from '../../../pages/DocumentEditorPage/types';
import {
  IMAGE_FIELDS,
  OPTION_FIELDS
} from '../../SelectionPanel/elementEntries';
import { useAppSelector } from '../../../hooks';

const PDFFieldMappingModal = ({
  show,
  setShow,
  selectedFieldId,
  selectedFieldType,
  onSelectField,
  annotatedField
}: {
  show: boolean;
  setShow: (_: boolean) => void;
  selectedFieldId: string | null;
  selectedFieldType: '' | 'servar' | 'hidden';
  onSelectField: (data: SelectedFieldData) => void;
  annotatedField: AnnotatedField;
}) => {
  const [showFieldSelector, setShowFieldSelector] = useState(true);
  const fieldTypes = useAppSelector((state) =>
    Object.entries(state.elements)
      .filter(([, entry]) => (entry as any).type === 'field')
      .map(([key]) => key)
  );

  const title = showFieldSelector
    ? 'Map form field to PDF entry'
    : 'PDF Field Info';

  // Disallowed mappable field types for the selected PDF field
  const disallowedFieldTypes: string[] = useMemo(() => {
    if (annotatedField.type === 'text') return ['matrix'];
    if (annotatedField.type === 'signature')
      return fieldTypes.filter(
        (type) => !['signature', 'file_upload'].includes(type)
      );
    if (annotatedField.type === 'image')
      return fieldTypes.filter((type) => !IMAGE_FIELDS.includes(type));
    if (annotatedField.type === 'checkbox')
      return fieldTypes.filter(
        (type) => type !== 'checkbox' && !OPTION_FIELDS.includes(type)
      );
    if (['radio', 'combobox', 'listbox'].includes(annotatedField.type))
      return fieldTypes.filter((type) => !OPTION_FIELDS.includes(type));
    // buttons not mappable
    return fieldTypes;
  }, [fieldTypes, annotatedField]);

  const dlgSize = showFieldSelector ? 'xlg' : 'lg';
  return (
    <Dialog
      isOpen={show}
      onClose={() => {
        setShowFieldSelector(true);
        setShow(false);
      }}
      shouldShowCloseIcon
      size={dlgSize}
      title={title}
    >
      {show /* Only mount when showing */ && (
        <>
          {/* Either showing the logic item rules builder or the field selector. */}
          {showFieldSelector && (
            <FieldSelector
              selectId={selectedFieldId}
              selectType={selectedFieldType}
              show={show}
              setShow={setShow}
              onSelect={(data: SelectedFieldData) => {
                onSelectField(data);
                setShowFieldSelector(true);
                setShow(false);
              }}
              selectTitle='Map Field'
              showOtherButton={false}
              excludeServarTypes={disallowedFieldTypes}
            />
          )}
        </>
      )}
    </Dialog>
  );
};

export default memo(PDFFieldMappingModal);
