import {
  CollapsibleSection,
  DropdownField,
  InlineTooltip,
  TextField,
  YesNoSwitch
} from '../../../../components/Core';
import classNames from 'classnames';
import styles from '../styles.module.scss';
import Combobox from '../../../../components/Core/Combobox';

export default function PageFilter({ group, onUpdate }: any) {
  return (
    <CollapsibleSection
      title='Page Filter'
      expanded={false}
      separator={false}
      customClasses={{
        header: styles.advancedSectionHeader,
        title: styles.advancedSectionTitle,
        content: styles.advancedSectionContent,
        expandIndicator: styles.advancedSectionExpandIndicator
      }}
    >
      <div className={styles.keyValuePair}>
        <div className={styles.key}>
          Filter
          <InlineTooltip text='Specify words, phrases, or regular expressions that must appear on a page. Only pages containing at least one of these terms will be included in the extraction.' />
        </div>
        <div className={styles.option}>
          <DropdownField
            selected={group.properties?.page_filter_whitelist_mode || 'OR'}
            options={[
              { value: 'OR', display: 'ANY' },
              { value: 'AND', display: 'ALL' }
            ]}
            onChange={(e) => {
              onUpdate('properties', {
                ...(group.properties || {}),
                page_filter_whitelist_mode: e.target.value
              });
            }}
            className={styles.controlField}
          />
        </div>
        <div className={styles.value}>
          <Combobox
            value={group.properties?.page_filter_whitelist || []}
            onChange={(items: string[]) => {
              onUpdate('properties', {
                ...(group.properties || {}),
                page_filter_whitelist: items
              });
            }}
            placeholder='Specify words or regexes that must appear on a page'
            classNames={{
              multiFieldValueContainer: styles.multiFieldValueContainer,
              multiFieldMultiValue: styles.multiFieldValue,
              multiFieldMultiValueLabel: styles.multiFieldValueLabel,
              multiFieldInput: styles.multiFieldInput,
              multiFieldPlaceholder: styles.multiFieldPlaceholder,
              multiFieldIndicatorsContainer:
                styles.multiFieldIndicatorsContainer,
              multiFieldControl: (state: any) =>
                classNames({
                  [styles.multiFieldControl]: true,
                  [styles.multiFieldControlDisabled]: state.isDisabled,
                  [styles.multiFieldControlFocused]: state.isFocused
                })
            }}
          />
        </div>
      </div>
      <div className={styles.keyValuePair}>
        <div className={styles.key}>
          Exclude
          <InlineTooltip text='Specify words, phrases, or regular expressions on a page that will exclude it from being processed.' />
        </div>
        <div className={styles.option}>
          <DropdownField
            selected={group.properties?.page_filter_blacklist_mode || 'OR'}
            options={[
              { value: 'OR', display: 'ANY' },
              { value: 'AND', display: 'ALL' }
            ]}
            onChange={(e) => {
              onUpdate('properties', {
                ...(group.properties || {}),
                page_filter_blacklist_mode: e.target.value
              });
            }}
            className={styles.controlField}
          />
        </div>
        <div className={styles.value}>
          <Combobox
            value={group.properties?.page_filter_blacklist || []}
            onChange={(items: string[]) => {
              onUpdate('properties', {
                ...(group.properties || {}),
                page_filter_blacklist: items
              });
            }}
            placeholder='Enter words or regexes that cannot appear on a page'
            classNames={{
              multiFieldValueContainer: styles.multiFieldValueContainer,
              multiFieldMultiValue: styles.multiFieldValue,
              multiFieldMultiValueLabel: styles.multiFieldValueLabel,
              multiFieldInput: styles.multiFieldInput,
              multiFieldPlaceholder: styles.multiFieldPlaceholder,
              multiFieldIndicatorsContainer:
                styles.multiFieldIndicatorsContainer,
              multiFieldControl: (state: any) =>
                classNames({
                  [styles.multiFieldControl]: true,
                  [styles.multiFieldControlDisabled]: state.isDisabled,
                  [styles.multiFieldControlFocused]: state.isFocused
                })
            }}
          />
        </div>
      </div>
      <div className={styles.keyValueRow}>
        <div className={styles.keyValuePair}>
          <div className={styles.key}>
            Start Page
            <InlineTooltip
              text={
                'The page to start answering the query from. ' +
                'Negative values count from the end. ' +
                'If `Merge Files` is on, this number will be relative to the entire merged file'
              }
            />
          </div>
          <div className={styles.value}>
            <TextField
              type='number'
              inputMode='numeric'
              className={styles.controlField}
              onComplete={(newVal: string) => {
                onUpdate('properties', {
                  ...(group.properties || {}),
                  page_filter_start: newVal.trim() ? parseInt(newVal, 10) : null
                });
              }}
              value={group.properties?.page_filter_start ?? ''}
              placeholder='1'
            />
          </div>
        </div>
        <div className={styles.keyValuePair}>
          <div className={styles.key}>
            End Page
            <InlineTooltip
              text={
                'The page to stop answering the query at. ' +
                'Negative values count from the end. ' +
                'If `Merge Files` is on, this number will be relative to the entire merged file'
              }
            />
          </div>
          <div className={styles.value}>
            <TextField
              type='number'
              inputMode='numeric'
              className={styles.controlField}
              onComplete={(newVal: string) => {
                onUpdate('properties', {
                  ...(group.properties || {}),
                  page_filter_end: newVal.trim() ? parseInt(newVal, 10) : null
                });
              }}
              value={group.properties?.page_filter_end ?? ''}
              placeholder='-1'
            />
          </div>
        </div>
      </div>
      {group.question_type === 'multiple_value' && (
        <div className={styles.keyValuePair}>
          <div className={styles.key}>
            Page Limit{' '}
            <InlineTooltip text='Enter a number to limit the number of relevant pages. Leave blank for no limit.' />
          </div>
          <div className={styles.value}>
            <TextField
              type='number'
              placeholder='No limit'
              className={styles.controlField}
              onComplete={(newVal: string) => {
                onUpdate('properties', {
                  ...(group.properties || {}),
                  limit_relevant_pages: newVal.trim()
                    ? parseInt(newVal, 10)
                    : null
                });
              }}
              value={group.properties?.limit_relevant_pages ?? null}
            />
          </div>
        </div>
      )}
      {(group.properties?.page_filter_whitelist?.length > 0 ||
        group.properties?.page_filter_blacklist?.length > 0) && (
        <div
          className={styles.keyValuePair}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <div className={styles.key} style={{ width: '190px' }}>
            Skip AI Page Filter{' '}
            <InlineTooltip text='When page filters are set, skip prompting AI whether a page is relevant or not.' />
          </div>
          <div className={styles.value}>
            <YesNoSwitch
              checked={group.properties?.skip_ai_page_filter || false}
              onCheckedChange={(value: boolean) => {
                onUpdate('properties', {
                  ...(group.properties || {}),
                  skip_ai_page_filter: value
                });
              }}
            />
          </div>
        </div>
      )}
    </CollapsibleSection>
  );
}
