import '../../../style/dialog-form.css';
import { useState } from 'react';
import { ALL_INTEGRATIONS_MAP, INTEGRATIONS } from '../types';
import {
  CheckboxField,
  DropdownField,
  InlineTooltip,
  PropertyLabel,
  TextField
} from '../../Core';
import { Button } from '../../Core/Button/button';
import IntegrationsSidebar from '../IntegrationsSidebar';
import useIntegrations from '../useIntegrations';
import { useParams } from 'react-router-dom';
import Combobox from '../../Core/Combobox';
import Label from '../../Dialog/Label';
import styles from '../styles.module.scss';
import UploadFieldMappingDialog from './components/UploadFieldMappingDialog';

const DEFAULT_SETTINGS = {
  username: '',
  password: '',
  form_ids: [],
  esign_send_type: '',
  esign_id_check: '',
  esign_hide_id_check: false,
  esign_read_only: false,
  sf_mapping_file_name: '',
  sf_field_mapping: null
};

function QuikSettingsSidebar() {
  const { formId } = useParams<{ formId: string }>();

  const integration = useIntegrations({
    type: INTEGRATIONS.QUIK,
    panelId: formId,
    includeInactive: true
  });

  const [err, setErr] = useState('');

  const [quikConfig, setQuikConfig] = useState(
    integration?.data.secret_metadata ?? DEFAULT_SETTINGS
  );

  const [showUploadFieldMappingCSVModal, setShowUploadFieldMappingCSVModal] =
    useState(false);

  const setField = (key: string, value: any) =>
    setQuikConfig((quikConfig: any) => ({
      ...quikConfig,
      [key]: value
    }));

  function onSubmitCustom(newIsActive: boolean) {
    if (quikConfig.form_ids.length === 0) {
      setErr('At least one Quik Form ID is required');
      return;
    }
    setErr('');

    return {
      isUpdate: integration?.data,
      secretMetadata: {
        form_ids: quikConfig.form_ids,
        username: quikConfig.username,
        password: quikConfig.password,
        esign_send_type: quikConfig.esign_send_type,
        esign_id_check: quikConfig.esign_id_check,
        esign_hide_id_check: quikConfig.esign_hide_id_check,
        esign_read_only: quikConfig.esign_read_only,
        sf_mapping_file_name: quikConfig.sf_mapping_file_name,
        sf_field_mapping: quikConfig.sf_field_mapping
      }
    };
  }

  return (
    <>
      {showUploadFieldMappingCSVModal && (
        <UploadFieldMappingDialog
          isOpen={showUploadFieldMappingCSVModal}
          setIsOpen={setShowUploadFieldMappingCSVModal}
          quikConfig={quikConfig}
          setQuikConfig={setQuikConfig}
        />
      )}
      <IntegrationsSidebar
        integrationInfo={ALL_INTEGRATIONS_MAP[INTEGRATIONS.QUIK]}
        onSubmitCustom={onSubmitCustom}
        customError={err}
      >
        <form className='integration-modal-form'>
          <Label>Authentication</Label>
          <div>
            <PropertyLabel label='Username' />
            <TextField
              value={quikConfig.username}
              placeholder='Default Test Account'
              onChange={(value: string) => setField('username', value)}
              className={styles.marginBottom}
            />
          </div>
          <div>
            <PropertyLabel label='Password' />
            <TextField
              value={quikConfig.password}
              placeholder='Default Test Account'
              onChange={(value: string) => setField('password', value)}
              type='password'
              className={styles.marginBottom}
            />
          </div>
          <Label style={{ display: 'flex' }}>
            Quik Form IDs{' '}
            <InlineTooltip
              text={
                'A list of Quik form IDs corresponding to documents to fill. ' +
                "If ':<FieldId>' is appended to the Quik form ID, the form will only be included in the generated form bundle if the submission has a value for the corresponding Feathery field. " +
                "If '::<FieldId>' is appended, the form will be included multiple times. The frequency is either a number or the length of a list of values in the field. Each form repeat will contain data from the corresponding repeat of Feathery field data."
              }
            />
          </Label>
          <div>
            <Combobox
              value={quikConfig.form_ids || []}
              onChange={(items: string[]) => {
                setField('form_ids', items);
              }}
              placeholder='132:IncludeIRAField::RepeatNumField'
              className={styles.marginBottom}
            />
          </div>
          <Label>{'E-Sign Pop-Up Configuration (Optional)'}</Label>
          <div>
            <PropertyLabel
              label={
                <>
                  Send Type
                  <InlineTooltip text='Set Quik E-Sign Pop-Up Send Type' />
                </>
              }
            />
            <DropdownField
              onChange={(e: any) => {
                setField('esign_send_type', e.target.value);
              }}
              selected={quikConfig.esign_send_type}
              options={[
                { value: '', display: 'Unset' },
                { value: 'EmailToSign', display: 'Email' },
                { value: 'SendCopy', display: 'Send Copy' },
                { value: 'InPersonSigner', display: 'In Person Signer' },
                {
                  value: 'CertifiedDeliveries',
                  display: 'Certified Deliveries'
                },
                { value: 'Editor', display: 'Editor' }
              ]}
              className={styles.marginBottom}
            />
          </div>
          <div>
            <PropertyLabel
              label={
                <>
                  Identity Check Type
                  <InlineTooltip text='Set Quik E-Sign Pop-Up Identity Check' />
                </>
              }
            />
            <DropdownField
              onChange={(e: any) => {
                setField('esign_id_check', e.target.value);
              }}
              selected={quikConfig.esign_id_check}
              options={[
                { value: 'None', display: 'Unset' },
                { value: 'SMS', display: 'SMS' },
                { value: 'Phone', display: 'Phone' },
                { value: 'LiveID', display: 'Live ID' },
                { value: 'Facebook', display: 'Facebook' },
                { value: 'LinkedIn', display: 'LinkedIn' },
                { value: 'Google', display: 'Google' },
                { value: 'Salesforce', display: 'Salesforce' },
                { value: 'Twitter', display: 'Twitter' },
                { value: 'Yahoo', display: 'Yahoo' },
                { value: 'OpenID', display: 'OpenID' },
                { value: 'IDCheck', display: 'ID Check' },
                { value: 'NoIdentityCheck', display: 'No Identity Check' }
              ]}
              className={styles.marginBottom}
            />
          </div>
          <div>
            <CheckboxField
              text={
                <>
                  Hide Identity Check Column
                  <InlineTooltip text='Hide the Identity Check column within the Quik E-Sign Pop-Up' />
                </>
              }
              checked={quikConfig.esign_hide_id_check}
              onChange={(isChecked) =>
                setField('esign_hide_id_check', isChecked)
              }
              className={styles.marginBottom}
            />
          </div>
          <div>
            <CheckboxField
              text={
                <>
                  Set E-Sign Fields As Read Only
                  <InlineTooltip text='Set fields as read only within the Quik E-Sign Pop-Up' />
                </>
              }
              checked={quikConfig.esign_read_only}
              onChange={(isChecked) => setField('esign_read_only', isChecked)}
              className={styles.marginBottom}
            />
          </div>
          <div>
            <Button
              className='mt-6'
              variant='outline-primary'
              type='button'
              onClick={(e) => {
                setShowUploadFieldMappingCSVModal(true);
              }}
            >
              {'Upload Quik <> Salesforce Field Map'}
            </Button>
          </div>
        </form>
      </IntegrationsSidebar>
    </>
  );
}

export default QuikSettingsSidebar;
