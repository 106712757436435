import { httpGet, URL } from './utils';

export default {
  fetchVancoToken: (token: string) => {
    return httpGet({
      token,
      url: `${URL.INTEGRATIONS}customer/vanco/token/`,
      forceLoad: true
    });
  }
};
