import '../../style/navigation.scss';
import { Link, useHistory, useParams } from 'react-router-dom';
import { BackArrowIcon } from '../Icons';
import { useAppSelector } from '../../hooks';
import { DynamicTextTooltip } from '../Core';
import { useEffect } from 'react';
import useFeatheryRedux from '../../redux';

const MAX_LABEL_LENGTH = 14;

export default function WorkspaceNavigation({
  activeItem,
  style,
  submitButton
}: any) {
  const history = useHistory();
  const { workspaceId } = useParams<{ workspaceId: string }>();
  const { getWorkspace } = useFeatheryRedux();
  const workspace = useAppSelector((state) => state.accounts.workspaceDetails);
  useEffect(() => {
    if (!workspace || workspace.id !== workspaceId) {
      getWorkspace({ workspaceId }).catch(() =>
        history.push({
          pathname: '/workspaces'
        })
      );
    }
  }, [workspaceId, workspace?.id]);

  if (!workspace) return null;
  let workspaceLabel = workspace.name;
  if (workspace.name.length > MAX_LABEL_LENGTH) {
    workspaceLabel = workspace.name.slice(0, MAX_LABEL_LENGTH) + '...';
  }

  return (
    <nav className='main-nav' style={style}>
      <div className='left-nav'>
        <div className='left-nav-header'>
          <Link to='/workspaces/' className='back-container'>
            <BackArrowIcon />
          </Link>
          <DynamicTextTooltip
            text={workspace.name}
            maxLength={MAX_LABEL_LENGTH}
            placement='bottom'
          >
            <span>{workspaceLabel}</span>
          </DynamicTextTooltip>
        </div>
        <Link
          className={activeItem === 'accounts' ? 'active' : undefined}
          to={`/workspaces/${workspaceId}/accounts`}
        >
          Accounts
        </Link>
        <Link
          className={activeItem === 'settings' ? 'active' : undefined}
          to={`/workspaces/${workspaceId}/settings`}
        >
          Settings
        </Link>
      </div>
      {submitButton && (
        <div className='right-sub-nav ml-auto justify-right'>
          {submitButton}
        </div>
      )}
    </nav>
  );
}
