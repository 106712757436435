import { useState } from 'react';
// @ts-expect-error TS(7016): Could not find a declaration file for module 'reac...
import DatePicker from 'react-datepicker';

import { FilterWidget } from '.';

import { DropdownField, DropdownMultiField, NumberInput } from '../Core';
import { parseDateFilterParam } from './utils/filters';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../Core/Tabs';

// Simple filter widgets that simply have a multi-select dropdown
export const createSimpleFilterWidget: (
  id: string,
  label: string,
  fixedParamName: string, // For these simple filters, the paramName is fixed
  selectOptions: { value: string; label: string }[],
  creatable?: boolean
) => FilterWidget = (id, label, fixedParamName, selectOptions, creatable) => {
  // eslint-disable-next-line react/display-name
  return ({ paramValue, onChange }) => {
    const selectedItems = paramValue ? paramValue.split(',') : [];
    const allSelectOptions = [
      ...selectOptions,
      // Add any selected items that are not in the options
      ...selectedItems
        .filter(
          (item) => !selectOptions.find((option) => option.value === item)
        )
        .map((item) => ({ value: item, label: item }))
    ];
    return (
      <>
        <DropdownMultiField
          selected={selectedItems}
          options={allSelectOptions}
          onChange={(items: { label: string; value: string }[]) => {
            const paramValue = items.map((item) => item.value).join(',');
            const paramValDisplay = items.map((item) => item.label).join(',');
            onChange({
              id,
              paramName: fixedParamName,
              paramValue,
              displayState: `${label}: ${paramValDisplay}`,
              error: !paramValue ? 'A value is required' : ''
            });
          }}
          creatable={creatable}
          formatCreateLabel={(inputValue: string) => `Add "${inputValue}"`}
        />
      </>
    );
  };
};

const DATE_TIME_OPERATOR_MAP: Record<string, string> = {
  eq: '=',
  ne: '!=',
  gt: '>',
  gte: '>=',
  lt: '<',
  lte: '<='
};
// Date comparison filter widgets that compare a field against a date time
export const createDateTimeFilterWidget: (
  id: string,
  label: string,
  baseParamName: string // Example param: invited_at__eq
) => FilterWidget = (id, label, baseParamName) => {
  // eslint-disable-next-line react/display-name
  return ({ paramName, paramValue, onChange }) => {
    const [dateType, setDateType] = useState('absolute');
    const [tab, setTab] = useState('absolute');
    const [filterOperator, setFilterOperator] = useState<string>(() => {
      if (paramName) {
        const parts = paramName.split('__');
        if (parts.length === 2) {
          return parts[1];
        }
      }
      return 'eq';
    });
    const [filterValue, setFilterValue] = useState<any>(() => {
      if (paramValue) {
        const result = parseDateFilterParam(paramValue);
        if (['date', 'integer'].includes(result.type)) {
          const paramType = result.type === 'date' ? 'absolute' : 'relative';
          setTab(paramType);
          setDateType(paramType);
          return result.value;
        }
      }
      return null;
    });

    const handleChange = (operator: string, value: any) => {
      let paramValue = value;
      if (typeof paramValue === 'number') {
        paramValue = paramValue.toString();
        onChange({
          id,
          paramName: baseParamName + '__' + operator,
          paramValue,
          displayState: `${label} ${
            DATE_TIME_OPERATOR_MAP[operator] ?? ''
          } ${paramValue} days ago`,
          error: !operator ? 'A comparison selection is required' : '',
          paramType: 'date'
        });
        return;
      }
      if (typeof paramValue === 'object' && paramValue.toISOString)
        paramValue = paramValue.toISOString();
      if (!value) {
        onChange({
          id,
          paramName: baseParamName + '__' + operator,
          paramValue: '',
          displayState: `${label} ${DATE_TIME_OPERATOR_MAP[operator] ?? ''}`,
          error: 'A date is required',
          paramType: 'date'
        });
        return;
      }

      onChange({
        id,
        paramName: baseParamName + '__' + operator,
        paramValue,
        displayState: `${label} ${
          DATE_TIME_OPERATOR_MAP[operator] ?? ''
        } ${paramValue}`,
        error: !operator ? 'A comparison selection is required' : '',
        paramType: 'date'
      });
    };

    return (
      <div className='flex flex-col gap-4'>
        <DropdownField
          onChange={(event: any) => {
            setFilterOperator(event.target.value);
            handleChange(event.target.value, filterValue);
          }}
          selected={filterOperator}
          options={[
            { value: 'eq', display: 'equals' },
            { value: 'ne', display: "doesn't equal" },
            { value: 'gt', display: 'is after' },
            { value: 'gte', display: 'is on or after' },
            { value: 'lt', display: 'is before' },
            { value: 'lte', display: 'is on or before' }
          ]}
        />
        <Tabs value={tab} onValueChange={setTab}>
          <TabsList className='mb-4 w-[288px]'>
            <TabsTrigger className='flex-1' value='absolute'>
              Exact
            </TabsTrigger>
            <TabsTrigger className='flex-1' value='relative'>
              Relative
            </TabsTrigger>
          </TabsList>
          <TabsContent value='absolute'>
            <DatePicker
              inline
              selected={dateType === 'absolute' ? filterValue : ''}
              showTimeSelect
              onSelect={(val: any) => {
                setDateType('absolute');
                setFilterValue(val ?? '');
                handleChange(filterOperator, val ?? '');
              }}
              onChange={(val: any) => {
                setDateType('absolute');
                setFilterValue(val ?? '');
                handleChange(filterOperator, val ?? '');
              }}
              maxDate={new Date()}
              dateFormat='MMM d, yyyy H:mm'
            />
          </TabsContent>
          <TabsContent value='relative'>
            <label className='flex items-center justify-end  gap-2'>
              <div className='[&_*]:!text-right flex-1'>
                <NumberInput
                  value={dateType === 'relative' ? filterValue : ''}
                  onChange={(value: number) => {
                    setDateType('relative');
                    setFilterValue(value);
                    handleChange(filterOperator, value);
                  }}
                />
              </div>
              <p className='font-medium text-gray-800 shrink-0'>days ago</p>
            </label>
          </TabsContent>
        </Tabs>
      </div>
    );
  };
};
