import { IAPIActionState } from '../../pages/types';
import type { Template } from '../types';

export const EMONEY_CREATE_FACTS: Template = {
  id: 'emoney_create_facts',
  display: 'Create Facts',
  configuration: {
    name: { type: 'textfield', label: 'Connector Name' },
    clientId: { type: 'textfield', label: 'Client ID' }
  },
  defaultState: {
    name: 'EMoney Create Facts',
    template: 'emoney_create_facts',
    url: 'https://api-externalbeta2.emaplan.com/public/v2/clients',
    method: 'POST',
    headers: {
      Authorization: 'Bearer {{emoney_token}}',
      apikey: '{{emoney_api_token}}'
    },
    body: '',
    params: {},
    responseStructure: '',
    responseMapping: {},
    testParameters: {}
  },
  loadConfig: (state) => {
    const items = state.url.split('/');

    // if 6 items in the URL then its default state and no clientId stored
    if (items.length === 6) {
      return {
        name: state.name,
        clientId: ''
      };
    } else {
      return {
        name: state.name,
        clientId: items[6]
      };
    }
  },
  saveConfig: (state, configValues) => {
    const { name, clientId } = configValues;
    const newValues: Partial<IAPIActionState> = {
      name: name,
      url: `https://api-externalbeta2.emaplan.com/public/v2/clients/${clientId}/connections/createandlinkfacts`
    };
    return { ...state, ...newValues };
  }
};
