import {
  CollapsibleSection,
  DynamicTextTooltip,
  InlineTooltip
} from '../../../Core';
import { ReactNode, useMemo, useState } from 'react';

import { OPERATOR_CODE } from '@feathery/react';
import {
  FIELD_OPERATOR_MAP,
  FIELD_OPERATORS
} from '../../../../utils/validation';

import { EditIcon, PlusIcon } from '../../../Icons';
import {
  DecisionLogicModal,
  HideShowBehaviors,
  UnresolvedComparisonRule,
  ValidationBehaviors
} from '../../../Modals';
import useFieldKey from '../../../../utils/useFieldKey';
import ruleStyles from '../../../NavigationRules/styles.module.scss';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { FEATHERY_CART_FIELD } from '../../../../utils/constants';
import { useStripeActions } from '../../Sections/ClickActionSection/useStripeActions';
import { useAppSelector } from '../../../../hooks';
import { getElementIcon } from '../../../SelectionPanel/elementEntries';

export interface ComparisonRule extends UnresolvedComparisonRule {
  field_type: 'servar' | 'hidden';
  comparison: OPERATOR_CODE;
  field_key: string;
  field_id: string;
}

export interface DecisionLogic {
  message?: string;
  rules: ComparisonRule[];
}

function LogicRulesSection({
  element,
  title = 'Custom Validation Errors',
  tooltip = 'Create custom logic rules that invalidate user submissions.',
  logicRulesType,
  logicItems = [],
  behaviorToPerform,
  handleUpdates
}: {
  element: any;
  title?: string;
  tooltip?: string;
  logicRulesType: 'validation' | 'visibility';
  logicItems: DecisionLogic[];
  behaviorToPerform: ValidationBehaviors | HideShowBehaviors;
  handleUpdates: any;
}) {
  const elementEntries = useAppSelector((state) => state.elements);

  const servars = useAppSelector((state) => state.formBuilder.servars);
  const getFieldKey = useFieldKey(true);

  const [Icon, label] = useMemo(
    () =>
      logicItems.length ? [EditIcon, 'Edit Rules'] : [PlusIcon, 'New Rule'],
    [logicItems]
  );

  const [showModal, setShowModal] = useState(false);

  //  the set of purchasable product ids found in SELECT_PRODUCT_TO_PURCHASE actions
  const { purchaseableProducts } = useStripeActions();

  const SelectedField = ({
    fieldId,
    icon,
    suffix = ''
  }: {
    fieldId: string | null;
    icon: ReactNode;
    suffix?: string;
  }) => (
    <DynamicTextTooltip text={getFieldKey(fieldId)} maxLength={15}>
      <span className={styles.fieldItem}>
        <div className={styles.logicRuleField}>
          {icon && <div className={styles.icon}>{icon}</div>}
          <span className={styles.field}>{getFieldKey(fieldId)}</span>
        </div>
        {suffix}
      </span>
    </DynamicTextTooltip>
  );

  return (
    <CollapsibleSection
      collapsible
      title={
        <>
          {title} <InlineTooltip text={tooltip} />
        </>
      }
    >
      {logicItems.length > 0 && <hr style={{ margin: 0 }} />}
      {logicItems.map((v: DecisionLogic, i: number) => {
        return (
          <div className={styles.validationSection} key={i}>
            <div className={styles.validationRuleName}>Rule {i + 1}</div>
            <div className={styles.validationRuleClauses}>
              {v.rules.map((r: ComparisonRule, index: number) => {
                const elementType = servars[r.field_id]?.type;
                const fieldType =
                  elementType === 'custom'
                    ? `custom-${servars[r.field_id].metadata.custom_field_key}`
                    : elementType;

                const icon =
                  r.field_type === 'servar'
                    ? getElementIcon(elementEntries[fieldType], elementType)
                    : null;
                let comparisonText = FIELD_OPERATORS[r.comparison].display;
                if (
                  r.field_type === 'servar' &&
                  FIELD_OPERATOR_MAP[elementType] &&
                  FIELD_OPERATOR_MAP[elementType][r.comparison]
                )
                  comparisonText =
                    FIELD_OPERATOR_MAP[elementType][r.comparison]?.display ||
                    comparisonText;
                const values = (r.values || []).map<React.ReactNode>(
                  (val, i, values) => {
                    const suffix =
                      i < values.length - 1
                        ? i === values.length - 2
                          ? ' or'
                          : ','
                        : '';
                    // each value is either a string or field
                    // However there is a special case for stripe product ids
                    if (typeof val === 'string') {
                      const fieldKey = getFieldKey(r.field_id);
                      const displayVal =
                        fieldKey === FEATHERY_CART_FIELD &&
                        purchaseableProducts[val]
                          ? purchaseableProducts[val].name
                          : val;

                      return (
                        <span className={styles.value} key={i}>
                          {displayVal + suffix}
                        </span>
                      );
                    } else {
                      const valueIcon =
                        val.field_type === 'servar'
                          ? getElementIcon(
                              elementEntries[fieldType],
                              elementType
                            )
                          : null;

                      return (
                        <SelectedField
                          fieldId={val.field_id}
                          key={i}
                          icon={valueIcon}
                          suffix={suffix}
                        />
                      );
                    }
                  }
                );

                return (
                  <div key={index}>
                    {index > 0 && <div className={styles.and}>And</div>}
                    <div
                      className={styles.validationRuleClause}
                      key={r.field_id}
                    >
                      <SelectedField fieldId={r.field_id} icon={icon} />
                      {comparisonText
                        .split(' ')
                        .map((word: string, i: number) => (
                          <span key={i}>{word}</span>
                        ))}
                      {FIELD_OPERATORS[r.comparison].infix && values}
                    </div>
                  </div>
                );
              })}
            </div>

            {v.message && (
              <>
                <div className={styles.validationErrMessageLabel}>
                  Error Message
                </div>
                <div className={styles.validationErrMessage}>{v.message}</div>
              </>
            )}
          </div>
        );
      })}
      <div
        className={classNames(
          ruleStyles.addNavigationRule,
          ruleStyles.ruleTarget,
          styles.validationsButton
        )}
        onClick={() => setShowModal(true)}
      >
        <>
          <div className={styles.icon}>
            <Icon width={15} height={15} color='#414859' />
          </div>
          {label}
        </>
      </div>
      <DecisionLogicModal
        show={showModal}
        setShow={setShowModal}
        element={element}
        logicRulesType={logicRulesType}
        decisionLogicItems={logicItems}
        behaviorToPerform={behaviorToPerform}
        handleUpdates={handleUpdates}
      />
    </CollapsibleSection>
  );
}

export default LogicRulesSection;
