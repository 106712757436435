import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  DropdownMultiField,
  Switch
} from '../../../../../../../../components/Core';
import { useRuleBuilderUpdater } from '../../../context';
import { RuleOperand } from '../../../context/RuleDSL';
import { RuleOperand as Operand } from '../../RuleOperand';
import { ALL_INTEGRATIONS_MAP } from '../../../../../../../../components/FormIntegrations';
import { ROLLOUT_BASE_URL } from '../../../../../../../../components/FormIntegrations/RolloutSidebar/utils';
import classNames from 'classnames';
import ruleStyles from '../../../../../../../../components/NavigationRules/styles.module.scss';
import styles from '../styles.module.scss';
import useFeatheryRedux from '../../../../../../../../redux';

const useRolloutActions = () => {
  const { formId } = useParams<{ formId: string }>();
  const { fetchRolloutToken } = useFeatheryRedux();
  const [actions, setActions] = useState<any[]>([]);

  useEffect(() => {
    fetchRolloutToken()
      .then(({ token }: { token: string }) =>
        fetch(`${ROLLOUT_BASE_URL}/automations`, {
          headers: { Authorization: `Bearer ${token}` }
        })
      )
      .then((res: Response) => res.json())
      .then((results: Record<string, any>[]) => {
        if (!results) return;

        const validAutomations = results.filter(
          (res) => res.trigger.inputParams.formId === formId
        );

        const actionOptions = validAutomations.map((automation) => ({
          label: `${ALL_INTEGRATIONS_MAP[automation.action.appKey]?.title}: ${
            automation.id
          }`,
          value: automation.id
        }));

        setActions(actionOptions);
      });
  }, [formId]);

  return actions;
};

type RunIntegrationActionProps = {
  action: IRuleAction;
  mode?: RuleBuilderMode;
};

const EditMode = ({ action }: RunIntegrationActionProps) => {
  const { updateAction, updateOperand } = useRuleBuilderUpdater((s) => ({
    updateAction: s.updateAction,
    updateOperand: s.updateOperand
  }));

  const rolloutActions = useRolloutActions();
  const integrationActions = action.parameters[0] as RuleOperand;
  const options = action.parameters[1] as RuleOperand;

  const handleActionsChange = (newValue: string) => {
    if (integrationActions) {
      updateOperand(integrationActions.id, {
        type: 'value',
        value: newValue
      });
    } else {
      const operand = new RuleOperand();

      operand.type = 'value';
      operand.value = newValue;

      updateAction(action.id, {
        parameters: [operand.toJSON(), (options as RuleOperand)?.toJSON()]
      });
    }
  };

  const handleAsyncChange = (newValue: boolean) => {
    if (options) {
      const existingValue = JSON.parse((options as IRuleOperand).value);
      existingValue.waitForCompletion = newValue;

      updateOperand((options as IRuleOperand).id, {
        type: 'value',
        value: JSON.stringify(existingValue)
      });
    } else {
      const operand = new RuleOperand();

      operand.type = 'value';
      operand.value = JSON.stringify({ waitForCompletion: newValue });

      updateAction(action.id, {
        parameters: [integrationActions.toJSON(), operand.toJSON()]
      });
    }
  };

  const integrationActionsValue = useMemo(() => {
    return integrationActions.value === ''
      ? []
      : integrationActions.value.split(',');
  }, [integrationActions.value]);

  return (
    <>
      <DropdownMultiField
        className={classNames(
          ruleStyles.ruleTextField,
          ruleStyles.valueInput,
          styles.ruleBuilderInput,
          styles.multiSelectInput
        )}
        selected={integrationActionsValue}
        options={rolloutActions}
        onChange={(items: { label: string; value: string }[]) =>
          handleActionsChange(items.map((item) => item.value).join(','))
        }
      />
      <div className={styles.inputGroup}>
        <label style={{ display: 'flex', gap: '5px' }}>
          Wait for completion
          <Switch
            checked={
              options && (options as IRuleOperand).value
                ? JSON.parse((options as IRuleOperand)?.value).waitForCompletion
                : false
            }
            onCheckedChange={handleAsyncChange}
          />
        </label>
      </div>
    </>
  );
};

const ViewMode = ({ action }: RunIntegrationActionProps) => {
  const rolloutActions = useRolloutActions();

  const [integrations, options] = action.parameters;
  const integrationIds = integrations.value.split(',');
  const integrationNames = integrationIds
    .map((id: string) => {
      return (
        rolloutActions.find((option) => option.value === id)?.label ??
        'Unknown Integration'
      );
    })
    .join(', ');

  let optionsValue;
  try {
    optionsValue = JSON.parse((options as IRuleOperand).value);
  } catch (err) {
    optionsValue = {};
  }
  const hasOptions = !!optionsValue.waitForCompletion;

  const getIntegrationDisplay = (_: IRuleOperand) => {
    return integrationNames;
  };

  const getOptionsDisplay = (operand: IRuleOperand) => {
    if (!operand.value) return null;

    let optionsValue;
    try {
      optionsValue = JSON.parse(operand.value);
    } catch (err) {
      return null;
    }

    return optionsValue.waitForCompletion ? 'Wait for completion' : null;
  };

  return (
    <>
      <Operand operand={integrations} getDisplay={getIntegrationDisplay} bold />
      {hasOptions && (
        <>
          <span>with options</span>
          <Operand
            operand={options as IRuleOperand}
            getDisplay={getOptionsDisplay}
            bold
          />
        </>
      )}
    </>
  );
};

export const RunIntegrationAction = ({
  action,
  mode = 'view'
}: RunIntegrationActionProps) => {
  return (
    <div className={styles.actionContent}>
      {mode === 'view' ? (
        <ViewMode action={action} />
      ) : (
        <EditMode action={action} />
      )}
    </div>
  );
};
