import { useDeferredValue, useEffect, useState } from 'react';
import { useSyncedRefState } from '../../../hooks/useSyncedRefState';
import {
  CollapsibleSection,
  DropdownField,
  InlineTooltip,
  Switch,
  TextField
} from '../../../components/Core';
import { TrashIcon } from '../../../components/Icons';
import { FieldProperties } from '../ExtractionCreateModal/types';
import classNames from 'classnames';
import styles from './styles.module.scss';
import Combobox from '../../../components/Core/Combobox';
import { FieldSelectorWithModal } from '../../../components/Modals';

type AIEntity = {
  name: string;
  field_id: string;
  criteria: string;
  options?: { value: string; label: string }[];
  type?: string;
  examples?: string[];
  default_option?: string;
  required?: boolean;
  unique?: boolean;
};

type Props = {
  type: string;
  entity: AIEntity;
  labels?: { [key: string]: string };
  helpers?: { [key: string]: string };
  placeholders?: { [key: string]: string };
  fieldProperties: Record<string, FieldProperties>;
  hiddenFieldProperties: Record<string, Partial<FieldProperties>>;
  onUpdate: (key: string | object, value?: any) => void;
  onRemove: () => void;
  advanced?: boolean;
  details?: boolean;
  variant_id?: null | string;
};

const Entity = ({
  type,
  entity,
  labels: _labels = {},
  helpers: _helpers = {},
  placeholders: _placeholders = {},
  fieldProperties,
  hiddenFieldProperties,
  onUpdate,
  onRemove,
  advanced = true,
  details = true,
  variant_id
}: Props) => {
  const [open, setOpen] = useState(false);
  const [showAdvanced, setShowAdvanced] = useState(
    !!entity.options?.length ||
      entity.required === false ||
      entity.unique === true
  );
  const [criteria, setCriteria] = useState(entity.criteria || '');
  const deferredEntity = useDeferredValue<any>(entity);
  const getCriteria = useSyncedRefState(criteria);
  const getEntity = useSyncedRefState(entity);
  const isPageNumberQuery = type === 'page_number';
  const isMultipleValueQuery = type === 'multiple_value';

  const labels = {
    name: 'Entity',
    details: 'Details',
    ..._labels
  };

  const helpers = {
    name: 'The name of the variable you want to extract',
    details: '(Optional) Additional entity details to tune the extraction',
    ..._helpers
  };

  const placeholders = {
    name: 'Stock Holding Name',
    details: 'Holdings will include the ticker symbol',
    ..._placeholders
  };

  const allFieldProperties = {
    ...fieldProperties,
    ...hiddenFieldProperties
  };

  useEffect(() => {
    if (allFieldProperties[entity.field_id]?.options) {
      onUpdate('options', allFieldProperties[entity.field_id].options);
    }
  }, []);

  useEffect(() => {
    if (entity.field_id && deferredEntity?.field_id !== entity.field_id) {
      const _entity = getEntity();
      const props = allFieldProperties[entity.field_id];
      const deferredProps = allFieldProperties[deferredEntity.field_id] || {};
      const canUpdateCriteria =
        !_entity.criteria ||
        (!getCriteria() &&
          deferredProps?.semanticDescription === _entity.criteria) ||
        getCriteria() === deferredProps?.semanticDescription;

      const data = {
        options: deferredEntity?.options,
        criteria: deferredEntity?.criteria,
        default_option: deferredEntity?.default_option
      };

      data.options = props?.options ? props.options : [];

      if (
        data.default_option &&
        !data.options.find((o: any) => o.value === data.default_option)
      ) {
        data.default_option = '';
      }

      if (canUpdateCriteria) {
        if (props?.semanticDescription) {
          data.criteria = props.semanticDescription;
          setCriteria('');
        } else {
          data.criteria = '';
          setCriteria('');
        }
      }

      onUpdate(data);
      setShowAdvanced(!!data.options?.length);
    }
  }, [entity.field_id, deferredEntity, setShowAdvanced]);

  const handleCriteriaChange = (newVal: string) => {
    onUpdate('criteria', newVal);
    setCriteria(newVal);
  };

  const fieldOptions = allFieldProperties[entity.field_id]?.options?.length
    ? allFieldProperties[entity.field_id].options
    : entity?.options || [];

  return (
    <div
      className={classNames(styles.keyValuePairs, styles.card, styles.white)}
    >
      <div className={styles.keyValuePair}>
        <div className={styles.key}>
          {labels.name}{' '}
          <InlineTooltip
            size={15}
            style={{ marginTop: '-2px' }}
            text={helpers.name}
          />
        </div>
        <div className={styles.value}>
          <TextField
            value={entity.name}
            placeholder={placeholders.name}
            onComplete={(newVal: string) => onUpdate('name', newVal)}
            required
            disabled={variant_id != null}
          />
        </div>
        {variant_id == null && (
          <div className={styles.actions}>
            <div className={styles.action} onClick={onRemove}>
              <TrashIcon width={16} height={16} />
            </div>
          </div>
        )}
      </div>
      {details && (
        <div className={styles.keyValuePair}>
          <div className={styles.key}>
            {labels.details}{' '}
            <InlineTooltip
              size={15}
              style={{ marginTop: '-2px' }}
              text={helpers.details}
            />
          </div>
          <div className={styles.value}>
            <TextField
              value={entity.criteria}
              placeholder={placeholders.details}
              onComplete={handleCriteriaChange}
              required={isPageNumberQuery}
              disabled={variant_id != null}
            />
          </div>
        </div>
      )}
      <div className={styles.keyValuePair}>
        <div className={styles.key}>
          Save To{' '}
          <InlineTooltip
            size={15}
            style={{ marginTop: '-2px' }}
            className='max-w-[30ch]'
            text={
              isPageNumberQuery ? (
                'The field to save the page numbers to'
              ) : (
                <>
                  <p className='mb-2'>
                    If set, the query result will be saved to this field.
                  </p>
                  <p>
                    Otherwise the result will only be shown in the extraction
                    results page.
                  </p>
                </>
              )
            }
          />
        </div>
        <div className={styles.value}>
          <FieldSelectorWithModal
            selectId={variant_id != null ? variant_id : entity.field_id}
            placeholder='None'
            open={open}
            onOpenChange={setOpen}
            onSelect={({ selectId }) => {
              onUpdate('field_id', selectId || null);
            }}
            className='m-0 flex-0 w-min border-solid border-gray-400'
          />
        </div>
      </div>
      {advanced && variant_id == null && (
        <CollapsibleSection
          title='Advanced Options'
          expanded={showAdvanced}
          onToggle={() => setShowAdvanced(!showAdvanced)}
          separator={false}
          customClasses={{
            header: styles.advancedSectionHeader,
            title: styles.advancedSectionTitle,
            content: styles.advancedSectionContent,
            expandIndicator: styles.advancedSectionExpandIndicator
          }}
        >
          <>
            <div className={styles.keyValuePair}>
              <div className={styles.key}>
                Format{' '}
                <InlineTooltip
                  size={15}
                  style={{ marginTop: '-2px' }}
                  text="(Optional) Specify the format of the data you're looking to pull"
                />
              </div>
              <div className={styles.value}>
                <DropdownField
                  value={entity.type || 'freeForm'}
                  options={[
                    { value: 'freeForm', display: 'Free-Form' },
                    { value: 'singleOption', display: 'Radio Option' },
                    { value: 'number', display: 'Number' },
                    { value: 'dollarValue', display: 'Dollar Value' },
                    { value: 'personsName', display: "Person's Name" },
                    { value: 'address', display: 'Address' }
                  ]}
                  onChange={(event: any) => {
                    const { value } = event.target;
                    onUpdate('type', value);
                  }}
                />
              </div>
            </div>

            {entity.type === 'singleOption' && (
              <>
                <div className={styles.keyValuePair}>
                  <div className={styles.key}>
                    Options{' '}
                    <InlineTooltip
                      size={15}
                      style={{ marginTop: '-2px' }}
                      text='(Optional) Specific values for the entity to be extracted from the document'
                    />
                  </div>
                  <div className={styles.value}>
                    <Combobox
                      value={fieldOptions?.map(({ label }) => label) || []}
                      onChange={(items: string[]) => {
                        onUpdate(
                          'options',
                          items.map((label) => ({ label, value: label }))
                        );
                      }}
                      placeholder='Add Options...'
                      classNames={{
                        multiFieldValueContainer:
                          styles.multiFieldValueContainer,
                        multiFieldMultiValue: styles.multiFieldValue,
                        multiFieldMultiValueLabel:
                          styles.multiFieldMultiValueLabel,
                        multiFieldInput: styles.multiFieldInput,
                        multiFieldPlaceholder: styles.multiFieldPlaceholder,
                        multiFieldIndicatorsContainer:
                          styles.multiFieldIndicatorsContainer,
                        multiFieldControl: (state: any) => {
                          return classNames({
                            [styles.multiFieldControl]: true,
                            [styles.multiFieldControlFocused]: state.isFocused
                          });
                        }
                      }}
                    />
                  </div>
                </div>

                {!!fieldOptions?.length && (
                  <div className={styles.keyValuePair}>
                    <div className={styles.key}>
                      Default{' '}
                      <InlineTooltip
                        size={15}
                        style={{ marginTop: '-2px' }}
                        text='Specify the default option to fallback to if the answer is unclear or not found.'
                      />
                    </div>
                    <div className={styles.value}>
                      <DropdownField
                        selected={entity.default_option}
                        options={fieldOptions.map(({ value, label }) => ({
                          value,
                          display: label
                        }))}
                        onChange={(event: any) =>
                          onUpdate('default_option', event.target.value)
                        }
                      />
                    </div>
                  </div>
                )}
              </>
            )}

            {(entity.type || 'freeForm') === 'freeForm' && (
              <div className={styles.keyValuePair}>
                <div className={styles.key}>
                  Examples{' '}
                  <InlineTooltip
                    size={15}
                    style={{ marginTop: '-2px' }}
                    text='(Optional) Example values for the entity to extract'
                  />
                </div>
                <div className={styles.value}>
                  <Combobox
                    value={entity?.examples || []}
                    onChange={(items: string[]) => {
                      onUpdate('examples', items);
                    }}
                    placeholder='Add Examples...'
                    classNames={{
                      multiFieldValueContainer: styles.multiFieldValueContainer,
                      multiFieldMultiValue: styles.multiFieldValue,
                      multiFieldMultiValueLabel:
                        styles.multiFieldMultiValueLabel,
                      multiFieldInput: styles.multiFieldInput,
                      multiFieldPlaceholder: styles.multiFieldPlaceholder,
                      multiFieldIndicatorsContainer:
                        styles.multiFieldIndicatorsContainer,
                      multiFieldControl: (state: any) => {
                        return classNames({
                          [styles.multiFieldControl]: true,
                          [styles.multiFieldControlFocused]: state.isFocused
                        });
                      }
                    }}
                  />
                </div>
              </div>
            )}

            {isMultipleValueQuery && (
              <>
                <div className={styles.keyValuePair}>
                  <div className={styles.key}>
                    Required{' '}
                    <InlineTooltip
                      size={15}
                      style={{ marginTop: '-2px' }}
                      text='Toggle to make this field required for the extraction to be successful.'
                    />
                  </div>
                  <div className={styles.value} style={{ marginTop: '8px' }}>
                    <Switch
                      id='extraction-required'
                      checked={
                        entity.required === undefined ? true : entity.required
                      }
                      onCheckedChange={(checked) => {
                        onUpdate('required', checked);
                      }}
                      className={styles.switch}
                    />
                  </div>
                </div>
                <div className={styles.keyValuePair}>
                  <div className={styles.key}>
                    Unique{' '}
                    <InlineTooltip
                      size={15}
                      style={{ marginTop: '-2px' }}
                      text='Toggle to make this field unique for the extraction to be successful.'
                    />
                  </div>
                  <div className={styles.value} style={{ marginTop: '8px' }}>
                    <Switch
                      id='extraction-unique'
                      checked={entity.unique || false}
                      onCheckedChange={(checked) => {
                        onUpdate('unique', checked);
                      }}
                      className={styles.switch}
                    />
                  </div>
                </div>
              </>
            )}
          </>
        </CollapsibleSection>
      )}
    </div>
  );
};

export default Entity;
