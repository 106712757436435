import { memo, useRef } from 'react';

import { SearchIcon } from '../../Icons';
import { TextField } from '../../Core';
import { Button } from '../../Core/Button/button';
import { cn } from '../../../utils/cn';

function SearchBar({ searchValue = '', onSearch, searchCount = 0 }: any) {
  const searchInput = useRef<any>({});

  let info;
  if (searchCount > 0) {
    let message = '1 element';
    if (searchCount > 1) message = `${searchCount} elements`;
    info = {
      type: 'normal',
      message: `${message} found for '${searchValue}'`
    };
  } else {
    info = {
      type: 'notice',
      message: `0 elements found for '${searchValue}'`
    };
  }

  const clearSearch = () => {
    searchInput.current.focus();
    onSearch('');
  };

  return (
    <div>
      <div className='relative'>
        <TextField
          name='search'
          placeholder='Search'
          ref={searchInput}
          value={searchValue}
          onChange={onSearch}
          className='!px-10 peer'
          autoComplete='none'
        />
        <SearchIcon
          color='currentColor'
          className='text-gray-600 absolute left-2 h-5 w-5 top-1/2 transform -translate-y-1/2 peer-focus:text-inherit'
        />
        {searchValue?.length > 0 && (
          <Button
            variant='text-primary'
            size='icon'
            className='absolute right-1 h-6 w-6 top-1/2 transform -translate-y-1/2 text-3xl text-gray-600 hover:text-gray-800'
            onClick={clearSearch}
          >
            &times;
          </Button>
        )}
      </div>
      {searchValue && info && (
        <div
          className={cn(
            'p-2.5 pb-0 text-sm',
            info.type === 'notice' && 'text-primary'
          )}
        >
          {(info as any).message}
        </div>
      )}
    </div>
  );
}

export default memo(SearchBar);
