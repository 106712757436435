import { elementOperation } from '../../../utils/themes';
import { calculateOverrideObjects } from '../utils';
import useFeatheryRedux from '../../../redux';
import { useAppSelector } from '../../../hooks';
import { VancoSearch, VancoSearchResult } from '../../../customer/vanco/search';

function CustomFieldPropertiesPanel({
  mode,
  viewport,
  theme,
  baseProps,
  overrideProps,
  handleUpdates
}: any) {
  const { result: element } = calculateOverrideObjects(
    baseProps,
    overrideProps,
    true
  );
  const servar = element.servar ?? {};
  const servarMeta = servar.metadata ?? {};

  const customFields = useAppSelector(
    (state) => state.accounts.organization?.custom_fields
  );

  const {
    formBuilder: { addChangedServar }
  } = useFeatheryRedux();

  function handlePropChange(propUpdate: any) {
    handleUpdates([elementOperation({ viewport, propUpdate })]);
  }

  function handleServarChange(servar: any) {
    handlePropChange({ servar });

    // In the form builder, we need to record that we made changes to this servar
    // So we can show the publish confirmation modal
    if (mode === 'builder') addChangedServar(element.servar.id);
  }

  /* Handle Vanco Search Panel */
  const showVanco =
    customFields &&
    customFields.find((field: any) => field.key === servarMeta.custom_field_key)
      ?.vanco_search;
  if (showVanco) {
    return (
      <VancoSearch
        onSelectItem={(item: VancoSearchResult | null) => {
          handleServarChange({
            metadata: {
              ...servarMeta,
              custom: {
                selectedVancoItem: item,
                renderUrl: item ? item.attributes['render-url'] : null
              }
            }
          });
        }}
        selectedItem={servarMeta.custom?.selectedVancoItem}
      />
    );
  }

  return (
    <div style={{ padding: '10px 15px' }}>
      No properties to configure for this field
    </div>
  );
}

export default CustomFieldPropertiesPanel;
