import { Negative, Positive } from '../../../../../components/Core/Button';
import Dialog from '../../../../../components/Dialog';
import styles from './styles.module.scss';

type ConfirmationModalProps = {
  show: boolean;
  title: string;
  message: string;
  setShow: (show: boolean) => void;
  onConfirm?: () => void;
  onCancel: () => void;
};

export const ConfirmationModal = (props: ConfirmationModalProps) => {
  const {
    show = false,
    title = 'Confirm',
    message = 'Are you sure?',
    setShow = () => {},
    onConfirm,
    onCancel = () => {}
  } = props;

  const handleCancel = () => {
    onCancel();
    setShow(false);
  };

  const handleConfirm = () => {
    if (onConfirm) onConfirm();
    setShow(false);
  };

  return (
    <Dialog
      isOpen={show}
      onClose={() => setShow(false)}
      title={title}
      size='sm'
      shouldShowCloseIcon
    >
      <div className={styles.message}>{message}</div>
      <div className={styles.actions}>
        <Negative onClick={handleCancel}>Cancel</Negative>
        {onConfirm && <Positive onClick={handleConfirm}>Confirm</Positive>}
      </div>
    </Dialog>
  );
};
