import { useState, useCallback, useEffect } from 'react';
import {
  VancoSearchConfig,
  VancoSearchResult,
  VancoSearchPagination
} from './types';
import searchVancoProducts from './service';
import mockSearchVancoProducts from './service.mock';
import useFeatheryRedux from '../../../redux';
import { useAppSelector } from '../../../hooks';

export interface UseVancoSearchConfig extends Omit<VancoSearchConfig, 'jwt'> {
  isMock?: boolean;
  mockOptions?: {
    mockDelay?: number;
    totalResultsCount?: number;
  };
}

export interface UseVancoSearchResult {
  searchTerm: string;
  results: VancoSearchResult[];
  resultsCount: number;
  isLoading: boolean;
  error: string | null;
  pagination: VancoSearchPagination;

  search: (term: string) => void;
  changePage: (pageNumber: number) => void;
  changePageSize: (pageSize: number) => void;
}

/**
 * Handle searching Vanco products
 *
 * @param config Configuration for the search
 */
const useVancoSearch = ({
  searchUrl,
  vancoOrgId,
  defaultPageSize = 10,
  isMock = false,
  mockOptions
}: UseVancoSearchConfig): UseVancoSearchResult => {
  const { fetchVancoToken } = useFeatheryRedux();
  const vancoToken = useAppSelector(
    (state) => (state.integrations as any).vancoToken
  );
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: defaultPageSize
  });

  const [results, setResults] = useState<VancoSearchResult[]>([]);
  const [resultsCount, setResultsCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const executeSearch = useCallback(
    async (term: string, pageNumber: number, pageSize: number) => {
      if (!term) {
        return;
      }

      setIsLoading(true);
      setError(null);

      try {
        // Check if the token exists or fetch it
        let token = vancoToken;
        if (!token) {
          const token_result = await fetchVancoToken();
          token = token_result.token;
        }

        const searchConfig = {
          searchUrl,
          jwt: token,
          vancoOrgId
        };

        const searchParams = { searchTerm: term, pageNumber, pageSize };

        // Use either the mock or real search function
        const searchFn = isMock ? mockSearchVancoProducts : searchVancoProducts;
        const response = await searchFn(searchConfig, searchParams);

        setResults(response.data || []);
        setResultsCount(response.resultsCount || 0);
      } catch (err) {
        const errorMessage =
          (err as Error).message || 'An error occurred during the search';
        setError(errorMessage);
        setResults([]);
        setResultsCount(0);

        // Token error, refetch jwt and try search again
        if (err instanceof Error && err.message.startsWith('Unauthorized')) {
          const searchParams = { searchTerm: term, pageNumber, pageSize };
          try {
            const token_result = await fetchVancoToken();
            const token = token_result.token;
            const retrySearchConfig = {
              searchUrl,
              jwt: token,
              vancoOrgId
            };

            const searchFn = isMock
              ? mockSearchVancoProducts
              : searchVancoProducts;
            const retryResponse = await searchFn(
              retrySearchConfig,
              searchParams
            );

            setError(null);
            setResults(retryResponse.data || []);
            setResultsCount(retryResponse.resultsCount || 0);
          } catch (refetchError) {
            setError(
              (refetchError as Error).message ||
                'An error occurred during the search'
            );
          }
        }
      } finally {
        setIsLoading(false);
      }
    },
    [vancoToken, isMock, searchUrl, vancoOrgId, fetchVancoToken, mockOptions]
  );

  const search = useCallback((term: string) => {
    if (!term) return;
    setSearchTerm(term);
    setPagination((prev) => ({ ...prev, pageNumber: 1 })); // Reset to first page on new search
  }, []);

  const changePage = useCallback((pageNumber: number) => {
    setPagination((prev) => ({ ...prev, pageNumber }));
  }, []);

  const changePageSize = useCallback((pageSize: number) => {
    setPagination((prev) => ({ ...prev, pageSize, pageNumber: 1 }));
  }, []);

  useEffect(() => {
    if (searchTerm) {
      executeSearch(searchTerm, pagination.pageNumber, pagination.pageSize);
    }
  }, [searchTerm, pagination.pageNumber, pagination.pageSize, executeSearch]);

  return {
    searchTerm,
    results,
    resultsCount,
    isLoading,
    error,
    pagination: {
      pageNumber: pagination.pageNumber,
      pageSize: pagination.pageSize,
      totalPages: Math.ceil(resultsCount / pagination.pageSize)
    },

    search,
    changePage,
    changePageSize
  };
};

export default useVancoSearch;
