import { Link, useParams } from 'react-router-dom';
import { Button, DynamicTextTooltip } from '../Core';

import '../../style/navigation.scss';
import { BackArrowIcon } from '../Icons';
import styles from './styles.module.scss';

import classNames from 'classnames';
import { useAppSelector } from '../../hooks';
import { openTab } from '../../utils/domOperations';
import { useState } from 'react';
import { UserCollaborationModal } from '../Modals';
import { Tooltip } from '../Core/Tooltip/Tooltip';
import { useHostedURL } from '../Core/Button/ShareButton/ShareFormOverlay';

const MAX_FORM_LABEL_LENGTH = 14;

export default function FormNavigation({
  activeItem,
  submitButton,
  handleUndo,
  handleRedo
}: any) {
  const { formId } = useParams<{ formId: string }>();
  const panel = useAppSelector((state) => state.panels.panels[formId]);
  const undoStack = useAppSelector((state) => state.formBuilder.undoStack);
  const redoStack = useAppSelector((state) => state.formBuilder.redoStack);
  const account = useAppSelector((state) => state.accounts.account);
  const roleAllowInvite =
    account.role === 'admin' || account.permission_invite_collaborators;
  const org = useAppSelector((state) => state.accounts.organization);
  const getHostedUrl = useHostedURL(org, panel);
  const [sendStep, setSendStep] = useState(0);
  const [initialSessionMethod, setInitialSessionMethod] = useState('direct');
  const collaborationAllowed = roleAllowInvite && panel?.collaboration_enabled;

  let formLabel = panel?.key;
  if (panel?.key.length > MAX_FORM_LABEL_LENGTH) {
    formLabel = panel.key.slice(0, MAX_FORM_LABEL_LENGTH) + '...';
  }

  return (
    <nav className='main-nav'>
      <div className='left-nav'>
        <div className='left-nav-header'>
          {!org?.whitelabel?.form_direct && (
            <Link to='/forms/' className='back-container'>
              <BackArrowIcon />
            </Link>
          )}
          <DynamicTextTooltip
            text={panel?.key}
            maxLength={MAX_FORM_LABEL_LENGTH}
            placement='bottom'
          >
            <span>{formLabel}</span>
          </DynamicTextTooltip>
        </div>
        {account.role !== 'viewer' && (
          <>
            <Link
              className={activeItem === 'builder' ? 'active' : undefined}
              to={`/forms/${formId}/`}
            >
              Designer
            </Link>
            {!org?.disabled_form_tabs.includes('flow') && (
              <Link
                className={activeItem === 'flow' ? 'active' : undefined}
                to={`/forms/${formId}/flow/`}
              >
                Flow
              </Link>
            )}
            {!org?.disabled_form_tabs.includes('logic') &&
              (account.role === 'admin' || account.permission_edit_logic) && (
                <Link
                  className={activeItem === 'logic' ? 'active' : undefined}
                  to={`/forms/${formId}/logic/`}
                >
                  Logic
                </Link>
              )}
            {!org?.disabled_form_tabs.includes('integrations') && (
              <Link
                className={activeItem === 'integrations' ? 'active' : undefined}
                to={`/forms/${formId}/integrations/`}
              >
                Integrations
              </Link>
            )}
          </>
        )}
        {!org?.disabled_form_tabs.includes('results') && (
          <Link
            className={activeItem === 'results' ? 'active' : undefined}
            to={`/forms/${formId}/results/`}
          >
            Results
          </Link>
        )}
        {account.role !== 'viewer' && !org?.whitelabel && (
          <>
            {org?.enterprise_features.analytics ? (
              <Link
                className={activeItem === 'analytics' ? 'active' : undefined}
                to={`/forms/${formId}/analytics/`}
              >
                Analytics
              </Link>
            ) : (
              <Tooltip content='Reach out to sales to access form analytics'>
                <div className='form-nav-tab disabled'>Analytics</div>
              </Tooltip>
            )}
          </>
        )}
        {!org?.disabled_form_tabs.includes('settings') && (
          <Link
            className={activeItem === 'settings' ? 'active' : undefined}
            to={`/forms/${formId}/settings/`}
          >
            Settings
          </Link>
        )}
        {account.role === 'viewer' && (
          <>
            {collaborationAllowed && (
              <>
                <div
                  className='form-nav-tab'
                  onClick={() => {
                    setInitialSessionMethod('email');
                    setSendStep(3);
                  }}
                >
                  Send Email Invites
                </div>
                <div
                  className='form-nav-tab'
                  onClick={() => {
                    setInitialSessionMethod('direct');
                    setSendStep(3);
                  }}
                >
                  Start Form Directly
                </div>
                <UserCollaborationModal
                  step={sendStep}
                  setStep={setSendStep}
                  initialSessionMethod={initialSessionMethod}
                />
              </>
            )}

            {!collaborationAllowed && (
              <div
                className='form-nav-tab'
                onClick={() => openTab(getHostedUrl())}
              >
                Open Form
              </div>
            )}
          </>
        )}
      </div>
      <div className={classNames('right-sub-nav', 'ml-auto')}>
        {['builder', 'flow', 'logic'].includes(activeItem) && (
          <div className={styles.editorButtons}>
            <Button.Undo onClick={handleUndo} disabled={undoStack.length < 1} />
            <Button.Redo onClick={handleRedo} disabled={redoStack.length < 1} />
            {submitButton}
          </div>
        )}
        {activeItem === 'settings' && submitButton}
      </div>
    </nav>
  );
}
