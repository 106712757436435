import { useMemo, useState } from 'react';
import { PageProps } from './types';
import { NavBar } from '../components/NavBar';
import { MAP_RESPONSE_STRUCTURE_PAGE } from '../constants';
import { DataMapInput } from '../components/DataMapInput';
import { JSONEditor } from '../../../JSONEditor';
import FieldSelector from '../../../../components/Modals/FieldSelectorWithModal/FieldSelector';
import styles from './styles.module.scss';
import useFieldKey from '../../../../utils/useFieldKey';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../../hooks';

const validateState = (state: any, { setError }: any) => {
  const { responseMapping } = state;
  const entries = Object.entries(responseMapping);

  const hasValues = entries.every(([, value]) => value);
  if (!hasValues) {
    setError('Please map all selected fields.');
    return false;
  }

  return true;
};

export const MapResponseDataPage = (props: PageProps) => {
  const {
    state,
    onChange = () => {},
    onComplete = () => {},
    onBack = () => {},
    setState = () => {}
  } = props;

  const { formId } = useParams<{ formId: string }>();
  const logicAllFields = useAppSelector((state) =>
    formId ? state.panels.panels[formId].logic_all_fields : true
  );

  const getFieldKey = useFieldKey(!!formId);

  const [error, setError] = useState('');
  const [fieldSelector, setFieldSelector] = useState<any>(null);
  const [selectedField, setSelectedField] = useState<any>(null);
  const responseStructure = useMemo(() => {
    try {
      return JSON.parse(state.responseStructure);
    } catch (err) {
      console.error(err);
    }
  }, [state.responseStructure]);

  const closeFieldSelector = () => {
    setFieldSelector(null);
    setSelectedField(null);
  };

  const handleFieldSelector = () => {
    if (!selectedField) return;

    const keyValuePairs = Object.entries(state.responseMapping);

    if (
      !keyValuePairs.length ||
      keyValuePairs.length - 1 < fieldSelector.index
    ) {
      keyValuePairs.push(['', '']);
    }

    const newResponseMapping = keyValuePairs.reduce((acc, [key, value], i) => {
      if (i === fieldSelector.index) {
        const fieldKey = getFieldKey(selectedField.selectId);

        if (fieldKey) {
          return {
            ...acc,
            [fieldKey]: value
          };
        }
      }

      return {
        ...acc,
        [key]: value
      };
    }, {});

    onChange('responseMapping', newResponseMapping);
    closeFieldSelector();
  };

  const handleSubmit = async () => {
    if (validateState(state, { setError })) {
      try {
        await onComplete();
      } catch (err: any) {
        setError(err.message);
      }
    }
  };

  const handleSkip = () => {
    setState({
      responseStructure: '{}',
      responseMapping: {}
    });

    onComplete();
  };

  return (
    <div id={styles.mapResponsePage} className={styles.page}>
      {fieldSelector ? (
        <div className={styles.fieldSelector}>
          <FieldSelector
            otherFormFields={logicAllFields}
            className={styles.fieldSelectorTable}
            selectId={fieldSelector?.id}
            selectType={fieldSelector?.type}
            show={fieldSelector}
            onChange={({ selectId }: any) => setSelectedField({ selectId })}
            inFormBuilder
            excludeServarTypes={['file_upload', 'signature', 'payment_method']}
            hideButtons
          />
        </div>
      ) : (
        <>
          <div className={styles.tableHeader}>
            <div>
              <div>Form Field</div>
              <div>JSON Location</div>
            </div>
            <div>
              Response <span className={styles.label}>Read Only</span>
            </div>
          </div>
          <div className={styles.tableContent}>
            <div className='max-h-[350px] overflow-scroll pt-2'>
              <DataMapInput
                value={state.responseMapping}
                structure={responseStructure}
                showFieldSelector={(index: number, selectedField: any) => {
                  setFieldSelector({
                    ...selectedField,
                    index
                  });
                }}
                onChange={(value) => {
                  onChange('responseMapping', value);
                }}
              />
            </div>
            <div style={{ marginLeft: '5px' }}>
              <JSONEditor code={state.responseStructure} readOnly />
            </div>
          </div>
        </>
      )}
      <NavBar
        back
        submit
        secondary={!fieldSelector}
        error={error}
        onClick={(btn: string) => {
          if (btn === 'submit') {
            if (fieldSelector) handleFieldSelector();
            else handleSubmit();
          } else if (btn === 'back') {
            if (fieldSelector) closeFieldSelector();
            else onBack(MAP_RESPONSE_STRUCTURE_PAGE);
          } else if (btn === 'secondary') handleSkip();
        }}
        text={{
          submit: fieldSelector ? 'Select' : state.id ? 'Save' : 'Create',
          secondary: 'Skip'
        }}
      />
    </div>
  );
};
