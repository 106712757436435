import { useState } from 'react';
import { CloseIcon } from '../../../../../../../components/Icons';
import { useRuleBuilderUpdater } from '../../context';
import { RuleClause } from '../RuleClause';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { ConfirmationModal } from '../../../ConfirmationModal';

type RuleBranchProps = {
  branch: IRuleBranch;
  mode?: RuleBuilderMode;
};

export const RuleBranch = ({ branch, mode = 'view' }: RuleBranchProps) => {
  const [showRemoveModal, setShowRemoveModal] = useState(false);

  const { clauses } = branch;
  const { addClause, removeBranch } = useRuleBuilderUpdater((s) => ({
    addClause: s.addClause,
    removeBranch: s.removeBranch
  }));

  const handleRemove = (force = false) => {
    if (!force) {
      setShowRemoveModal(true);
    } else {
      removeBranch(branch.id);
    }
  };

  return (
    <>
      <ConfirmationModal
        title='Remove Branch'
        message='Are you sure you want to remove this branch?'
        show={showRemoveModal}
        setShow={setShowRemoveModal}
        onConfirm={() => handleRemove(true)}
        onCancel={() => setShowRemoveModal(false)}
      />
      <div
        className={classNames(styles.ruleBranchContainer, {
          [styles.emptyBranch]:
            clauses.length === 1 &&
            !clauses[0].when &&
            !clauses[0].actions.length,
          [styles.viewMode]: mode === 'view',
          [styles.editMode]: mode === 'edit'
        })}
      >
        <div className={styles.ruleBranchContent}>
          {clauses.map((clause, index) => (
            <RuleClause
              isOnlyChild={clauses.length === 1}
              key={clause.id}
              clause={clause}
              mode={mode}
              conditional={clauses.length === 1 && !clause.when}
              addClause={
                mode === 'edit' && index === clauses.length - 1
                  ? () => addClause(branch.id)
                  : undefined
              }
            />
          ))}
        </div>
        {mode === 'edit' && (
          <div className={styles.ruleBranchActions}>
            <CloseIcon width={18} height={18} onClick={() => handleRemove()} />
          </div>
        )}
      </div>
    </>
  );
};
