import Dialog from '../../../../../../../components/Dialog';
import FieldSelector from '../../../../../../../components/Modals/FieldSelectorWithModal/FieldSelector';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../../../../../hooks';

export type SelectFieldPayload = {
  selectId: string;
  selectType: '' | 'servar' | 'hidden';
  selectServarType?: string;
};

type FieldSelectorModalProps = {
  show?: boolean;
  setShow?: (value: boolean) => void;
  selectedField?: Field | null;
  onSelect?: (payload: SelectFieldPayload) => void;
  includeServarTypes?: string[];
  excludeServarTypes?: string[];
  visibleBackdrop?: boolean;
  includeReserved?: boolean;
};

export const LogicFieldSelectorModal = (props: FieldSelectorModalProps) => {
  const {
    show = false,
    setShow = () => {},
    selectedField = null,
    onSelect = () => {},
    includeServarTypes = null,
    excludeServarTypes = [],
    visibleBackdrop = true,
    includeReserved = false
  } = props;

  const { formId } = useParams<{ formId: string }>();
  const allFieldLogic = useAppSelector((state) =>
    formId ? state.panels.panels[formId].logic_all_fields : true
  );

  return (
    <Dialog
      isOpen={show}
      onClose={() => setShow(false)}
      shouldShowCloseIcon
      title='Select Field'
      size='xlg'
      visibleBackdrop={visibleBackdrop}
    >
      <FieldSelector
        selectId={selectedField?.id}
        selectType={selectedField?.type}
        show={show}
        setShow={(value: boolean) => setShow(value)}
        onSelect={onSelect}
        otherFormFields={allFieldLogic}
        inFormBuilder={!!formId}
        selectTitle='Confirm'
        includeServarTypes={includeServarTypes}
        excludeServarTypes={[
          ...(selectedField?.ruleValueIndex !== null
            ? ['file_upload', 'signature', 'payment_method', 'hex_color'] // exclude these types from the right side
            : ['hex_color']), // not ever usable in a rule on either side (no operators)
          ...excludeServarTypes
        ]}
        includeReserved={includeReserved}
      />
    </Dialog>
  );
};
