import { useState } from 'react';
import { useAppSelector } from '../../hooks';
import { EditIcon, FolderIcon, MenuIcon, TrashIcon } from '../Icons';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from '../Core/DropdownMenu';
import { Button } from '../Core/Button/button';
import Dialog from '../Dialog';
import Label from '../Dialog/Label';
import { TextField } from '../Core';
import { Neutral, Positive } from '../Core/Button';
import styles from './styles.module.scss';

type FolderListProps = {
  folderData: Record<string, any[]>;
  onClick: (folder: string) => void;
  name: string;
  onRename?: (oldName: string, newName: string) => void;
  onDelete?: (folderName: string) => void;
  searchStr?: string;
};

export function FolderList({
  folderData,
  onClick,
  name,
  onRename,
  onDelete,
  searchStr = ''
}: FolderListProps) {
  const [isRenameDialogOpen, setIsRenameDialogOpen] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState('');
  const [newFolderName, setNewFolderName] = useState('');

  const sessionFolders =
    useAppSelector((state) => state.folders.sessionFolders[name]) || [];

  const folders = Array.from(
    new Set([...sessionFolders, ...Object.keys(folderData)])
  );

  function reset() {
    setNewFolderName('');
    setSelectedFolder('');
    setIsRenameDialogOpen(false);
  }

  const handleRename = (folder: string) => {
    setSelectedFolder(folder);
    setNewFolderName(folder);
    setIsRenameDialogOpen(true);
  };

  const handleRenameSubmit = async () => {
    if (onRename && newFolderName.trim() !== '') {
      await onRename(selectedFolder, newFolderName.trim());
    }
    setIsRenameDialogOpen(false);
  };

  const handleDelete = (folder: string) => {
    if (onDelete) {
      onDelete(folder);
    }
  };

  const filteredFolders = folders.filter(
    (folder) =>
      !searchStr || folder.toLowerCase().includes(searchStr.toLowerCase())
  );

  if (filteredFolders.length === 0) {
    return <span>No folders found</span>;
  }

  return (
    <>
      {filteredFolders
        .sort((a: any, b: any) => (a.toLowerCase() > b.toLowerCase() ? 1 : -1))
        .map((folder) => {
          const data = folderData[folder] ?? [];
          return (
            <div key={folder} className={styles.folderItem}>
              <div
                className='flex items-center flex-1'
                onClick={() => onClick(folder)}
              >
                <FolderIcon />
                <p className={styles.folderLabel}>{folder || 'No Folder'}</p>
                <p className={styles.folderDetails}>{`${data.length} ${
                  data.length === 1 ? name : name + 's'
                }`}</p>
              </div>

              {folder && (
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button
                      variant='ghost'
                      className='hover:bg-gray-300'
                      size='icon'
                    >
                      <MenuIcon height={16} width={16} />
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent align='end'>
                    <DropdownMenuItem
                      onClick={(e) => {
                        e.stopPropagation();
                        handleRename(folder);
                      }}
                    >
                      <EditIcon className='mr-2 h-4 w-4' color='currentColor' />
                      Rename
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDelete(folder);
                      }}
                    >
                      <TrashIcon
                        className='mr-2 h-4 w-4'
                        color='currentColor'
                      />
                      Delete
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              )}
            </div>
          );
        })}

      <Dialog
        isOpen={isRenameDialogOpen}
        onClose={reset}
        shouldShowCloseIcon={false}
        title={`Rename folder`}
        alignCenter
        style={{ padding: '24px 40px' }}
      >
        <div style={{ textAlign: 'left', paddingBlock: 16, display: 'block' }}>
          <label style={{ display: 'block' }}>
            <Label>Folder Name</Label>
            <TextField
              autoFocus
              value={newFolderName}
              onChange={setNewFolderName}
              placeholder='Enter new folder name'
            />
          </label>
        </div>
        <div className='dialog-form-action'>
          <Neutral onClick={reset}>Cancel</Neutral>
          <Positive disabled={!newFolderName} onClick={handleRenameSubmit}>
            Rename
          </Positive>
        </div>
      </Dialog>
    </>
  );
}

export default FolderList;
