import { memo, useEffect, useMemo, useState } from 'react';

import HeaderFilterResultsTable from '../components/HeaderFilterResultsTable';
import { useHistory } from 'react-router-dom';
import {
  CreateWorkspaceModal,
  DeleteWorkspaceModal
} from '../components/Modals';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { formatDate } from '../utils/format';
import useFeatheryRedux from '../redux';
import { useAppSelector } from '../hooks';

function WorkspacesPage() {
  const history = useHistory();
  const { getWorkspaces } = useFeatheryRedux();
  const [resultsPage, setResultsPage] = useState(
    new URLSearchParams(document.location.search).get('page') || 1
  );
  const workspaces = useAppSelector((state) => state.accounts.workspaces);
  const [queryState, setQueryState] = useState({
    searchKey: '',
    sortKey: '',
    sortDirection: ''
  });

  const [workspaceToDelete, setWorkspaceToDelete] = useState('');
  const [isCreating, setIsCreating] = useState(false);
  const dataToRender = useMemo(() => {
    const results = workspaces.data ?? [];
    const toRender = results.map((workspace: any) => {
      const render = {
        ...workspace,
        created_at_pretty: formatDate(workspace.created_at)
      };
      return render;
    });
    return toRender;
  }, [workspaces.data]);

  useEffect(() => {
    (async () => {
      try {
        const { searchKey, sortKey, sortDirection, ...query } = queryState;
        history.push({
          search: `?page=${resultsPage}`
        });
        await getWorkspaces({
          page: resultsPage,
          search: searchKey,
          sort_order: sortKey,
          sort_direction: sortDirection,
          ...query
        });
      } catch {
        console.warn('Load workspace error');
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryState, resultsPage]);

  const onRefresh = () => setQueryState({ ...queryState });

  const getUrlPageParam = (url: string) => {
    if (!url) return '';
    const urlObj = new URL(url);
    const searchParams = new URLSearchParams(urlObj.search);
    const page = searchParams.get('page') ?? '0';
    return page;
  };
  const onPageChange = (page: string) => {
    if (page === '0') page = '1';
    setResultsPage(page);
  };

  const onSearch = (searchTerm: string) => {
    setResultsPage('1'); // reset the page because search/filter change changes pages
    setQueryState({ ...queryState, searchKey: searchTerm });
  };

  return (
    <>
      <div className={styles.pageHeadRow}>
        <h1 className={classNames('page-head', styles.pageHeadTitle)}>
          Workspaces
        </h1>
      </div>
      <HeaderFilterResultsTable
        data={dataToRender}
        columns={[
          { key: 'name', name: 'Name' },
          {
            key: 'monthly_submissions',
            name: 'Submissions This Month'
          },
          { key: 'created_at_pretty', name: 'Created At', sortBy: 'created_at' }
        ]}
        sortable={false}
        onSearch={onSearch}
        useSearch
        name='Workspace'
        dataKey='id'
        onRefresh={onRefresh}
        prevPage={getUrlPageParam(workspaces.prevUrl)}
        nextPage={getUrlPageParam(workspaces.nextUrl)}
        onPageChange={onPageChange}
        onCreate={() => setIsCreating(true)}
        onSelect={(workspace: any) =>
          history.push(`/workspaces/${workspace.id}/accounts`)
        }
        onDelete={(workspace: any, e: any) => {
          e.preventDefault();
          e.stopPropagation();
          setWorkspaceToDelete(workspace.id);
        }}
      />
      <CreateWorkspaceModal
        show={isCreating}
        setShow={(show: any) => setIsCreating(show)}
      />
      <DeleteWorkspaceModal
        setShow={() => setWorkspaceToDelete('')}
        show={Boolean(workspaceToDelete)}
        workspaceId={workspaceToDelete}
      />
    </>
  );
}

export default memo(WorkspacesPage);
