import '../../style/dialog-form.css';

import { Fragment, useState } from 'react';
import IntegrationsSidebar from './IntegrationsSidebar';

import { ALL_INTEGRATIONS_MAP, INTEGRATIONS } from './types';
import { DropdownField, InlineTooltip, PropertyLabel } from '../Core';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { FieldSelectorWithModal } from '../Modals';
import { PlusIcon, TrashIcon } from '../Icons';
import useIntegrations from './useIntegrations';
import useReceiveOauthMessage from './shared/useReceiveOauthMessage';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../hooks';
import { capitalizeFirstLetter } from '../../utils/format';
import { IS_PROD_BE } from '../../api/utils';

const schwabDomain = IS_PROD_BE ? 'api.schwabapi.com' : 'sandbox.schwabapi.com';
const schwabClientId = IS_PROD_BE
  ? 'Z2sDI6OWfh4JUQOf5le6VBnPsNE4Gj2w'
  : 'GYm1yyAQXew2Eatq0PCJTJhv2yylz2af';
const oauthRedirect = `https://${schwabDomain}/v1/oauth/authorize?client_id=${schwabClientId}&redirect_uri=${location.origin}/oauth-redirect/`;

const DEFAULT_SCHWAB_FIELDS = [{ param: '', field_id: '', field_type: '' }];

function SchwabSettingsSidebar() {
  const { formId } = useParams<{ formId: string }>();

  const email = useAppSelector((state) => state.accounts.account.email);

  // Global/cached integration settings
  const integration = useIntegrations({
    type: INTEGRATIONS.SCHWAB,
    panelId: formId,
    includeInactive: true
  });

  // Local/draft integration settings
  const [error, setError] = useState('');
  const [isPartial, setIsPartial] = useState(false);
  const meta = integration?.data.secret_metadata ?? {};
  const [schwabFields, setSchwabFields] = useState(
    meta.schwab_fields ?? DEFAULT_SCHWAB_FIELDS
  );
  const [emailFieldId, setEmailFieldId] = useState(meta.email_field ?? '');
  const [emailFieldType, setEmailFieldType] = useState(
    meta.email_field_type ?? ''
  );

  const oauthState = meta.oauth ?? {};
  const authorizedEmails = Object.keys(oauthState);
  const oauthToken: string | undefined = oauthState[email]?.oauth_token;

  function onSubmitCustom(newIsActive: boolean) {
    setError('');

    // Filter out completely empty custom info rows
    const filtered = schwabFields.filter((f: any) => f.param || f.field_id);

    if (newIsActive) {
      const partial = filtered.some(
        ({ param, field_id }: any) => !param || !field_id
      );
      setIsPartial(partial);
      if (partial) return;
      if (
        !Object.values(oauthState).some(
          (accountOauth: any) => accountOauth.oauth_token
        )
      ) {
        setError('At least one Schwab advisor account must be authorized');
        return;
      }
    }

    return {
      isUpdate: integration?.data,
      apiKey: '',
      secretMetadata: {
        schwab_fields: filtered,
        email_field: emailFieldId,
        email_field_type: emailFieldType
      }
    };
  }

  const authorizeSchwab = useReceiveOauthMessage(
    INTEGRATIONS.SCHWAB,
    oauthRedirect,
    !!oauthToken
  );

  return (
    <IntegrationsSidebar
      integrationInfo={ALL_INTEGRATIONS_MAP[INTEGRATIONS.SCHWAB]}
      authorizeData={{
        authorize: authorizeSchwab,
        oauthToken
      }}
      isPartial={isPartial}
      onSubmitCustom={onSubmitCustom}
      customError={error}
    >
      <form>
        {authorizedEmails.length > 0 && (
          <div style={{ marginTop: '10px' }}>
            Authorized Accounts: {authorizedEmails.join(', ')}
          </div>
        )}
        {authorizedEmails.length > 1 && (
          <div style={{ marginTop: '15px' }}>
            <PropertyLabel
              label={
                <>
                  Advisor Email (Optional){' '}
                  <InlineTooltip text='If multiple advisor accounts are authenticated, use the credentials corresponding to the email in this field' />
                </>
              }
            />
            <FieldSelectorWithModal
              selectId={emailFieldId}
              selectType={emailFieldType}
              placeholder='Select'
              onSelect={(data) => {
                setEmailFieldId(data.selectId);
                setEmailFieldType(data.selectType);
              }}
              className={classNames(styles.marginBottom, styles.fieldSelector)}
            />
          </div>
        )}
        <div className={styles.twoColumnContainer}>
          <div className={styles.fieldHeaderText}>Schwab Datapoint</div>
          <div className={styles.fieldHeaderText}>Field</div>
          {schwabFields.map(
            ({ param, field_id, field_type }: any, index: any) => (
              <Fragment key={index}>
                <DropdownField
                  className='dialog-form-input'
                  selected={param ?? ''}
                  onChange={(e: any) => {
                    const newParam = e.target.value;
                    const newSchwabFields = [...schwabFields];
                    newSchwabFields[index] = {
                      param: newParam,
                      field_id,
                      field_type
                    };
                    setSchwabFields(newSchwabFields);
                  }}
                  options={API_PARAMETERS}
                  error={isPartial && !param}
                />
                <div className={styles.fieldSelectorContainer}>
                  <FieldSelectorWithModal
                    selectId={field_id}
                    selectType={field_type}
                    placeholder='Select'
                    onSelect={(data) => {
                      const newSchwabFields = [...schwabFields];
                      newSchwabFields[index] = {
                        param,
                        field_id: data.selectId,
                        field_type: data.selectType
                      };
                      setSchwabFields(newSchwabFields);
                    }}
                    error={isPartial && !field_id}
                    className={classNames(
                      styles.marginBottom,
                      styles.fieldSelector
                    )}
                  />
                  <TrashIcon
                    height={16}
                    width={16}
                    className={classNames(
                      'tr-icon',
                      styles.customPropertyDelete
                    )}
                    onClick={() => {
                      const newSchwabFields = [...schwabFields];
                      newSchwabFields.splice(index, 1);
                      if (newSchwabFields.length === 0)
                        newSchwabFields.push({ ...DEFAULT_SCHWAB_FIELDS[0] });
                      setSchwabFields(newSchwabFields);
                    }}
                  />
                </div>
              </Fragment>
            )
          )}
        </div>
        <PlusIcon
          className={styles.customPropertyAdd}
          onClick={() => {
            const newSchwabFields = [...schwabFields];
            newSchwabFields.push({ ...DEFAULT_SCHWAB_FIELDS[0] });
            setSchwabFields(newSchwabFields);
          }}
        />
      </form>
    </IntegrationsSidebar>
  );
}

const API_PARAMETERS = [
  { value: '', display: 'Select...' },
  { value: 'nameFirstName', display: 'First Name' },
  { value: 'nameMiddleName', display: 'Middle Name' },
  { value: 'nameLastName', display: 'Last Name' },
  { value: 'nameSuffix', display: 'Suffix' },
  { value: 'nameAlias', display: 'Alias' },
  { value: 'dateOfBirth', display: 'Date of Birth' },
  { value: 'ssn', display: 'SSN' },
  { value: 'emailAddress', display: 'Email Address' },
  { value: 'homePhoneNumber', display: 'Home Phone Number' },
  { value: 'mobilePhoneNumber', display: 'Mobile Phone Number' },
  { value: 'businessPhoneNumber', display: 'Business Phone Number' },
  { value: 'mailingAddressType', display: 'Mailing Address Type' },
  {
    value: 'isCitizenOfAnotherCountry',
    display: 'Is Citizen of Another Country'
  },
  { value: 'isUsCitizen', display: 'Is US Citizen' },
  { value: 'isUsResident', display: 'Is US Resident' },
  { value: 'employmentStatus', display: 'Employment Status' },
  { value: 'employmentOccupation', display: 'Occupation' },
  { value: 'employmentEmployerName', display: 'Employer Name' },
  {
    value: 'employmentIsEmployedBySecurityOrBrokerFirm',
    display: 'Is Employed by Security or Broker Firm'
  },
  { value: 'employmentIsDirector', display: 'Is a Director' },
  { value: 'employmentDirectorCompanyName', display: 'Director Company Name' },
  {
    value: 'employmentDirectorTradingSymbol',
    display: 'Director Trading Symbol'
  },
  {
    value: 'identificationIdentificationType',
    display: 'Identification Type'
  },
  {
    value: 'identificationIdentificationNumber',
    display: 'Identification Number'
  },
  {
    value: 'identificationIssuedDate',
    display: 'Identification Issued Date'
  },
  {
    value: 'identificationExpiryDate',
    display: 'Identification Expiry Date'
  },
  {
    value: 'identificationCountry',
    display: 'Identification Country'
  },
  {
    value: 'identificationOtherCountry',
    display: 'Identification Other Country'
  },
  {
    value: 'identificationCountryOfBirth',
    display: 'Identification Country of Birth'
  },
  {
    value: 'identificationPassportIssuedCountry',
    display: 'Identification Passport Issued Country'
  },
  {
    value: 'identificationDriversLicenseIssuedState',
    display: 'Identification Drivers License Issued State'
  },
  {
    value: 'identificationGovernmentIdIssuedState',
    display: 'Identification Government Id Issued State'
  }
];
['home', 'business', 'mailing', 'alternate'].forEach((addrType) => {
  const label = capitalizeFirstLetter(addrType);
  API_PARAMETERS.push(
    {
      value: `${addrType}AddressParamAddressLine1`,
      display: `${label} Address Line 1`
    },
    {
      value: `${addrType}AddressParamAddressLine2`,
      display: `${label} Address Line 2`
    },
    {
      value: `${addrType}AddressParamAddressLine3`,
      display: `${label} Address Line 3`
    },
    { value: `${addrType}AddressParamCity`, display: `${label} Address City` },
    {
      value: `${addrType}AddressParamState`,
      display: `${label} Address State`
    },
    {
      value: `${addrType}AddressParamZipCode`,
      display: `${label} Address Zip Code`
    },
    {
      value: `${addrType}AddressParamCountry`,
      display: `${label} Address Country`
    },
    {
      value: `${addrType}AddressParamIsInternational`,
      display: `${label} Address Is International`
    }
  );
});

export default SchwabSettingsSidebar;
