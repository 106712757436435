import { createSlice } from '@reduxjs/toolkit';
import {
  createCustomField,
  deleteCustomField,
  getOrganization,
  updateCustomField
} from './accounts';
import elementEntries from '../components/SelectionPanel/elementEntries';

const elementsSlice = createSlice({
  name: 'elements',
  initialState: elementEntries,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getOrganization.fulfilled, (_state, action) => {
      const newState = { ...elementEntries };
      const customFields = action.payload.custom_fields;

      if (customFields) {
        customFields.forEach((field: any) => {
          newState[`custom-${field.key}`] = {
            label: field.label,
            image: field.icon,
            type: 'field'
          };
        });
      }

      return newState;
    });

    builder.addCase(createCustomField.fulfilled, (state, action) => {
      const newState = {
        ...state,
        [`custom-${action.payload.key}`]: {
          label: action.payload.label,
          image: action.payload.icon,
          type: 'field'
        }
      };

      return newState;
    });

    builder.addCase(updateCustomField.fulfilled, (state, action) => {
      const newState = { ...state };

      Object.entries(newState).forEach(([key, _value]) => {
        if (
          key.startsWith('custom-') &&
          key.replace('custom-', '') === action.meta.arg.key
        ) {
          if (action.meta.arg.key !== action.payload.key) {
            delete newState[key];
          }
        }
      });

      newState[`custom-${action.payload.key}`] = {
        label: action.payload.label,
        image: action.payload.icon,
        type: 'field'
      };
      return newState;
    });

    builder.addCase(deleteCustomField.fulfilled, (state, action) => {
      const newState = { ...state };
      delete newState[`custom-${action.meta.arg.key}`];
      return newState;
    });
  }
});

export default elementsSlice.reducer;
