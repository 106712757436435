import { useCallback, useLayoutEffect, useRef, useState } from 'react';
import debounce from 'lodash.debounce';
import { v4 as uuidv4 } from 'uuid';

import {
  EditIcon,
  TextFieldIcon
} from '../../../../../../../../../components/Icons';
import {
  TextField,
  ContextMenu
} from '../../../../../../../../../components/Core';
import useField from '../../../../../../../../../utils/useField';
import { RuleExpression, RuleOperand } from '../../../../context/RuleDSL';
import { CaretIcon } from '../../../../../../../../../components/Icons';
import { useGlobalMouseDownToggle } from '../../../../../../../../../components/Core/util';
import useExpressionParser from './useExpressionParser';
import { MULTI_SELECT_FIELDS } from '../../../../../../../../../components/SelectionPanel/elementEntries';

import classNames from 'classnames';
import ruleStyles from '../../../../../../../../../components/NavigationRules/styles.module.scss';
import comparisonRuleStyles from '../../../../../../../../../components/Modals/DecisionLogicModal/styles.module.scss';
import styles from '../styles.module.scss';
import useRuleServarKeys from '../../../../useRuleServarKeys';

type ExpressionProps = {
  leftOperand?: IRuleOperand;
  expression?: IRuleOperand | IRuleExpression;
  onComplete?: (expression: any) => void;
};

export const UNSUPPORTED_SERVAR_TYPES: string[] = ['matrix', 'payment_method'];

export const Expression = ({
  leftOperand,
  expression,
  onComplete = () => {}
}: ExpressionProps) => {
  const fieldType = (leftOperand?.meta.servar_type ?? '').toLowerCase();
  const getField = useField(true);
  const field = getField((leftOperand as IRuleOperand).value);

  const [expressionText, setExpressionText] = useState(
    (expression as IRuleOperand)?.type
      ? new RuleOperand(expression as IRuleOperand).toText()
      : new RuleExpression(expression).toText()
  );

  const [focusState, setFocusState] = useState('');

  // code completion dropdown menu
  const expressionTargetRef = useRef<HTMLDivElement>(null);
  const expressionInputRef = useRef<HTMLInputElement>(null);
  const focusExpressionInput = (newCursorPosition?: number) =>
    setTimeout(() => {
      expressionInputRef.current?.focus();
      if (newCursorPosition !== undefined)
        expressionInputRef.current?.setSelectionRange(
          newCursorPosition,
          newCursorPosition
        );
    }, 100);

  const codeCompleteMenuRef = useRef<HTMLDivElement>(null);
  const [showCodeCompletion, setShowCodeCompletion] = useGlobalMouseDownToggle(
    [codeCompleteMenuRef],
    {
      onCloseCb: () => focusState && focusExpressionInput()
    }
  );
  const [position, setPosition] = useState({});

  function setDialogPosition() {
    // The menu will always be positioned below to the text field
    const inputRect = expressionInputRef.current?.getBoundingClientRect();
    if (inputRect) {
      setPosition({
        x: inputRect.x,
        y: inputRect.y + inputRect.height
      });
    }
  }

  function revealCodeCompletionMenu(alwaysShow = true, termFilter = true) {
    if (showCodeCompletion) return; // already open - don't do anything!

    // Use the word that the cursor is withing or at the end of to filter the
    // code completion options
    const term = getPartialTextBeforeCursor();
    setCodeCompletionFilterTerm(termFilter ? term : '');
    if (term || alwaysShow) {
      setDialogPosition();
      if (!showCodeCompletion) setActiveCodeCompleteIndex(0);
      setShowCodeCompletion(true);
    }
  }

  // value options for dropdown fields, etc.
  // TODO: figure out why field type is changing?
  let valueOptions: any[] = [];
  switch (fieldType) {
    case 'dropdown':
    case 'select': // radio group
    case 'dropdown_multi':
    case 'button_group':
    case 'multiselect': // checkbox group
      valueOptions = field.metadata.options;
      break;
    case 'checkbox':
      valueOptions = ['true', 'false'];
      break;
  }
  const isMultiSelect = MULTI_SELECT_FIELDS.includes(fieldType);

  // validation error
  const [validationError, setValidationError] = useState<string | null>(null);
  // code completion fiter term
  const [codeCompletionFilterTerm, setCodeCompletionFilterTerm] = useState('');

  // Servar field options
  const servarKeys = useRuleServarKeys(UNSUPPORTED_SERVAR_TYPES);
  // The code completion choices:
  // 1 Selectable fields
  const codeCompletionFields = servarKeys
    .filter((key) => key.toLowerCase().includes(codeCompletionFilterTerm))
    .map((key) => {
      const _id = `_${uuidv4()}`;
      return {
        id: _id,
        // TODO: deal with keys that are not valid JS identifiers:
        //   Option 1 - filter out invalid keys identiifier
        //   Option 2 - add them as feathery.fields["key"]
        onMouseDown: () => onCodeComplete(key),
        title: (
          <div id={_id} key={_id}>
            {key}
          </div>
        ),
        Icon: TextFieldIcon
      };
    });

  // 2 Choosable option values
  // Note only present options for selection if the expression is empty
  // (or is just the partial search term) or it is
  // not empty but the expression only contains
  // previoulsly selected options (possibly comma separated)
  const codeCompletionValues = valueOptions
    .filter(
      (opt: any) =>
        expressionText.toLowerCase() === codeCompletionFilterTerm ||
        expressionText
          .split(',')
          .map((selectedValue) => selectedValue.trim())
          .every(
            (selectedValue) =>
              valueOptions.includes(selectedValue) ||
              // could be single quoted
              valueOptions.includes(
                selectedValue.slice(1, selectedValue.length - 1)
              ) ||
              selectedValue.toLowerCase() === codeCompletionFilterTerm
          )
    )
    .filter((opt: any) => opt.toLowerCase().includes(codeCompletionFilterTerm))
    .map((opt: any) => {
      const _id = `_${uuidv4()}`;
      return {
        id: _id,
        onMouseDown: () => {
          // Add the selected value to the expression

          // Special handling for checkbox and boolean values which are not quoted
          let _opt = `'${opt}'`;
          if (fieldType === 'checkbox') _opt = `${opt}`;

          if (isMultiSelect) {
            // If this is a multi-select field, the users selections are added
            // to the expression as a comma separated list.
            // Gets a little more complicated because we may be replacing a
            // search term here as well.
            let exprText = expressionText.trim();
            if (exprText.toLowerCase().endsWith(codeCompletionFilterTerm))
              // remove the search term
              exprText = exprText
                .slice(0, exprText.length - codeCompletionFilterTerm.length)
                .trim();
            const opts = exprText
              .split(',')
              .filter((v) => !!v)
              .map((v) => v.trim());
            const s = new Set(opts);
            // preserving the order here
            if (!s.has(_opt)) opts.push(_opt);

            onCodeComplete(opts.join(', '), true); // replacing the whole expression
          } else onCodeComplete(_opt, true); // single select - just replace the complete value
        },
        title: (
          <div id={_id} key={_id}>
            {opt}
          </div>
        ),
        Icon: EditIcon
      };
    });

  const codeCompletionMatchMenuItems: any[] = []; // includes divider
  codeCompletionMatchMenuItems.push(...codeCompletionValues);
  if (codeCompletionValues.length > 0 && codeCompletionFields.length > 0)
    codeCompletionMatchMenuItems.push('divider');
  codeCompletionMatchMenuItems.push(...codeCompletionFields);
  // Selectable code completions
  const codeCompletionItems = [
    ...codeCompletionValues,
    ...codeCompletionFields
  ];

  const [activeCodeCompleteIndex, setActiveCodeCompleteIndex] = useState<
    number | undefined
  >();

  function scrollToMenuItem(menuItemId: string) {
    const element = document.querySelector(`#${menuItemId}`);
    element?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  }

  useLayoutEffect(() => {
    if (activeCodeCompleteIndex !== undefined) {
      const codeCompleteItem = codeCompletionItems[activeCodeCompleteIndex];
      if (codeCompleteItem) scrollToMenuItem(codeCompleteItem.id);
    }
  }, [activeCodeCompleteIndex, codeCompletionItems]);

  // Used to paste selected code completion text into the expression.
  // It will replace any word that the cursor is within or at the end of.
  const onCodeComplete = (selectedText: string, replace = false) => {
    if (!expressionInputRef.current) return;

    let inputText = selectedText;
    let newCursorPosition = 0;
    if (!replace) {
      inputText = expressionInputRef.current.value;
      const cursorPosition = expressionInputRef.current.selectionStart ?? 0;
      const inputTextBeforeCursor = inputText.slice(0, cursorPosition);
      const termIndex = inputTextBeforeCursor
        .toLowerCase()
        .lastIndexOf(codeCompletionFilterTerm, cursorPosition);

      // if this is an append onto the end of the expression, add a space if there
      // isn't one already
      if (
        cursorPosition === expressionText.length &&
        inputText.slice(-1) !== ' '
      )
        selectedText = ` ${selectedText}`;

      if (termIndex >= 0) {
        inputText =
          inputText.slice(0, termIndex) +
          selectedText +
          inputText.slice(cursorPosition);
        newCursorPosition = termIndex + selectedText.length;
      }
    }
    inputText = inputText.trim();
    setExpressionText(inputText);
    // position the cursor where the user would expect it
    if (replace) newCursorPosition = inputText.length;
    // re-focus the expression input
    focusExpressionInput(newCursorPosition);

    onExpressionComplete();
  };

  // Function to determine if there is partial text before the cursor
  // (it becomes a search term for code completion matches)
  const getPartialTextBeforeCursor = () => {
    if (!expressionInputRef.current) return '';
    const inputText = expressionInputRef.current.value;
    const cursorPosition = expressionInputRef.current.selectionStart ?? 0;
    const inputTextBeforeCursor = inputText.slice(0, cursorPosition);
    const term = inputTextBeforeCursor.split(' ').pop() ?? '';
    // remove any parentheses
    return term.replace(/[()]/g, '').toLowerCase().trim();
  };

  // Debouncing the show of the code completion menu so that it doesn't run on every keystroke
  const showCodeCompleteMenuOnPause = useCallback(
    debounce(async () => {
      revealCodeCompletionMenu(false, true);
    }, 500),
    [expressionInputRef.current]
  );

  // Expression parsing
  const { parseExpressionIntoDsl } = useExpressionParser();

  const onExpressionComplete = () => {
    // don't validate or complete if the user is using code complete
    if (showCodeCompletion) return;

    const result = parseExpressionIntoDsl(expressionText);
    if (typeof result === 'string') {
      setValidationError(result);
      return;
    }
    setValidationError(null);
    onComplete(result);
  };

  // Parse the expression on a pause in typing and only if valid call onComplete
  // so that the save button can be enabled.
  const parseOnPause = useCallback(
    debounce(async () => {
      const result = parseExpressionIntoDsl(expressionText);
      if (typeof result !== 'string') {
        // no error
        setValidationError(null);
        onComplete(result);
      }
    }, 500),
    [expressionText]
  );

  return (
    <>
      <div
        className={classNames(
          styles.expression,
          validationError && styles.error
        )}
      >
        <div
          className={classNames(
            styles.expressionInputContainer,
            focusState && styles[focusState]
          )}
          ref={expressionTargetRef}
        >
          <TextField
            ref={expressionInputRef}
            className={classNames(
              ruleStyles.ruleTextField,
              comparisonRuleStyles.valueInput,
              styles.expressionInput
            )}
            value={expressionText}
            placeholder='Enter a value or expression'
            onComplete={onExpressionComplete}
            onChange={(value: string) => {
              setShowCodeCompletion(false); // hide the code completion menu if user resumes typing
              showCodeCompleteMenuOnPause.cancel();
              setExpressionText(value);
              showCodeCompleteMenuOnPause();

              parseOnPause.cancel();
              parseOnPause();
            }}
            onClick={() => {
              // To avoid annoying the user, only open the code complete menu on click if the
              // expression input is empty or the cursor is at the end of the text (because they
              // clicked on trailing whitespace).
              if (
                !expressionText.trim() ||
                (expressionInputRef.current &&
                  expressionInputRef.current.selectionStart ===
                    expressionText.length)
              )
                revealCodeCompletionMenu(true, false);
            }}
            //
            // KeyDown controls the code completion menu focus and selection
            //
            onKeyDown={(e: KeyboardEvent) => {
              setValidationError('');
              if (e.key === 'Tab') {
                showCodeCompleteMenuOnPause.cancel();
                revealCodeCompletionMenu();
              } else if (e.key === 'ArrowDown') {
                e.preventDefault(); // important to prevent insertion position from moving
                // Open the menu if not already open and
                // advance selected menu item down (and wrap if necessary)
                showCodeCompleteMenuOnPause.cancel();
                if (showCodeCompletion)
                  // If already open the advance the selected item down with wrapping
                  setActiveCodeCompleteIndex(
                    ((activeCodeCompleteIndex ?? 0) + 1) %
                      codeCompletionItems.length
                  );
                revealCodeCompletionMenu();
              } else if (e.key === 'ArrowUp') {
                e.preventDefault(); // important to prevent insertion position from moving
                // advance selected menu item up (and wrap if necessary)
                if (showCodeCompletion)
                  setActiveCodeCompleteIndex(
                    ((activeCodeCompleteIndex ?? 0) -
                      1 +
                      codeCompletionItems.length) %
                      codeCompletionItems.length
                  );
              } else if (e.key === 'Enter') {
                // select the code completion item
                if (!showCodeCompletion) {
                  setFocusState('');
                  onExpressionComplete();
                } else if (activeCodeCompleteIndex !== undefined) {
                  codeCompletionItems[activeCodeCompleteIndex]?.onMouseDown();
                  setShowCodeCompletion(false);
                }
              } else if (e.key === 'Escape') {
                // get it out of the way
                showCodeCompleteMenuOnPause.cancel();
                setShowCodeCompletion(false);
                focusExpressionInput();
              }
            }}
            triggerCleanUp
            onFocus={() => setFocusState('focus')}
            onBlur={() => {
              setFocusState('');
              onExpressionComplete();
            }}
          />
          <CaretIcon
            height={40}
            className={classNames(styles.caretIcon)}
            onClick={() => {
              revealCodeCompletionMenu(true, false);
              expressionInputRef.current?.focus();
            }}
          />
        </div>
        <div className={styles.errorMsg}>{validationError}</div>
      </div>
      <ContextMenu
        ref={codeCompleteMenuRef}
        position={position as { x: number; y: number }}
        show={showCodeCompletion}
        className={styles.codeCompletionMenu}
        close={() => {
          setShowCodeCompletion(false);
        }}
        actions={codeCompletionMatchMenuItems}
        selectedIndex={activeCodeCompleteIndex}
        target={expressionTargetRef.current}
      />
    </>
  );
};
