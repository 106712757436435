import classNames from 'classnames';
import { TextField } from '../../../../../../../../components/Core';
import { useRuleBuilderUpdater } from '../../../context';
import { RuleOperand } from '../../../context/RuleDSL';
import { RuleOperand as Operand } from '../../RuleOperand';
import ruleStyles from '../../../../../../../../components/NavigationRules/styles.module.scss';
import comparisonRuleStyles from '../../../../../../../../components/Modals/DecisionLogicModal/styles.module.scss';
import styles from '../styles.module.scss';

type CalendlyActionProps = {
  action: IRuleAction;
  mode?: RuleBuilderMode;
};

const EditMode = ({ action }: CalendlyActionProps) => {
  const { updateAction, updateOperand } = useRuleBuilderUpdater((s) => ({
    updateAction: s.updateAction,
    updateOperand: s.updateOperand
  }));

  const [url] = action.parameters;

  const handleURLChange = (newValue: string) => {
    if (url) {
      updateOperand(url.id, {
        type: 'value',
        value: newValue
      });
    } else {
      const operand = new RuleOperand();

      operand.type = 'value';
      operand.value = newValue;

      updateAction(action.id, {
        parameters: [operand.toJSON()]
      });
    }
  };

  return (
    <TextField
      className={classNames(
        ruleStyles.ruleTextField,
        comparisonRuleStyles.valueInput
      )}
      value={url?.value ?? ''}
      title={url?.value ?? ''}
      placeholder='Calendly URL'
      onComplete={handleURLChange}
      triggerCleanUp
    />
  );
};

const ViewMode = ({ action }: CalendlyActionProps) => {
  const [url] = action.parameters;

  return <Operand operand={url} bold />;
};

export const CalendlyAction = ({
  action,
  mode = 'view'
}: CalendlyActionProps) => {
  return (
    <div className={styles.actionContent}>
      {mode === 'view' ? (
        <ViewMode action={action} />
      ) : (
        <EditMode action={action} />
      )}
    </div>
  );
};
