import { useFields } from '../../../../../hooks/useFields';
import { useMemo } from 'react';
import { INTEGRATION_TOKENS } from '../utils';
import { useAppSelector } from '../../../../../hooks';

export function useConnectorVariables() {
  const fields = useFields(true);
  const documents = useAppSelector((state) => state.documents.documents);

  return useMemo(() => {
    const fieldMap = fields.reduce((acc, f) => ({ ...acc, [f.key]: true }), {});
    INTEGRATION_TOKENS.forEach((token) => (fieldMap[token] = true));
    Object.values(documents).forEach(
      (doc) => (fieldMap[`FeatheryDocument:${doc.id}`] = true)
    );
    fieldMap.feathery_user_id = true;
    fieldMap.feathery_form_id = true;
    fieldMap.feathery_auth_email = true;
    fieldMap.feathery_file_name = true;
    return fieldMap;
  }, [fields, documents]);
}
