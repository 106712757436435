const handleDate = (value: any): Date => {
  const result = parseDateFilterParam(value.toString());
  let date = new Date();
  if (result.type === 'date') {
    return result.value;
  } else if (result.type === 'integer') {
    date = new Date(new Date().setDate(new Date().getDate() - result.value));
  }

  return setToMidnight(date);
};

export function parseDateFilterParam(param: string):
  | { type: 'integer'; value: number }
  | { type: 'date'; value: Date }
  | {
      type: 'none';
      value: unknown;
    } {
  if (!param || typeof param !== 'string') {
    return { type: 'none', value: param };
  }

  const trimmed = param.trim();

  // Check if integer
  if (/^-?\d+$/.test(trimmed)) {
    // (optional minus sign)(1+ digits)
    return {
      type: 'integer',
      value: parseInt(trimmed, 10)
    };
  }

  // Check if date
  const date = new Date(trimmed);
  if (!isNaN(date.valueOf())) {
    return {
      type: 'date',
      value: date
    };
  }

  return {
    type: 'none',
    value: param
  };
}

const setToMidnight = (date: Date) => {
  const midnightDate = new Date(date);
  midnightDate.setHours(0, 0, 0, 0);
  return midnightDate;
};

export function buildQuery(
  filters: Record<any, any>,
  existingState: any = { searchKey: '', sortKey: '', sortDirection: '' }
) {
  const newQueryState: any = {
    searchKey: existingState.searchKey,
    sortKey: existingState.sortKey,
    sortDirection: existingState.sortDirection
  };
  Object.values(filters).forEach((f: any) => {
    const value =
      f.paramType === 'date'
        ? handleDate(f.paramValue).toISOString()
        : f.paramValue;
    newQueryState[f.paramName] = value;
  });

  return newQueryState;
}
