import { useParams } from 'react-router-dom';
import useFeatheryRedux from '../../../../redux';
import { useAppSelector } from '../../../../hooks';
import { memo, useCallback, useState } from 'react';
import DraftStatus, { STATE } from './DraftStatus';
import { DRAFT_STATUS, PUBLISH_STATUS } from '../../../../redux/utils';
import { formatDate } from '../../../../utils/format';
import {
  RestoreLastPublishedConfirmModal,
  UserCollaborationModal
} from '../../../Modals';
import { useActiveAuthIntegration } from '../../../FormIntegrations/useIntegrations';
import { encodeGetParams, REGION } from '../../../../api/utils';
import { getPanelSlug } from '../../../../utils/panel';
import { v4 as uuidv4 } from 'uuid';
import PromoteFormModal from '../../../Modals/PromoteFormModal';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from '../../DropdownMenu';
import { Tooltip } from '../../Tooltip/Tooltip';
import {
  BorderEyeIcon,
  EmbedCodeIcon,
  LinkIcon,
  OpenLinkIcon,
  PromoteIcon,
  ReactIcon,
  RestoreIcon
} from '../../../Icons';
import { cn } from '../../../../utils/cn';
import { InlineTooltip } from '../..';
import { openTab } from '../../../../utils/domOperations';

export const useHostedURL = (org: any, panel: any) => {
  const authActive = !!useActiveAuthIntegration(panel?.id);

  return useCallback(
    (type: 'live' | 'test' | 'draft' = 'live', queryParams = {}) => {
      if (!panel) return '';

      const panelSlug = getPanelSlug(panel, type);

      const extension = org.tier === 0 ? 'app' : 'io';

      let baseUrl = '';
      let isApexForm = false;
      let isCustomDomain = false;

      // 1. Check for matching custom domain apex form
      const matchingCustomDomain = org.custom_domains?.find(
        ({ apex_form }: any) => apex_form === panel.id
      );
      if (matchingCustomDomain?.url) {
        baseUrl = matchingCustomDomain.url;
        isApexForm = true;
        isCustomDomain = true;
      }

      // 2. Check for matching subdomain apex form
      else if (org.apex_form === panel.id && org.slug) {
        baseUrl = `https://${org.slug}.feathery.${extension}`;
        isApexForm = true;
      }

      // 3. Check for any custom domain
      else if (org.custom_domains?.length) {
        baseUrl = org.custom_domains[0].url;
        isCustomDomain = true;
      }

      // 4. Check for subdomain
      else if (org.slug) {
        baseUrl = `https://${org.slug}.feathery.${extension}`;
      }

      // 5. Default to standard host
      else {
        baseUrl = `https://form.feathery.${extension}`;
      }

      // Overwrite for local development environment
      if (process.env.REACT_APP_BACKEND_ENV === 'local') {
        baseUrl = 'http://localhost:3001';
        isApexForm = false;
        isCustomDomain = true;
      }

      // Special case for auth forms
      if (authActive) {
        // Use query param for auth forms to distinguish from normal forms
        // Stytch cannot use custom subdomain currently so must use legacy query parameter
        // TODO: Automatically whitelist custom subdomains for our Stytch template key
        if (!isApexForm || !isCustomDomain) {
          queryParams = {
            ...queryParams,
            _slug: panelSlug
          };
        }

        // Special case for Feathery templates org
        if (!isCustomDomain && org.slug === 'templates') {
          baseUrl = 'https://templates.feathery.io';
        }
      }

      if ((!isApexForm || type !== 'live') && !authActive) {
        baseUrl = `${baseUrl}/to/${panelSlug}`;
      }

      const encodedQueryParams = encodeGetParams(queryParams);
      if (encodedQueryParams) {
        baseUrl += `?${encodedQueryParams}`;
      }

      return baseUrl;
    },
    [authActive, panel, org]
  );
};

const generateEmbedSnippet = (
  sdkKey: string,
  formLiveSlug: string,
  loginEnabled: boolean
) => {
  const region = REGION ? `, { _enterpriseRegion: '${REGION}' }` : '';
  return `
    <script src="https://cdn.jsdelivr.net/npm/@feathery/react@latest/umd/index.js"></script>
    <div id='container'></div>
    <script>
    (() => {
        Feathery.init('${sdkKey}'${region})
        const loginEnabled = ${loginEnabled};
        Feathery.renderAt('container', { formId: "${formLiveSlug}" }, loginEnabled);
    })();
    </script>`;
};

function ShareFormOverlay({
  unsavedDraftChanges,
  deleteDraft,
  children
}: {
  unsavedDraftChanges?: boolean;
  deleteDraft?: () => void;
  children: any;
}) {
  const { formId } = useParams<{ formId: string }>();
  const {
    toasts: { addInfoToast }
  } = useFeatheryRedux();
  const org = useAppSelector((state) => state.accounts.organization) as any;
  const roleAllowInvite = useAppSelector((state) => {
    const account = state.accounts.account;
    return account.role === 'admin' || account.permission_invite_collaborators;
  });
  const prodKey = org.environments.find((env: any) => env.primary).sdk_key;
  const panel = useAppSelector((state) => state.panels.panels[formId]);
  const getHostedUrl = useHostedURL(org, panel);

  const collabInviteEnabled = panel?.collaboration_enabled;
  const collaborationAllowed = roleAllowInvite && collabInviteEnabled;

  const draftStatus = useAppSelector((s) => s.formBuilder.draftStatus);
  const draftTimestamp = useAppSelector((s) => s.formBuilder.draftTimestamp);
  const flowPublishStatus = useAppSelector(
    (s) => s.formBuilder.flowPublishStatus
  );
  const authActive = !!useActiveAuthIntegration(panel?.id);

  const [showRestoreModal, setShowRestoreModal] = useState(false);
  const [sendStep, setSendStep] = useState(0);
  const [openPromotionModal, setOpenPromotionModal] = useState(false);

  if (!panel) return null;

  let draftState = STATE.NO_DRAFT;
  if (draftStatus === DRAFT_STATUS.NOT_CONNECTED) {
    draftState = STATE.NO_CONNECTION;
  } else if (
    draftStatus === DRAFT_STATUS.CONFLICT ||
    draftStatus === DRAFT_STATUS.CONFLICT_IGNORE
  )
    draftState = STATE.CONFLICT;
  else if (
    flowPublishStatus === PUBLISH_STATUS.LOADING ||
    flowPublishStatus === PUBLISH_STATUS.AUTO_SAVING
  )
    draftState = STATE.SAVING;
  else if (unsavedDraftChanges) draftState = STATE.UNSAVED;
  else if (draftTimestamp) draftState = STATE.SAVED;

  const draftIsSaved = [STATE.SAVED].includes(draftState);

  const CollaborationLink = () => (
    <DropdownMenuItem
      unstyled
      disabled={!collaborationAllowed}
      className={cn('share-link', {
        disabled: !collaborationAllowed
      })}
      onClick={() => collaborationAllowed && setSendStep(1)}
    >
      <OpenLinkIcon />
      Send & Collaborate
      <InlineTooltip text='Email and route forms between multiple users.' />
    </DropdownMenuItem>
  );

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>{children}</DropdownMenuTrigger>

        <DropdownMenuContent unstyled align='end' className='share-modal'>
          <div className='share-body first'>
            <DropdownMenuItem
              disabled={!panel.active}
              unstyled
              className='share-link-container'
            >
              <a
                className={cn('share-link', {
                  disabled: !panel.active
                })}
                target='_blank'
                href={getHostedUrl()}
                rel='noreferrer'
              >
                <OpenLinkIcon />
                Live Form
              </a>
            </DropdownMenuItem>
            <DropdownMenuItem unstyled>
              <a
                className='share-link'
                target='_blank'
                href={getHostedUrl('test')}
                rel='noreferrer'
              >
                <BorderEyeIcon />
                Test Form
              </a>
              <div className={cn('status', 'indent')}>Saves test data</div>
            </DropdownMenuItem>
            <PromotionTrigger
              panel={panel}
              draftState={draftState}
              setOpenPromotionModal={setOpenPromotionModal}
            />
          </div>
          {!panel.promote_from && (
            <>
              <div className={cn('share-header', 'status-header')}>
                Draft <DraftStatus draftState={draftState} />
              </div>
              <div className='share-body'>
                <DropdownMenuItem unstyled disabled={!draftIsSaved}>
                  <a
                    className={cn('share-link', {
                      disabled: !draftIsSaved
                    })}
                    target='_blank'
                    href={getHostedUrl('draft')}
                    rel='noreferrer'
                  >
                    <BorderEyeIcon />
                    Preview Draft
                  </a>
                  <div className={cn('status', 'indent')}>
                    No test data saved
                  </div>
                </DropdownMenuItem>
                <DropdownMenuItem unstyled disabled={!draftIsSaved}>
                  <span
                    className={cn('share-link', {
                      disabled: !draftIsSaved
                    })}
                    onClick={() => {
                      if (draftIsSaved) setShowRestoreModal(true);
                    }}
                  >
                    <RestoreIcon />
                    Restore last published
                  </span>
                  <div className={cn('status', 'indent')}>
                    {formatDate(panel.updated_at)}
                  </div>
                </DropdownMenuItem>
              </div>
            </>
          )}
          {!org?.whitelabel && (
            <>
              <div className='share-header'>
                Embed
                <InlineTooltip
                  onClick={() =>
                    openTab(
                      'https://docs.feathery.io/platform/launch#embed-form'
                    )
                  }
                />
              </div>
              <div className='share-body'>
                <DropdownMenuItem
                  unstyled
                  className='share-link'
                  onClick={() => {
                    navigator.clipboard.writeText(
                      generateEmbedSnippet(
                        prodKey,
                        getPanelSlug(panel, 'live'),
                        authActive
                      )
                    );
                    addInfoToast('Embed code snippet copied to clipboard');
                  }}
                >
                  <EmbedCodeIcon />
                  Javascript
                </DropdownMenuItem>
                <DropdownMenuItem unstyled>
                  <a
                    className='share-link'
                    target='_blank'
                    href={'https://docs.feathery.io/develop/react'}
                    rel='noreferrer'
                  >
                    <ReactIcon />
                    React
                  </a>
                </DropdownMenuItem>
              </div>
              <div className='share-header'>Other Sharing Options</div>
              <div className='share-body'>
                {!collabInviteEnabled ? (
                  <Tooltip
                    content='Enable collaboration & email invites in your form
                      settings.'
                  >
                    <div>
                      <CollaborationLink />
                    </div>
                  </Tooltip>
                ) : (
                  <CollaborationLink />
                )}
                <DropdownMenuItem
                  unstyled
                  className='share-link'
                  onClick={() => {
                    const url = getHostedUrl('live', { _id: uuidv4() });
                    openTab(url);
                  }}
                >
                  <LinkIcon />
                  Share Submission Link
                  <InlineTooltip text='Creates a unique link that allows multiple people to work on the same submission' />
                </DropdownMenuItem>
              </div>
            </>
          )}
        </DropdownMenuContent>
      </DropdownMenu>
      {openPromotionModal && (
        <PromoteFormModal
          open={openPromotionModal}
          setOpen={setOpenPromotionModal}
        />
      )}
      <RestoreLastPublishedConfirmModal
        show={showRestoreModal}
        setShow={setShowRestoreModal}
        onRestore={() => {
          deleteDraft && deleteDraft();
        }}
      />
      <UserCollaborationModal step={sendStep} setStep={setSendStep} />
    </>
  );
}

function PromotionTrigger({ panel, draftState, setOpenPromotionModal }: any) {
  const account = useAppSelector((state) => state.accounts.account);
  const isSomePromoting = useAppSelector((state) => {
    if (!state.panels.panels) return false;

    return Object.values(state.panels.panels).some((panel) => panel.promoting);
  });
  const canPublish =
    account.role === 'admin' || account.permission_publish_forms;

  const isPromotion = panel && panel.promote_to && !panel.promote_live;
  const isRollback =
    panel && panel.promote_from && !panel.promote_live && !panel.promote_to;

  if (!canPublish || (!isPromotion && !isRollback)) {
    return null;
  }

  let disabled: false | string = false;

  if (isSomePromoting) {
    disabled =
      'Wait for the current promotion to finish before starting another promotion.';
  } else if (!(draftState === STATE.NO_DRAFT && canPublish)) {
    disabled = `Publish or revert any draft changes before ${
      isPromotion ? 'promoting' : 'rolling back'
    }`;
  }

  return (
    <Tooltip disabled={!disabled} content={disabled}>
      <DropdownMenuItem unstyled disabled={Boolean(disabled)}>
        <span
          className={cn('share-link', {
            disabled: Boolean(disabled)
          })}
          onClick={() => {
            if (!disabled) setOpenPromotionModal(true);
          }}
        >
          {isPromotion ? (
            <>
              <PromoteIcon />
              Promote Form
            </>
          ) : (
            <>
              <RestoreIcon />
              Rollback Form
            </>
          )}
        </span>
      </DropdownMenuItem>
    </Tooltip>
  );
}

export default memo(ShareFormOverlay);
