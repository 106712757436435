import Neutral from '../../components/Core/Button/Neutral';
import styles from './styles.module.scss';
import classNames from 'classnames';
import RuleSettingsModal from './RuleSettingsModal';
import { useState } from 'react';

export default function RuleSettingsButton({ empty = false }) {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <Neutral
        onClick={() => setShowModal(true)}
        className={classNames(
          styles.globalConfigButton,
          empty && styles.emptyState
        )}
      >
        Settings
      </Neutral>
      <RuleSettingsModal isOpen={showModal} close={() => setShowModal(false)} />
    </>
  );
}
