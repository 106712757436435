import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useFeatheryRedux from '../../redux';

const APIConnectorLogsTable: React.FC = () => {
  const { formId } = useParams<{ formId: string }>();
  const { getPanelAPIConnectorErrorLogs } = useFeatheryRedux();

  const [logs, setLogs] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchLogs = async () => {
      try {
        const logs = await getPanelAPIConnectorErrorLogs({ panelId: formId });
        setLogs(logs);
      } catch (error: any) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchLogs();
  }, [formId, getPanelAPIConnectorErrorLogs]);

  const formatResponse = (response: string) => {
    try {
      return JSON.stringify(JSON.parse(response), null, 2);
    } catch (e) {
      return response;
    }
  };

  if (loading)
    return (
      <p style={{ textAlign: 'center', marginTop: '24px' }}>
        Loading API Connector Logs...
      </p>
    );
  if (error) return <p style={{ color: 'red' }}>Error: {error}</p>;
  if (logs.length === 0)
    return (
      <p style={{ textAlign: 'center', marginTop: '24px' }}>
        No Logs Available
      </p>
    );

  return (
    <div>
      <table
        style={{
          width: '100%',
          marginTop: '20px',
          borderCollapse: 'collapse',
          border: '2px solid black'
        }}
      >
        <thead>
          <tr>
            {['URL', 'Status Code', 'Request', 'Response', 'Created At'].map(
              (header, index) => (
                <th
                  key={index}
                  style={{
                    border: '2px solid black',
                    padding: '8px',
                    textAlign: 'center',
                    backgroundColor: '#f4f4f4',
                    whiteSpace: 'nowrap'
                  }}
                >
                  {header}
                </th>
              )
            )}
          </tr>
        </thead>
        <tbody>
          {logs.map((log, index) => (
            <tr key={index}>
              <td
                style={{
                  maxWidth: '200px',
                  overflowWrap: 'break-word',
                  wordBreak: 'break-word',
                  whiteSpace: 'pre-wrap',
                  border: '1px solid black',
                  padding: '8px'
                }}
              >
                <a href={log.url} target='_blank' rel='noopener noreferrer'>
                  {log.url}
                </a>
              </td>
              <td
                style={{
                  maxWidth: '24px',
                  textAlign: 'center',
                  border: '1px solid black',
                  padding: '8px'
                }}
              >
                {log.status_code}
              </td>
              <td
                style={{
                  maxWidth: '300px',
                  wordBreak: 'break-word',
                  whiteSpace: 'pre-wrap',
                  overflowWrap: 'break-word',
                  overflow: 'hidden',
                  border: '1px solid black',
                  padding: '8px'
                }}
              >
                <pre
                  style={{
                    margin: 0,
                    whiteSpace: 'pre-wrap',
                    wordBreak: 'break-word',
                    overflowWrap: 'break-word'
                  }}
                >
                  {log.request}
                </pre>
              </td>
              <td
                style={{
                  maxWidth: '250px',
                  wordBreak: 'break-word',
                  whiteSpace: 'pre-wrap',
                  overflowWrap: 'break-word',
                  overflow: 'hidden',
                  border: '1px solid black',
                  padding: '8px'
                }}
              >
                <pre
                  style={{
                    margin: 0,
                    whiteSpace: 'pre-wrap',
                    wordBreak: 'break-word',
                    overflowWrap: 'break-word'
                  }}
                >
                  {formatResponse(log.response)}
                </pre>
              </td>
              <td
                style={{
                  maxWidth: '96px',
                  wordBreak: 'break-word',
                  whiteSpace: 'pre-wrap',
                  overflowWrap: 'break-word',
                  overflow: 'hidden',
                  border: '1px solid black',
                  padding: '8px',
                  textAlign: 'center'
                }}
              >
                {new Date(log.created_at).toLocaleString()}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default APIConnectorLogsTable;
