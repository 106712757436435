import { useCallback, useMemo } from 'react';
import jp from 'jsonpath';

// processes JSON data and returns all possible paths as well as
// getOptions function returns all paths that are prefixed by the
// input and handles wildcard paths
export default function useJSONPaths(data: any) {
  // all possible paths
  const paths = useMemo(() => {
    if (data) {
      return jp.paths(data, '$..*');
    }
    return [];
  }, [data]);

  const isArrayAtPath = useCallback(
    (arrayPath: string[]) => {
      try {
        const value = jp.value(data, jp.stringify(arrayPath));
        return Array.isArray(value);
      } catch (e) {
        return false;
      }
    },
    [data]
  );

  const getArrayItemProperties = useCallback(
    (arrayPath: string[]) => {
      try {
        arrayPath = arrayPath.map((pathItem: string) =>
          pathItem === '*' ? '0' : pathItem
        );
        const array = jp.value(data, jp.stringify(arrayPath));
        if (!Array.isArray(array) || array.length === 0) return [];

        const sampleItem = array.find(
          (item) => item && typeof item === 'object' && !Array.isArray(item)
        );
        if (!sampleItem) return [];

        return Object.keys(sampleItem);
      } catch (e) {
        return [];
      }
    },
    [data]
  );

  // if wildcard, get schema from implicit item
  const handleWildcardPaths = useCallback(
    (inp: string[]) => {
      const wildcardIdx = inp.lastIndexOf('*');

      if (wildcardIdx === inp.length - 1) {
        const arrayPath = inp.slice(0, wildcardIdx);
        const arrayProps = getArrayItemProperties(arrayPath);

        return arrayProps.map((prop) => [...inp, prop]);
      } else {
        const arrayPath = inp.slice(0, wildcardIdx);
        const array = jp.value(data, jp.stringify(arrayPath));

        if (!Array.isArray(array) || array.length === 0) return [];

        // find a sample item that isn't undefined
        let sampleItemPath = null;
        for (let i = 0; i < array.length; i++) {
          const specificPath = [...arrayPath, i, ...inp.slice(wildcardIdx + 1)];
          try {
            const value = jp.value(data, jp.stringify(specificPath));
            if (value !== undefined) {
              sampleItemPath = specificPath;
              break;
            }
          } catch (e) {
            // item path is invalid, skip
          }
        }

        if (!sampleItemPath) return [];

        const sampleValue = jp.value(data, jp.stringify(sampleItemPath));

        if (Array.isArray(sampleValue)) {
          const result = [];
          result.push([...inp, '*']);
          for (let i = 0; i < sampleValue.length; i++) {
            result.push([...inp, i]);
          }
          return result;
        }

        if (sampleValue && typeof sampleValue === 'object') {
          return Object.keys(sampleValue).map((key) => [...inp, key]);
        }

        return [];
      }
    },
    [data]
  );

  // returns a subset of paths (and adds wildcard) that are prefixed by input
  const getOptions = useCallback(
    (inp: string[]) => {
      // wildcard path doesn't actually exist in schema, so to handle it we use implicit item
      if (inp.includes('*')) {
        return handleWildcardPaths(inp);
      }

      const nextLevelPaths = paths.filter(
        (path) =>
          path.length === inp.length + 1 &&
          path.slice(0, path.length - 1).toString() === inp.toString()
      );

      if (isArrayAtPath(inp)) {
        const wildcardPath = [...inp, '*'];

        const filteredPaths = nextLevelPaths.filter(
          (path) =>
            path.length !== wildcardPath.length ||
            path.toString() !== wildcardPath.toString()
        );

        return [wildcardPath, ...filteredPaths];
      }

      return nextLevelPaths;
    },
    [data, paths, isArrayAtPath, getArrayItemProperties, handleWildcardPaths]
  );

  return [paths, getOptions] as const;
}
