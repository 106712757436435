import { exportFunctions as formBuilder } from './formBuilder';
import { exportFunctions as tables } from './tables';
import { exportFunctions as folders } from './folders';
import { exportFunctions as panels } from './panels';
import { exportFunctions as clipboard } from './clipboard';
import {
  deleteUser,
  getUserDetail,
  getUsers,
  syncFunctions as userSyncFunctions
} from './users';
import { getTasks } from './tasks';
import * as themeBuilder from './themeBuilder';
import * as toasts from './toasts';

import { fetchVancoToken } from './customers';
import {
  createDocument,
  createEnvelope,
  deleteDocument,
  deleteEnvelope,
  editDocument,
  getDocuments,
  getEnvelopes
} from './documents';
import { createABTest, deleteABTest, editABTest, getABTests } from './abTests';
import {
  commitAIExtractionEntries,
  createAIExtraction,
  createAIExtractionLogicRule,
  deleteAIExtraction,
  deleteAIExtractionLogicRule,
  deleteAIExtractionRun,
  editAIExtraction,
  editAIExtractionLogicRule,
  editAIExtractionRun,
  getAIExtractionLogicRule,
  getAIExtractionRun,
  getAIExtractionRuns,
  getAIExtractions,
  updateAIExtractionEntry
} from './ai';
import { createImages, deleteImage, editImage, getImages } from './images';
import {
  createCustomAPI,
  createIntegration,
  editCustomAPI,
  editIntegration,
  editTranslations,
  exchangeOAuthToken,
  fetchQuikFields,
  fetchRolloutToken,
  fetchSalesforceObjectFields,
  fetchSalesforceObjects,
  getCustomAPIs,
  getDynamicFonts,
  getIntegrations,
  getTranslations,
  revokeOAuthToken,
  switchIntegrationProperty,
  testCustomAPI,
  uploadFont
} from './integrations';
import {
  bulkUpload,
  createField,
  deleteField,
  deleteFieldValue,
  editField,
  editFieldValue,
  getFields
} from './fields';
import {
  getBillingMetrics,
  getPanelAPIConnectorErrorLogs,
  getPanelMetrics
} from './metrics';
import { exportFunctions as themes } from './themes';

import {
  createCustomField,
  createWorkspace,
  deleteCustomField,
  deleteWorkspace,
  editAccount,
  editFavicon,
  editOrganization,
  editWorkspace,
  getBill,
  getDefaultSettings,
  getOrganization,
  getWorkspace,
  getWorkspaces,
  inviteToWorkspace,
  inviteUsers,
  migrateAccount,
  removeUser,
  updateAccountViewport,
  updateCard,
  updateConfig,
  updateCustomField
} from './accounts';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { useMemo } from 'react';
import useGetJwt from '../utils/useGetJwt';

const functions = {
  getDocuments,
  createDocument,
  editDocument,
  deleteDocument,
  getEnvelopes,
  createEnvelope,
  deleteEnvelope,
  getABTests,
  createABTest,
  editABTest,
  deleteABTest,
  getAIExtractions,
  getAIExtractionRuns,
  getAIExtractionRun,
  editAIExtractionRun,
  deleteAIExtractionRun,
  updateAIExtractionEntry,
  commitAIExtractionEntries,
  getAIExtractionLogicRule,
  createAIExtractionLogicRule,
  editAIExtractionLogicRule,
  deleteAIExtractionLogicRule,
  createAIExtraction,
  deleteAIExtraction,
  editAIExtraction,
  getOrganization,
  getDefaultSettings,
  editOrganization,
  editAccount,
  editFavicon,
  migrateAccount,
  updateAccountViewport,
  getBill,
  updateCard,
  inviteUsers,
  removeUser,
  getWorkspaces,
  getWorkspace,
  createWorkspace,
  editWorkspace,
  deleteWorkspace,
  inviteToWorkspace,
  createCustomField,
  deleteCustomField,
  updateCustomField,
  updateConfig,
  getImages,
  createImages,
  editImage,
  deleteImage,
  createIntegration,
  getIntegrations,
  switchIntegrationProperty,
  editIntegration,
  uploadFont,
  getDynamicFonts,
  getTranslations,
  editTranslations,
  exchangeOAuthToken,
  revokeOAuthToken,
  fetchRolloutToken,
  fetchVancoToken,
  fetchSalesforceObjects,
  fetchSalesforceObjectFields,
  fetchQuikFields,
  getFields,
  createField,
  editField,
  editFieldValue,
  deleteField,
  deleteFieldValue,
  bulkUpload,
  getBillingMetrics,
  getPanelMetrics,
  getPanelAPIConnectorErrorLogs,
  getTasks,
  getUsers,
  deleteUser,
  getUserDetail,
  getCustomAPIs,
  createCustomAPI,
  editCustomAPI,
  testCustomAPI,
  ...themes.asyncFunctions,
  ...formBuilder.asyncFunctions,
  ...panels.asyncFunctions
};

const syncFuncs = {
  themes: themes.syncFunctions,
  formBuilder: formBuilder.syncFunctions,
  tables: tables.syncFunctions,
  folders: folders.syncFunctions,
  panels: panels.syncFunctions,
  users: userSyncFunctions,
  themeBuilder: { ...themeBuilder },
  toasts: { ...toasts },
  clipboard: clipboard.syncFunctions
};

type ReduxHook = (data: any) => Promise<any>;
type ReduxHookMap = Record<string, ReduxHook | any>;

export default function useFeatheryRedux(): any {
  const dispatch = useDispatch();
  const getJwt = useGetJwt();

  return useMemo(() => {
    const hooks: ReduxHookMap = {};

    Object.entries(functions).forEach(([key, func]) => {
      hooks[key] = (data: any) => {
        const token = getJwt();
        // @ts-ignore
        return dispatch(func({ token, ...data })).then(unwrapResult);
      };
    });

    Object.entries(syncFuncs).forEach(([key, func]) => {
      delete (func as any).default;
      hooks[key] = {};
      Object.entries(func).forEach(([key1, func1]) => {
        hooks[key][key1] = (...data: any[]) => {
          return dispatch(func1(...data));
        };
      });
    });
    return hooks;
  }, [dispatch, getJwt]);
}
