import { useMemo } from 'react';
import { PlusIcon } from '../../../../../../../components/Icons';
import styles from './styles.module.scss';
import { Button } from '../../../../../../../components/Core/Button/button';

export type ActionBarEvent =
  | 'addBranch'
  | 'addCondition'
  | 'addAction'
  | 'addClause';

const ACTION_EVENT_MAP = {
  addBranch: 'Add Branch',
  addCondition: 'Add Condition',
  addAction: 'Add Action',
  addClause: 'Add Clause'
};

type ActionBarProps = {
  addBranch?: boolean;
  addCondition?: boolean;
  addAction?: boolean;
  addClause?: boolean;
  onClick?: (action: ActionBarEvent) => void;
  texts?: { [key: string]: string };
};

type ActionButtonProps = {
  action: ActionBarEvent;
  text?: string;
  onClick?: (action: ActionBarEvent) => void;
};

const ActionButton = ({
  action,
  text = ACTION_EVENT_MAP[action],
  onClick = () => {}
}: ActionButtonProps) => {
  return (
    <Button variant='dashed' onClick={() => onClick(action)}>
      <PlusIcon className={styles.actionButtonIcon} width={12} />
      <span>{text}</span>
    </Button>
  );
};

export const ActionBar = (props: ActionBarProps) => {
  const {
    addBranch = false,
    addCondition = false,
    addAction = false,
    addClause = false,
    onClick = () => {},
    texts = {
      addBranch: 'Add Branch',
      addCondition: 'Add Condition',
      addAction: 'Add Action',
      addClause: 'Add Clause'
    }
  } = props;

  const actions = useMemo(() => {
    const _actions = [];

    if (addBranch) {
      _actions.push(
        <ActionButton
          key='addBranch'
          action='addBranch'
          text={texts['addBranch']}
          onClick={onClick}
        />
      );
    }

    if (addCondition) {
      _actions.push(
        <ActionButton
          key='addCondition'
          action='addCondition'
          text={texts['addCondition']}
          onClick={onClick}
        />
      );
    }

    if (addAction) {
      _actions.push(
        <ActionButton
          key='addAction'
          action='addAction'
          text={texts['addAction']}
          onClick={onClick}
        />
      );
    }

    if (addClause) {
      _actions.push(
        <ActionButton
          key='addClause'
          action='addClause'
          text={texts['addClause']}
          onClick={onClick}
        />
      );
    }

    return _actions;
  }, [addBranch, addCondition, addAction, addClause]);

  return <div className={styles.actionBar}>{actions}</div>;
};
