import { InfoIcon, WarningIcon } from '../../Icons';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { Tooltip } from './Tooltip';

export default function InlineTooltip({
  text,
  warning = false,
  size = 17,
  inline = true,
  style = {},
  placement = 'top',
  className,
  ...props
}: any) {
  const IconType = warning ? WarningIcon : InfoIcon;
  if (inline) style = { paddingLeft: '5px', ...style };
  const base = (
    <div
      className={classNames(styles.tooltip, warning && styles.warning)}
      style={style}
      {...props}
    >
      <IconType width={size} height={size} />
    </div>
  );
  return text ? (
    <Tooltip className={className} content={text}>
      {base}
    </Tooltip>
  ) : (
    base
  );
}
