// inverts keys and values of a Record<string, string>
export function invert(data: Record<string, string>): Record<string, string> {
  const result: Record<string, string> = {};
  Object.keys(data).forEach((oldKey: string) => {
    result[data[oldKey]] = oldKey;
  });
  return result;
}

export function transformFieldMap(
  fieldMap: Record<string, string>,
  formServars: string[]
): Record<string, string> {
  // Remove any field maps that aren't on the current form anymore
  const filteredMapFieldData = Object.keys(fieldMap)
    .filter((key) => formServars.includes(key))
    // remove any fields that map to themselves
    .filter((key) => key !== fieldMap[key])
    // remove any empty fields
    .filter((key) => fieldMap[key])
    .reduce((obj, key) => {
      obj[key] = fieldMap[key];
      return obj;
    }, {} as Record<string, any>);

  // invert field_map for BE
  return invert(filteredMapFieldData);
}

export function groupKeysById<T extends { id: string; key: string }>(
  acc: Record<string, string>,
  item: T
) {
  acc[item.id] = item.key;
  return acc;
}

export function groupByForm(
  acc: Record<string, string[]>,
  current: [string, { panel_id: string }[]]
) {
  const [servarId, forms] = current;

  forms.forEach((form) => {
    const panelId = form.panel_id;
    if (!acc[panelId]) {
      acc[panelId] = [];
    }
    acc[panelId].push(servarId);
  });

  return acc;
}

function hasProperty<T extends string>(
  obj: unknown,
  key: T
): obj is Record<T, unknown> {
  return typeof obj === 'object' && obj !== null && key in obj;
}

export function handleErrorMessage(error: unknown, defaultError?: string) {
  if (hasProperty(error, 'message')) {
    const errorMessage = error.message || '';
    if (typeof errorMessage === 'string') {
      return errorMessage;
    }
  }
  return defaultError || 'An error occurred. Please try again.';
}

export function getExtractionsUsedInForm(
  formBuilderData: any,
  extractions: any[]
): Set<string> {
  // combine results from buttons, code, and dsl
  const result = new Set<string>();

  const validExtractions = extractions.reduce((acc, curr) => {
    acc[curr.id] = true;
    return acc;
  }, {} as Record<string, boolean>);

  // Extract from buttons
  if (formBuilderData.workingSteps) {
    const buttonsResult = getExtractionsFromButtons(
      formBuilderData.workingSteps
    );
    buttonsResult.forEach((id) => {
      if (validExtractions[id]) result.add(id);
    });
  }

  // Extract from logic rules code
  if (formBuilderData.workingLogicRules) {
    const codeResult = getExtractionsFromLogicRuleCode(
      formBuilderData.workingLogicRules
    );
    codeResult.forEach((id) => {
      if (validExtractions[id]) result.add(id);
    });

    // Extract from logic rules DSL
    const dslResult = getExtractionsFromLogicRuleDsl(
      formBuilderData.workingLogicRules
    );
    dslResult.forEach((id) => {
      if (validExtractions[id]) result.add(id);
    });
  }

  return result;
}

function getExtractionsFromButtons(buttonsData: any): Set<string> {
  // for each step in working_steps, look at buttons array and return any extraction_id's from actions with type 'ai_document_extract'
  const result = new Set<string>();

  // Iterate through all steps
  Object.values(buttonsData).forEach((step: any) => {
    // Check if the step has buttons
    if (step.buttons && Array.isArray(step.buttons)) {
      // Iterate through all buttons in the step
      step.buttons.forEach((button: any) => {
        // Check if the button has properties with actions
        if (
          button.properties &&
          button.properties.actions &&
          Array.isArray(button.properties.actions)
        ) {
          // Iterate through all actions in the button
          button.properties.actions.forEach((action: any) => {
            // Check if the action is of type 'ai_document_extract'
            if (action.type === 'ai_document_extract' && action.extraction_id) {
              result.add(action.extraction_id);
            }
          });
        }
      });
    }
  });

  return result;
}

function getExtractionsFromLogicRuleCode(logicRules: any): Set<string> {
  // for each rule in working logic rules with mode 'code_editor', look at code and return any ids from the first argument of feathery.runAIExtraction
  const result = new Set<string>();

  // Iterate through all logic rules
  Object.values(logicRules).forEach((rule: any) => {
    // Check if the rule's mode is 'code_editor' and it has code
    if (rule.mode === 'code_editor' && rule.code) {
      // Use regex to find all extraction IDs in feathery.runAIExtraction calls
      const regex = /feathery\.runAIExtraction\s*\(\s*['"]([\w-]+)['"]/g;
      let match;

      while ((match = regex.exec(rule.code)) !== null) {
        if (match[1]) {
          result.add(match[1]);
        }
      }
    }
  });

  return result;
}

function getExtractionsFromLogicRuleDsl(logicRules: any): Set<string> {
  // for each rule in working logic rules with mode 'rule_builder', look at dsl and return the 0th param of an ai_extraction actions
  const result = new Set<string>();

  // Iterate through all logic rules
  Object.values(logicRules).forEach((rule: any) => {
    // Check if the rule's mode is 'rule_builder' and it has a DSL
    if (rule.mode === 'rule_builder' && rule.dsl) {
      // Navigate through the DSL structure to find ai_extraction actions
      if (rule.dsl.branches && Array.isArray(rule.dsl.branches)) {
        rule.dsl.branches.forEach((branch: any) => {
          if (branch.clauses && Array.isArray(branch.clauses)) {
            branch.clauses.forEach((clause: any) => {
              if (clause.actions && Array.isArray(clause.actions)) {
                clause.actions.forEach((action: any) => {
                  // Check if this is an ai_extraction action with parameters
                  if (
                    action.name === 'ai_extraction' &&
                    action.parameters &&
                    Array.isArray(action.parameters) &&
                    action.parameters.length > 0
                  ) {
                    // Get the first parameter (index 0)
                    const firstParam = action.parameters[0];
                    if (firstParam.type === 'value' && firstParam.value) {
                      result.add(firstParam.value);
                    }
                  }
                });
              }
            });
          }
        });
      }
    }
  });

  return result;
}
