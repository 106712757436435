import { OPERATOR_CODE } from '@feathery/react';
import { useEffect, useMemo, useState } from 'react';
import { useAppSelector } from '../../hooks';
import { AIStarIcon, UsersIcon } from '../Icons';
import {
  ResultColumn,
  useResultColumns
} from '../HeaderFilterResultsTable/useResultColumns';
import { formatDate } from '../../utils/format';
import useFeatheryRedux from '../../redux';
import classNames from 'classnames';
import styles from './styles.module.scss';
import HeaderFilterResultsTable, {
  FilterItem,
  FilterItemState,
  SortDirection
} from '../HeaderFilterResultsTable';
import ResultsHeader from '../HeaderFilterResultsTable/ResultsHeader';

import CollaboratorFilter from '../HeaderFilterResultsTable/CollaboratorFilter';
import FieldFilter from '../HeaderFilterResultsTable/FieldFilter';
import {
  createDateTimeFilterWidget,
  createSimpleFilterWidget
} from '../HeaderFilterResultsTable/filters';
import {
  COLLABORATOR_FILTER_ID,
  COLLABORATOR_ROLE_FILTER_ID,
  COMPLETED_FILTER_ID,
  COMPLETED_FILTER_LABEL,
  FIELD_FILTER_ID,
  FIELD_FILTER_LABEL,
  LAST_UPDATED_AT_FILTER_ID,
  LAST_UPDATED_AT_FILTER_LABEL,
  PENDING_COLLABORATOR_LABEL_FILTER_LABEL
} from '../HeaderFilterResultsTable/constants';
import { useHistory } from 'react-router-dom';
import { buildQuery } from '../HeaderFilterResultsTable/utils/filters';

const w = window as any;

type Props = {
  formId?: string;
  extractionId?: string;
  title?: string;
  hideEditTableView?: boolean;
  hideDownloadCSV?: boolean;
  hideUploadSubmissions?: boolean;
  hideTitle?: boolean;
  padding?: boolean;
  noSort?: boolean;
  filtering?: boolean;
  resultColumns?: ResultColumn[];
  onFetchData: (payload: { [key: string]: any }) => Promise<any>;
  onSelectResult?: (obj: any) => void;
  onLoadError?: (error: string) => void;
  onDelete?: (obj: any) => void;
  onCreate?: (envName: string) => void;
  refetch?: boolean;
};

const UserResultsTable = ({
  formId = '',
  extractionId = '',
  title = 'Results',
  hideEditTableView = false,
  hideDownloadCSV = false,
  hideUploadSubmissions = false,
  hideTitle = false,
  padding = true,
  filtering,
  noSort = false,
  resultColumns: _resultColumns = [],
  onFetchData,
  onSelectResult = () => {},
  onLoadError = () => {},
  onDelete,
  onCreate,
  refetch
}: Props) => {
  const history = useHistory();
  const [resultsPage, setResultsPage] = useState(
    new URLSearchParams(document.location.search).get('page') || 1
  );
  const [envName, setEnvName] = useState(w.envName || 'Production');
  const {
    editAccountPanelData,
    users: { clearUsers }
  } = useFeatheryRedux();

  const resultData = useAppSelector((state) =>
    extractionId ? state.ai : state.users
  );
  const panelsData = useAppSelector((state) => state.panels.panels);
  const panel = !formId ? null : panelsData[formId];

  const initialFilterState = panel?.account?.result_filters ?? {};
  const [queryState, setQueryState] = useState(() =>
    buildQuery(initialFilterState)
  );

  useEffect(() => {
    (async () => {
      onLoadError('');
      try {
        const { searchKey, sortKey, sortDirection, ...query } = queryState;
        history.push({
          search: `?page=${resultsPage}`
        });
        await onFetchData({
          page: resultsPage,
          environment_name: envName,
          search: searchKey,
          sort_order: sortKey,
          sort_direction: sortDirection,
          ...query
        });
      } catch {
        onLoadError('An unknown error occurred');
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [envName, queryState, resultsPage, refetch]);

  useEffect(() => {
    // @ts-ignore
    if (!extractionId && formId && formId !== resultData.panelId) clearUsers();
    // @ts-ignore
  }, [formId, resultData.panelId]);

  // @ts-ignore
  const results = resultData[extractionId ? 'runs' : 'users'];
  const dataToRender = useMemo(() => {
    return results
      ? results.map((d: any) => {
          const newD = {
            ...d,
            created_at: formatDate(d.created_at),
            updated_at: formatDate(d.updated_at)
          };
          if ('completed_fields' in d) {
            const status = d.completed ? 'Completed' : 'Partial';
            newD.status = `${status} - ${d.completed_fields} fields`;
          }
          if ('last_updated_at' in d) {
            newD.last_updated_at = d.last_updated_at
              ? formatDate(d.last_updated_at)
              : '';
          }
          return newD;
        })
      : [];
  }, [results]);

  const onFiltersChange = (filters: Record<string, FilterItemState>) => {
    setResultsPage('1'); // reset the page because filter change changes pages
    setQueryState(buildQuery(filters, queryState));
    // save filters for the account/panel
    editAccountPanelData({
      panelId: formId,
      result_filters: filters
    });
  };

  const onRefresh = () => setQueryState({ ...queryState });

  const resultColumns = useResultColumns(
    'allowed_result_columns',
    panel,
    _resultColumns,
    noSort
  );

  // paging
  const getUrlPageParam = (url: string) => {
    if (!url) return '';
    return new URLSearchParams(url).get('page') ?? '0';
  };
  const onPageChange = (page: string) => {
    if (page === '0') page = '1';
    setResultsPage(page);
  };

  const onSearch = (searchTerm: string) => {
    setResultsPage('1'); // reset the page because search/filter change changes pages
    setQueryState({ ...queryState, searchKey: searchTerm });
  };

  const tableSortCallback = (
    sortDirection: SortDirection,
    key: any,
    sortByKey: any
  ) => {
    setQueryState({ ...queryState, sortKey: sortByKey ?? key, sortDirection });
  };

  //////////////////////////////////////////////////////
  // Filter widgets
  const filters: Record<string, FilterItem> = {};
  if (filtering) {
    if (panel && panel.collaboration_enabled) {
      filters[COLLABORATOR_FILTER_ID] = {
        id: COLLABORATOR_FILTER_ID,
        name: 'Pending Collaborator',
        filterWidget: CollaboratorFilter
      };
      filters[COLLABORATOR_ROLE_FILTER_ID] = {
        id: COLLABORATOR_ROLE_FILTER_ID,
        name: PENDING_COLLABORATOR_LABEL_FILTER_LABEL,
        filterWidget: createSimpleFilterWidget(
          COLLABORATOR_ROLE_FILTER_ID,
          PENDING_COLLABORATOR_LABEL_FILTER_LABEL,
          'collaborator_roles',
          [
            ...new Set(
              Object.values(panelsData).flatMap((panel: any) =>
                panel.collaborator_template.map(
                  (template: any) => template.label
                )
              )
            )
          ]
            .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
            .map((label: string) => ({
              value: label,
              label: label
            })),
          true // allow adding new items
        )
      };
    }
    filters[COMPLETED_FILTER_ID] = {
      id: COMPLETED_FILTER_ID,
      name: COMPLETED_FILTER_LABEL,
      filterWidget: createSimpleFilterWidget(
        COMPLETED_FILTER_ID,
        COMPLETED_FILTER_LABEL,
        'completed',
        [
          { value: 'true', label: 'Yes' },
          { value: 'false', label: 'No' }
        ]
      )
    };
    filters[LAST_UPDATED_AT_FILTER_ID] = {
      id: LAST_UPDATED_AT_FILTER_ID,
      name: LAST_UPDATED_AT_FILTER_LABEL,
      filterWidget: createDateTimeFilterWidget(
        LAST_UPDATED_AT_FILTER_ID,
        LAST_UPDATED_AT_FILTER_LABEL,
        'last_updated_at'
      )
    };

    const DISALLOWED_OPERATORS: OPERATOR_CODE[] = ['contains', 'not_contains'];
    filters[FIELD_FILTER_ID] = {
      id: FIELD_FILTER_ID,
      name: FIELD_FILTER_LABEL,
      filterWidget: FieldFilter(
        'id',
        panel.allowed_result_columns,
        // Disallow contains and not contains because they do not work well with the backend.
        // This is because the data is annotated from json and it appears that the
        // json array __contains lookup is being invoked instead and it does
        // something other than a LIKE comparison.  So only allow the ignore case version
        // of contains which does not have a similar issue.
        {
          text_field: DISALLOWED_OPERATORS,
          text_area: DISALLOWED_OPERATORS,
          email: DISALLOWED_OPERATORS,
          ssn: DISALLOWED_OPERATORS,
          phone_number: DISALLOWED_OPERATORS,
          url: DISALLOWED_OPERATORS,
          pin_input: DISALLOWED_OPERATORS,
          qr_scanner: DISALLOWED_OPERATORS,
          gmap_line_1: DISALLOWED_OPERATORS,
          gmap_line_2: DISALLOWED_OPERATORS,
          gmap_zip: DISALLOWED_OPERATORS
        }
      )
    };
  }

  //////////////////////////////////////////////////////

  return (
    <div className={classNames(styles.resultsTable, padding && styles.padding)}>
      {!hideTitle && <h1 className='page-head'>{title}</h1>}
      <ResultsHeader
        label={extractionId ? 'Runs' : 'Users'}
        count={resultData.count}
        refresh={onRefresh}
        onPageChange={onPageChange}
        onEnvChange={setEnvName}
        Icon={extractionId ? AIStarIcon : UsersIcon}
        hideEditTableView={hideEditTableView}
        hideDownloadCSV={hideDownloadCSV}
        hideUploadSubmissions={hideUploadSubmissions}
      />
      <HeaderFilterResultsTable
        name={extractionId ? 'Run' : 'Submission'}
        columns={resultColumns}
        data={dataToRender}
        sortCallback={tableSortCallback}
        onSelect={onSelectResult}
        onDelete={onDelete}
        onCreate={onCreate ? () => onCreate(envName) : undefined}
        dataKey='key'
        useSearch
        onSearch={onSearch}
        filters={filters}
        initialFilterState={initialFilterState}
        onFiltersChange={onFiltersChange}
        onRefresh={onRefresh}
        prevPage={getUrlPageParam(resultData.prevUrl)}
        nextPage={getUrlPageParam(resultData.nextUrl)}
        onPageChange={onPageChange}
      />
    </div>
  );
};

export default UserResultsTable;
