import { useAppSelector } from '../../hooks';
import { deepEquals } from '../../utils/core';
import { renderFile } from '../../pages/FormResultsDetailPage/utils';
import { FILE_FIELDS } from '../SelectionPanel/elementEntries';
import { capitalizeFirstLetter } from '../../utils/format';
import { LinkText } from '../../utils/domOperations';
import ExpandableText from './ExpandableText';
import { nameFromUrl } from '../../utils/browserUtils';

export type ResultColumn = {
  key: string;
  name: string;
  renderCell?: (data: any) => JSX.Element | string;
  noSort?: boolean;
};

export const useResultColumns = (
  allowedColumnsProp:
    | 'allowed_result_columns'
    | 'allowed_task_list_result_columns',
  panel = null,
  resultColumns: ResultColumn[] = [],
  noSort = false
) => {
  const org = useAppSelector((state) => state.accounts.organization);

  const hfMap = useAppSelector((state) => {
    const labelMap: Record<string, any> = {};
    (state.fields.hiddenFields ?? []).forEach(
      (hf: any) => (labelMap[hf.id] = hf)
    );
    return labelMap;
  }, deepEquals);
  const servarMap = useAppSelector((state) => {
    const labelMap: Record<string, any> = {};
    (state.fields.servars ?? []).forEach(
      (servar: any) => (labelMap[servar.id] = servar)
    );
    return labelMap;
  }, deepEquals);

  if (resultColumns.length) {
    return resultColumns;
  }

  const defaultColumns: Record<string, any> = {
    panel_key: { key: 'panel_key', name: 'Form', noSort },
    fuser_key: { key: 'fuser_key', name: 'User ID', noSort },
    collaborator_email: {
      key: 'collaborator_email',
      name: 'Collaborator',
      noSort
    },
    template_label: { key: 'template_label', name: 'Role', noSort },
    status: { key: 'status', name: 'Status', noSort },
    completed_at: { key: 'completed_at', name: 'Completed At', noSort },
    updated_at: { key: 'updated_at', name: 'Last Updated', noSort },
    user_id: { key: 'key', name: 'User ID' },
    submission_status: {
      key: 'status',
      name: 'Submission Status',
      noSort: true
    },
    last_updated: {
      key: 'last_updated_at',
      name: 'Last Updated',
      noSort: true
    },
    pending_collaborator: {
      key: 'pending_collaborator',
      name: 'Pending Collaborator',
      renderCell: (data: any) => {
        const column = 'pending_collaborator';
        const value = data[column];
        if (!value) return value;
        // split value on '\n' and eliminate duplicates.
        // Duplicates are possible from multiple groups, etc.  Easiest to de-dup here.
        const collabEmailsAndGroups = [...new Set(value.split('\n'))];
        return collabEmailsAndGroups.join(', ');
      },
      noSort
    },
    pending_collaborator_label: {
      key: 'pending_collaborator_label',
      name: 'Pending Collaborator Role',
      noSort
    },
    created_at: { key: 'created_at', name: 'Started At' }
  };

  return (panel || org)?.[allowedColumnsProp].map(({ column, type }: any) => {
    const defaultColItem = defaultColumns[column];
    if (type === 'default' && defaultColItem) return defaultColItem;
    else if (type === 'servar')
      return {
        key: column,
        name: servarMap[column].key,
        renderCell: (data: any) => {
          let value = data[column];
          if (!value) return value;

          const servarType = servarMap[column].type;
          if (FILE_FIELDS.includes(servarType)) {
            value = value ?? '';
            // if single file, still treat as an array so render logic can use [].map
            const urls = typeof value === 'string' ? [value] : value;
            value = urls.map((url: string) => nameFromUrl(url));
            return (
              <div onClick={(e) => e.stopPropagation()}>
                {renderFile(value, urls)}
              </div>
            );
          } else if (servarType === 'payment_method') {
            return Object.entries(value.card_data).map(([key, val]) => {
              return (
                <div key={key}>
                  <>
                    {capitalizeFirstLetter(key)}: {val}
                  </>
                </div>
              );
            });
          } else if (servarType === 'matrix') {
            return JSON.stringify(value, null, 2);
          } else if (
            ['text_field', 'text_area', 'email'].includes(servarType) &&
            typeof value === 'string'
          ) {
            return <ExpandableText text={value} />;
          } else if (servarType === 'checkbox') {
            return value ? 'checked' : 'unchecked';
          }
          return value;
        },
        noSort: FILE_FIELDS.includes(column.type)
      };
    else if (type === 'hidden') {
      const key = hfMap[column].key;
      return {
        key: column,
        name: key,
        renderCell: (data: any) => {
          const value = data[column];
          if (!value) return null;

          if (type === 'file_value') {
            const name = nameFromUrl(value);
            return <LinkText text={name} link={value} />;
          } else if (typeof value === 'object') {
            return JSON.stringify(value, null, 2);
          } else {
            return value;
          }
        }
      };
    }
  });
};
