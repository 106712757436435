import {
  DropdownField,
  InlineTooltip,
  Switch,
  TextField
} from '../../../../components/Core';
import { PlusIcon } from '../../../../components/Icons';
import { getDefaultEntity } from '../../utils';
import Entity from '../Entity';
import classNames from 'classnames';
import styles from '../styles.module.scss';
import { Button } from '../../../../components/Core/Button/button';
import PageFilter from './PageFilter';
import GroupType from '../GroupType';

const TYPE_OPTIONS = [
  { value: 'one_value', display: 'Single Value' },
  {
    value: 'multiple_value',
    display: 'Multiple or Related Values'
  },
  { value: 'yes_no', display: 'Yes or No' },
  {
    value: 'page_number',
    display: 'Page Numbers'
  }
];

export function DocumentQuestionGroup({
  group,
  onUpdate,
  isEmailIntegActive,
  index,
  fieldProperties,
  hiddenFieldProperties,
  display_only,
  variant_group
}: any) {
  const showPageFilterSection =
    group.question_type === 'one_value' ||
    group.question_type === 'multiple_value' ||
    group.question_type === 'yes_no';

  return (
    <div className={classNames(styles.keyValuePairs, styles.moreGap)}>
      {!display_only && (
        <GroupType group={group} onUpdate={onUpdate} options={TYPE_OPTIONS} />
      )}
      {!display_only &&
        !['one_value', 'page_number', 'yes_no'].includes(
          group.question_type
        ) && <MultipleValueSettings group={group} onUpdate={onUpdate} />}
      {!display_only && isEmailIntegActive && (
        <EmailSettings group={group} onUpdate={onUpdate} />
      )}
      <div className={styles.keyValuePair}>
        <div className={styles.entitiesContainer}>
          {group.entities?.map((entity: any, entityIndex: number) => (
            <Entity
              key={`group-${index}-entity-${entityIndex + 1}`}
              type={group.question_type}
              labels={{
                name: group.question_type === 'page_number' ? 'Name' : 'Entity'
              }}
              helpers={{
                name:
                  group.question_type === 'page_number'
                    ? 'The name of the page number query'
                    : group.question_type === 'yes_no'
                    ? 'The question you want to ask about a document attribute'
                    : 'The name of the variable you want to extract',
                details:
                  group.question_type === 'page_number'
                    ? 'Details that the page must match to return the page number'
                    : '(Optional) Additional entity details to tune the extraction'
              }}
              placeholders={
                group.question_type === 'page_number'
                  ? {
                      name: 'Active Holding Pages',
                      details: 'Pages that include active holdings'
                    }
                  : group.question_type === 'yes_no'
                  ? {
                      name: 'Is Account Investment',
                      details: 'Is this document an investment account?'
                    }
                  : undefined
              }
              entity={entity}
              fieldProperties={fieldProperties}
              hiddenFieldProperties={hiddenFieldProperties}
              advanced={
                !['page_number', 'yes_no'].includes(group.question_type)
              }
              onUpdate={
                display_only
                  ? (key: string | object, value?: any) => {
                      if (key !== 'field_id') return;
                      onUpdate(entityIndex, value);
                    }
                  : (key: string | object, value?: any) => {
                      const updatedEntities = [...group.entities];
                      updatedEntities[entityIndex] =
                        typeof key !== 'string'
                          ? {
                              ...entity,
                              ...key
                            }
                          : {
                              ...entity,
                              [key]: value
                            };
                      if (key === 'type') {
                        if (value !== 'singleOption') {
                          updatedEntities[entityIndex].options = [];
                          updatedEntities[entityIndex].default_option = '';
                        }
                        if (value !== 'freeForm') {
                          updatedEntities[entityIndex].examples = [];
                        }
                      }
                      onUpdate('entities', updatedEntities);
                    }
              }
              onRemove={
                display_only
                  ? () => {}
                  : () => {
                      const updatedEntities = [...group.entities];
                      updatedEntities.splice(entityIndex, 1);
                      if (updatedEntities.length === 0) {
                        updatedEntities.push(getDefaultEntity());
                      }
                      onUpdate(
                        'entities',
                        updatedEntities,
                        entityIndex,
                        'remove'
                      );
                    }
              }
              variant_id={
                display_only
                  ? variant_group?.field_ids[entityIndex] || ''
                  : undefined
              }
            />
          ))}
        </div>
      </div>
      {!display_only && group.question_type === 'multiple_value' && (
        <div className={styles.keyValuePair}>
          <Button
            variant='dashed'
            onClick={(e) => {
              e.preventDefault();
              const newEntities = [
                ...(group.entities || []),
                getDefaultEntity()
              ];
              onUpdate('entities', newEntities, newEntities.length - 1, 'add');
            }}
          >
            <PlusIcon className={styles.icon} width={12} />
            <span>Add Entity</span>
          </Button>
        </div>
      )}
      {!display_only && showPageFilterSection && (
        <PageFilter group={group} onUpdate={onUpdate} />
      )}
    </div>
  );
}

function MultipleValueSettings({ group, onUpdate, disabled }: any) {
  return (
    <div style={{ marginBottom: '8px' }}>
      <div className={styles.keyValuePair}>
        <div className={styles.key} style={{ width: '190px' }}>
          Multiple Occurrences
          <InlineTooltip
            size={15}
            style={{ marginTop: '-2px' }}
            text='Return one or multiple occurences of a group of related entities.'
          />
        </div>
        <div className={styles.value} style={{ marginTop: '8px' }}>
          <Switch
            id='extraction-question-group-list-toggle'
            checked={
              group.properties?.single_occurrence === undefined
                ? true
                : !group.properties?.single_occurrence
            }
            onCheckedChange={(checked) => {
              onUpdate('properties', {
                ...(group.properties || {}),
                single_occurrence: !checked
              });
            }}
            disabled={disabled}
          />
        </div>
      </div>
      <div className={styles.keyValuePair}>
        <div className={styles.key} style={{ width: '150px' }}>
          Query Details{' '}
          <InlineTooltip text='(Optional) Description & constraints for the overall query' />
        </div>
        <div className={styles.value}>
          <TextField
            placeholder='Only return active account assets'
            className={styles.controlField}
            onComplete={(newVal: string) => {
              onUpdate('criteria', newVal);
            }}
            value={group.criteria}
            disabled={disabled}
          />
        </div>
      </div>
    </div>
  );
}

function EmailSettings({ group, onUpdate, disabled }: any) {
  return (
    <div
      className={styles.keyValuePair}
      style={{ display: 'flex', alignItems: 'center' }}
    >
      <div className={styles.key} style={{ width: '134px' }}>
        Run on Email{' '}
        <InlineTooltip text='Run the query on the email itself (subject and body) and/or the attachments found within the email. If running on both and a value is found on the email, the query will not run on the attachments.' />
      </div>
      <div className={styles.value}>
        <DropdownField
          value={group.run_email_mode}
          options={[
            { value: 'document_only', display: 'Attachments Only' },
            { value: 'email_only', display: 'Email Content Only' },
            {
              value: 'email_document',
              display: 'Email Content, then Attachments'
            }
          ]}
          onChange={(event: any) => {
            const { value } = event.target;
            onUpdate('run_email_mode', value);
          }}
          disabled={disabled}
        />
      </div>
    </div>
  );
}
