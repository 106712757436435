/* eslint-disable react-hooks/exhaustive-deps */

import {
  FIELD_TYPES,
  fieldTypeNameMap,
  TYPE_BUTTON,
  TYPE_IMAGE,
  TYPE_PROGRESS_BAR,
  TYPE_TEXT,
  TYPE_VIDEO
} from '../utils/elements';
import { memo, useEffect, useMemo, useRef, useState } from 'react';
import {
  ELEMENT_DATA,
  elementTypeToAsset,
  filterToStyleOverridesOnly,
  getAsset,
  getThemeStyle,
  themeSelectorToElementType,
  updateThemeAsset,
  updateThemeElement
} from '../utils/themes';
import { objectApply, objectRemove } from '../utils/core';

import { CollapsibleSection, FColorPicker } from '../components/Core';
import DetailPanel from '../components/DetailPanel';
import ElementAssetSection from '../components/Themes/ElementAssetSection';
import { ElementPanel } from '../components/Panels';
import { ElementSection } from '../components/Themes';
import { Elements } from '@feathery/react';
import { PUBLISH_STATUS } from '../redux/utils';
import ThemeNavigation from '../components/NavBar/ThemeNavigation';
import { calculateElementRenderData } from '../utils/step';
import styles from './themes.module.scss';
import useFeatheryRedux from '../redux';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import { calculateOverrideObjects } from '../components/Panels/utils';
import { getTextEditCallbacks } from '../utils/inlineTextEdit';
import { produce } from 'immer';
import { Viewport } from '../components/RenderingEngine/GridInGrid/engine';
import useViewport from '../hooks/useViewport';
import { LaptopIcon, MobileIcon } from '../components/Icons';
import { useAppSelector } from '../hooks';
import { isDark } from '../utils/color';

const FIELDS = [
  {
    label: fieldTypeNameMap.text_field,
    key: 'text_field',
    Field: Elements.TextField
  },
  {
    label: fieldTypeNameMap.text_area,
    key: 'text_area',
    Field: Elements.TextArea
  },
  {
    label: fieldTypeNameMap.integer_field,
    key: 'integer_field',
    Field: Elements.TextField
  },
  {
    label: fieldTypeNameMap.slider,
    key: 'slider',
    Field: Elements.SliderField
  },
  {
    label: fieldTypeNameMap.rating,
    key: 'rating',
    Field: Elements.RatingField
  },
  {
    label: fieldTypeNameMap.button_group,
    key: 'button_group',
    Field: Elements.ButtonGroupField
  },
  {
    label: fieldTypeNameMap.select,
    key: 'select',
    Field: Elements.RadioButtonGroupField
  },
  {
    label: fieldTypeNameMap.multiselect,
    key: 'multiselect',
    Field: Elements.CheckboxGroupField
  },
  {
    label: fieldTypeNameMap.checkbox,
    key: 'checkbox',
    Field: Elements.CheckboxField
  },
  {
    label: fieldTypeNameMap.dropdown,
    key: 'dropdown',
    Field: Elements.DropdownField
  },
  {
    label: fieldTypeNameMap.dropdown_multi,
    key: 'dropdown_multi',
    Field: Elements.DropdownMultiField
  },
  {
    label: fieldTypeNameMap.file_upload,
    key: 'file_upload',
    Field: Elements.FileUploadField
  },
  {
    label: fieldTypeNameMap.date_selector,
    key: 'date_selector',
    Field: Elements.DateSelectorField
  },
  {
    label: fieldTypeNameMap.signature,
    key: 'signature',
    Field: Elements.SignatureField
  },
  {
    label: fieldTypeNameMap.hex_color,
    key: 'hex_color',
    Field: Elements.ColorPickerField
  },
  { label: fieldTypeNameMap.email, key: 'email', Field: Elements.TextField },
  {
    label: fieldTypeNameMap.phone_number,
    key: 'phone_number',
    Field: Elements.PhoneField
  },
  {
    label: fieldTypeNameMap.pin_input,
    key: 'pin_input',
    Field: Elements.PinInputField
  },
  {
    label: fieldTypeNameMap.password,
    key: 'password',
    Field: Elements.PasswordField
  },
  {
    label: fieldTypeNameMap.qr_scanner,
    key: 'qr_scanner',
    Field: Elements.QRScanner
  },
  { label: fieldTypeNameMap.ssn, key: 'ssn', Field: Elements.TextField },
  { label: fieldTypeNameMap.url, key: 'url', Field: Elements.TextField },
  {
    label: fieldTypeNameMap.gmap_line_1,
    key: 'gmap_line_1',
    Field: Elements.TextField
  },
  {
    label: fieldTypeNameMap.gmap_line_2,
    key: 'gmap_line_2',
    Field: Elements.TextField
  },
  {
    label: fieldTypeNameMap.gmap_city,
    key: 'gmap_city',
    Field: Elements.TextField
  },
  {
    label: fieldTypeNameMap.gmap_state,
    key: 'gmap_state',
    Field: Elements.DropdownField
  },
  {
    label: fieldTypeNameMap.gmap_zip,
    key: 'gmap_zip',
    Field: Elements.TextField
  },
  {
    label: fieldTypeNameMap.gmap_country,
    key: 'gmap_country',
    Field: Elements.DropdownField
  },
  {
    label: fieldTypeNameMap.payment_method,
    key: 'payment_method',
    Field: Elements.PaymentMethodField
  }
];
const CATEGORIES = [
  {
    title: 'Basic',
    keys: [TYPE_BUTTON, TYPE_PROGRESS_BAR, TYPE_TEXT, TYPE_IMAGE, TYPE_VIDEO]
  },
  { title: 'Fields', keys: FIELD_TYPES }
];

function ThemeBuilderPage() {
  const { themeId } = useParams<{ themeId: string }>();
  const {
    getTheme,
    editTheme,
    toasts: { addErrorToast },
    themeBuilder: { setPublishStatus, setTheme, setTextSelection }
  } = useFeatheryRedux();
  const { setViewport, isMobile } = useViewport();

  const scrollTracker = useRef(null);
  const themePreview = useRef(null);
  const theme = useAppSelector((s) => s.themeBuilder.theme);
  const themeMap = useAppSelector((s) => s.themeBuilder.themeMap);
  const flatThemeMap = useAppSelector((s) => s.themeBuilder.flatThemeMap);
  const publishStatus = useAppSelector((s) => s.themeBuilder.publishStatus);
  const textSelection = useAppSelector((s) => s.themeBuilder.textSelection);
  const elementEntries = useAppSelector((state) => {
    return Object.fromEntries(
      Object.entries(state.elements).filter(
        ([key]) => !key.startsWith('custom')
      )
    );
  });

  const viewport = useAppSelector(
    (state) => state.accounts.account.viewport_toggle
  );
  const [selectedItem, setSelectedItem] = useState<any>({
    theme: ['global', '']
  });
  const [appliedStyle, setAppliedStyle] = useState(['global', '']);

  function onSelect(newSelectedItem: any, newAppliedStyle: any) {
    setSelectedItem(newSelectedItem);
    setAppliedStyle(newAppliedStyle);
    setTextSelection(null);
  }

  // If the user clicks on an element in the ElementPanel, scroll to the entry in the theme preview
  function scrollTo(type: any) {
    const id = `#element-section-${type}`;
    const element = document.querySelector(id);

    if (element) {
      const rect = element.getBoundingClientRect();
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      const scrollRect = scrollTracker.current.getBoundingClientRect();
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      themePreview.current.scrollTo({
        behavior: 'smooth',
        top: rect.top - scrollRect.top - 50
      });
    }
  }

  function deleteAssetFromTheme(assetProp: any, assetId: any) {
    // make sure the asset about to be deleted is de-selected
    if (assetId === selectedItem.assetId)
      setSelectedItem({ theme: ['global', ''] });
    const newTheme = produce(theme, (draft: any) => {
      draft[assetProp] = draft[assetProp].filter((a: any) => a.id !== assetId);
    });
    setTheme({ theme: newTheme });
    return newTheme;
  }

  const { result: elementStyles } = useMemo(
    () =>
      theme ? calculateOverrideObjects(getBaseStyle(), getOverrideStyle()) : {},
    [appliedStyle, selectedItem, flatThemeMap, viewport, theme, themeMap]
  );

  function handleUpdates(operations: any[]) {
    let newTheme = theme;
    let themeUpdated = false;

    // We get a list of operations when the user updates or reset properties or styles in the theme elements or assets
    // Iterate through each operation and update or reset the correct object
    // Each operation has metadata for specifying the object to change and what properties/styles to change
    operations.forEach(({ viewport, ...operation }: any) => {
      const styleKey =
        viewport !== Viewport.Mobile ? 'styles' : 'mobile_styles';

      function updatePropsHelper(propUpdate: any) {
        return (element: any) => objectApply(element, propUpdate);
      }

      function updateStylesHelper(styleUpdate: any) {
        return (element: any) =>
          objectApply(element, {
            [styleKey]: filterToStyleOverridesOnly(styleUpdate, elementStyles)
          });
      }

      function resetPropsHelper(propReset: any) {
        return (element: any) => {
          // Currently only handles resetting text_formatted prop for assets in theme builder
          if (propReset.includes('text_formatted')) {
            (element.properties.text_formatted ?? []).forEach(
              (op: any) => (op.attributes = {})
            );
          }
          return element;
        };
      }

      function resetStylesHelper(styleReset: any) {
        return (element: any) => {
          element[styleKey] = objectRemove(element[styleKey], styleReset);
          return element;
        };
      }

      // If type is 'element', this could be a change to theme elements or assets
      if (operation.type === 'element') {
        const { propUpdate, propReset, styleUpdate, styleReset, remove } =
          operation;
        let { asset, elementType } = operation; // asset and elementType optional
        const { assetId, theme: themeSelector } = selectedItem;

        themeUpdated = true;
        // The asset can either be passed directly or as an id to use as a lookup
        if (asset || assetId) {
          const [l2, l1] = appliedStyle;
          if (!elementType) elementType = themeSelectorToElementType(l2, l1);
          if (!asset) asset = getAsset(theme, elementType, assetId);

          if (remove) {
            const assetProp = elementTypeToAsset(elementType);
            newTheme = deleteAssetFromTheme(assetProp, asset.id);
          }
          if (styleUpdate)
            newTheme = updateThemeAsset(
              newTheme,
              elementType,
              asset,
              updateStylesHelper(styleUpdate)
            );
          if (styleReset)
            newTheme = updateThemeAsset(
              newTheme,
              elementType,
              asset,
              resetStylesHelper(styleReset)
            );
          if (propUpdate)
            newTheme = updateThemeAsset(
              newTheme,
              elementType,
              asset,
              updatePropsHelper(propUpdate)
            );
          if (propReset)
            newTheme = updateThemeAsset(
              newTheme,
              elementType,
              asset,
              resetPropsHelper(propReset)
            );
        } else if (themeSelector) {
          if (styleUpdate)
            newTheme = updateThemeElement(
              newTheme,
              themeSelector,
              updateStylesHelper(styleUpdate)
            );
          if (styleReset)
            newTheme = updateThemeElement(
              newTheme,
              themeSelector,
              resetStylesHelper(styleReset)
            );
        }
      }

      themeUpdated && setTheme(newTheme);
    });
  }

  function getBaseProps() {
    const { assetId, theme: themeSelector } = selectedItem;

    if (assetId) {
      const [l2, l1] = appliedStyle;
      const elementType = themeSelectorToElementType(l2, l1);
      const asset = getAsset(theme, elementType, assetId);
      return { ...asset, source_asset: asset.id };
    } else if (themeSelector) {
      return {};
    }
  }

  function getBaseStyle() {
    const [l2, l1] = appliedStyle;
    const { assetId } = selectedItem;

    let styles;
    if (flatThemeMap) {
      if (l2 === 'global') {
        styles = getThemeStyle(flatThemeMap, viewport, 'global');
      } else if (l1) {
        styles = getThemeStyle(
          flatThemeMap,
          viewport,
          l2,
          viewport !== Viewport.Mobile ? '' : l1
        );
      } else {
        styles = getThemeStyle(
          flatThemeMap,
          viewport,
          viewport !== Viewport.Mobile ? 'global' : l2
        );
      }

      if (assetId) {
        styles = objectApply(
          styles,
          getThemeStyle(flatThemeMap, Viewport.Desktop, l2, l1)
        );

        if (viewport === Viewport.Mobile) {
          const currentAsset = getAsset(
            theme,
            themeSelectorToElementType(l2, l1),
            assetId
          );
          styles = objectApply(styles, currentAsset?.styles);
        }
      }
    }

    return styles;
  }

  function getOverrideProps() {
    const { assetId, theme: themeSelector } = selectedItem;

    if (assetId) {
      return {};
    } else if (themeSelector) {
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      return ELEMENT_DATA[appliedStyle[1] || appliedStyle[0]];
    }
  }

  function getOverrideStyle() {
    const [l2, l1] = appliedStyle;
    const { assetId } = selectedItem;
    if (assetId) {
      const currentAsset = getAsset(
        theme,
        themeSelectorToElementType(l2, l1),
        assetId
      );
      return viewport !== Viewport.Mobile
        ? currentAsset?.styles
        : currentAsset?.mobile_styles;
    }
    if (l2 === 'global') {
      if (viewport !== Viewport.Mobile) {
        return {};
      } else if (viewport === Viewport.Mobile) {
        return themeMap.global[''].mobileStyles;
      }
    } else {
      return getThemeStyle(themeMap, viewport, l2, l1);
    }
  }

  // Load up the specified theme on load
  useEffect(() => {
    (async () => {
      try {
        const theme = await getTheme({ themeId });
        setTheme(theme, PUBLISH_STATUS.FULFILLED);
      } catch (error) {
        addErrorToast({ title: error });
        setTheme(undefined, PUBLISH_STATUS.FULFILLED);
      }
    })();

    // Reset the theme builder on unmount so we avoid flickering upon re-entry
    return () => setTheme(undefined, PUBLISH_STATUS.FULFILLED);
  }, [themeId]);

  const isAsset = !!selectedItem.assetId;
  const getTextCallbacks = (asset: any) =>
    getTextEditCallbacks({
      setText: (text: any, textFormatted: any) => {
        setTheme(
          updateThemeAsset(theme, TYPE_TEXT, asset, (el: any) =>
            objectApply(el, {
              properties: { text, text_formatted: textFormatted }
            })
          )
        );
      },
      curTextFormatted: asset.properties.text_formatted,
      textSelection,
      setTextSelection
    });

  return (
    <div className={styles.themeBuilder}>
      <ThemeNavigation
        style={{ gridColumn: '1 / -1' }}
        activeItem='builder'
        status={publishStatus}
        onPublish={() => {
          setPublishStatus(PUBLISH_STATUS.LOADING);
          editTheme({ themeId, ...theme })
            .then(() => setPublishStatus(PUBLISH_STATUS.FULFILLED))
            .catch((err: any) => {
              setPublishStatus(PUBLISH_STATUS.ACTIVE);
              addErrorToast({
                title: 'Publish Error',
                body: err.message,
                decay: 10000
              });
            });
        }}
      />
      <div className={styles.elementNavigator}>
        <ElementPanel
          elements={elementEntries}
          categories={CATEGORIES}
          mode='nav'
          context='theme'
          handleUpdates={handleUpdates}
          deleteAssetFromTheme={deleteAssetFromTheme}
          onElementMouseDown={(category: any, asset: any) => {
            const categoryMap = {
              [TYPE_TEXT]: 'text',
              [TYPE_BUTTON]: 'button',
              [TYPE_IMAGE]: 'image',
              [TYPE_VIDEO]: 'video'
            };

            // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            category = categoryMap[category] ?? category;
            scrollTo(category);

            const specifier = FIELD_TYPES.includes(category)
              ? ['field', category]
              : [category, ''];
            const newSelectedItem = asset
              ? { assetId: asset.id }
              : { theme: specifier };
            onSelect(newSelectedItem, specifier);
          }}
        />
      </div>
      {themeMap && (
        <div
          ref={themePreview}
          className={styles.themePreview}
          onMouseDown={() => {
            const specifier = ['global', ''];
            onSelect({ theme: specifier }, specifier);
          }}
        >
          <div ref={scrollTracker} />
          <div className={styles.backgroundColorPicker}>
            Step&nbsp;Background
            <FColorPicker
              value={theme?.step_background_color ?? 'FFFFFFFF'}
              onChange={(color: any) =>
                setTheme({ theme: { ...theme, step_background_color: color } })
              }
              vertical='top'
            />
          </div>
          <div className={styles.viewportButtons}>
            <div
              className={classNames(
                styles.viewportButton,
                !isMobile && styles.active
              )}
              onClick={() => setViewport(Viewport.Desktop)}
            >
              <LaptopIcon />
            </div>
            <div
              className={classNames(
                styles.viewportButton,
                isMobile && styles.active
              )}
              onClick={() => setViewport(Viewport.Mobile)}
            >
              <MobileIcon />
            </div>
          </div>
          <ElementSection
            overflow={false}
            category='basic'
            label='Basic'
            style={{ paddingBottom: 0 }}
            background={theme?.step_background_color}
          >
            <div style={{ display: 'grid', gridTemplateColumns: '50% 50%' }}>
              <ElementAssetSection
                isElement
                title='Buttons'
                category='button'
                appliedStyle={appliedStyle || []}
                assets={theme.button_assets}
                viewport={viewport}
                component='ButtonElement'
                elementData={ELEMENT_DATA.button}
                selectedItem={selectedItem}
                theme={theme}
                onMouseDownAsset={(id: any) => {
                  onSelect({ assetId: id }, ['button', '']);
                }}
                mouseDownTheme={() => {
                  onSelect({ theme: ['button', ''] }, ['button', '']);
                }}
                getTextCallbacks={getTextCallbacks}
                background={theme?.step_background_color}
              />
              <ElementAssetSection
                isElement
                title='Progress Bars'
                category='progress_bar'
                component='ProgressBarElement'
                appliedStyle={appliedStyle || []}
                assets={theme.progress_bar_assets}
                viewport={viewport}
                elementData={ELEMENT_DATA.progress_bar}
                selectedItem={selectedItem}
                theme={theme}
                onMouseDownAsset={(id: any) => {
                  onSelect({ assetId: id }, ['progress_bar', '']);
                }}
                mouseDownTheme={() => {
                  onSelect({ theme: ['progress_bar', ''] }, [
                    'progress_bar',
                    ''
                  ]);
                }}
                background={theme?.step_background_color}
              />
              <ElementAssetSection
                isElement
                title='Text Elements'
                category='text'
                component='TextElement'
                appliedStyle={appliedStyle || []}
                assets={theme.text_assets}
                viewport={viewport}
                elementData={ELEMENT_DATA.text}
                selectedItem={selectedItem}
                theme={theme}
                onMouseDownAsset={(id: any) => {
                  onSelect({ assetId: id }, ['text', '']);
                }}
                mouseDownTheme={() => {
                  onSelect({ theme: ['text', ''] }, ['text', '']);
                }}
                getTextCallbacks={getTextCallbacks}
                background={theme?.step_background_color}
              />
              <ElementAssetSection
                isElement
                title='Images'
                category='image'
                component='ImageElement'
                appliedStyle={appliedStyle || []}
                assets={theme.image_assets}
                viewport={viewport}
                elementData={ELEMENT_DATA.image}
                selectedItem={selectedItem}
                theme={theme}
                onMouseDownAsset={(id: any) => {
                  onSelect({ assetId: id }, ['image', '']);
                }}
                mouseDownTheme={() => {
                  onSelect({ theme: ['image', ''] }, ['image', '']);
                }}
                background={theme?.step_background_color}
              />
              <ElementAssetSection
                isElement
                title='Videos'
                category='video'
                component='VideoElement'
                appliedStyle={appliedStyle || []}
                assets={theme.video_assets}
                viewport={viewport}
                elementData={ELEMENT_DATA.video}
                selectedItem={selectedItem}
                theme={theme}
                onMouseDownAsset={(id: any) => {
                  onSelect({ assetId: id }, ['video', '']);
                }}
                mouseDownTheme={() => {
                  onSelect({ theme: ['video', ''] }, ['video', '']);
                }}
                background={theme?.step_background_color}
              />
            </div>
          </ElementSection>
          <ElementSection
            overflow={false}
            label='Fields'
            category='field'
            highlighted={appliedStyle[0] === 'field' && appliedStyle[1] === ''}
            onMouseDown={() => {
              onSelect({ theme: ['field', ''] }, ['field', '']);
            }}
            background={theme?.step_background_color}
          >
            <div className={styles.editStyle}>Edit All Field Styles</div>
            <div style={{ display: 'grid', gridTemplateColumns: '50% 50%' }}>
              {FIELDS.map(({ label, key, Field }) => {
                const fieldAssets = theme.servar_field_assets.filter(
                  (asset: any) => asset.servar.type === key
                );
                return (
                  <div key={key} className={styles.elementSectionContainer}>
                    <div
                      className={styles.borderOnly}
                      style={{ marginTop: '8px' }}
                    >
                      <ElementSection
                        label={label}
                        category={key}
                        showLabel
                        highlighted={
                          selectedItem?.theme && selectedItem?.theme[1] === key
                        }
                        onMouseDown={() => {
                          onSelect({ theme: ['field', key] }, ['field', key]);
                        }}
                        background={theme?.step_background_color}
                      >
                        <Field
                          element={{
                            // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                            ...ELEMENT_DATA[key],
                            styles: getThemeStyle(
                              flatThemeMap,
                              viewport,
                              'field',
                              key
                            )
                          }}
                          editMode='editable'
                        />
                      </ElementSection>
                      {fieldAssets.length > 0 && (
                        <CollapsibleSection
                          title='Assets'
                          collapsible
                          customClasses={{
                            header: isDark(theme?.step_background_color)
                              ? styles.darkSectionHeader
                              : ''
                          }}
                          resetHeaderStyle
                          isAssetSelected={appliedStyle[1] === key}
                          mouseDownTheme={() => {
                            onSelect({ assetId: fieldAssets[0].id }, [
                              'field',
                              key
                            ]);
                          }}
                        >
                          <div className={styles.assetContainer}>
                            {fieldAssets.map((asset: any) => (
                              <ElementAssetSection
                                key={asset.key}
                                label={asset.key}
                                category={key}
                                highlighted={selectedItem?.assetId === asset.id}
                                onMouseDown={() => {
                                  onSelect({ assetId: asset.id }, [
                                    'field',
                                    key
                                  ]);
                                }}
                                background={theme?.step_background_color}
                              >
                                <Field
                                  element={calculateElementRenderData({
                                    element: asset,
                                    theme,
                                    viewport,
                                    style: ['field', key]
                                  })}
                                  editMode='editable'
                                />
                              </ElementAssetSection>
                            ))}
                          </div>
                        </CollapsibleSection>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </ElementSection>
        </div>
      )}
      <div className={styles.styleTab}>
        {themeMap && (
          <DetailPanel
            key={selectedItem?.assetId}
            mode='theme'
            // @ts-expect-error TS(2556) FIXME: A spread argument must either have a tuple type or... Remove this comment to see the full error message
            elementType={themeSelectorToElementType(...appliedStyle)}
            viewport={viewport}
            theme={theme}
            baseProps={getBaseProps()}
            baseStyle={getBaseStyle()}
            overrideProps={getOverrideProps()}
            overrideStyle={getOverrideStyle()}
            handleUpdates={handleUpdates}
            isAsset={isAsset}
            onClose={() => {
              const specifier = ['global', ''];
              onSelect({ theme: specifier }, specifier);
            }}
          />
        )}
      </div>
    </div>
  );
}

export default memo(ThemeBuilderPage);
