import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ProgressBar } from './components/ProgressBar';
import {
  API_CONNECTOR_TEMPLATE_PAGE,
  CONFIGURE_REQUEST_PAGE,
  MAP_RESPONSE_DATA_PAGE,
  MAP_RESPONSE_STRUCTURE_PAGE,
  PAGE_COMPONENTS,
  SELECT_API_SERVICE_PAGE
} from './constants';
import { DEFAULT_STATE } from '../../../pages/FormLogicDetailPage/LogicRuleDetail/components/RuleBuilder/components/RuleAction/ConnectToAPIAction/constants';
import { IAPIActionState } from './pages/types';
import { mapAPIConnectorToRaw, mapRawAPIConnector } from './utils';
import classNames from 'classnames';
import useFeatheryRedux from '../../../redux';
import Dialog from '../../Dialog';
import styles from './styles.module.scss';
import { Spinner } from '../../Core/Spinner';

type APIServiceModalOptions = {
  startPage?: number;
};

type APIServiceModalProps = {
  value?: string;
  show: boolean;
  onClose?: () => void;
  onComplete?: (state: any) => void;
  options?: APIServiceModalOptions;
};

export const APIServiceModal = (props: APIServiceModalProps) => {
  const {
    show,
    value,
    onClose = () => {},
    onComplete = () => {},
    options = {}
  } = props;
  const { startPage = 0 } = options;

  const { formId } = useParams<{ formId: string }>();
  const [apiConnectors, setAPIConnectors] = useState<any[]>([]);
  const [activePage, setActivePage] = useState(startPage);
  const [activeStep, setActiveStep] = useState(0);
  const [state, setState] = useState(JSON.parse(JSON.stringify(DEFAULT_STATE)));
  const [pageOptions, setPageOptions] = useState<any>(options);
  const [isLoading, setLoading] = useState(true);

  const {
    getCustomAPIs,
    editCustomAPI,
    createCustomAPI,
    toasts: { addErrorToast }
  } = useFeatheryRedux();

  const PageComponent = useMemo(() => {
    return (
      PAGE_COMPONENTS[activePage] || PAGE_COMPONENTS[SELECT_API_SERVICE_PAGE]
    );
  }, [activePage]);

  const resetState = () => {
    setActivePage(0);
    setActiveStep(0);
    setState(JSON.parse(JSON.stringify(DEFAULT_STATE)));
    setLoading(true);
  };

  const fetchAPIs = async () => {
    return getCustomAPIs({ panelId: formId }).then((res: any) => {
      if (Array.isArray(res)) {
        const connectors = res.map(mapRawAPIConnector);
        setAPIConnectors(connectors);
        return connectors;
      }

      return [];
    });
  };

  // Reset the state when the modal is closed
  useEffect(() => {
    if (!show) {
      resetState();
    }

    if (show) {
      fetchAPIs().then((connectors: any[]) => {
        if (value) {
          const apiConnector = connectors.find((c) => c.id === value);

          if (apiConnector) {
            setState(apiConnector as IAPIActionState);
          } else {
            setState(JSON.parse(JSON.stringify(DEFAULT_STATE)));
          }
        }

        // Skip the first page if there isn't any existing API connectors
        if (!connectors.length) {
          setActivePage(API_CONNECTOR_TEMPLATE_PAGE);
          setPageOptions({
            ...pageOptions,
            startPage: 1
          });
        }

        if (startPage) {
          setActivePage(startPage);
          setPageOptions({
            ...pageOptions,
            startPage: startPage
          });
        }

        setLoading(false);
      });
    }
  }, [show, startPage]);

  // Refetch API Connectors when landing on the select API connector page
  useEffect(() => {
    if (activePage === SELECT_API_SERVICE_PAGE) {
      fetchAPIs();
    }
  }, [activePage]);

  // Function to handle going to the next page
  const handleNext = (gotoPage?: number) => {
    if (gotoPage !== undefined) {
      setActivePage(gotoPage);
    } else if (activePage < Object.keys(PAGE_COMPONENTS).length - 1) {
      setActivePage((p) => p + 1);
    }

    // If the next page is the map response structure page, increment the active step
    const nextActivePage = gotoPage || activePage + 1;
    if (nextActivePage === MAP_RESPONSE_STRUCTURE_PAGE && activeStep < 1) {
      setActiveStep((s) => s + 1);
    }
  };

  // Function to handle going to the previous page
  const handleBack = (gotoPage?: number) => {
    if (gotoPage !== undefined) {
      setActivePage(gotoPage);
    } else if (activePage > 0) {
      setActivePage((p) => p - 1);
    }

    const nextActivePage = gotoPage || activePage - 1;
    if (nextActivePage <= CONFIGURE_REQUEST_PAGE && activeStep >= 1) {
      setActiveStep((s) => s - 1);
    }
  };

  // Function to handle changes made by the pages in the modal
  const handleChange = (key: string, value: any) => {
    if (key === 'id') {
      const apiConnector = apiConnectors.find((c) => c.id === value);

      if (apiConnector) {
        setState(apiConnector as IAPIActionState);
      } else {
        setState(JSON.parse(JSON.stringify(DEFAULT_STATE)));
      }
    } else if (key === 'method') {
      setState((s: any) => ({
        ...s,
        method: value,
        body: value === 'GET' || value === 'DELETE' ? '' : s.body // Reset the body if needed
      }));
    } else {
      setState((s: any) => ({
        ...s,
        [key]: value
      }));
    }
  };

  // Function to handle the completion of the modal
  const handleComplete = async (persist = true) => {
    const payload = mapAPIConnectorToRaw(state);

    if (!persist) {
      onComplete(payload);
      return;
    }

    if (payload.id) {
      // Edit an existing API
      try {
        const response = await editCustomAPI({
          customAPI: payload
        });
        onComplete(response);
      } catch (err) {
        console.error(err);
        addErrorToast({ text: 'Failed to update API Connector' });
      }
    } else {
      // Create a new API
      try {
        const response = await createCustomAPI({
          panelId: formId,
          customAPI: payload
        });

        onComplete(response);
      } catch (err: any) {
        console.error(err);
        addErrorToast({
          text: 'Failed to create API Connector'
        });
      }
    }
  };

  // Function to handle clicks on the progress bar
  const handleSetActiveStep = (newActiveStep: number) => {
    if (newActiveStep === 0) setActivePage(CONFIGURE_REQUEST_PAGE);
    if (newActiveStep === 1) setActivePage(MAP_RESPONSE_STRUCTURE_PAGE);
    setActiveStep(newActiveStep);
  };

  return (
    <Dialog
      isOpen={show}
      onClose={onClose}
      showExitConfirmation
      title='Connect to API'
      size={activePage === MAP_RESPONSE_DATA_PAGE ? 'xlg' : 'lg'}
      className={classNames(styles.modal, {
        [styles.lgModal]: activePage === MAP_RESPONSE_DATA_PAGE
      })}
    >
      {activePage >= CONFIGURE_REQUEST_PAGE && (
        <ProgressBar
          activeStep={activeStep}
          onSetActiveStep={handleSetActiveStep}
          allowSkipping={!!state.id}
        />
      )}
      {isLoading ? (
        <div className={styles.loading}>
          <Spinner className='w-[50px] h-[50px]' />
        </div>
      ) : (
        <PageComponent
          state={state}
          setState={setState}
          apiConnectors={apiConnectors}
          onChange={(key: string, value: any) => handleChange(key, value)}
          onComplete={handleComplete}
          onSubmit={(gotoPage?: number) => handleNext(gotoPage)}
          onBack={(gotoPage?: number) => handleBack(gotoPage)}
          goto={(page: number) => setActivePage(page)}
          options={pageOptions}
          setOptions={setPageOptions}
        />
      )}
    </Dialog>
  );
};
