import '../../style/dialog-form.css';

import { useState } from 'react';

import { ALL_INTEGRATIONS_MAP, INTEGRATIONS } from './types';
import { PropertyLabel, TextField } from '../Core';
import IntegrationsSidebar from './IntegrationsSidebar';
import useIntegrations from './useIntegrations';
import { useParams } from 'react-router-dom';
import styles from './styles.module.scss';

function AddeparSettingsSidebar() {
  const { formId } = useParams<{ formId: string }>();

  const integration = useIntegrations({
    type: INTEGRATIONS.ADDEPAR,
    panelId: formId
  });

  const secretMeta = integration?.data.secret_metadata ?? {};
  const [addeparConfig, setAddeparConfig] = useState({
    firm_id: secretMeta.firm_id ?? '',
    api_key: secretMeta.api_key ?? '',
    api_secret: secretMeta.api_secret ?? ''
  });

  const [isPartial, setIsPartial] = useState(false);
  const [err, setErr] = useState('');

  const updateConfig = (key: string) => (val: string) => {
    setAddeparConfig({ ...addeparConfig, [key]: val });
  };

  function onSubmitCustom(newIsActive: boolean) {
    setErr('');

    if (newIsActive) {
      const partial = Object.values(addeparConfig).some((entry) => !entry);
      setIsPartial(partial);
      if (partial) return;
    }

    return {
      isUpdate: integration?.data,
      secretMetadata: addeparConfig
    };
  }

  return (
    <IntegrationsSidebar
      integrationInfo={ALL_INTEGRATIONS_MAP[INTEGRATIONS.ADDEPAR]}
      isPartial={isPartial}
      onSubmitCustom={onSubmitCustom}
      customError={err}
    >
      <form className='integration-modal-form'>
        <div>
          <PropertyLabel label='Firm ID' />
          <TextField
            value={addeparConfig.firm_id}
            onChange={updateConfig('firm_id')}
            className={styles.marginBottom}
            error={isPartial && !addeparConfig.firm_id}
          />
        </div>
        <div>
          <PropertyLabel label='API Key' />
          <TextField
            value={addeparConfig.api_key}
            onChange={updateConfig('api_key')}
            className={styles.marginBottom}
            error={isPartial && !addeparConfig.api_key}
          />
        </div>
        <div>
          <PropertyLabel label='API Secret' />
          <TextField
            type='password'
            value={addeparConfig.api_secret}
            onChange={updateConfig('api_secret')}
            className={styles.marginBottom}
            error={isPartial && !addeparConfig.api_secret}
          />
        </div>
      </form>
    </IntegrationsSidebar>
  );
}

export default AddeparSettingsSidebar;
