import { memo, useCallback, useState } from 'react';

import Dialog from '../../Dialog';
import { useParams } from 'react-router-dom';
import useModalSubmissionLockout from '../../../utils/useModalSubmissionLockout';
import ConfirmationOverlay from '../components/ConfirmationOverlay';
import { Neutral, Positive } from '../../Core/Button';
import { usePromoteForm } from './usePromoteForm';
import { ConfirmStep } from './steps/ConfirmStep';
import { MapFieldsStep } from './steps/MapFieldsStep';
import { RollbackExtractionsStep } from './steps/RollbackExtractionsStep';
import { MapExtractionsStep } from './steps/MapExtractionsStep';
import { useAppSelector } from '../../../hooks';
import { handleErrorMessage } from './utils';
import useFeatheryRedux from '../../../redux';

const CONFIRM_STEP = 'confirm';
const MAP_FIELDS_STEP = 'map_fields';
const ROLLBACK_EXTRACTIONS_STEP = 'rollback_extractions';
const MAP_EXTRACTIONS_STEP = 'map_extractions';
type Step =
  | typeof CONFIRM_STEP
  | typeof MAP_FIELDS_STEP
  | typeof ROLLBACK_EXTRACTIONS_STEP
  | typeof MAP_EXTRACTIONS_STEP;

interface FormPromotionProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

function FormPromotionModal({ open, setOpen }: FormPromotionProps) {
  const isSomePromoting = useAppSelector((state) => {
    if (!state.panels.panels) return false;

    return Object.values(state.panels.panels).some((panel) => panel.promoting);
  });

  const { formId } = useParams<{ formId: string }>();
  const {
    toasts: { addErrorToast }
  } = useFeatheryRedux();

  const {
    promoteForm,
    isRollback,
    promote_id,
    promote_key,
    servarKeysById,
    thisFormServarIds,
    promoteToServarIds,
    mapFieldData,
    setMapFieldData,
    extractions,
    mapExtractionData,
    setMapExtractionData,
    formHasExtractions
  } = usePromoteForm(formId);

  const [step, setStep] = useState<Step>(CONFIRM_STEP);
  const [showCloseConfirmOverlay, setShowCloseConfirmOverlay] = useState(false);

  const closeModal = useCallback(() => {
    setStep(CONFIRM_STEP);
    setOpen(false);
  }, []);

  const handlePromote = async () => {
    try {
      await promoteForm();
      closeModal();
    } catch (error) {
      addErrorToast({
        title: handleErrorMessage(
          error,
          'Could not promote form. Please try again.'
        )
      });
    }
  };
  const submit = async (currentStep: Step) => {
    if (currentStep === CONFIRM_STEP) {
      setStep(MAP_FIELDS_STEP);
    } else if (currentStep === MAP_FIELDS_STEP) {
      if (isRollback && extractions.length > 0) {
        setStep(ROLLBACK_EXTRACTIONS_STEP);
      } else if (formHasExtractions) {
        setStep(MAP_EXTRACTIONS_STEP);
      } else {
        await handlePromote();
      }
    } else if (currentStep === ROLLBACK_EXTRACTIONS_STEP) {
      await handlePromote();
    } else if (currentStep === MAP_EXTRACTIONS_STEP) {
      await handlePromote();
    }
  };
  const { lockOutFlag, lockoutFunction } = useModalSubmissionLockout(submit);

  const title =
    step === MAP_FIELDS_STEP
      ? 'Map Form Fields'
      : step === MAP_EXTRACTIONS_STEP
      ? 'Map Extractions'
      : step === ROLLBACK_EXTRACTIONS_STEP
      ? 'Rollback Extractions'
      : isRollback
      ? 'Rollback Form'
      : 'Promote Form';

  return (
    <Dialog
      isOpen={open}
      title={title}
      size='sm'
      onClose={() => {
        if (step !== CONFIRM_STEP) setShowCloseConfirmOverlay(true);
        else closeModal();
      }}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          return lockoutFunction(step);
        }}
      >
        {step === CONFIRM_STEP && (
          <ConfirmStep
            isRollback={isRollback}
            formId={promote_id}
            formKey={promote_key}
          />
        )}
        {step === MAP_FIELDS_STEP && (
          <MapFieldsStep
            thisFormServarIds={thisFormServarIds}
            promoteToServarIds={promoteToServarIds}
            servarKeysById={servarKeysById}
            setMapFieldData={setMapFieldData}
            mapFieldData={mapFieldData}
            promoteKey={promote_key}
          />
        )}
        {step === ROLLBACK_EXTRACTIONS_STEP && (
          <RollbackExtractionsStep
            thisFormServarIds={thisFormServarIds}
            promoteToServarIds={promoteToServarIds}
            servarKeysById={servarKeysById}
            setMapFieldData={setMapFieldData}
            mapFieldData={mapFieldData}
            promoteKey={promote_key}
            extractions={extractions}
            setMapExtractionData={setMapExtractionData}
            mapExtractionData={mapExtractionData}
          />
        )}
        {step === MAP_EXTRACTIONS_STEP && (
          <MapExtractionsStep
            thisFormServarIds={thisFormServarIds}
            promoteToServarIds={promoteToServarIds}
            servarKeysById={servarKeysById}
            setMapFieldData={setMapFieldData}
            mapFieldData={mapFieldData}
            promoteKey={promote_key}
            extractions={extractions}
            setMapExtractionData={setMapExtractionData}
            mapExtractionData={mapExtractionData}
          />
        )}
        <div className='dialog-form-action text-center'>
          {(step === CONFIRM_STEP || step === MAP_FIELDS_STEP) && (
            <Neutral
              onClick={() => {
                if (step === MAP_FIELDS_STEP) setShowCloseConfirmOverlay(true);
                else closeModal();
              }}
            >
              Cancel
            </Neutral>
          )}

          {(step === MAP_EXTRACTIONS_STEP ||
            step === ROLLBACK_EXTRACTIONS_STEP) && (
            <Neutral
              onClick={() => {
                setStep(MAP_FIELDS_STEP);
              }}
            >
              Back to Field Map
            </Neutral>
          )}

          {(step === CONFIRM_STEP ||
            (step === MAP_FIELDS_STEP &&
              isRollback &&
              extractions.length > 0) ||
            (step === MAP_FIELDS_STEP &&
              !isRollback &&
              formHasExtractions)) && <Positive>Continue</Positive>}
          {step === MAP_FIELDS_STEP &&
            ((isRollback && extractions.length === 0) ||
              (!isRollback && !formHasExtractions)) && (
              <Positive
                disabled={isSomePromoting}
                lockoutOverride={lockOutFlag}
              >
                {isRollback ? 'Rollback Form' : 'Promote Form'}
              </Positive>
            )}
          {step === MAP_EXTRACTIONS_STEP && (
            <Positive disabled={isSomePromoting} lockoutOverride={lockOutFlag}>
              Promote Form
            </Positive>
          )}
          {step === ROLLBACK_EXTRACTIONS_STEP && (
            <Positive disabled={isSomePromoting} lockoutOverride={lockOutFlag}>
              Rollback Form
            </Positive>
          )}
        </div>
        <ConfirmationOverlay
          show={showCloseConfirmOverlay}
          hideIt={() => setShowCloseConfirmOverlay(false)}
          onConfirm={closeModal}
        />
      </form>
    </Dialog>
  );
}

export default memo(FormPromotionModal);
