import { useEffect, useState } from 'react';
import FormNavigation from '../../components/NavBar/FormNavigation';
import { useAppSelector } from '../../hooks';
import { Prompt, useHistory, useParams } from 'react-router-dom';
import useFeatheryRedux from '../../redux';
import { DRAFT_STATUS, PUBLISH_STATUS } from '../../redux/utils';
import type { ERROR_WARNINGS } from '../../utils/useDraftOps';
import useDraftOps from '../../utils/useDraftOps';
import useDraftForm from '../../utils/useDraftForm';
import useAssetInUseUpdater from '../../utils/useAssetInUseUpdater';
import { PublishWarningsAndErrorsModal } from '../../components/Modals';
import { UUID_REGEX } from '../../components/RenderingEngine/utils';
import { useAuthClient } from '@feathery/react';
import { PublishButton, ShareButton } from '../../components/Core/Button';

type FormDesignerUtilsProps = {
  mode: string;
};

const FormDesignerUtils = ({ mode }: FormDesignerUtilsProps) => {
  const { formId } = useParams<{ formId: string }>();
  const history = useHistory();

  const panel = useAppSelector((state) => state.panels.panels[formId]);

  const {
    formBuilder: {
      clearUndoRedoStacks,
      handleUndo,
      handleRedo,
      resetToInitialState,
      setAccountData,
      setDraftStatus,
      setFormId,
      setTheme,
      setWorkingLogicRules,
      setWorkingSteps
    }
  } = useFeatheryRedux();
  const authClient = useAuthClient();

  const account = useAppSelector((state) => state.accounts.account);
  const formPublishPermission =
    account.role === 'admin' || account.permission_publish_forms;

  const {
    steps,
    draftSteps,
    logicRules,
    draftLogicRules,
    draftTheme,
    loadedFormId,
    setReloadForm
  } = useDraftForm(true);
  const { unsavedDraftChanges, saveAndValidate, publish, deleteDraft } =
    useDraftOps(
      true,
      () => setDraftStatus(DRAFT_STATUS.CONFLICT),
      true, // init the servar cache
      draftSteps,
      loadedFormId,
      setReloadForm
    );

  // Load up formBuilder redux state when we open the builder with workingSteps,
  // workingLogicRules and theme.
  const newSteps = draftSteps ?? steps;
  useEffect(() => {
    if (Object.keys(newSteps).length !== 0) {
      setWorkingSteps({
        workingSteps: newSteps,
        status:
          draftSteps || draftLogicRules || draftTheme
            ? PUBLISH_STATUS.ACTIVE
            : PUBLISH_STATUS.FULFILLED
      });
    } else {
      clearUndoRedoStacks();
      setWorkingSteps({
        workingSteps: {},
        status: PUBLISH_STATUS.ACTIVE
      });
    }
  }, [newSteps]);
  // Logic Rules
  const newLogicRules = draftLogicRules ?? logicRules;
  useEffect(() => {
    if (panel && newLogicRules) {
      const status =
        draftSteps || draftLogicRules || draftTheme
          ? PUBLISH_STATUS.ACTIVE
          : PUBLISH_STATUS.FULFILLED;
      if (Object.keys(newLogicRules).length !== 0) {
        setWorkingLogicRules({
          workingLogicRules: newLogicRules,
          status
        });
      } else {
        setWorkingLogicRules({
          workingLogicRules: {},
          status
        });
      }
    }
  }, [formId, newLogicRules]);
  // Theme
  const themes = useAppSelector((state) => state.themes).themes;
  const newTheme = draftTheme ?? (panel ? themes[panel.theme] : null);
  useEffect(() => {
    if (panel) {
      setTheme({
        theme: newTheme,
        status: draftTheme ? PUBLISH_STATUS.ACTIVE : PUBLISH_STATUS.FULFILLED,
        flowStatus:
          draftSteps || draftLogicRules || draftTheme
            ? PUBLISH_STATUS.ACTIVE
            : PUBLISH_STATUS.FULFILLED,
        rebase: true
      });
    }
  }, [formId, draftTheme, draftSteps, draftLogicRules]);

  const { updatePanelThemeAssetUse } = useAssetInUseUpdater();
  useEffect(() => {
    if (panel) updatePanelThemeAssetUse(panel.id, newTheme.id, newSteps);
  }, [panel, newSteps, newTheme]);

  useEffect(() => {
    if (panel) {
      setFormId(formId);
      setAccountData({
        showConnections: panel.account.show_all_connections
      });
    } else history.push('/forms');
    return () => resetToInitialState();
  }, [formId]);

  const themePublishStatus = useAppSelector(
    (state) => state.formBuilder.themePublishStatus
  );
  const draftStatus = useAppSelector((s) => s.formBuilder.draftStatus);
  const flowPublishStatus = useAppSelector(
    (s) => s.formBuilder.flowPublishStatus
  );
  const [publishErrorsAndWarnings, setPublishErrorsAndWarnings] =
    useState<ERROR_WARNINGS>(null);

  const submitSteps = async () => {
    const validationResults = await saveAndValidate();
    if (!validationResults) return;
    const { errors, warnings } = validationResults;
    // If any errors, put up dialog and don't publish
    // If any warnings, put up confirmation dialog and only publish if confirmed
    if (errors.length || warnings.length)
      setPublishErrorsAndWarnings({ errors: errors, warnings: warnings });
    else await publish();
  };

  const needsRefresh = [
    DRAFT_STATUS.CONFLICT,
    DRAFT_STATUS.CONFLICT_IGNORE,
    DRAFT_STATUS.ERROR
  ].includes(draftStatus);

  useEffect(() => {
    const onUnload = (e: any) => {
      const session = authClient?.session?.getSync();
      if (session && unsavedDraftChanges && !needsRefresh) {
        e.preventDefault();
        e.returnValue = '';
      }
    };
    window.addEventListener('beforeunload', onUnload);
    return () => window.removeEventListener('beforeunload', onUnload);
  }, [authClient, unsavedDraftChanges, needsRefresh]);

  // handleBlockedNavigation method get called when prompt is created
  const handleBlockedNavigation = (nextLocation: any) => {
    // we need below checks to verify we're not showing
    // prompt when user is on editor
    const validPathRegex = new RegExp(`^/forms/${formId}/(${UUID_REGEX}/?)?$`);
    const validFlowPathRegex = new RegExp(`^/forms/${formId}/flow/?$`);
    const validLogicPathRegex = new RegExp(`^/forms/${formId}/logic(/.*)?$`);
    const nextPath = nextLocation.pathname;
    if (
      panel &&
      !validFlowPathRegex.test(nextPath) &&
      !validPathRegex.test(nextPath) &&
      !validLogicPathRegex.test(nextPath)
    )
      if (unsavedDraftChanges && !needsRefresh)
        return 'You have unsaved changes. Are you sure you want to continue?';

    return true;
  };

  const submitButton = (
    <div className='flex items-center'>
      <PublishButton
        status={flowPublishStatus}
        draftStatus={draftStatus}
        disabled={!formPublishPermission}
        onPublish={async (e: any) => {
          if (!formPublishPermission) return;
          e.preventDefault();
          if (needsRefresh) location.reload();
          else await submitSteps();
        }}
        partial
      />
      <ShareButton
        unsavedDraftChanges={unsavedDraftChanges}
        deleteDraft={deleteDraft}
      />
    </div>
  );

  return (
    <>
      <Prompt
        when={
          flowPublishStatus === PUBLISH_STATUS.ACTIVE ||
          themePublishStatus === PUBLISH_STATUS.ACTIVE
        }
        message={handleBlockedNavigation}
      />
      <PublishWarningsAndErrorsModal
        errorsWarnings={publishErrorsAndWarnings}
        setErrorsWarnings={setPublishErrorsAndWarnings}
        onPublish={async () => await publish()}
      />
      <FormNavigation
        activeItem={mode}
        submitButton={submitButton}
        handleUndo={() => handleUndo()}
        handleRedo={() => handleRedo()}
      />
    </>
  );
};

export default FormDesignerUtils;
