import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const getAIExtractions = createAsyncThunk(
  'ai/getAIExtractions',
  async ({ token }: { token: string }) => {
    const response = await FeatheryAPI.getAIExtractions(token);

    if (response.status === 200) {
      return await response.json();
    } else {
      throw new Error('Could not get AI extraction');
    }
  }
);

const getAIExtractionLogicRule = createAsyncThunk(
  'ai/getAIExtractionLogicRule',
  async ({ token, extractionId }: { token: string; extractionId: string }) => {
    const response = await FeatheryAPI.getAIExtractionLogicRule(
      token,
      extractionId
    );

    if (response.status === 200) {
      return await response.json();
    } else {
      throw new Error('Could not get AI extraction logic rules');
    }
  }
);

const createAIExtractionLogicRule = createAsyncThunk(
  'ai/createAIExtractionLogicRule',
  async ({
    token,
    extractionId,
    ...data
  }: {
    token: string;
    extractionId: string;
  }) => {
    const response = await FeatheryAPI.createAIExtractionLogicRule(
      token,
      extractionId,
      data
    );

    if (response.status === 201) {
      return await response.json();
    } else {
      throw new Error('Could not create AI extraction logic rule');
    }
  }
);

const editAIExtractionLogicRule = createAsyncThunk(
  'ai/editAIExtractionLogicRule',
  async ({
    token,
    extractionId,
    ruleId,
    ...data
  }: {
    token: string;
    extractionId: string;
    ruleId: string;
    data: Record<string, any>;
  }) => {
    const response = await FeatheryAPI.editAIExtractionLogicRule(
      token,
      extractionId,
      ruleId,
      data
    );

    if (response.status === 200) {
      return await response.json();
    } else {
      throw new Error('Could not edit AI extraction logic rule');
    }
  }
);

const deleteAIExtractionLogicRule = createAsyncThunk(
  'ai/deleteAIExtractionLogicRule',
  async ({
    token,
    extractionId,
    ruleId
  }: {
    token: string;
    extractionId: string;
    ruleId: string;
  }) => {
    const response = await FeatheryAPI.deleteAIExtractionLogicRule(
      token,
      extractionId,
      ruleId
    );

    if (response.status !== 204) {
      throw new Error('Could not delete AI extraction logic rule');
    }
  }
);

const createAIExtraction = createAsyncThunk(
  'ai/createAIExtraction',
  async ({ token, ...data }: { token: string }) => {
    const response = await FeatheryAPI.createAIExtraction(token, data);

    if (response.status === 201) {
      return await response.json();
    }

    throw new Error('Could not create AI extraction');
  }
);

const editAIExtraction = createAsyncThunk(
  'ai/editAIExtraction',
  async ({ token, ...data }: { token: string }) => {
    const response = await FeatheryAPI.editAIExtraction(token, data);

    if (response.status === 200) {
      return await response.json();
    }

    throw new Error('Could not edit AI extraction');
  }
);

const deleteAIExtraction = createAsyncThunk(
  'ai/deleteAIExtraction',
  async ({ extractionId, token }: { extractionId: string; token: string }) => {
    const response = await FeatheryAPI.deleteAIExtraction(token, {
      extractionId
    });

    if (response.status !== 204) {
      throw new Error('Could not delete AI extraction');
    }
  }
);

const getAIExtractionRuns = createAsyncThunk(
  'ai/getAIExtractionRuns',
  async ({ token, ...data }: { token: string }) => {
    const response = await FeatheryAPI.getAIExtractionRuns(token, data);

    if (response.status === 200) {
      return await response.json();
    }

    throw new Error('Could not get AI extraction runs');
  }
);

const getAIExtractionRun = createAsyncThunk(
  'ai/getAIExtractionRun',
  async ({ token, runId }: { token: string; runId: string }) => {
    const response = await FeatheryAPI.getAIExtractionRun(token, runId);

    if (response.status === 200) {
      return await response.json();
    }

    throw new Error('Could not get AI extraction run');
  }
);

const editAIExtractionRun = createAsyncThunk(
  'ai/editAIExtractionRun',
  async ({ token, runId, ...data }: { token: string; runId: string }) => {
    const response = await FeatheryAPI.editAIExtractionRun(token, runId, data);

    if (response.status === 200) {
      return await response.json();
    }

    throw new Error('Could not edit AI extraction run');
  }
);

const deleteAIExtractionRun = createAsyncThunk(
  'ai/deleteAIExtractionRun',
  async ({ runId, token }: { runId: string; token: string }) => {
    const response = await FeatheryAPI.deleteAIExtractionRun(token, runId);
    if (response.status !== 204) {
      throw new Error('Could not delete AI extraction run');
    }
  }
);

const updateAIExtractionEntry = createAsyncThunk(
  'ai/updateAIExtractionEntry',
  async ({
    token,
    runId,
    data
  }: {
    token: string;
    runId: string;
    data: any;
  }) => {
    const response = await FeatheryAPI.updateAIExtractionEntry(
      token,
      runId,
      data
    );

    if (response.status === 200) {
      return await response.json();
    }

    throw new Error('Could not update AI extraction entry');
  }
);

const commitAIExtractionEntries = createAsyncThunk(
  'ai/commitAIExtractionEntries',
  async ({
    token,
    runId,
    data
  }: {
    token: string;
    runId: string;
    data: any;
  }) => {
    const response = await FeatheryAPI.commitAIExtractionEntries(
      token,
      runId,
      data
    );

    if (response.status === 200) {
      return await response.json();
    }

    throw new Error('Could not commit AI extraction entries');
  }
);

const updateRunEntries = (run: any, entries: any[]) => {
  if (!run.question_groups) return run;

  return {
    ...run,
    question_groups: run.question_groups.map((questionGroup: any) => {
      return {
        ...questionGroup,
        entries: questionGroup.entries.map((entry: any) => {
          const updatedEntry = entries.find(({ id }: any) => id === entry.id);
          return updatedEntry || entry;
        })
      };
    })
  };
};

const aiExtractionsSlice = createSlice({
  name: 'aiExtractions',
  initialState: {
    extractions: [] as any[],
    rules: {} as Record<string, Record<string, any>>,
    runs: [] as any[],
    runDetails: {} as any,
    count: 0,
    prevUrl: '',
    nextUrl: '',
    extractionId: ''
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAIExtractionLogicRule.fulfilled, (state, action) => {
      const rules: Record<string, any> = {};
      action.payload.forEach((rule: any) => (rules[rule.id] = rule));
      state.rules[action.meta.arg.extractionId] = rules;
    });
    builder.addCase(createAIExtractionLogicRule.fulfilled, (state, action) => {
      state.rules[action.meta.arg.extractionId][action.payload.id] =
        action.payload;
    });
    builder.addCase(editAIExtractionLogicRule.fulfilled, (state, action) => {
      const extractionState = state.rules[action.meta.arg.extractionId];
      const oldIndex = extractionState[action.payload.id].index;
      const newIndex = action.payload.index;
      if (oldIndex !== newIndex) {
        Object.values(extractionState).forEach((rule) => {
          if (rule.index === newIndex) rule.index = oldIndex;
        });
      }
      extractionState[action.payload.id] = action.payload;
    });
    builder.addCase(deleteAIExtractionLogicRule.fulfilled, (state, action) => {
      const arg = action.meta.arg;
      delete state.rules[arg.extractionId][arg.ruleId];
    });
    builder.addCase(getAIExtractions.fulfilled, (state, action) => {
      state.extractions = action.payload;
    });
    builder.addCase(createAIExtraction.fulfilled, (state: any, action: any) => {
      state.extractions = [...state.extractions, action.payload];
    });
    builder.addCase(editAIExtraction.fulfilled, (state: any, action: any) => {
      const {
        meta: { arg },
        payload
      } = action;

      const id = state.extractions.findIndex(
        (extraction: any) => extraction.id === arg.extractionId
      );
      const newAIExtractions = state.extractions.filter(
        (extraction: any) => extraction.id !== arg.extractionId
      );
      newAIExtractions.splice(id, 0, payload);
      return { ...state, extractions: newAIExtractions };
    });
    builder.addCase(deleteAIExtraction.fulfilled, (state: any, action: any) => {
      const {
        meta: { arg }
      } = action;
      const index = state.extractions.findIndex(
        (extraction: any) => extraction.id === arg.extractionId
      );
      if (index >= 0) {
        state.extractions.splice(index, 1);
      }
    });
    builder.addCase(
      getAIExtractionRuns.fulfilled,
      (state: any, action: any) => {
        const {
          meta: { arg },
          payload
        } = action;
        state.extractionId = arg.extractionId;
        state.runs = payload.results;
        state.count = payload.count;
        state.prevUrl = payload.previous;
        state.nextUrl = payload.next;
      }
    );

    builder.addCase(getAIExtractionRun.fulfilled, (state: any, action: any) => {
      const { payload } = action;
      state.runDetails = payload;
    });
    builder.addCase(
      deleteAIExtractionRun.fulfilled,
      (state: any, action: any) => {
        const {
          meta: { arg }
        } = action;
        const index = state.runs.findIndex((run: any) => run.id === arg.runId);
        if (index >= 0) state.runs.splice(index, 1);
      }
    );
    builder.addCase(
      editAIExtractionRun.fulfilled,
      (state: any, action: any) => {
        const {
          meta: { arg }
        } = action;
        const index = state.runs.findIndex((run: any) => run.id === arg.runId);
        state.runs[index] = action.payload;
        state.runDetails = action.payload;
      }
    );
    builder.addCase(
      updateAIExtractionEntry.fulfilled,
      (state: any, action: any) => {
        const { payload } = action;

        const updatedEntries = payload.entries;
        state.runDetails = updateRunEntries(state.runDetails, updatedEntries);
        state.runs = state.runs.map((run: any) =>
          updateRunEntries(run, updatedEntries)
        );
      }
    );
    builder.addCase(
      commitAIExtractionEntries.fulfilled,
      (state: any, action: any) => {
        const { payload } = action;
        const updatedEntries = payload.entries;
        state.runDetails = updateRunEntries(state.runDetails, updatedEntries);
        state.runs = state.runs.map((run: any) =>
          updateRunEntries(run, updatedEntries)
        );
      }
    );
  }
});

export default aiExtractionsSlice.reducer;
export {
  getAIExtractions,
  createAIExtraction,
  editAIExtraction,
  deleteAIExtraction,
  editAIExtractionRun,
  getAIExtractionRuns,
  getAIExtractionRun,
  deleteAIExtractionRun,
  updateAIExtractionEntry,
  commitAIExtractionEntries,
  getAIExtractionLogicRule,
  createAIExtractionLogicRule,
  deleteAIExtractionLogicRule,
  editAIExtractionLogicRule
};
