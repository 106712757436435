import {
  CONTENT_TYPE,
  encodeGetParams,
  httpDelete,
  httpGet,
  httpPatch,
  httpPost,
  URL
} from './utils';

export default {
  getAIExtractions: (token: string) => {
    return httpGet({ token, url: `${URL.AI}extraction/` });
  },
  deleteAIExtraction: (token: string, { extractionId }: any) => {
    return httpDelete({
      token,
      baseUrl: `${URL.AI}extraction/`,
      path: `${extractionId}/`
    });
  },
  createAIExtraction: (token: string, data: any) => {
    return httpPost({
      token,
      url: `${URL.AI}extraction/`,
      body: data,
      invalid: [URL.SERVARS]
    });
  },
  editAIExtraction: (token: string, data: any) => {
    const { extractionId, ...extractionData } = data;
    return httpPatch({
      token,
      baseUrl: `${URL.AI}extraction/`,
      path: `${extractionId}/`,
      body: extractionData,
      invalid: [URL.SERVARS]
    });
  },
  getAIExtractionRuns: (token: string, { extractionId, ...query }: any) => {
    return httpGet({
      token,
      url: `${URL.AI}extraction/${extractionId}/run/?${encodeGetParams(query)}`,
      cachable: false
    });
  },
  createAIExtractionRun: (
    token: string,
    extractionId: string,
    file: any,
    liveEnv: 'true' | 'false'
  ) => {
    return httpPost({
      token,
      url: `${URL.AI}extraction/${extractionId}/run/`,
      body: { file, environment_live: liveEnv },
      contentType: CONTENT_TYPE.MULTIPART_FORM
    });
  },
  getAIExtractionRun: (token: string, runId: string) => {
    return httpGet({
      token,
      url: `${URL.AI}extraction/run/${runId}/`,
      cachable: false
    });
  },
  editAIExtractionRun: (token: string, runId: string, data: any) => {
    return httpPatch({
      token,
      baseUrl: `${URL.AI}extraction/`,
      path: `run/${runId}/`,
      body: data
    });
  },
  deleteAIExtractionRun: (token: string, runId: string) => {
    return httpDelete({
      token,
      baseUrl: `${URL.AI}extraction/run/`,
      path: `${runId}/`
    });
  },
  updateAIExtractionEntry: (token: string, runId: string, data: any) => {
    return httpPatch({
      token,
      baseUrl: `${URL.AI}extraction/`,
      path: `run/${runId}/entry/`,
      body: data
    });
  },
  commitAIExtractionEntries: (token: string, runId: string, data: any) => {
    return httpPatch({
      token,
      baseUrl: `${URL.AI}extraction/`,
      path: `run/${runId}/commit/`,
      body: data
    });
  },
  getAIExtractionLogicRule: (token: string, extractionId: string) => {
    return httpGet({
      token,
      url: `${URL.AI}extraction/${extractionId}/logic/`
    });
  },
  createAIExtractionLogicRule: (
    token: string,
    extractionId: string,
    data: any
  ) => {
    const ruleUrl = `${URL.AI}extraction/${extractionId}/logic/`;
    return httpPost({
      token,
      url: ruleUrl,
      body: data,
      invalid: [ruleUrl]
    });
  },
  deleteAIExtractionLogicRule: (
    token: string,
    extractionId: string,
    ruleId: string
  ) => {
    return httpDelete({
      token,
      baseUrl: `${URL.AI}extraction/${extractionId}/logic/`,
      path: `${ruleId}/`
    });
  },
  editAIExtractionLogicRule: (
    token: string,
    extractionId: string,
    ruleId: string,
    data: any
  ) => {
    return httpPatch({
      token,
      baseUrl: `${URL.AI}extraction/${extractionId}/logic/`,
      path: `${ruleId}/`,
      body: data
    });
  },
  getAICSVData: (token: string, { extractionId, startDate, endDate }: any) => {
    return httpGet({
      token,
      url: `${URL.AI}extraction/${extractionId}/csv/?start_date=${startDate}&end_date=${endDate}`,
      cachable: false
    });
  }
};
