import { DropdownField, InlineTooltip } from '../../../components/Core';
import styles from './styles.module.scss';

export default function GroupType({ group, onUpdate, disabled, options }: any) {
  return (
    <div className={styles.keyValuePair}>
      <div className={styles.key} style={{ width: '75px' }}>
        Type{' '}
        <InlineTooltip text='What type of extraction do you want to run?' />
      </div>
      <div className={styles.value}>
        <DropdownField
          className={styles.controlField}
          selected={group.question_type}
          onChange={(event: any) =>
            onUpdate('question_type', event.target.value)
          }
          options={options}
          required
          disabled={disabled}
        />
      </div>
    </div>
  );
}
