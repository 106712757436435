import { Fragment } from 'react';
import { DropdownField } from '../../../Core';
import Label from '../../../Dialog/Label';
import { CheckCircleIcon, ErrorMarkerIcon, TrashIcon } from '../../../Icons';
import { Button } from '../../../Core/Button/button';

type MapExtractionsStepProps = {
  thisFormServarIds: string[];
  promoteToServarIds: string[];
  servarKeysById: Record<string, string>;
  setMapFieldData: React.Dispatch<React.SetStateAction<Record<string, string>>>;
  mapFieldData: Record<string, string>;
  promoteKey: string;
  extractions: any[];
  mapExtractionData: Record<string, string>;
  setMapExtractionData: React.Dispatch<
    React.SetStateAction<Record<string, string>>
  >;
};

export function MapExtractionsStep(props: MapExtractionsStepProps) {
  const {
    extractions,
    mapFieldData,
    mapExtractionData,
    setMapExtractionData,
    servarKeysById
  } = props;
  const usedExtractions = new Set([
    ...Object.keys(mapExtractionData),
    ...Object.values(mapExtractionData)
  ]);
  const options = [
    ...extractions.map((extraction) => {
      return {
        value: extraction.id,
        display: extraction.key,
        disabled: usedExtractions.has(extraction.id)
      };
    })
  ];
  const extractionsById = extractions.reduce((acc, curr) => {
    acc[curr.id] = curr;
    return acc;
  }, {} as Record<string, any>);

  const reversedFieldMap = reverseRecord(mapFieldData);
  return (
    <div
      className='overflow-y-scroll max-h-[calc(100vh-32px-200px)] p-2'
      style={{ maxHeight: 'calc(100vh - 200px)' }}
    >
      <p className='mb-6'>
        Choose related document extractions to promote. They will be adjusted to
        the new fields and this form will adjust to the live extraction.
      </p>
      {extractions.length === 0 && (
        <div className='h-16 flex items-center font-lg gap-2'>
          <CheckCircleIcon height={18} width={18} />
          No extractions to map
        </div>
      )}
      {extractions.length > 0 && (
        <div className='grid grid-cols-[1fr_1fr_35px] gap-x-4 gap-y-2 items-start'>
          <Label className='-mb-1.5'>Test Extraction</Label>
          <Label className='-mb-1.5 col-span-2'>Prod Extraction</Label>
          {Object.entries(mapExtractionData).map(([dev, prod]) => {
            const devExtraction = extractionsById[dev];
            return (
              <Fragment key={dev}>
                <div className='flex items-center h-full'>
                  {devExtraction?.key}
                </div>
                <DropdownField
                  onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                    setMapExtractionData((curr) => ({
                      ...curr,
                      [dev]: event.target.value
                    }))
                  }
                  selected={prod || ''}
                  options={options.map((opt) => {
                    const ext = extractionsById[opt.value];
                    return {
                      ...opt,
                      disabled:
                        opt.disabled || ext.file_type != devExtraction.file_type
                    };
                  })}
                />
                <Button
                  variant='ghost'
                  size='icon'
                  onClick={() => {
                    setMapExtractionData((curr) => {
                      const result = { ...curr };
                      delete result[dev];
                      return result;
                    });
                  }}
                >
                  <TrashIcon className='w-5 h-5' />
                </Button>
                {dev != '' &&
                  prod != '' &&
                  extractions.find((e) => e.id === prod)?.file_sources?.length >
                    0 && (
                    <div className='border border-gray-300/50 bg-gray-100/50 py-2 px-3 col-span-3 space-y-1 rounded mb-5'>
                      {extractions
                        .find((e) => e.id === prod)
                        ?.file_sources.map((f_id: string) => {
                          const mappedField = reversedFieldMap[f_id];

                          if (!mappedField)
                            return (
                              <div
                                key={f_id}
                                className='flex items-center gap-[6px]'
                              >
                                <ErrorMarkerIcon width={16} height={16} />
                                <p className='font-light'>
                                  <span className='font-medium'>
                                    {servarKeysById[f_id]}
                                  </span>{' '}
                                  is unmapped!
                                </p>
                              </div>
                            );

                          return (
                            <div
                              key={f_id}
                              className='flex items-center gap-[6px]'
                            >
                              <CheckCircleIcon width={16} height={16} />
                              <p key={f_id} className='font-light'>
                                <span className='font-medium'>
                                  {servarKeysById[f_id]}
                                </span>{' '}
                                maps to{' '}
                                <span className='font-medium'>
                                  {servarKeysById[mappedField]}
                                </span>
                              </p>
                            </div>
                          );
                        })}
                    </div>
                  )}
              </Fragment>
            );
          })}
          <DropdownField
            className='text-center bg-none mt-5 cursor-pointer box-border text-neutral-text bg-neutral hover:bg-neutral-hover'
            onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
              setMapExtractionData(
                (curr) =>
                  ({
                    ...curr,
                    [event.target.value]: ''
                  } as Record<string, string>)
              )
            }
            selected={''}
            placeholder='Add mapping'
            options={options}
          />
          <div className='col-span-3 border-b border-b-gray-100 mt-3' />
        </div>
      )}
    </div>
  );
}

function reverseRecord(record: Record<string, string>): Record<string, string> {
  const result: Record<string, string> = {};

  for (const [key, value] of Object.entries(record)) {
    result[value] = key;
  }

  return result;
}
