import styles from './styles.module.scss';
import Switch from '../../components/Core/Switch';
import useFeatheryRedux from '../../redux';
import { InlineTooltip } from '../../components/Core';

export default function AllFieldsToggle({
  logicAllFields,
  setLogicAllFields
}: any) {
  const {
    toasts: { addInfoToast }
  } = useFeatheryRedux();

  const toggleOtherFormFields = (checked: boolean) => {
    setLogicAllFields(checked);
    const message = checked
      ? 'Fields across all forms are now usable in your rules'
      : 'Only fields in your current form can now be used in your rules';
    addInfoToast(message);
  };

  return (
    <div
      style={{
        width: 'max-content',
        marginTop: '20px',
        marginBottom: '25px'
      }}
    >
      <label className={styles.otherFieldsSwitch}>
        <Switch
          id='other-form-fields'
          checked={logicAllFields}
          onCheckedChange={toggleOtherFormFields}
        />
        Enable all form fields
        <InlineTooltip
          inline={false}
          text='Allow your rules to access fields from other forms. This may have a performance impact if there are many additional forms & fields'
        />
      </label>
    </div>
  );
}
