import { ComponentPropsWithRef, useMemo, useRef } from 'react';
import jp from 'jsonpath';
import Select from 'react-select';
import { getClassNames } from '../../../../Core/DropdownMultiField';
import styles from '../styles.module.scss';
import useJSONPaths from './useJSONPaths';
import { stripPath } from './utils';

const { className = '', ...classNameOverrides } = getClassNames({
  multiFieldValueContainer: styles.jsonPathInputValueContainer,
  className: styles.jsonPathInput
});

const staticProps = {
  menuPortalTarget: document.body,
  styles: {
    dropdownIndicator: (baseStyles = {}) => ({
      ...baseStyles,
      display: 'none'
    }),
    menuPortal: (baseStyles = {}) => ({
      ...baseStyles,
      zIndex: 99999999
    })
  },
  classNamePrefix: 'multi-field',
  className,
  classNames: classNameOverrides,
  closeMenuOnSelect: false,
  formatOptionLabel: ({ label, isWildcard }: any) => (
    <div style={{ color: isWildcard ? '#0088cc' : 'inherit' }}>{label}</div>
  )
};

interface JSONPathInputProps extends ComponentPropsWithRef<typeof Select> {
  data?: any; //json object
  value?: string;
  onValueChange?: (val: string) => void;
  disabled?: boolean;
}

function JSONPathInput({
  data = {},
  value = '',
  onValueChange = () => {},
  ...rest
}: JSONPathInputProps) {
  const input = ['$', ...value.split('.').filter(Boolean)];
  const selectRef = useRef(null);

  const setInput = (inp: any) => {
    onValueChange(stripPath(jp.stringify(inp)));
  };

  // Get all paths in the data
  const [_paths, getOptions] = useJSONPaths(data);

  const options = useMemo(() => {
    return getOptions(input);
  }, [getOptions, input]);

  const formattedOptions = useMemo(() => {
    return options.map((opt) => {
      const lastSegment = opt[opt.length - 1];

      const label =
        lastSegment === '*' ? '* (all items)' : lastSegment.toString();

      return {
        value: opt,
        label: stripPath(jp.stringify(input))
          ? `${stripPath(jp.stringify(input))}.${label}`
          : label,
        isWildcard: lastSegment === '*'
      };
    });
  }, [options]);

  // Back option, shows label for previous path
  const backOption =
    input.length >= 2
      ? {
          value: input.slice(0, input.length - 1),
          label: stripPath(jp.stringify(input.slice(0, input.length - 1)))
            ? `← Back to ${stripPath(
                jp.stringify(input.slice(0, input.length - 1))
              )}`
            : '← Back'
        }
      : null;

  return (
    // use raw select instead of DropdownMulti so we
    // have complete control over the component and value
    <Select
      {...rest}
      ref={selectRef}
      onChange={(option: any) => {
        setInput(option?.value);
        // if item is last, close menu
        if (option?.value) {
          const nextOptions = getOptions(option?.value);
          if (nextOptions.length === 0) {
            (selectRef.current as any)?.blur?.();
          }
        }
      }}
      options={[
        backOption,
        {
          label: 'Next Part',
          options: formattedOptions
        }
      ].filter(Boolean)}
      value={{
        value: input,
        label: stripPath(jp.stringify(input))
      }}
      isDisabled={rest.disabled}
      {...staticProps}
    />
  );
}

export default JSONPathInput;
