import { useMemo, useState } from 'react';
import { useRuleBuilderState, useRuleBuilderUpdater } from '../../context';
import { RuleDSLManager } from '../../context/RuleDSL';
import { transformToCode } from '../../transform';
import { getAllFieldsInDSL } from '../../transform/utils';
import { InvalidDSLModal } from '../InvalidDSLModal';
import { ConfirmationModal } from '../../../ConfirmationModal';
import { getErrors, validateDSL } from '../../validation';
import { isEmptyDSL } from '../../utils';
import { SaveButton } from './components/SaveButton';
import { DocsIcon, PlusIcon } from '../../../../../../../components/Icons';
import { NO_CODE_DOCS_URL } from '../../../RuleCodeEditor';
import { validateDSLFields } from '../../../../utils';
import { useAppSelector } from '../../../../../../../hooks';
import useFeatheryRedux from '../../../../../../../redux';
import { UNDO_TYPES } from '../../../../../../../utils/constants';
import useField from '../../../../../../../utils/useField';
import styles from './styles.module.scss';
import { Tooltip } from '../../../../../../../components/Core/Tooltip/Tooltip';
import { Button } from '../../../../../../../components/Core/Button/button';
import { useParams } from 'react-router-dom';

export type RuleBuilderCompletePayload = {
  dsl: IRuleDSL;
  code: string;
  valid: boolean;
};

type RuleBuilderNavProps = {
  mode: RuleBuilderMode;
  setMode: (mode: RuleBuilderMode) => void;
  onComplete: (payload: RuleBuilderCompletePayload) => void;
};

export const RuleBuilderNav = ({
  mode,
  setMode = () => {},
  onComplete = () => {}
}: RuleBuilderNavProps) => {
  const { formId } = useParams<{ formId?: string }>();
  const servars = useAppSelector((s) =>
    formId ? s.formBuilder.servars : s.fields.servars
  );
  const hiddenFields = useAppSelector((s) => s.fields.hiddenFields);
  const getField = useField(!!formId);

  const {
    formBuilder: { filterUndoRedoStacksByType }
  } = useFeatheryRedux();

  const filterStack = () =>
    formId && filterUndoRedoStacksByType(UNDO_TYPES.RULE_BUILDER);

  const [showInvalidDSLModal, setShowInvalidDSLModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);

  const addBranch = useRuleBuilderUpdater((s) => s.addBranch);
  const updateDSL = useRuleBuilderUpdater((s) => s.updateDSL);
  const updateErrors = useRuleBuilderUpdater((s) => s.updateErrors);
  const updateDSLValidity = useRuleBuilderUpdater((s) => s.updateDSLValidity);

  const {
    errors: commitedErrors,
    dsl,
    originalDSL
  } = useRuleBuilderState((s) => ({
    errors: s.errors,
    dsl: s.dsl,
    originalDSL: s.originalDSL
  }));

  const [valid, errors] = useMemo(
    () => [validateDSL(dsl), getErrors(dsl)],
    [dsl]
  );

  const error = commitedErrors['dsl']?.message;
  const isValid = valid && Object.keys(commitedErrors).length === 0;
  const isValidFields = useMemo(
    () => validateDSLFields(dsl, { ...servars, ...hiddenFields }),
    [dsl, servars, hiddenFields]
  );

  const handleSaveChanges = () => {
    if (!isValid && !isEmptyDSL(dsl)) {
      updateErrors(errors);
      updateDSLValidity(valid);
      setShowInvalidDSLModal(true); // Errors or invalid DSL, prevent saving
    } else {
      const manager = new RuleDSLManager(dsl);
      const fields = getAllFieldsInDSL(dsl, true);

      fields.forEach((field) => {
        const servarField = getField(field.value);

        if (servarField) {
          manager.updateOperand(field.id, {
            ...field,
            meta: {
              ...field.meta,
              field_key: servarField.key,
              servar_type: servarField.type
            }
          });
        }
      });

      const finalDSL = manager.toJSON() as IRuleDSL;

      transformToCode(finalDSL).then((code) => {
        onComplete({
          dsl: finalDSL,
          code,
          valid: true
        });

        updateDSL(finalDSL);
        setMode('view');
        filterStack();
      });
    }
  };

  const handleCancelEditMode = () => {
    const currDSL = JSON.stringify(dsl, (k, v) => (k === 'id' ? undefined : v));
    const prevDSL = JSON.stringify(originalDSL, (k, v) =>
      k === 'id' ? undefined : v
    );

    if (prevDSL !== currDSL) {
      setShowCancelModal(true);
    } else {
      setMode('view');
      filterStack();
    }
  };

  return (
    <>
      <InvalidDSLModal
        show={showInvalidDSLModal}
        setShow={setShowInvalidDSLModal}
      />
      <ConfirmationModal
        show={showCancelModal}
        setShow={setShowCancelModal}
        title='Warning'
        message='Are you sure you want to discard your unsaved changes?'
        onConfirm={() => {
          updateDSL(originalDSL);
          setMode('view');
          filterStack();
          setShowCancelModal(false);
        }}
        onCancel={() => {
          setShowCancelModal(false);
        }}
      />
      {mode !== 'edit' && !isValidFields && !isEmptyDSL(dsl) && (
        <Tooltip
          content='There are various fields referenced by this rule that do not exist.
            Please edit the rule to ensure it works properly.'
        >
          <div className={styles.ruleBuilderHeaderStatus}>
            There are invalid fields referenced by this rule.
          </div>
        </Tooltip>
      )}
      {mode !== 'edit' && !isValid && !isEmptyDSL(dsl) && (
        <Tooltip
          content='The generated code currently does not reflect the logic defined
            within Rule Builder. Please fix all of the errors outlined within
            Rule Builder to resolve.'
        >
          <div className={styles.ruleBuilderHeaderStatus}>
            There are errors in the rule logic.
          </div>
        </Tooltip>
      )}
      {mode === 'edit' && error && (
        <div className={styles.ruleBuilderHeaderStatus}>{error}</div>
      )}
      <div className={styles.ruleBuilderHeaderActions}>
        {!isEmptyDSL(dsl) && (
          <Button variant='gray' onClick={() => window.open(NO_CODE_DOCS_URL)}>
            <DocsIcon width={22} height={22} /> Docs
          </Button>
        )}
        {mode === 'edit' && (
          <>
            <Button variant='gray' onClick={() => handleCancelEditMode()}>
              Cancel
            </Button>
            <SaveButton onSave={() => handleSaveChanges()} />
          </>
        )}
        {mode === 'view' && !isEmptyDSL(dsl) && (
          <Button onClick={() => setMode('edit')}>Edit Logic</Button>
        )}
      </div>
      {mode === 'view' && isEmptyDSL(dsl) && (
        <div className={styles.emptyRuleBuilder}>
          <h2 style={{ marginBottom: '15px' }}>No logic yet</h2>
          <h3>To get started, add an action to your rule.</h3>
          <div className={styles.emptyRuleBuilderActions}>
            <Button
              variant='gray'
              onClick={() => window.open(NO_CODE_DOCS_URL)}
            >
              <DocsIcon width={22} height={22} /> Docs
            </Button>
            <Button
              onClick={() => {
                setMode('edit');
                addBranch();
              }}
            >
              <PlusIcon width={12} height={12} /> <span>Add Action</span>
            </Button>
          </div>
        </div>
      )}
    </>
  );
};
