import { getDefaultEntity } from '../utils';
import Entity from './Entity';
import classNames from 'classnames';
import styles from './styles.module.scss';
import GroupType from './GroupType';

const TYPE_OPTIONS = [
  { value: 'cell_value', display: 'Cell' },
  {
    value: 'column_value',
    display: 'Column'
  }
];

export function CSVQuestionGroup({
  group,
  onUpdate,
  index,
  fieldProperties,
  hiddenFieldProperties,
  display_only,
  variant_group
}: any) {
  return (
    <div className={classNames(styles.keyValuePairs, styles.moreGap)}>
      {!display_only && (
        <GroupType group={group} onUpdate={onUpdate} options={TYPE_OPTIONS} />
      )}
      <div className={styles.keyValuePair}>
        <div className={styles.entitiesContainer}>
          {group.entities?.map((entity: any, entityIndex: number) => (
            <Entity
              key={`group-${index}-entity-${entityIndex + 1}`}
              type={group.question_type}
              labels={{
                name: group.question_type === 'cell_value' ? 'Cell' : 'Column',
                details:
                  group.question_type === 'cell_value' ? 'Sheet' : 'Details'
              }}
              helpers={{
                name:
                  group.question_type === 'cell_value'
                    ? 'The exact column and row index of the cell'
                    : 'A description of the column header. Will use AI to match',
                details:
                  group.question_type === 'cell_value'
                    ? '(Optional) The exact sheet name to pull from. Defaults to the first sheet'
                    : ''
              }}
              placeholders={{
                name:
                  group.question_type === 'cell_value'
                    ? 'A3'
                    : 'Employee State',
                details: group.question_type === 'cell_value' ? 'Sheet1' : ''
              }}
              entity={entity}
              fieldProperties={fieldProperties}
              hiddenFieldProperties={hiddenFieldProperties}
              onUpdate={
                display_only
                  ? (key: string | object, value?: any) => {
                      if (key !== 'field_id') return;
                      onUpdate(entityIndex, value);
                    }
                  : (key: string | object, value?: any) => {
                      const updatedEntities = [...group.entities];
                      updatedEntities[entityIndex] =
                        typeof key !== 'string'
                          ? {
                              ...entity,
                              ...key
                            }
                          : {
                              ...entity,
                              [key]: value
                            };
                      onUpdate('entities', updatedEntities);
                    }
              }
              onRemove={
                display_only
                  ? () => {}
                  : () => {
                      const updatedEntities = [...group.entities];
                      updatedEntities.splice(entityIndex, 1);
                      if (updatedEntities.length === 0) {
                        updatedEntities.push(getDefaultEntity());
                      }
                      onUpdate(
                        'entities',
                        updatedEntities,
                        entityIndex,
                        'remove'
                      );
                    }
              }
              details={group.question_type === 'cell_value'}
              advanced={false}
              variant_id={
                display_only
                  ? variant_group?.field_ids[entityIndex] || ''
                  : undefined
              }
            />
          ))}
        </div>
      </div>
    </div>
  );
}
