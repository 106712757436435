// Remove jsonpath characters to show condensed path to user
// $."user".[0]."id" -> user.0.id
export const stripPath = (input: string): string => {
  let newValue = input;

  if (newValue) {
    if (newValue.charAt(0) === '$') {
      newValue = newValue.slice(1);
    }

    if (newValue.charAt(0) === '.') {
      newValue = newValue.slice(1);
    }

    newValue = (newValue ?? '')
      .split('.')
      .filter((segment: string) => !!segment)
      .join('.')
      .replaceAll("['", '.')
      .replaceAll("']", '')
      .replaceAll('[', '.')
      .replaceAll(']', '')
      .replaceAll('"', '');

    if (newValue.charAt(0) === '.') {
      newValue = newValue.slice(1);
    }
  }
  return newValue;
};
