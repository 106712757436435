import { CollapsibleSection } from '../../Core';
import ElementListSource from './ElementListSource';
import { memo } from 'react';

import styles from './styles.module.scss';

function ElementListPanel({
  elements = {},

  // Valid modes: 'drag' (step builder) or 'nav' (theme builder) or 'click' (context menu)
  mode = 'drag',

  onElementMouseDown = () => {},
  categories,
  theme,
  setTheme,
  handleUpdates,
  context = 'builder',
  deleteAssetFromTheme,
  ...props
}: any) {
  return (
    <div className={styles.elementPanel} {...props}>
      {categories.map(({ title, keys }: any) => {
        if (keys.length === 0) {
          return null;
        }
        const elementsList = (
          <div className={styles.listPanel}>
            {keys
              .map((key: any) => [key, elements[key]])
              // @ts-expect-error TS(7031) FIXME: Binding element 'key' implicitly has an 'any' type... Remove this comment to see the full error message
              .map(([key, element]) => (
                <ElementListSource
                  key={key}
                  mode={mode}
                  elementType={key}
                  image={element.image}
                  label={element.label}
                  message={element.message}
                  onElementMouseDown={
                    element.message ? () => {} : onElementMouseDown
                  }
                  theme={theme}
                  setTheme={setTheme}
                  handleUpdates={handleUpdates}
                  context={context}
                  deleteAssetFromTheme={deleteAssetFromTheme}
                />
              ))}
          </div>
        );

        return mode === 'click' ? (
          elementsList
        ) : (
          <CollapsibleSection
            key={title}
            title={title}
            expanded
            collapsible
            separator={false}
          >
            {elementsList}
          </CollapsibleSection>
        );
      })}
    </div>
  );
}

export default memo(ElementListPanel);
