import abTestsAPI from './abTests';
import accountsAPI from './accounts';
import aiAPI from './ai';
import customersAPI from './customers';
import documentsAPI from './documents';
import imagesAPI from './images';
import integrationsAPI from './integrations';
import metricsAPI from './metrics';
import panelsAPI from './panels';
import fieldsAPI from './fields';
import themesAPI from './themes';
import usersAPI from './users';

export { BASE_URL } from './utils';
export default function initFeatheryAPI() {
  if (global.FeatheryAPI) {
    return;
  }

  global.FeatheryAPI = {
    ...abTestsAPI,
    ...accountsAPI,
    ...aiAPI,
    ...customersAPI,
    ...documentsAPI,
    ...imagesAPI,
    ...integrationsAPI,
    ...metricsAPI,
    ...panelsAPI,
    ...fieldsAPI,
    ...themesAPI,
    ...usersAPI
  };
  global.FeatheryRequestCache = {};
  global.FeatheryConfig = {};
}

declare const global: any;

type APIFunction = (token: string, ...args: any[]) => Promise<any>;

declare global {
  let FeatheryAPI: Record<string, APIFunction>;
  const FeatheryRequestCache: Record<string, Promise<any> | undefined>;
  const FeatheryConfig: Record<string, any>;
}
