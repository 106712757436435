import styles from '../styles.module.scss';
import { InlineTooltip, TextField } from '../../../components/Core';
import useFeatheryRedux from '../../../redux';
import { useAppSelector } from '../../../hooks';
import { useState } from 'react';
import { Button } from '../../../components/Core/Button/button';
import { Spinner } from '../../../components/Core/Spinner';
import { Negative, Positive } from '../../../components/Core/Button';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/ext-searchbox';

const codeExamplePlaceholder = `import React from 'react';

const Counter = ({ value = 0, onChange }) => {
  const handleClick = () => {
    onChange(value + 1); // Increments the current value
  };

  return (
    <div>
      <p>Current value: {value}</p>
      <button onClick={handleClick}>Increment</button>
    </div>
  );
};

export default Counter;`;

const DEFAULT_CUSTOM_FIELD = {
  label: '',
  key: '',
  code: codeExamplePlaceholder,
  icon: ''
} as const;

type CustomFieldKey = keyof typeof DEFAULT_CUSTOM_FIELD;
type CustomField = typeof DEFAULT_CUSTOM_FIELD & { id?: string };

export default function CustomFieldTab() {
  const org = useAppSelector((state) => state.accounts.organization);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [isCreatingNew, setIsCreatingNew] = useState(false);
  const [fieldConfig, setFieldConfig] =
    useState<CustomField>(DEFAULT_CUSTOM_FIELD);

  const updateFieldConfig = (key: CustomFieldKey, value: string) => {
    setFieldConfig({ ...fieldConfig, [key]: value });
  };

  const {
    createCustomField,
    updateCustomField,
    toasts: { addInfoToast, addErrorToast }
  } = useFeatheryRedux();

  const resetForm = () => {
    setFieldConfig(DEFAULT_CUSTOM_FIELD);
    setSelectedIndex(null);
    setIsCreatingNew(false);
  };

  const validateField = () => {
    let err = '';
    (Object.keys(DEFAULT_CUSTOM_FIELD) as CustomFieldKey[]).forEach((key) => {
      if (!fieldConfig[key]) {
        err = 'All custom field settings must be defined to save';
      }
    });

    // Check for duplicate keys when creating new field
    if (
      isCreatingNew &&
      customFields.some((field) => field.key === fieldConfig.key)
    ) {
      err = 'A custom field with this key already exists';
    }

    return err;
  };

  const saveForm = () => {
    const err = validateField();
    if (err) {
      addErrorToast({ title: err });
      return;
    }

    if (isCreatingNew) {
      return createCustomField(fieldConfig)
        .then(() => {
          addInfoToast('Custom field updated');
          resetForm();
        })
        .catch(() => {
          addErrorToast({ title: 'Invalid custom field' });
        });
    } else if (selectedIndex !== null) {
      return updateCustomField(fieldConfig)
        .then(() => {
          addInfoToast('Custom field updated');
          resetForm();
        })
        .catch(() => {
          addErrorToast({ title: 'Invalid custom field' });
        });
    }
  };

  const customFields: CustomField[] = org?.custom_fields ?? [];
  const isEditing = isCreatingNew || selectedIndex !== null;
  return (
    <>
      <div className={styles.sectionSeparator} />
      <div className={styles.lowerSection}>
        <div className={styles.customFieldContainer}>
          {!isEditing && (
            <ListPage
              customFields={customFields}
              setEditing={(index) => {
                setIsCreatingNew(index == null);
                setSelectedIndex(index);
                setFieldConfig(
                  index == null ? DEFAULT_CUSTOM_FIELD : customFields[index]
                );
              }}
            />
          )}

          {isEditing && (
            <EditPage
              fieldConfig={fieldConfig}
              updateFieldConfig={updateFieldConfig}
              isCreatingNew={isCreatingNew}
              saveForm={saveForm}
              resetForm={resetForm}
            />
          )}
        </div>
      </div>
    </>
  );
}

interface EditPageProps {
  fieldConfig: CustomField;
  updateFieldConfig: (key: CustomFieldKey, value: string) => void;
  isCreatingNew: boolean;
  resetForm: () => void;
  saveForm: () => Promise<unknown>;
}

function EditPage({
  fieldConfig,
  updateFieldConfig,
  isCreatingNew,
  resetForm,
  saveForm
}: EditPageProps) {
  return (
    <div className={styles.fieldForm}>
      <div className={styles.formHeader}>
        <span>
          <Button variant='outline' onClick={resetForm}>
            Back
          </Button>
          <h3>{isCreatingNew ? 'New Custom Field' : fieldConfig.label}</h3>
        </span>
        <Positive className={styles.saveButton} onClick={saveForm}>
          Save
        </Positive>
      </div>
      <label className={styles.settingsLabel}>
        Custom Field Name
        <InlineTooltip text='User-friendly label for the field' />
      </label>
      <div className={styles.teamFieldSection}>
        <TextField
          placeholder='Inventory Selector'
          maxLength={32}
          value={fieldConfig.label}
          onChange={(val: string) => updateFieldConfig('label', val)}
          className={styles.settingsField}
        />
      </div>
      <label className={styles.settingsLabel}>
        Custom Field Type
        <InlineTooltip text='Specify a unique, static internal identifier for the field type' />
      </label>
      <div className={styles.teamFieldSection}>
        <TextField
          placeholder='inventory_selector'
          maxLength={32}
          value={fieldConfig.key}
          onChange={(val: string) =>
            updateFieldConfig('key', val.toLowerCase())
          }
          className={styles.settingsField}
          disabled={!isCreatingNew}
        />
      </div>
      <label className={styles.settingsLabel}>
        Custom Field Icon URL
        <InlineTooltip text='Specify a link to the icon to display for the custom field in the form designer' />
      </label>
      <div className={styles.teamFieldSection}>
        <TextField
          type='url'
          placeholder='https://icon.com/selector.svg'
          value={fieldConfig.icon}
          onChange={(val: string) => updateFieldConfig('icon', val)}
          className={styles.settingsField}
        />
        {fieldConfig.icon && (
          <ImageWithFallback key={fieldConfig.icon} src={fieldConfig.icon} />
        )}
      </div>
      <label className={styles.settingsLabel}>
        Custom Field Code
        <InlineTooltip text='React code to render for the custom field' />
      </label>
      <div className={styles.teamFieldSection} style={{ width: '100%' }}>
        <Codebox
          code={fieldConfig.code}
          onChange={(val: string) => updateFieldConfig('code', val)}
        />
      </div>
    </div>
  );
}

interface ListPageProps {
  customFields: CustomField[];
  setEditing: (index: number | null) => void;
}

function ListPage({ customFields, setEditing }: ListPageProps) {
  const {
    deleteCustomField,
    toasts: { addInfoToast, addErrorToast }
  } = useFeatheryRedux();

  const onDelete = (index: number) => {
    const customField = customFields[index];
    return deleteCustomField(customField)
      .then(() => {
        addInfoToast('Custom field deleted');
      })
      .catch(() => {
        addErrorToast({ title: 'Failed to delete custom field' });
      });
  };

  return (
    <div className={styles.customFieldsList}>
      <div className={styles.listHeader}>
        <Button onClick={() => setEditing(null)}>Create Custom Field</Button>
      </div>
      <div className={styles.fieldsList}>
        {customFields.length === 0 && (
          <div className={styles.listEmpty}>
            No custom fields yet. Add one to get started.
          </div>
        )}
        {customFields.map((field, index) => (
          <div key={index} className={styles.fieldListItem}>
            <div className={styles.fieldInfo}>
              <img
                className={styles.fieldIcon}
                src={field.icon}
                alt={field.label}
              />
              <span className={styles.fieldLabel}>{field.label}</span>
              <span className={styles.fieldKey}>({field.key})</span>
            </div>
            <div className={styles.fieldActions}>
              <Button
                variant='outline'
                size='sm'
                onClick={() => setEditing(index)}
              >
                Edit
              </Button>
              <Negative size='sm' onClick={() => onDelete(index)}>
                Delete
              </Negative>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

const ImageWithFallback = ({ src = '', alt = 'Image', className = '' }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  const handleLoad = () => {
    setIsLoading(false);
  };

  const handleError = () => {
    setIsLoading(false);
    setHasError(true);
  };

  return (
    <div className='relative h-[48px] w-[48px]'>
      {!src && null}

      {isLoading && (
        <div className='absolute inset-0 flex items-center justify-center'>
          <Spinner className='w-9 h-9 text-primary animate-spin' />
        </div>
      )}

      {hasError && (
        <div className='absolute inset-0 flex flex-col items-center justify-center gap-2'>
          <p className='text-sm text-gray-500'>Failed to load image</p>
        </div>
      )}

      <img
        src={src}
        alt={alt}
        className={`w-full h-full object-cover ${className} ${
          isLoading || hasError ? 'opacity-0' : 'opacity-100'
        }`}
        onLoad={handleLoad}
        onError={handleError}
      />
    </div>
  );
};

function Codebox({ code, onChange }: any) {
  // Constants for height calculation
  const LINE_HEIGHT = 16; // Height of each line in pixels
  const MIN_LINES = 10; // Minimum number of lines to show
  const PADDING = 2; // Extra padding for editor chrome (adjust as needed)

  const calculateHeight = (value: string) => {
    const lineCount = value.split('\n').length;
    const clampedLines = Math.max(MIN_LINES, lineCount);
    return `${clampedLines * LINE_HEIGHT + PADDING}px`;
  };

  const height = calculateHeight(code);

  return (
    <>
      <AceEditor
        mode='javascript'
        theme='tomorrow'
        style={{
          width: '100%',
          background: 'white',
          border: '1px solid #dee2e6',
          borderRadius: 'var(--input-radius)',
          minHeight: `${MIN_LINES * LINE_HEIGHT + PADDING}px`
        }}
        height={height}
        fontSize={12}
        value={code}
        onChange={onChange}
        onLoad={(editor) => {
          // F1 open the command palette which is an issue
          editor.commands.removeCommand('openCommandPalette');
        }}
        setOptions={{
          useWorker: false,
          showLineNumbers: true,
          tabSize: 2,
          enableBasicAutocompletion: true,
          enableLiveAutocompletion: true,
          minLines: MIN_LINES
        }}
        editorProps={{ $blockScrolling: true }}
      />
    </>
  );
}
