import { DEFAULT_STATE } from '../../../../pages/FormLogicDetailPage/LogicRuleDetail/components/RuleBuilder/components/RuleAction/ConnectToAPIAction/constants';
import { searchForFields } from '../utils';
import { ROLLOUT_APPS } from '../../../FormIntegrations/RolloutSidebar/components/constants';
import { INTEGRATIONS } from '../../../FormIntegrations';

const REQUESTS_WITH_BODY = ['POST', 'PUT', 'PATCH'];
const URL_REGEX = new RegExp(
  /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i
);
export const FEATHERY_INTEGRATION_TOKENS = [
  INTEGRATIONS.ORION,
  INTEGRATIONS.REDTAIL,
  INTEGRATIONS.EMONEY,
  'emoney_api'
];
export const INTEGRATION_TOKENS = [
  ...Object.values(ROLLOUT_APPS),
  ...FEATHERY_INTEGRATION_TOKENS
].map((app) => `${app}_token`);

export const INTEGRATIONS_TOKEN_DICT = INTEGRATION_TOKENS.reduce(
  (dict: Record<string, true>, app) => ((dict[app] = true), dict),
  {}
);

export const validateState = (state: any, options: any) => {
  const {
    targets = Object.keys(DEFAULT_STATE),
    setErrors = null,
    apiConnectors = [],
    fieldKeys = {}
  } = options;
  const errors: { [key: string]: string } = {};

  // Reset errors of targets
  if (setErrors) {
    setErrors((s: any) => {
      return {
        ...s,
        ...targets.reduce((acc: any, key: string) => {
          acc[key] = '';
          return acc;
        }, {})
      };
    });
  }

  if (targets.includes('name')) {
    if (!state.name) {
      errors['name'] = 'Please enter a name.';
    }

    if (
      apiConnectors.find((a: any) => a.name === state.name && a.id !== state.id)
    ) {
      errors['name'] = 'The name is already taken.';
    }
  }

  if (targets.includes('url')) {
    if (!state.url) {
      errors['url'] = 'Please enter a URL.';
    }
    const urlFields = searchForFields(state.url);

    // if there are no field variables and the url is invalid
    if (!URL_REGEX.test(state.url) && !urlFields.length) {
      errors['url'] = 'Please enter a valid URL.';
    }
    if (urlFields.length) {
      const hasInvalidVariable = urlFields.find((f) => !fieldKeys[f]);

      if (hasInvalidVariable) {
        errors['url'] = 'A variable specified in the URL does not exist.';
      }
    }
  }

  if (targets.includes('headers')) {
    const hasInvalidVariables = Object.values(state.headers).find(
      (val: any) => {
        if (!val) return false;
        else return searchForFields(val).find((f) => !fieldKeys[f]);
      }
    );

    if (hasInvalidVariables) {
      errors['headers'] = 'Invalid variables were found in headers.';
    }
  }

  if (targets.includes('params')) {
    const hasInvalidVariables = Object.values(state.params).find((val: any) => {
      if (!val) return false;
      else return searchForFields(val).find((f) => !fieldKeys[f]);
    });

    if (hasInvalidVariables) {
      errors['params'] = 'Invalid variables were found in query parameters.';
    }
  }

  if (targets.includes('method')) {
    if (!state.method) {
      errors['method'] = 'Please select a request method.';
    }
  }

  if (targets.includes('body')) {
    const validJSONString = state.body.replace(/(\r\n|\n|\r)/gm, '');

    const hasInvalidVariables = !!searchForFields(validJSONString, true).find(
      (f) => !fieldKeys[f]
    );

    if (hasInvalidVariables) {
      errors['body'] = 'The request body contains invalid variables.';
    }

    if (REQUESTS_WITH_BODY.includes(state.method) && state.body) {
      try {
        JSON.parse(validJSONString);
      } catch (e) {
        errors['body'] = 'The request body is not a valid JSON.';
      }
    }
  }

  if (targets.includes('tokens')) {
    const usedApps = getUsedAppTokens(state)
      .map((token) => token.slice(0, -6))
      .filter((app) => !FEATHERY_INTEGRATION_TOKENS.includes(app));
    if (usedApps.length) {
      if (state.tokens) {
        const hasMissingAccounts = usedApps.find((app: string) => {
          return !state.tokens[app];
        });

        if (hasMissingAccounts) {
          errors['tokens'] = 'Not all used integration tokens are configured.';
        }
      } else {
        errors['tokens'] = 'Not all used integration tokens are configured.';
      }
    }

    if (
      apiConnectors.find((a: any) => a.name === state.name && a.id !== state.id)
    ) {
      errors['name'] = 'Connector name is already taken.';
    }
  }

  if (Object.keys(errors).length > 0) {
    if (setErrors) {
      setErrors((s: any) => {
        return {
          ...s,
          ...errors
        };
      });
    }

    return false;
  }

  return true;
};

export function getUsedAppTokens(state: any): string[] {
  if (!state) return [];

  const tokensInURL = searchForFields(state.url).filter(
    (token) => INTEGRATIONS_TOKEN_DICT[token]
  );

  const tokensInHeaders = Object.values(state.headers ?? {}).reduce(
    (list: string[], headerVal: any) => {
      const tokensInHeader = searchForFields(headerVal).filter(
        (token) => INTEGRATIONS_TOKEN_DICT[token]
      );

      list.push(...tokensInHeader);
      return list;
    },
    []
  );

  const tokensInParams = Object.values(state.params ?? {}).reduce(
    (list: string[], paramVal: any) => {
      const tokensInHeader = searchForFields(paramVal).filter(
        (token) => INTEGRATIONS_TOKEN_DICT[token]
      );

      list.push(...tokensInHeader);
      return list;
    },
    []
  );

  const validJSONString = (state.body ?? '').replace(/(\r\n|\n|\r)/gm, '');
  const tokensInBody = searchForFields(validJSONString, true).filter(
    (token) => INTEGRATIONS_TOKEN_DICT[token]
  );

  const token_set = new Set([
    ...tokensInURL,
    ...tokensInHeaders,
    ...tokensInParams,
    ...tokensInBody
  ]);
  return Array.from(token_set);
}

export function replaceValue(state: any, search: string, replace: string): any {
  if (!state) return;

  const state_str = JSON.stringify(state);
  const new_state_str = state_str.replaceAll(search, replace);
  const new_state = JSON.parse(new_state_str);

  return new_state;
}
