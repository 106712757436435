import {
  CONTENT_TYPE,
  encodeGetParams,
  httpDelete,
  httpGet,
  httpPatch,
  httpPost,
  httpPut,
  URL
} from './utils';

export default {
  editAccount: (token: string, data: any) => {
    return httpPatch({
      token,
      baseUrl: URL.ACCOUNT,
      body: data,
      invalid: [URL.PANELS]
    });
  },
  migrateAccount: (token: string, destination: string) => {
    return httpPatch({
      token,
      baseUrl: URL.ACCOUNT,
      path: 'migration/',
      body: { destination }
    });
  },
  getOrganization: (token: string, data: any) => {
    return httpGet({
      token,
      url: `${URL.ACCOUNT}organization/`,
      params: data
    });
  },
  editOrganization: (token: string, data: any) => {
    return httpPatch({
      token,
      baseUrl: `${URL.ACCOUNT}organization/`,
      body: data
    });
  },
  getDefaultSettings: (token: string) => {
    return httpGet({ token, url: `${URL.ACCOUNT}default-settings/` });
  },
  editFavicon: (token: string, favicon: any) => {
    return httpPatch({
      token,
      baseUrl: `${URL.ACCOUNT}organization/favicon/`,
      contentType: CONTENT_TYPE.MULTIPART_FORM,
      body: { favicon }
    });
  },
  getBill: (token: string) => {
    return httpGet({ token, url: `${URL.ACCOUNT}organization/bill/` });
  },
  initializeCard: (token: string) => {
    return httpPatch({
      token,
      baseUrl: `${URL.ACCOUNT}organization/initialize_card/`
    });
  },
  updateCard: (token: string, paymentMethod: any) => {
    return httpPatch({
      token,
      baseUrl: `${URL.ACCOUNT}organization/update_card/`,
      body: { stripe_payment_method: paymentMethod }
    });
  },
  editAccountPanelData: (token: string, data: any) => {
    const { panelId, ...newAccountData } = data;
    return httpPatch({
      token,
      baseUrl: URL.ACCOUNT,
      path: `panel/${panelId}/`,
      body: newAccountData
    });
  },
  inviteUsers: (token: string, invites: any[]) => {
    return httpPost({
      token,
      url: `${URL.ACCOUNT}invite/`,
      body: invites
    });
  },
  removeUser: (token: string, email: any) => {
    return httpPost({
      token,
      url: `${URL.ACCOUNT}uninvite/`,
      body: { email }
    });
  },
  getWorkspaces: (token: string, query: any) => {
    query.submission_data = true;
    return httpGet({
      token,
      url: `${URL.WORKSPACE}?${encodeGetParams(query)}`,
      cachable: false
    });
  },
  getWorkspace: (token: string, workspaceId: string) => {
    return httpGet({
      token,
      url: `${URL.WORKSPACE}${workspaceId}/`,
      cachable: false
    });
  },
  createWorkspace: (token: string, data: any) => {
    return httpPost({
      token,
      url: URL.WORKSPACE,
      body: data
    });
  },
  editWorkspace: (token: string, data: any) => {
    const { workspaceId, ...newWorkspaceData } = data;
    return httpPatch({
      token,
      baseUrl: URL.WORKSPACE,
      path: `${workspaceId}/`,
      body: newWorkspaceData
    });
  },
  deleteWorkspace: (token: string, workspaceId: string) => {
    return httpDelete({
      token,
      baseUrl: URL.WORKSPACE,
      path: `${workspaceId}/`
    });
  },
  inviteToWorkspace: (token: string, workspaceId: string, data: any) => {
    return httpPatch({
      token,
      baseUrl: URL.WORKSPACE,
      path: `${workspaceId}/invite/`,
      body: data
    });
  },
  createCustomField: (token: string, data: any) => {
    return httpPost({
      token,
      url: `${URL.ACCOUNT}organization/custom-field/`,
      body: data,
      invalid: [URL.PANELS, `${URL.ACCOUNT}organization`]
    });
  },
  updateCustomField: (token: string, id: string, data: any) => {
    return httpPatch({
      token,
      baseUrl: `${URL.ACCOUNT}organization/custom-field/`,
      path: `${id}/`,
      body: data,
      invalid: [URL.PANELS, `${URL.ACCOUNT}organization`]
    });
  },
  deleteCustomField: (token: string, id: string) => {
    return httpDelete({
      token,
      baseUrl: `${URL.ACCOUNT}organization/custom-field/`,
      path: `${id}/`,
      invalid: [URL.PANELS, `${URL.ACCOUNT}organization`]
    });
  },
  updateConfig: (token: string, data: Record<string, string>[]) => {
    return httpPut({
      token,
      url: `${URL.ACCOUNT}config/`,
      body: { data },
      invalid: [`${URL.ACCOUNT}organization`]
    });
  }
};
