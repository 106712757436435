import { useCallback, useEffect, useState } from 'react';
import { useAppSelector } from '../../../hooks';
import {
  getExtractionsUsedInForm,
  groupByForm,
  groupKeysById,
  invert,
  transformFieldMap
} from './utils';
import useFeatheryRedux from '../../../redux';

interface Result {
  promoteForm: () => void;
  isRollback: boolean;
  promote_id: string;
  promote_key: string;
  servarKeysById: Record<string, string>;
  thisFormServarIds: string[];
  promoteToServarIds: string[];
  mapFieldData: Record<string, string>;
  setMapFieldData: React.Dispatch<React.SetStateAction<Record<string, string>>>;
  extractions: any[];
  mapExtractionData: Record<string, string>;
  setMapExtractionData: React.Dispatch<
    React.SetStateAction<Record<string, string>>
  >;
  formHasExtractions: boolean;
}

export function usePromoteForm(formId: string): Result {
  const panel = useAppSelector((state) => state.panels.panels[formId]);
  const promote_id = panel.promote_to || panel.promote_from;
  const isRollback = !panel.promote_to && panel.promote_from;
  const promote_key = useAppSelector(
    (state) => state.panels.panels[promote_id]?.key ?? ''
  );

  const extractions = useAppSelector((state) => state.ai.extractions);
  const thisFormExtractions = useAppSelector((state) =>
    getExtractionsUsedInForm(state.formBuilder, state.ai.extractions)
  );

  const [mapFieldData, setMapFieldData] = useState<Record<string, string>>({});
  const [mapExtractionData, setMapExtractionData] = useState<
    Record<string, string>
  >(() => {
    // preset mappings to the extractions used in this form
    const result: Record<string, string> = {};

    thisFormExtractions.forEach((str) => {
      result[str] = isRollback ? str : '';
    });

    return result;
  });

  const {
    promotePanel,
    getPanelPromoteLinkFields,
    toasts: { addErrorToast }
  } = useFeatheryRedux();

  const servarKeysById = useAppSelector((state) =>
    (state.fields.servars ?? []).reduce<Record<string, string>>(
      groupKeysById,
      {}
    )
  );

  const servarsByForm = useAppSelector((state) =>
    Object.entries<{ panel_id: string }[]>(state.fields.usage ?? {}).reduce<
      Record<string, string[]>
    >(groupByForm, {})
  );

  // remove duplicates from each array and eachother
  const setThisFormServars = new Set(servarsByForm[formId]);
  const setPromoteFormServars = new Set(servarsByForm[promote_id]);

  const thisFormServarIds = (servarsByForm[formId] ?? []).filter(
    (item) => !setPromoteFormServars.has(item)
  );
  const promoteToServarIds = (servarsByForm[promote_id] ?? []).filter(
    (item) => !setThisFormServars.has(item)
  );

  useEffect(() => {
    if (panel.promote_to && formId) {
      (async () => {
        const result = await getPanelPromoteLinkFields({ panelId: formId });
        setMapFieldData(result);
      })();
    }
  }, [panel.promote_to]);

  const promoteForm = useCallback(async () => {
    if (
      Object.entries(mapExtractionData).some(([key, value]) => !key || !value)
    ) {
      addErrorToast({
        title: 'Some extraction mappings are incomplete.'
      });
      return;
    }

    return promotePanel({
      panelId: formId,
      fieldMap: transformFieldMap(mapFieldData, thisFormServarIds),
      extraction_map: invert(mapExtractionData)
    });
  }, [formId, mapFieldData, thisFormServarIds]);

  return {
    promoteForm,
    isRollback,
    promote_id,
    promote_key,
    servarKeysById,
    thisFormServarIds,
    promoteToServarIds,
    mapFieldData,
    setMapFieldData,
    extractions,
    mapExtractionData,
    setMapExtractionData,
    formHasExtractions: thisFormExtractions.size > 0
  };
}
