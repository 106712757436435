import { memo } from 'react';

import styles from './styles.module.scss';
import { useAppSelector } from '../../hooks';
import classNames from 'classnames';

function SettingsContainer({ title, wide, children }: any) {
  const role = useAppSelector((state) => state.accounts.account.role);
  if (title !== 'Reporting' && role === 'viewer') return null;
  return (
    <div className={classNames(styles.settingsContainer, wide && styles.wide)}>
      <div className={styles.titleContainer}>
        <div className={styles.titleText}>{title}</div>
        <div className={styles.separator} />
      </div>
      {children}
    </div>
  );
}

export default memo(SettingsContainer);
