import {
  VancoSearchConfig,
  VancoSearchParams,
  VancoSearchResponse,
  VancoSearchErrorResponse
} from './types';

/**
 * Perform a search against the Vanco API
 * @param config Service configuration
 * @param params Search parameters
 * @returns Promise resolving to search results
 * @throws Error if the search fails
 */
export async function searchVancoProducts(
  config: VancoSearchConfig,
  params: VancoSearchParams
): Promise<VancoSearchResponse> {
  const { searchTerm, pageNumber, pageSize } = params;
  const { searchUrl, jwt, vancoOrgId } = config;

  if (!searchTerm) {
    return {
      data: [],
      resultsCount: 0,
      'page[number]': pageNumber,
      'page[size]': pageSize
    };
  }

  const url = new URL(`${searchUrl}/`);
  url.searchParams.append('search-term', searchTerm);
  url.searchParams.append('page[number]', pageNumber.toString());
  url.searchParams.append('page[size]', pageSize.toString());

  const response = await fetch(url.toString(), {
    method: 'GET',
    headers: {
      Authorization: `Token ${jwt}`,
      'X-Org-ID': vancoOrgId,
      'Content-Type': 'application/json'
    }
  });

  const data = await response.json();

  if (!response.ok) {
    if (response.status === 401) {
      throw new Error('Unauthorized: Please check your token or login again.');
    }

    const errorResponse = data as VancoSearchErrorResponse;
    throw new Error(
      errorResponse.errors?.[0]?.detail || 'Unknown error occurred'
    );
  }

  return data as VancoSearchResponse;
}

export default searchVancoProducts;
