import * as Logos from '../Logos';
import { mapArrayToObject } from '../../utils/core';

const AI_INTEGRATION_TYPES: { [key: string]: string } = {
  EMAIL_INBOX: 'email-inbox',
  S3: 's3',
  GOOGLE_CLOUD_STORAGE: 'google-cloud-storage',
  GOOGLE_DRIVE: 'google-drive',
  DROPBOX: 'dropbox',
  BOX: 'box',
  SALESFORCE: 'salesforce',
  HUBSPOT: 'hubspot',
  EGNYTE: 'egnyte'
};

const AI_INTEGRATION_TAGS = {
  INBOX: 'Inbox',
  CRM: 'CRM',
  CLOUD: 'Cloud',
  FILE_SYSTEM: 'File System'
};

const AI_INTEGRATIONS = [
  {
    key: AI_INTEGRATION_TYPES.EMAIL_INBOX,
    title: 'Email Inbox',
    description:
      'Process documents pulled automatically from your email inbox.',
    tags: [AI_INTEGRATION_TAGS.INBOX],
    logo: <Logos.SimpleEmailService />
  },
  {
    key: AI_INTEGRATION_TYPES.S3,
    title: 'AWS S3',
    description:
      'Process documents pulled automatically from your AWS S3 bucket.',
    tags: [AI_INTEGRATION_TAGS.CLOUD],
    logo: <Logos.S3 />
  },
  {
    key: AI_INTEGRATION_TYPES.GOOGLE_CLOUD_STORAGE,
    title: 'Google Cloud Storage',
    description:
      'Process documents pulled automatically from your Google Cloud Storage account.',
    tags: [AI_INTEGRATION_TAGS.CLOUD],
    logo: <Logos.GoogleCloudStorage />
  },
  {
    key: AI_INTEGRATION_TYPES.GOOGLE_DRIVE,
    title: 'Google Drive',
    description:
      'Process documents pulled automatically from your Google Drive account.',
    tags: [AI_INTEGRATION_TAGS.FILE_SYSTEM],
    logo: <Logos.GoogleDrive />
  },
  {
    key: AI_INTEGRATION_TYPES.DROPBOX,
    title: 'Dropbox',
    description:
      'Process documents pulled automatically from your Dropbox account.',
    tags: [AI_INTEGRATION_TAGS.FILE_SYSTEM],
    logo: <Logos.Dropbox />
  },
  {
    key: AI_INTEGRATION_TYPES.BOX,
    title: 'Box',
    description:
      'Process documents pulled automatically from your Box account.',
    tags: [AI_INTEGRATION_TAGS.FILE_SYSTEM],
    logo: <Logos.Box />
  },
  {
    key: AI_INTEGRATION_TYPES.EGNYTE,
    title: 'Egnyte',
    description:
      'Process documents pulled automatically from your Egnyte account.',
    tags: [AI_INTEGRATION_TAGS.FILE_SYSTEM],
    logo: <Logos.Egnyte />
  },
  {
    key: AI_INTEGRATION_TYPES.SALESFORCE,
    title: 'Salesforce',
    description:
      'Process documents pulled automatically from your Salesforce account.',
    tags: [AI_INTEGRATION_TAGS.CRM],
    logo: <Logos.Salesforce />
  },
  {
    key: AI_INTEGRATION_TYPES.HUBSPOT,
    title: 'Hubspot',
    description:
      'Process documents pulled automatically from your Hubspot account.',
    tags: [AI_INTEGRATION_TAGS.CRM],
    logo: <Logos.Hubspot />
  }
];

const AI_INTEGRATION_ORDER = AI_INTEGRATIONS.reduce(
  (orderMap, integ, index) => {
    orderMap[integ.key] = index;
    return orderMap;
  },
  {} as Record<string, any>
);

const AI_INTEGRATIONS_MAP = mapArrayToObject(AI_INTEGRATIONS, 'key');

export {
  AI_INTEGRATION_TAGS,
  AI_INTEGRATION_TYPES,
  AI_INTEGRATIONS,
  AI_INTEGRATION_ORDER,
  AI_INTEGRATIONS_MAP
};
