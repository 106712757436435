import * as Icon from '../Icons';

import {
  TYPE_BUTTON,
  TYPE_CONTAINER,
  TYPE_IMAGE,
  TYPE_PROGRESS_BAR,
  TYPE_TEXT,
  TYPE_VIDEO
} from '../../utils/elements';

const elementIconMap: Record<string, any> = {
  [TYPE_TEXT]: <Icon.TextIcon />,
  [TYPE_BUTTON]: <Icon.ButtonIcon />,
  [TYPE_PROGRESS_BAR]: <Icon.ProgressIcon />,
  [TYPE_IMAGE]: <Icon.ImageIcon />,
  [TYPE_VIDEO]: <Icon.VideoIcon />,
  custom: <Icon.CodeIcon />,
  text_field: <Icon.TextFieldIcon />,
  text_area: <Icon.TextAreaIcon />,
  dropdown: <Icon.DropdownIcon />,
  dropdown_multi: <Icon.MultiselectDropdownIcon />,
  checkbox: <Icon.CheckboxIcon />,
  matrix: <Icon.MatrixIcon />,
  multiselect: <Icon.CheckboxGroupIcon />,
  select: <Icon.RadioGroupIcon />,
  button_group: <Icon.ButtonGroupIcon />,
  date_selector: <Icon.DateSelectorIcon />,
  file_upload: <Icon.FileElementUploadIcon />,
  signature: <Icon.SignatureIcon />,
  email: <Icon.EmailIcon />,
  phone_number: <Icon.PhoneNumberIcon />,
  pin_input: <Icon.PINIcon />,
  password: <Icon.PasswordIcon />,
  integer_field: <Icon.NumberIcon />,
  slider: <Icon.SliderIcon />,
  rating: <Icon.RatingIcon />,
  url: <Icon.URLIcon />,
  ssn: <Icon.SSNumberIcon />,
  hex_color: <Icon.ColorPickerIcon />,
  gmap_line_1: <Icon.AddressIcon />,
  gmap_line_2: <Icon.AddressIcon />,
  gmap_city: <Icon.AddressIcon />,
  gmap_state: <Icon.AddressIcon />,
  gmap_zip: <Icon.AddressIcon />,
  gmap_country: <Icon.AddressIcon />,
  payment_method: <Icon.PaymentMethodIcon />,
  qr_scanner: <Icon.QRScannerIcon />,
  [TYPE_CONTAINER]: <Icon.ContainerIcon />
};

const elementEntries: Record<
  string,
  { label: string; type: string; icon?: any; image?: any }
> = {
  [TYPE_TEXT]: {
    label: 'Text',
    type: 'basic'
  },
  [TYPE_BUTTON]: {
    label: 'Button',
    type: 'basic'
  },
  [TYPE_PROGRESS_BAR]: {
    label: 'Progress',
    type: 'basic'
  },
  [TYPE_IMAGE]: {
    label: 'Image',
    type: 'basic'
  },
  [TYPE_VIDEO]: {
    label: 'Video',
    type: 'basic'
  },
  custom: {
    label: 'Custom Field',
    type: 'field'
  },
  text_field: {
    label: 'Text Field',
    type: 'field'
  },
  text_area: {
    label: 'Text Area',
    type: 'field'
  },
  dropdown: {
    label: 'Dropdown',
    type: 'field'
  },
  dropdown_multi: {
    label: 'Dropdown Multiselect',
    type: 'field'
  },
  checkbox: {
    label: 'Checkbox',
    type: 'field'
  },
  matrix: {
    label: 'Matrix',
    type: 'field'
  },
  multiselect: {
    label: 'Checkbox Group',
    type: 'field'
  },
  select: {
    label: 'Radio Group',
    type: 'field'
  },
  button_group: {
    label: 'Button Group',
    type: 'field'
  },
  date_selector: {
    label: 'Date Selector',
    type: 'field'
  },
  file_upload: {
    label: 'File Upload',
    type: 'field'
  },
  signature: {
    label: 'Signature',
    type: 'field'
  },
  email: {
    label: 'Email',
    type: 'field'
  },
  phone_number: {
    label: 'Phone',
    type: 'field'
  },
  pin_input: {
    label: 'PIN Input',
    type: 'field'
  },
  password: {
    label: 'Password',
    type: 'field'
  },
  integer_field: {
    label: 'Number',
    type: 'field'
  },
  slider: {
    label: 'Slider',
    type: 'field'
  },
  rating: {
    label: 'Rating',
    type: 'field'
  },
  url: {
    label: 'URL',
    type: 'field'
  },
  ssn: {
    label: 'SSN',
    type: 'field'
  },
  hex_color: {
    label: 'Color Picker',
    type: 'field'
  },
  gmap_line_1: {
    label: 'Street Address',
    type: 'field'
  },
  gmap_line_2: {
    label: 'Address Line 2',
    type: 'field'
  },
  gmap_city: {
    label: 'City',
    type: 'field'
  },
  gmap_state: {
    label: 'State',
    type: 'field'
  },
  gmap_zip: {
    label: 'Zip Code',
    type: 'field'
  },
  gmap_country: {
    label: 'Country',
    type: 'field'
  },
  payment_method: {
    label: 'Payment Method',
    type: 'field'
  },
  qr_scanner: {
    label: 'QR Code Scanner',
    type: 'field'
  },
  [TYPE_CONTAINER]: {
    label: 'Container',
    type: 'layout'
  }
};

export function getElementIcon(element: any, elementType: any) {
  const renderIcon = element?.image ? (
    <img src={element?.image} alt={element?.label} />
  ) : (
    elementIconMap[elementType]
  );

  return renderIcon;
}

const OTHER_FIELDS = ['select', 'multiselect'];
const OPTION_FIELDS = [
  'button_group',
  'dropdown',
  'dropdown_multi',
  'matrix',
  ...OTHER_FIELDS
];
const MULTI_SELECT_FIELDS = ['multiselect', 'button_group', 'dropdown_multi'];
const IMAGE_FIELDS = ['file_upload', 'url', 'text_field', 'text_area'];
const TEXT_BASED_FIELDS = [
  'date_selector',
  'password',
  'dropdown',
  'dropdown_multi',
  'email',
  'phone_number',
  'gmap_line_1',
  'gmap_line_2',
  'gmap_city',
  'gmap_state',
  'gmap_country',
  'gmap_zip',
  'integer_field',
  'ssn',
  'text_field',
  'text_area',
  'url',
  'payment_method'
];
const NUMBER_FIELDS = ['slider', 'integer_field', 'rating'];
// Mapped to max length
const MINMAX_LENGTH_FIELDS = {
  text_field: 1024, // Max storage limit on backend column
  text_area: 16384, // Max storage limit on backend column
  integer_field: Number.MAX_SAFE_INTEGER,
  slider: 2147483647, // Max size of Django integer field
  email: 1024, // Max storage limit on backend column
  password: 1024, // Max storage limit on backend column
  gmap_line_1: 1024, // Max storage limit on backend column
  gmap_zip: 10
};
const FILE_FIELDS = ['file_upload', 'signature'];
const AUTO_SUBMITTABLE_FIELDS = [
  'button_group',
  'date_selector',
  'file_upload',
  'dropdown',
  'gmap_state',
  'gmap_country',
  'pin_input',
  'qr_scanner',
  'select',
  'hex_color',
  'ssn',
  'rating'
];
const LENGTH_FIELDS = ['pin_input'];
const ACTION_FIELDS = AUTO_SUBMITTABLE_FIELDS;

function defaultFieldValue(type: any) {
  if (type === 'checkbox') return false;
  else if (type === 'multiselect') return [];
  else if (NUMBER_FIELDS.includes(type)) return 0;
  else if (type === 'hex_color') return '000000';
  else if (type === 'custom') return {};
  else return '';
}

export default elementEntries;
export {
  AUTO_SUBMITTABLE_FIELDS,
  OTHER_FIELDS,
  OPTION_FIELDS,
  IMAGE_FIELDS,
  TEXT_BASED_FIELDS,
  NUMBER_FIELDS,
  MINMAX_LENGTH_FIELDS,
  MULTI_SELECT_FIELDS,
  LENGTH_FIELDS,
  ACTION_FIELDS,
  FILE_FIELDS,
  defaultFieldValue,
  elementIconMap
};
