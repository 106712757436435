import { FC, useState, FormEvent, useMemo } from 'react';
import useVancoSearch from './useVancoSearch';
import { VancoSearchResult } from './types';
import { Button } from '../../../components/Core/Button/button';
import {
  CheckboxField,
  CollapsibleSection,
  TextField
} from '../../../components/Core';
import { cn } from '../../../utils/cn';
import { CloseIcon } from '../../../components/Icons';
import { useAppSelector } from '../../../hooks';
import Row from '../../../components/Core/Layout/Row';
import Col from '../../../components/Core/Layout/Col';

function VancoSearchPanel({ selectedItem, onSelectItem }: any) {
  const workspaceMeta = useAppSelector(
    (state) => state.accounts.organization?.whitelabel?.workspace_meta
  );

  const [isMock, setIsMock] = useState(true);

  const {
    SearchUrl: searchUrl,
    VancoOrgId: vancoOrgId,
    SearchLabel: searchLabel
  } = workspaceMeta ?? {};

  return (
    // TODO (tyler): remove mock data (once vanco tests)
    <CollapsibleSection title={searchLabel || 'Search'}>
      <Row>
        <Col>
          <CheckboxField
            checked={isMock}
            text='Use Mock Data (testing purposes)'
            onChange={setIsMock}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <VancoSearch
            searchUrl={searchUrl}
            vancoOrgId={vancoOrgId}
            isMock={isMock}
            defaultPageSize={10}
            onSelectItem={onSelectItem}
            selectedItem={selectedItem}
          />
        </Col>
      </Row>
    </CollapsibleSection>
  );
}

export default VancoSearchPanel;

interface VancoSearchProps {
  searchUrl: string;
  vancoOrgId: string;
  isMock?: boolean;
  defaultPageSize?: number;
  className?: string;
  onSelectItem?: (item: VancoSearchResult | null) => void;
  selectedItem?: VancoSearchResult | null;
  showSearchOnly?: boolean;
}

/**
 * Component for searching and displaying Vanco products
 */
const VancoSearch: FC<VancoSearchProps> = ({
  vancoOrgId,
  searchUrl,
  isMock = false,
  defaultPageSize = 10,
  className = '',
  onSelectItem,
  selectedItem = null,
  showSearchOnly = false
}) => {
  const [inputValue, setInputValue] = useState<string>('');

  const config = useMemo(
    () => ({
      searchUrl,
      vancoOrgId,
      defaultPageSize,
      isMock
    }),
    [searchUrl, vancoOrgId, defaultPageSize, isMock]
  );

  const {
    searchTerm,
    results,
    resultsCount,
    isLoading,
    error,
    pagination,
    search,
    changePage
  } = useVancoSearch(config);

  const handleSearch = (e: FormEvent) => {
    e.preventDefault();
    search(inputValue);
  };

  const handlePageChange = (newPage: number) => {
    changePage(newPage);
  };

  const handleSelectItem = (item: VancoSearchResult) => {
    if (onSelectItem) {
      onSelectItem(item);
    }
  };

  const handleClearSelection = () => {
    if (onSelectItem) {
      onSelectItem(null);
    }
  };

  // Selected Item
  if (selectedItem && !showSearchOnly) {
    return (
      <div className={cn('flex items-center w-full', className)}>
        <VancoItem item={selectedItem} disabled />
        <Button
          size='icon'
          onClick={handleClearSelection}
          variant='ghost'
          className='w-10 h-10'
        >
          <CloseIcon className='stroke-1 w-7 h-7' />
        </Button>
      </div>
    );
  }

  // Regular search view
  const renderSearchResult = (result: VancoSearchResult) => {
    return (
      <VancoItem
        key={result.id}
        item={result}
        onSelect={() => handleSelectItem(result)}
      />
    );
  };

  return (
    <div className={cn('w-full', className)}>
      <div className='mb-4'>
        {/* Search Form */}
        <form onSubmit={handleSearch} className='flex gap-4 mb-4'>
          <TextField
            value={inputValue}
            onChange={setInputValue}
            placeholder='Search...'
            aria-label='Search'
            className='flex-1'
          />
          <Button type='submit' disabled={isLoading} className='w-[105px]'>
            {isLoading ? 'Searching...' : 'Search'}
          </Button>
        </form>
        {/* Results Count */}
        {searchTerm && !error && (
          <div className='text-sm text-gray-600 m-2'>
            <span>
              Found {resultsCount} {resultsCount === 1 ? 'result' : 'results'}{' '}
              for &ldquo;{searchTerm}&rdquo;
            </span>
          </div>
        )}
      </div>

      {/* Error Display */}
      {error && <Error errorMsg={error} />}

      {/* Results Display */}
      {results.length > 0 && <div>{results.map(renderSearchResult)}</div>}

      {/* No Results */}
      {searchTerm && results.length === 0 && !isLoading && !error && (
        <NoResults searchTerm={searchTerm} />
      )}

      {/* Pagination Controls */}
      {results.length > 0 && (
        <Pagination
          isLoading={isLoading}
          pagination={pagination}
          handlePageChange={handlePageChange}
        />
      )}
    </div>
  );
};

function VancoItem({ item, onSelect = () => {}, ...rest }: any) {
  const { title, description, thumbnail } = item.attributes;
  return (
    <button
      onClick={onSelect}
      className='p-3 w-full select-none rounded-lg enabled:hover:bg-gray-100 flex items-center text-left gap-4 transition-colors'
      {...rest}
    >
      {thumbnail && (
        <div className='shrink-0 w-[50px] h-[50px]'>
          <img
            className='w-full h-full rounded-lg'
            src={thumbnail}
            alt={title}
          />
        </div>
      )}
      <div className='flex-1 min-w-0'>
        <p className='text-sm font-semibold text-gray-900 line-clamp-1'>
          {title}
        </p>
        <p className='text-sm text-gray-600 line-clamp-2'>{description}</p>
      </div>
    </button>
  );
}

function Pagination({ isLoading, pagination, handlePageChange }: any) {
  return (
    <div className='flex items-center gap-1 justify-between border-t border-gray-200 pt-4 [&_button]:w-[75px]'>
      <Button
        variant='outline'
        size='sm'
        onClick={() => handlePageChange(pagination.pageNumber - 1)}
        disabled={pagination.pageNumber <= 1 || isLoading}
        aria-label='Previous page'
      >
        Previous
      </Button>

      <span className='text-gray-700 text-sm'>
        Page {pagination.pageNumber} of {pagination.totalPages || 1}
      </span>

      <Button
        variant='outline'
        size='sm'
        onClick={() => handlePageChange(pagination.pageNumber + 1)}
        disabled={pagination.pageNumber >= pagination.totalPages || isLoading}
        aria-label='Next page'
      >
        Next
      </Button>
    </div>
  );
}

function NoResults({ searchTerm }: any) {
  return (
    <div className='p-8 text-center text-gray-700 bg-gray-100 rounded-lg'>
      No results found for &ldquo;{searchTerm}&rdquo;
    </div>
  );
}

function Error({ errorMsg }: any) {
  return (
    <div
      className='p-8 py-4 text-center text-red-600 bg-red-100 rounded-lg '
      role='alert'
    >
      Error: {errorMsg}
    </div>
  );
}
