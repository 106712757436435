import React, { useEffect, useState } from 'react';
import Dialog from '../../Dialog';
import useQuikFieldConfig from '../../../hooks/useQuikFieldConfig';
import { DynamicTextTooltip, TextField, YesNoSwitch } from '../../Core';
import styles from './styles.module.scss';
import classNames from 'classnames';
import ruleStyles from '../../NavigationRules/styles.module.scss';
import { SearchIcon } from '../../Icons';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../Core/Tabs';
import Table from '../../Table';
import { Neutral, Positive } from '../../Core/Button';
import useFeatheryRedux from '../../../redux';
import { useAppSelector } from '../../../hooks';
import { Tooltip } from '../../Core/Tooltip/Tooltip';

export interface QuikFieldRow {
  id: string;
  field: string;
  role: string;
}

export interface QuikTableColumn {
  key: string;
  name: string;
}

interface QuikFieldSelectorProps {
  showQuikFieldSelector: any;
  setShowQuikFieldSelector: any;
  servarMeta: any;
  elementType: string;
  isRepeatable?: boolean;
  onSelect: (data: any) => void;
  disabled?: boolean;
}

const QuikFieldSelector: React.FC<QuikFieldSelectorProps> = ({
  showQuikFieldSelector,
  setShowQuikFieldSelector,
  servarMeta,
  elementType,
  isRepeatable = false,
  onSelect,
  disabled = false
}) => {
  const { getQuikFieldSelectorTabData } = useQuikFieldConfig(servarMeta);

  const {
    formBuilder: { toggleQuikMultiForm }
  } = useFeatheryRedux();

  const quikMultiForm = useAppSelector(
    (state) => state.formBuilder.quikMultiForm
  );

  const [selectedQuikFieldId, setSelectedQuikFieldId] = useState('');
  const [quikFieldSearchFilter, setQuikFieldSearchFilter] = useState('');

  const quikFieldSelectorTableTabs = getQuikFieldSelectorTabData(
    onSelect,
    elementType,
    isRepeatable && !quikMultiForm
  );

  const filteredTabs = quikFieldSelectorTableTabs.map((tab) => ({
    ...tab,
    rows: tab.rows.filter(
      (row: QuikFieldRow) =>
        !quikFieldSearchFilter ||
        row.field.toLowerCase().includes(quikFieldSearchFilter.toLowerCase())
    )
  }));

  const [currentTab, setCurrentTab] = useState(
    filteredTabs.length > 0 ? filteredTabs[0].key : ''
  );

  useEffect(() => {
    if (
      filteredTabs.length > 0 &&
      !filteredTabs.some((tab) => tab.key === currentTab)
    ) {
      setCurrentTab(filteredTabs[0].key);
    }
  }, [filteredTabs, currentTab]);

  return (
    <div>
      <DynamicTextTooltip text={servarMeta.quik_field_id} maxLength={12}>
        <button
          disabled={disabled}
          className={classNames(
            ruleStyles.ruleTarget,
            ruleStyles.fieldTarget,
            servarMeta.quik_field_id && ruleStyles.selected
          )}
          onClick={(e) => {
            e.preventDefault();
            setShowQuikFieldSelector(true);
          }}
        >
          <span className={ruleStyles.fieldSpan}>
            {servarMeta.quik_field_id || 'Select'}
          </span>
        </button>
      </DynamicTextTooltip>
      <Dialog
        isOpen={showQuikFieldSelector}
        onClose={() => setShowQuikFieldSelector(false)}
        shouldShowCloseIcon
        title='Select Quik Field'
        size='xlg'
      >
        {isRepeatable && elementType !== 'signature' && (
          <Tooltip content='If filling a single form, each repeating Feathery field can map to multiple Quik field IDs (e.g. first repeating field maps to 1own, second maps to 2own, etc.). If filling multiple forms, each repeating Feathery field will map to the same Quik ID on a different form'>
            <div style={{ width: 'max-content' }}>
              <YesNoSwitch
                checked={quikMultiForm}
                customNoLabel='Fill single form'
                customYesLabel='Fill multiple forms'
                onCheckedChange={() => toggleQuikMultiForm()}
                switchStyle={{ marginBottom: '20px', marginTop: '20px' }}
              />
            </div>
          </Tooltip>
        )}
        <div className={styles.fieldSelectorContainer}>
          <div
            className={classNames('search-wrap', styles.fieldSelectorSearch)}
          >
            <SearchIcon className='search-icon' />
            <TextField
              className='inp list-view-search'
              value={quikFieldSearchFilter}
              placeholder='Search'
              onComplete={(val: string) => setQuikFieldSearchFilter(val)}
              style={{ height: '30px' }}
            />
          </div>
          <Tabs value={currentTab} onValueChange={setCurrentTab}>
            <TabsList className='legacyTabsList' unstyled>
              {quikFieldSelectorTableTabs.map((tab) => (
                <TabsTrigger
                  key={tab.key}
                  value={tab.key}
                  className='legacyTabsTrigger'
                  unstyled
                >
                  {tab.label}
                </TabsTrigger>
              ))}
            </TabsList>
            {filteredTabs.map((tab) => (
              <TabsContent
                key={tab.key}
                value={tab.key}
                className='legacyTabsContent'
                unstyled
              >
                <Table
                  name={tab.label}
                  data={tab.rows}
                  columns={tab.columns}
                  showSelected={!!tab.onRowSelect}
                  initSelectId={tab.selectedRowId}
                  onSelect={(row: QuikFieldRow) => {
                    setSelectedQuikFieldId(row.field);
                  }}
                  type='modal'
                  sortable={true}
                />
              </TabsContent>
            ))}
          </Tabs>
          <div className={classNames('text-center', styles.buttonContainer)}>
            <div className='flex items-center gap-3'>
              {servarMeta.quik_field_id !== '' && (
                <Neutral
                  onClick={() => {
                    onSelect('');
                    setShowQuikFieldSelector(false);
                  }}
                >
                  Clear
                </Neutral>
              )}
              <Positive
                onClick={async () => {
                  onSelect(selectedQuikFieldId);
                  setShowQuikFieldSelector(false);
                }}
              >
                Select
              </Positive>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default QuikFieldSelector;
