import { useEffect } from 'react';
import { useAppSelector } from '..';
import useFeatheryRedux from '../../redux';
import { Spinner } from '../../components/Core/Spinner';

const LOADING_TOAST_ID = 'promotion-loading-toast';

// custom persistent toast message for promotion status
export default function usePromotionToast() {
  const isSomePromoting = useAppSelector((state) => {
    if (!state.panels.panels) return false;
    return Object.values(state.panels.panels).some((panel) => panel.promoting);
  });

  const hasLoadingToast = useAppSelector(
    (state) =>
      state.toasts &&
      Object.values(state.toasts).some(
        (toast: any) => toast.id === LOADING_TOAST_ID
      )
  );
  const {
    toasts: { addToast, hideToast, removeToast }
  } = useFeatheryRedux();

  useEffect(() => {
    if (isSomePromoting && !hasLoadingToast) {
      addToast({
        id: LOADING_TOAST_ID,
        icon: (
          <Spinner
            className='!h-8 !w-8 !text-primary !stroke-current [&_*]:!stroke-current'
            role='presentation'
            aria-roledescription='Loading'
          />
        ),
        title: 'Form promotion in progress',
        body: "The site will refresh when it's complete",
        autohide: false,
        dismissible: false,
        show: true
      });
    } else if (!isSomePromoting && hasLoadingToast) {
      hideToast({ id: LOADING_TOAST_ID });
      setTimeout(() => removeToast({ id: LOADING_TOAST_ID }), 500);
    }
  }, [isSomePromoting, hasLoadingToast, addToast, hideToast, removeToast]);
}
