import { useEffect, useState } from 'react';
import { Positive } from '../../components/Core/Button';
import Label from '../../components/Dialog/Label';
import Dialog from '../../components/Dialog';
import AllFieldsToggle from './AllFieldsToggle';
import { useAppSelector } from '../../hooks';
import { useParams } from 'react-router-dom';
import useFeatheryRedux from '../../redux';
import { InlineTooltip } from '../../components/Core';
import { parseCSV } from '../../utils/documents';

export default function RuleSettingsModal({ isOpen, close }: any) {
  const { formId } = useParams<{ formId: string }>();
  const logicAllFields = useAppSelector(
    (state) => state.panels.panels[formId].logic_all_fields
  );
  const [curLogicAllFields, setCurLogicAllFields] =
    useState<boolean>(logicAllFields);
  const [curConfigs, setCurConfigs] = useState<any>(null);

  useEffect(() => setCurLogicAllFields(logicAllFields), [isOpen]);

  const { editPanel, updateConfig } = useFeatheryRedux();

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const proms = [];
    if (curLogicAllFields !== logicAllFields)
      proms.push(
        editPanel({ panelId: formId, logic_all_fields: curLogicAllFields })
      );
    if (curConfigs) proms.push(updateConfig({ data: curConfigs }));
    await Promise.all(proms);
    close();
  };

  return (
    <Dialog isOpen={isOpen} onClose={close} title='Rule Settings' size='sm'>
      <AllFieldsToggle
        logicAllFields={curLogicAllFields}
        setLogicAllFields={setCurLogicAllFields}
      />
      <Label style={{ display: 'flex' }}>
        Upload Configs{' '}
        <InlineTooltip text='Provide a CSV file that contains data you want to be accessible from any logic rule across all forms. This is an efficient way to make large quantities of data accessible to your rules while maintaining it in a separate spreadsheet.' />
      </Label>
      <input
        type='file'
        onChange={(event) => {
          if (!event.target.files || !event.target.files[0]) return;
          const csvFile = event.target.files[0];
          const reader = new FileReader();

          reader.onload = (e) => {
            const text = e.target?.result;
            if (!text || typeof text !== 'string') return;

            const lines = parseCSV(text);
            const headers = lines[0].map((header) => header.trim());
            const result = [];

            for (let i = 1; i < lines.length; i++) {
              const obj: Record<string, string> = {};
              for (let j = 0; j < headers.length; j++) {
                obj[headers[j]] = lines[i][j].trim();
              }
              result.push(obj);
            }

            setCurConfigs(result);
          };

          reader.readAsText(csvFile);
        }}
        accept='.csv'
      />
      <div className='dialog-form-action text-center'>
        <Positive onClick={handleSubmit}>Save</Positive>
      </div>
    </Dialog>
  );
}
