import { useEffect, useMemo, useState } from 'react';

import { FilterWidget } from '.';
import { useAppSelector } from '../../hooks';

import styles from './styles.module.scss';
import classNames from 'classnames';
import { FIELD_FILTER_ID } from './constants';
import { DropdownField, TextField } from '../Core';
import {
  EQUAL_OPERATORS,
  FIELD_OPERATORS,
  getComparisonOperators,
  OperatorMeta
} from '../../utils/validation';

import { OPERATOR_CODE } from '@feathery/react';
import { OPTION_FIELDS } from '../SelectionPanel/elementEntries';

const OPERATOR_CODE_FIELD_LOOKUP_MAP: Partial<
  Record<OPERATOR_CODE, { lookup: string; fixedValue?: string }>
> = {
  equal: { lookup: 'eq' },
  not_equal: { lookup: 'ne' },
  equal_ignore_case: { lookup: 'ieq' },
  not_equal_ignore_case: { lookup: 'ine' },
  greater_than: { lookup: 'gt' },
  greater_than_or_equal: { lookup: 'gte' },
  less_than: { lookup: 'lt' },
  less_than_or_equal: { lookup: 'lte' },
  contains: { lookup: 'contains' },
  contains_ignore_case: { lookup: 'icontains' },
  not_contains: { lookup: 'ncontains' },
  not_contains_ignore_case: { lookup: 'incontains' },
  starts_with: { lookup: 'startswith' },
  not_starts_with: { lookup: 'nstartswith' },
  ends_with: { lookup: 'endswith' },
  not_ends_with: { lookup: 'nendswith' },
  is_empty: { lookup: 'empty', fixedValue: 'true' },
  is_filled: { lookup: 'empty', fixedValue: 'false' },
  is_true: { lookup: 'eq', fixedValue: 'true' },
  is_false: { lookup: 'empty', fixedValue: 'true' }
};
const OPERATOR_CODE_FIELD_REVERSE_LOOKUP: Record<string, OPERATOR_CODE[]> = {};
Object.entries(OPERATOR_CODE_FIELD_LOOKUP_MAP).forEach(([key, value]) => {
  OPERATOR_CODE_FIELD_REVERSE_LOOKUP[value.lookup] =
    OPERATOR_CODE_FIELD_REVERSE_LOOKUP[value.lookup] ?? [];
  OPERATOR_CODE_FIELD_REVERSE_LOOKUP[value.lookup].push(key as OPERATOR_CODE);
});

// Need to restrict the operators that can be used with certain field types
// because some operators are not supported on the BE
const RESTRICTED_MULTI_OPERATORS: OPERATOR_CODE[] = [
  'is_empty',
  'is_filled',
  'contains'
];
const RESTRICTED_FIELD_TYPE_OPERATORS: Record<string, OPERATOR_CODE[]> = {
  dropdown_multi: RESTRICTED_MULTI_OPERATORS,
  multiselect: RESTRICTED_MULTI_OPERATORS,
  button_group: RESTRICTED_MULTI_OPERATORS
};
// Some field types are not supported for filtering at all
const UNSUPPORTED_SERVAR_TYPES: string[] = ['matrix', 'payment_method'];

type FieldIdentifiers = 'key' | 'id';

// Field filter custom widget.
// Components using this filter can use either the field key or the field id
// as the param name of the field filter param.
const FieldFilter: (
  idAttr: FieldIdentifiers,
  columns: { column: string }[],
  servarTypeDisallowedOperators?: Record<string, OPERATOR_CODE[]>
) => FilterWidget =
  (idAttr, columns, servarTypeDisallowedOperators = {}) =>
  // eslint-disable-next-line react/display-name
  ({ paramName, paramValue, onChange }) => {
    const servars = useAppSelector((state) => state.fields.servars ?? []);
    const hfs = useAppSelector((state) => state.fields.hiddenFields ?? []);

    // map field identifier to field
    const [servarMap, fieldMap] = useMemo(() => {
      const sMap: Record<string, any> = {};
      servars.forEach((servar: any) => (sMap[servar[idAttr]] = servar));
      const hfMap: Record<string, any> = {};
      hfs.forEach((hf: any) => (hfMap[hf[idAttr]] = { ...hf, type: 'hidden' }));

      return [sMap, { ...sMap, ...hfMap }];
    }, [servars, hfs, idAttr]);
    const SERVAR_TYPES = useAppSelector((state) =>
      Object.entries(state.elements)
        .filter(([, value]: [string, any]) => value.type === 'field')
        .map(([key]) => key)
    );
    const [fieldIdentifier, setFieldIdentifier] = useState('');
    const [operator, setOperator] = useState<OPERATOR_CODE>();
    const [val, setVal] = useState(paramValue);
    const fieldOptions = useMemo(() => {
      const cols = columns.map((col) => col.column);
      return [...servars, ...hfs]
        .filter((field: any) => !UNSUPPORTED_SERVAR_TYPES.includes(field.type))
        .sort((a, b) => {
          if (cols.includes(a.id)) return -1;
          if (cols.includes(b.id)) return 1;
          return a.key.toLowerCase().localeCompare(b.key.toLowerCase());
        })
        .map((field: any) => ({
          value: field[idAttr],
          display: field.key
        }));
    }, [servars, hfs, idAttr, columns]);
    const getSupportedOperators = (fieldId: string) => {
      const supportedOperators = Object.keys(OPERATOR_CODE_FIELD_LOOKUP_MAP);
      const field = fieldMap[fieldId];
      const restrictedOperators = RESTRICTED_FIELD_TYPE_OPERATORS[field.type];
      return (
        getComparisonOperators(field)
          .filter((o: any) => supportedOperators.includes(o.code))
          .filter(
            // either no restrictions or the operators is in the restricted list
            (o: any) =>
              !restrictedOperators || restrictedOperators.includes(o.code)
          )
          // also filter out any disallowed operators
          .filter(
            (o: any) =>
              !servarTypeDisallowedOperators[field.type] ||
              !servarTypeDisallowedOperators[field.type].includes(o.code)
          )
      );
    };
    const operatorOptions = useMemo(() => {
      if (fieldIdentifier)
        return getSupportedOperators(fieldIdentifier).map((o: any) => ({
          value: o.code,
          display: o.display
        }));
      return [];
    }, [fieldIdentifier]);

    const valueOptions = useMemo(() => {
      if (
        fieldIdentifier &&
        operator &&
        operator in EQUAL_OPERATORS &&
        OPTION_FIELDS.includes(servarMap[fieldIdentifier]?.type)
      ) {
        const selectableValues = servarMap[fieldIdentifier].metadata.options;

        const options = selectableValues.map((option: string) => {
          return {
            display: option,
            value: option
          };
        });
        if (!selectableValues.includes(val)) {
          options.unshift({
            display: val,
            option: val,
            disabled: true
          });
        }
        return options;
      }
      return [];
    }, [fieldIdentifier, operator, servarMap, val]);

    // Field filter request param name format: <type>_<servar_key>__<field_lookup_alias>
    // e.g. text_field_my_text_field__icontains
    useEffect(() => {
      if (paramName && !fieldIdentifier && !operator) {
        // parse the paramName to get the servarIdentifier, fieldLookupAlias
        const fieldLookupAliasIndex = paramName.lastIndexOf('__');
        if (fieldLookupAliasIndex > -1) {
          const fieldLookupAlias = paramName.substring(
            fieldLookupAliasIndex + 2
          );
          const remaining = paramName.substring(0, fieldLookupAliasIndex);
          // Find the servar type that the remaining string starts with
          // It is bracketed with []
          const fieldType = ['hidden', SERVAR_TYPES].find((key) => {
            const searchText = `[${key}]_`;
            return remaining.startsWith(searchText);
          });
          if (fieldType) {
            const fieldId = remaining.substring(fieldType.length + 3); // brackets and underscore
            setFieldIdentifier(fieldId);

            // Use the fieldLookupAlias and the servar type and value to determine the operator
            let operators =
              OPERATOR_CODE_FIELD_REVERSE_LOOKUP[fieldLookupAlias] ?? [];
            operators = getSupportedOperators(fieldId)
              .map((o: OperatorMeta) => o.code)
              .filter((o: OPERATOR_CODE) => operators.includes(o))
              .filter((o: OPERATOR_CODE) => {
                const fixedValue =
                  OPERATOR_CODE_FIELD_LOOKUP_MAP[o]?.fixedValue;
                return fixedValue === undefined || fixedValue === paramValue;
              });

            let operator: OPERATOR_CODE | undefined =
              operators.length > 0 ? operators[0] : undefined;
            // special handling for is_empty -> is_filled
            if (operator === 'is_empty' && paramValue === 'false')
              operator = 'is_filled';
            setOperator(operator);
          }
        }
      }
    }, [
      paramName,
      paramValue,
      fieldIdentifier,
      setFieldIdentifier,
      operator,
      setOperator
    ]);

    const handleChange = (
      fieldIdentifier: string | undefined,
      operator: OPERATOR_CODE | undefined,
      val: string | undefined
    ) => {
      if (!fieldIdentifier || !operator) return;
      // construct the paramName as: <type>_<servar_key>__<field_lookup_alias>
      const type = fieldMap[fieldIdentifier]?.type;
      const lookupAlias = OPERATOR_CODE_FIELD_LOOKUP_MAP[operator]?.lookup;
      // infix type operators are handled differently and have a fix value for the paramValue
      const value = OPERATOR_CODE_FIELD_LOOKUP_MAP[operator]?.fixedValue ?? val;
      const displayValue = FIELD_OPERATORS[operator]?.infix ? value : '';

      onChange({
        // Adding the field key to the id so that we can support multiple field filters
        // but only one per field.
        id: `${FIELD_FILTER_ID}_${fieldIdentifier}`,
        paramName: `[${type}]_${fieldIdentifier}__${lookupAlias}`,
        paramValue: value ?? '',
        displayState: `${fieldMap[fieldIdentifier].key} ${FIELD_OPERATORS[operator].display} ${displayValue}`
      });
    };

    return (
      <div className={classNames(styles.filterExpression, styles.field)}>
        <DropdownField
          className={styles.input}
          onChange={(event: any) => {
            setFieldIdentifier(event.target.value);
            handleChange(event.target.value, operator, val);
          }}
          selected={fieldIdentifier}
          options={fieldOptions}
        />
        <DropdownField
          className={styles.input}
          onChange={(event: any) => {
            setOperator(event.target.value);
            handleChange(fieldIdentifier, event.target.value, val);
          }}
          selected={operator}
          options={operatorOptions}
        />
        {operator && FIELD_OPERATORS[operator]?.infix && (
          <>
            {operator in EQUAL_OPERATORS &&
            OPTION_FIELDS.includes(servarMap[fieldIdentifier]?.type) ? (
              <DropdownField
                autoFocus
                hideCaret
                className={styles.valueInput}
                selected={val}
                title={val}
                options={valueOptions}
                onChange={(event: any) => {
                  setVal(event.target.value);
                  handleChange(fieldIdentifier, operator, event.target.value);
                }}
              />
            ) : (
              <TextField
                autoFocus
                className={styles.valueInput}
                value={val}
                title={val}
                placeholder='value1;;value2'
                onComplete={(newVal: string) => {
                  setVal(newVal);
                  handleChange(fieldIdentifier, operator, newVal);
                }}
                triggerCleanUp
              />
            )}
          </>
        )}
      </div>
    );
  };

export default FieldFilter;
