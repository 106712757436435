import { objectApply } from '../../utils/core';
import { INTEGRATIONS } from '../FormIntegrations/types';

export const isOverride = (
  base: any,
  override: any,
  props: any,
  strict = false
) => {
  function overridesObject(
    listOrObject = [],
    base: Record<string, any> = {},
    _override: Record<string, any> = {}
  ) {
    if (Array.isArray(listOrObject)) {
      return listOrObject.some((prop) => {
        if (strict) {
          return (
            prop in _override &&
            (!(prop in base) || _override[prop] !== base[prop])
          );
        } else {
          return prop in _override && prop in base;
        }
      });
    } else if (listOrObject && typeof listOrObject === 'object') {
      return Object.keys(listOrObject).some(
        (key: string): boolean =>
          overridesObject(listOrObject[key], base[key], _override[key]) ?? false
      );
    }
  }

  return overridesObject(props, base, override);
};

function calculateOverrideObjects(
  base = {},
  override = {},
  propertyOverride = false
): any {
  const result: any = objectApply(base, override);

  if (
    propertyOverride &&
    (base as any).properties &&
    (override as any).properties
  ) {
    // Certain properties like images have the URL stored in the properties object and the image ID stored in the base
    // object under the same key. The properties object stores the true inheritance structure (undefined in override
    // means use base value) so we need to apply the same inheritance to the base object attributes.
    Object.keys((base as any).properties)
      .filter(
        (prop) =>
          // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          override.properties[prop] === undefined && base[prop] !== undefined
      )
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      .forEach((prop) => (result[prop] = base[prop]));
  }

  const isOverrideFn = (props: any) => {
    return isOverride(base, override, props);
  };

  return { base, override, result, isOverride: isOverrideFn };
}

export interface ActionOption {
  value: string;
  display: string;
}

const ADD_REPEATED_ROW = 'add_repeated_row';
const ALLOY_VERIFY_ID = 'alloy_verify_id';
const BACK = 'back';
const CUSTOM = 'custom';
const PURCHASE_PRODUCTS = 'purchase_products';
const SELECT_PRODUCT_TO_PURCHASE = 'select_product_to_purchase';
const REMOVE_PRODUCT_FROM_PURCHASE = 'remove_product_from_purchase';
const FLINKS = 'trigger_flinks';
const LOGOUT = 'logout';
const MAGIC_LINK = 'send_magic_link';
const NEXT = 'next';
const NEW_SUBMISSION = 'new_submission';
const GENERATE_ENVELOPES = 'open_fuser_envelopes';
const GENERATE_QUIK_DOCUMENTS = 'generate_quik_documents';
const OAUTH = 'trigger_oauth_login';
const ARGYLE = 'trigger_argyle';
const PLAID = 'trigger_plaid';
const PERSONA = 'trigger_persona';
const SCHWAB_CREATE_CONTACT = 'schwab_create_contact';
const REMOVE_REPEATED_ROW = 'remove_repeated_row';
const SMS_CODE = 'send_sms_code';
const SMS_MESSAGE = 'send_sms_message';
const EMAIL_CODE = 'send_email_code';
const STORE_FIELD_VALUE = 'store_field_value';
const URL = 'url';
const VERIFY_SMS = 'verify_sms';
const VERIFY_EMAIL = 'verify_email';
const VERIFY_COLLABORATOR = 'verify_collaborator';
const INVITE_COLLABORATOR = 'invite_collaborator';
const REWIND_COLLABORATION = 'rewind_collaboration';
const AI_DOCUMENT_EXTRACT = 'ai_document_extract';
const TELESIGN_SILENT_VERIFICATION = 'telesign_silent_verification';
const TELESIGN_PHONE_TYPE = 'telesign_phone_type';
const TELESIGN_VOICE_OTP = 'telesign_voice_otp';
const TELESIGN_SMS_OTP = 'telesign_sms_otp';
const TELESIGN_VERIFY_OTP = 'telesign_verify_otp';
const ACTION_OPTIONS = {
  NEXT,
  BACK,
  ADD_REPEATED_ROW,
  REMOVE_REPEATED_ROW,
  OAUTH,
  MAGIC_LINK,
  SMS_CODE,
  EMAIL_CODE,
  SMS_MESSAGE,
  VERIFY_SMS,
  VERIFY_EMAIL,
  LOGOUT,
  AI_DOCUMENT_EXTRACT,
  PLAID,
  ALLOY_VERIFY_ID,
  FLINKS,
  PERSONA,
  ARGYLE,
  STORE_FIELD_VALUE,
  SELECT_PRODUCT_TO_PURCHASE,
  REMOVE_PRODUCT_FROM_PURCHASE,
  PURCHASE_PRODUCTS,
  INVITE_COLLABORATOR,
  VERIFY_COLLABORATOR,
  REWIND_COLLABORATION,
  GENERATE_ENVELOPES,
  GENERATE_QUIK_DOCUMENTS,
  URL,
  NEW_SUBMISSION,
  SCHWAB_CREATE_CONTACT,
  TELESIGN_SILENT_VERIFICATION,
  TELESIGN_PHONE_TYPE,
  TELESIGN_VOICE_OTP,
  TELESIGN_SMS_OTP,
  TELESIGN_VERIFY_OTP,
  CUSTOM
};

// ACTION_OPTIONS.OAUTH is not included because oauth must redirect to oauth provider
export const AUTH_TARGET_ACTIONS = [
  ACTION_OPTIONS.SMS_CODE,
  ACTION_OPTIONS.MAGIC_LINK,
  ACTION_OPTIONS.VERIFY_SMS,
  ACTION_OPTIONS.VERIFY_EMAIL
];

export const COLLABORATOR_ACTIONS = [
  ACTION_OPTIONS.INVITE_COLLABORATOR,
  ACTION_OPTIONS.VERIFY_COLLABORATOR,
  ACTION_OPTIONS.REWIND_COLLABORATION
];

export const ONLY_ONE_ACTION = [
  ACTION_OPTIONS.NEXT,
  ACTION_OPTIONS.BACK,
  ACTION_OPTIONS.PURCHASE_PRODUCTS,
  ACTION_OPTIONS.OAUTH,
  ACTION_OPTIONS.LOGOUT,
  ACTION_OPTIONS.PLAID,
  ACTION_OPTIONS.PERSONA,
  ACTION_OPTIONS.ARGYLE,
  ACTION_OPTIONS.NEW_SUBMISSION,
  ACTION_OPTIONS.TELESIGN_SILENT_VERIFICATION,
  ACTION_OPTIONS.TELESIGN_PHONE_TYPE,
  ACTION_OPTIONS.TELESIGN_VOICE_OTP,
  ACTION_OPTIONS.TELESIGN_SMS_OTP,
  ACTION_OPTIONS.TELESIGN_VERIFY_OTP,
  ACTION_OPTIONS.CUSTOM
];

interface ACTION_FLAGS {
  isTheme?: boolean;
  repeatExists?: boolean;
  inRepeatContainer?: boolean;
  integrations?: any;
  org?: any;
  panel?: any;
}

// DON'T alphabetize these options. The order listed here is the order they will render in dropdowns
const ACTION_CONFIG = [
  {
    value: NEXT,
    display: 'Go To Step'
  },
  {
    value: BACK,
    display: 'Go To Previous Step'
  },
  {
    value: ADD_REPEATED_ROW,
    display: 'Add Repeating Container',
    testFn: ({ repeatExists, isTheme }: ACTION_FLAGS) => repeatExists || isTheme
  },
  {
    value: REMOVE_REPEATED_ROW,
    display: 'Remove Repeating Container',
    testFn: ({ repeatExists, isTheme }: ACTION_FLAGS) => repeatExists || isTheme
  },
  {
    value: URL,
    display: 'Open New URL'
  },
  {
    value: STORE_FIELD_VALUE,
    display: 'Save Value To Field'
  },
  {
    value: NEW_SUBMISSION,
    display: 'Start New Submission'
  },
  {
    value: GENERATE_QUIK_DOCUMENTS,
    display: 'Fill Quik Documents',
    testFn: ({ org, integrations = {} }: ACTION_FLAGS) =>
      org.enterprise_features?.quik && integrations[INTEGRATIONS.QUIK]
  },
  {
    value: GENERATE_ENVELOPES,
    display: 'Generate Documents',
    testFn: ({ org }: ACTION_FLAGS) => org.enterprise_features?.documents
  },
  {
    value: SCHWAB_CREATE_CONTACT,
    display: 'Create Schwab Advisor Center Contact',
    testFn: ({ integrations = {}, isTheme }: ACTION_FLAGS) =>
      integrations[INTEGRATIONS.SCHWAB] || isTheme
  },
  {
    value: PLAID,
    display: 'Plaid Verification',
    testFn: ({ integrations = {}, isTheme }: ACTION_FLAGS) =>
      integrations[INTEGRATIONS.PLAID] || isTheme
  },
  {
    value: ALLOY_VERIFY_ID,
    display: 'Alloy ID Verification',
    testFn: ({ integrations = {}, isTheme }: ACTION_FLAGS) =>
      integrations[INTEGRATIONS.ALLOY] || isTheme
  },
  {
    value: FLINKS,
    display: 'Flinks Verification',
    testFn: ({ integrations = {}, isTheme }: ACTION_FLAGS) =>
      integrations[INTEGRATIONS.FLINKS] || isTheme
  },
  {
    value: PERSONA,
    display: 'Persona ID Verification',
    testFn: ({ integrations = {}, isTheme }: ACTION_FLAGS) =>
      integrations[INTEGRATIONS.PERSONA] || isTheme
  },
  {
    value: ARGYLE,
    display: 'Argyle Verification',
    testFn: ({ integrations = {}, isTheme }: ACTION_FLAGS) =>
      integrations[INTEGRATIONS.ARGYLE] || isTheme
  },
  {
    value: SMS_CODE,
    display: 'Send SMS Verification Code',
    testFn: ({ integrations = {}, isTheme }: ACTION_FLAGS) =>
      integrations[INTEGRATIONS.FIREBASE] ||
      integrations[INTEGRATIONS.STYTCH] ||
      integrations[INTEGRATIONS.SMS_OTP] ||
      isTheme
  },
  {
    value: EMAIL_CODE,
    display: 'Send Email Verification Code',
    testFn: ({ integrations = {}, isTheme }: ACTION_FLAGS) =>
      integrations[INTEGRATIONS.EMAIL_OTP] || isTheme
  },
  {
    value: SMS_MESSAGE,
    display: 'Send Custom SMS Message',
    testFn: ({ integrations = {}, isTheme }: ACTION_FLAGS) =>
      integrations[INTEGRATIONS.SMS_MESSAGE] || isTheme
  },
  {
    value: VERIFY_SMS,
    display: 'Verify SMS Code',
    testFn: ({ integrations = {}, isTheme }: ACTION_FLAGS) =>
      integrations[INTEGRATIONS.FIREBASE] ||
      integrations[INTEGRATIONS.STYTCH] ||
      integrations[INTEGRATIONS.SMS_OTP] ||
      isTheme
  },
  {
    value: VERIFY_EMAIL,
    display: 'Verify Email OTP',
    testFn: ({ integrations = {}, isTheme }: ACTION_FLAGS) =>
      integrations[INTEGRATIONS.EMAIL_OTP] || isTheme
  },
  {
    value: MAGIC_LINK,
    display: 'Send Email Magic Link',
    testFn: ({ integrations = {}, isTheme }: ACTION_FLAGS) =>
      integrations[INTEGRATIONS.FIREBASE] ||
      integrations[INTEGRATIONS.STYTCH] ||
      isTheme
  },
  {
    value: OAUTH,
    display: 'Trigger Social Login',
    testFn: ({ integrations = {}, isTheme }: ACTION_FLAGS) =>
      integrations[INTEGRATIONS.FIREBASE] ||
      integrations[INTEGRATIONS.STYTCH] ||
      isTheme
  },
  {
    value: LOGOUT,
    display: 'Logout',
    testFn: ({ integrations = {}, isTheme }: ACTION_FLAGS) =>
      integrations[INTEGRATIONS.FIREBASE] ||
      integrations[INTEGRATIONS.STYTCH] ||
      isTheme
  },
  {
    value: AI_DOCUMENT_EXTRACT,
    display: 'Document Extraction',
    testFn: ({ org }: ACTION_FLAGS) => org?.enterprise_features.ai_documents
  },
  {
    value: PURCHASE_PRODUCTS,
    display: 'Checkout Cart',
    testFn: ({ integrations = {}, isTheme }: ACTION_FLAGS) =>
      integrations[INTEGRATIONS.STRIPE]?.data?.active || isTheme
  },
  {
    value: SELECT_PRODUCT_TO_PURCHASE,
    display: 'Add Product to Cart',
    testFn: ({ integrations = {}, isTheme }: ACTION_FLAGS) =>
      integrations[INTEGRATIONS.STRIPE]?.data?.active || isTheme
  },
  {
    value: REMOVE_PRODUCT_FROM_PURCHASE,
    display: 'Clear Product(s) from Cart',
    testFn: ({ integrations = {}, isTheme }: ACTION_FLAGS) =>
      integrations[INTEGRATIONS.STRIPE]?.data?.active || isTheme
  },
  {
    value: INVITE_COLLABORATOR,
    display: 'Invite Collaborator to Submission',
    testFn: ({ org, panel }: ACTION_FLAGS) =>
      org?.enterprise_features.collaboration &&
      panel?.collaboration_enabled &&
      panel?.collaborator_template.length > 1
  },
  {
    value: VERIFY_COLLABORATOR,
    display: 'Verify Collaborator Email',
    testFn: ({ org, panel }: ACTION_FLAGS) =>
      org?.enterprise_features.collaboration &&
      panel?.collaboration_enabled &&
      panel?.collaborator_template.length > 0
  },
  {
    value: REWIND_COLLABORATION,
    display: 'Return to Previous Collaborator',
    testFn: ({ org, panel }: ACTION_FLAGS) =>
      org?.enterprise_features.collaboration &&
      panel?.collaboration_enabled &&
      panel?.collaborator_template.length > 0
  },
  {
    value: TELESIGN_SILENT_VERIFICATION,
    display: 'Trigger Telesign Silent Verification',
    testFn: ({ integrations = {}, isTheme }: ACTION_FLAGS) =>
      integrations[INTEGRATIONS.TELESIGN]?.data?.active || isTheme
  },
  {
    value: TELESIGN_PHONE_TYPE,
    display: 'Request Telesign Phone Type',
    testFn: ({ integrations = {}, isTheme }: ACTION_FLAGS) =>
      integrations[INTEGRATIONS.TELESIGN]?.data?.active || isTheme
  },
  {
    value: TELESIGN_VOICE_OTP,
    display: 'Send Telesign Voice OTP',
    testFn: ({ integrations = {}, isTheme }: ACTION_FLAGS) =>
      integrations[INTEGRATIONS.TELESIGN]?.data?.active || isTheme
  },
  {
    value: TELESIGN_SMS_OTP,
    display: 'Send Telesign SMS OTP',
    testFn: ({ integrations = {}, isTheme }: ACTION_FLAGS) =>
      integrations[INTEGRATIONS.TELESIGN]?.data?.active || isTheme
  },
  {
    value: TELESIGN_VERIFY_OTP,
    display: 'Verify Telesign OTP',
    testFn: ({ integrations = {}, isTheme }: ACTION_FLAGS) =>
      integrations[INTEGRATIONS.TELESIGN]?.data?.active || isTheme
  },
  {
    value: CUSTOM,
    display: 'Custom Action'
  }
];

const SOCIAL_LOGIN_OPTIONS = {
  [INTEGRATIONS.STYTCH]: [
    { display: 'Google', value: 'google' },
    { display: 'Amazon', value: 'amazon' },
    { display: 'Apple', value: 'apple' },
    { display: 'Bitbucket', value: 'bitbucket' },
    { display: 'Coinbase', value: 'coinbase' },
    { display: 'Discord', value: 'discord' },
    { display: 'Facebook', value: 'facebook' },
    { display: 'GitHub', value: 'github' },
    { display: 'GitLab', value: 'gitlab' },
    { display: 'LinkedIn', value: 'linkedin' },
    { display: 'Microsoft', value: 'microsoft' },
    { display: 'Slack', value: 'slack' },
    { display: 'Twitch', value: 'twitch' }
  ],
  [INTEGRATIONS.FIREBASE]: [
    { display: 'Google', value: 'google' },
    { display: 'Apple', value: 'apple' },
    { display: 'Facebook', value: 'facebook' },
    { display: 'GitHub', value: 'github' },
    { display: 'Microsoft', value: 'microsoft' },
    { display: 'Twitter', value: 'twitter' }
  ]
};

function getActionOptions(
  actionFlags: ACTION_FLAGS,
  supportedActionIds: string[]
): ActionOption[] {
  const allActionOptions: any = [];
  return [
    ...ACTION_CONFIG.reduce((actionOptions, actionConfig) => {
      if (
        supportedActionIds.includes(actionConfig.value) &&
        (!actionConfig.testFn || actionConfig.testFn(actionFlags))
      )
        actionOptions.push({
          value: actionConfig.value,
          display: actionConfig.display
        });
      return actionOptions;
    }, allActionOptions)
  ];
}

export {
  calculateOverrideObjects,
  getActionOptions,
  ACTION_OPTIONS,
  SOCIAL_LOGIN_OPTIONS
};
