import { PDFDocument } from 'pdf-lib';
import { REGION } from '../api/utils';

export type FileType = 'pdf' | 'docx' | 'xlsx' | 'idml';

export function getSignUrl(document: any) {
  const region = REGION ? `${REGION}.` : '';
  return `https://${region}document.feathery.io/to/${document.id}`;
}

// controls the accepted file types for upload (and reupload)
export const acceptedFileTypesMap: Record<FileType, string> = {
  pdf: '.pdf',
  docx: '.docx',
  xlsx: '.xlsx,.xlsm',
  idml: '.idml'
} as const;

export const NON_SIGNABLE_TYPES = ['xlsx', 'idml'];

// checks the upload files against the accepted file types
const fileTypeMap: Record<string, FileType> = {
  'application/pdf': 'pdf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    'docx',
  'application/msword': 'docx',
  'application/vnd.ms-excel': 'xlsx',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx'
} as const;

export function getFileType(file: File): FileType | null {
  if (!file) return null;

  if (file.name.split('.')[1] === 'idml') return 'idml';

  // quick lookup
  if (fileTypeMap[file.type]) return fileTypeMap[file.type];

  // check file with subtypes
  const fileType = file.type;
  const mappedType = Object.entries(fileTypeMap).find(([key, value]) => {
    if (fileType.includes(key)) return value;
  });
  return mappedType ? mappedType[1] : null;
}

export async function validatePdf(readPromise: Promise<any>): Promise<any> {
  return new Promise((resolve) => {
    let hasFields = false;
    readPromise
      .then((result) => {
        PDFDocument.load(result)
          .then((pdfDoc) => {
            // do a simple check by getting the form fields
            const form = pdfDoc.getForm();
            hasFields = form.getFields().length > 0;
            // no errors, so resolve
            resolve({ error: '', hasFields });
          })
          .catch((err) => {
            // Couldn't get `err instance of EncryptedPDFError` to work
            // Reading error message for the word 'encrypted' instead.
            if (err.toString().includes('encrypted')) {
              resolve({
                error: `The file you uploaded is password-protected or encrypted. Please remove the password protection and try uploading the file again.`,
                hasFields
              });
            }
            resolve({ error: `PDF did not validate - ${err}`, hasFields });
          });
      })
      .catch((err) => {
        resolve({
          error: 'PDF did not validate - failed to load file',
          hasFields
        });
      });
  });
}

export const imageExtensions = [
  'jpg',
  'jpeg',
  'jif',
  'jfif',
  'jfi',
  'png',
  'gif',
  'webp',
  'tiff',
  'tif',
  'psd',
  'raw',
  'bmp',
  'dib'
];

export function readFile(file: Blob): Promise<string | ArrayBuffer> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.result) resolve(reader.result);
      else resolve('Failed to load file');
    };
    reader.onerror = (error) => reject(error);
    reader.readAsArrayBuffer(file);
  });
}

export function parseCSV(csv: string) {
  const rows = [];
  let currentRow = [];
  let currentValue = '';
  let insideQuotes = false;

  for (let i = 0; i < csv.length; i++) {
    const char = csv[i];
    const nextChar = csv[i + 1];

    if (char === '"' && insideQuotes && nextChar === '"') {
      // Escaped quote
      currentValue += '"';
      i++; // skip next quote
    } else if (char === '"') {
      insideQuotes = !insideQuotes;
    } else if (char === ',' && !insideQuotes) {
      currentRow.push(currentValue);
      currentValue = '';
    } else if ((char === '\n' || char === '\r') && !insideQuotes) {
      if (currentValue || currentRow.length) {
        currentRow.push(currentValue);
        rows.push(currentRow);
        currentRow = [];
        currentValue = '';
      }
      if (char === '\r' && nextChar === '\n') i++; // Handle CRLF
    } else {
      currentValue += char;
    }
  }

  // Add the last value
  if (currentValue || currentRow.length) {
    currentRow.push(currentValue);
    rows.push(currentRow);
  }

  return rows;
}
