// Index defaults to 0 if all existing spans are deleted and user starts typing again

import { sentryError } from './runtime';

const nodeIndex = (node: any) => node.dataset?.index ?? 0;

const DEFAULT_NODE_RANGE = { start: 0, end: 0, focus: 0, anchor: 0 };

const nodeRange = (sel: any) => {
  if (!sel.focusNode || !sel.anchorNode || sel.rangeCount <= 0) {
    // Default selection to handle sentry error
    // https://sentry.io/organizations/feathery-forms/issues/3563375428/
    return DEFAULT_NODE_RANGE;
  }
  // use getRangeAt and selection lengths to accurately
  // calculate selection offsets across multiple elements
  const parent = sel.focusNode.parentNode?.parentNode;
  const range = sel.getRangeAt(0);

  // Offset from parent to range start
  const startRange = document.createRange();
  startRange.setStart(parent, 0);
  startRange.setEnd(range.startContainer, range.startOffset);
  const start = startRange.toString().length;

  // Offset from parent to range end
  const endRange = document.createRange();
  endRange.setStart(parent, 0);
  endRange.setEnd(range.endContainer, range.endOffset);
  const end = endRange.toString().length;

  return {
    start,
    end,
    focus: end,
    anchor: start
  };
};

const newTextSelection = (sel: any) => {
  if (!sel.focusNode || !sel.anchorNode) {
    // Default selection to handle sentry error
    // https://sentry.io/organizations/feathery-forms/issues/3439170329/
    return {
      startIndex: 0,
      range: DEFAULT_NODE_RANGE,
      text: ''
    };
  }

  const range = nodeRange(sel);
  const startIndex = nodeIndex(
    (range.focus < range.anchor ? sel.focusNode : sel.anchorNode).parentNode
  );
  return { startIndex, range, text: sel.toString() };
};

// replace asterisks with bullet points if they are the first
// non-space character of a line and followed by a space
function replaceAsterisksWithBulletPoints(text: string) {
  return text.replace(/((^|\n)\s*)\*\s/g, '$1• ');
}

export const getTextEditCallbacks = ({
  setText,
  curTextFormatted,
  textSelection,
  setTextSelection,
  setEditingText
}: any) => {
  return {
    onEditModeChange: (newMode: boolean) =>
      setEditingText && setEditingText(newMode),
    onTextSelect: (sel: any) => {
      const newSelection = newTextSelection(sel);

      if (
        textSelection !== null &&
        textSelection.startIndex === newSelection.startIndex
      ) {
        const oldRange = textSelection.range;
        const newRange = newSelection.range;
        const sameRange =
          newRange.start === oldRange.start && newRange.end === oldRange.end;
        const noRange =
          newRange.start === newRange.end && oldRange.start === oldRange.end;
        if (sameRange || noRange) return;
      }

      setTextSelection(newSelection);
    },
    onTextKeyDown: (e: any, element: any, selection: any) => {
      // the node that contains the caret
      const node = selection.anchorNode;
      // the caret position inside the node
      const offset = selection.anchorOffset;

      if (e.key === 'Enter') {
        // Code from https://stackoverflow.com/a/7949770 to
        // prevent contenteditable from adding <div> or <br> on newline

        // prevent the browsers from inserting <div>, <p>, or <br> on their own
        e.preventDefault();

        // insert a '\n' character at that position
        const text = node.textContent;
        const textBefore = text.slice(0, offset);
        const textAfter = text.slice(offset) || ' ';
        node.textContent = textBefore + '\n' + textAfter;

        // position the caret after that new-line character
        const range = document.createRange();
        // Ensure offset isn't greater than text content length
        // https://sentry.io/organizations/feathery-forms/issues/3462457724
        const rangeOffset = Math.max(
          Math.min(offset + 1, node.textContent.length - 1),
          0
        );
        try {
          range.setStart(node, rangeOffset);
          range.setEnd(node, rangeOffset);

          // update the selection
          selection.removeAllRanges();
          selection.addRange(range);
        } catch (e) {
          // Sometimes offset can exceed the content length
          // https://feathery-forms.sentry.io/issues/4081370777/?project=5280968&referrer=slack
          sentryError(e, { text, rangeOffset });
        }
      } else if (['Backspace', 'Delete'].includes(e.key)) {
        const textLength = element.textContent.length;
        let flag =
          textLength === 1 &&
          ((e.key === 'Backspace' && offset === 1) ||
            (e.key === 'Delete' && offset === 0));
        if (!flag) {
          const range = nodeRange(selection);
          flag = range.start === 0 && range.end === textLength;
        }
        if (flag) {
          // If text is fully cleared, leave a `space` placeholder
          // so cursor will remain focused and user can click back in.
          // TODO: refactor to Webflow structure where first span text lives directly
          //  in parent rather than in child span. Will allow us to migrate off `space` placeholder

          e.preventDefault();
          node.textContent = ' ';
        }
      }
    },
    onTextBlur: (e: any) => {
      const textNodes = e.target.childNodes;
      const newRichText = [];
      for (let i = 0; i < textNodes.length; i++) {
        const node = textNodes[i];
        const oldEntry = curTextFormatted?.[nodeIndex(node)] ?? {
          attributes: {}
        };
        newRichText.push({
          attributes: oldEntry.attributes,
          insert: replaceAsterisksWithBulletPoints(node.textContent)
        });
      }
      const newText = replaceAsterisksWithBulletPoints(e.target.textContent);
      setText(newText, newRichText);
    }
  };
};
