import { useEffect, useRef } from 'react';
import useFeatheryRedux from '../../redux';
import { useAppSelector } from '..';

// polls the promotion status endpoint for a panel with promoting: true
// after the promotion is complete we refresh the page to maintain data
const usePromotionPolling = () => {
  const { pollPromotion } = useFeatheryRedux();
  // ref to the current NodeJS.Timeout. used to cancel the timeout
  const pollTimerRef = useRef<number | null>(null);

  // isPolling flag used to ensure only one active poll at a time
  const isPollingRef = useRef<boolean>(false);

  // find the first panel that is promoting
  const promotingPanel = useAppSelector((state) => {
    return Object.values(state.panels.panels).find((panel) => panel.promoting);
  });

  // poll the panel, if the panel is still promoting, poll again
  const pollPanel = (panel: any, retryCount = 0) => {
    if (isPollingRef.current) return;

    isPollingRef.current = true;
    const maxRetries = 3;
    const pollingDelay = 5_000;

    pollPromotion({ panelId: panel.id })
      .then((result: any) => {
        isPollingRef.current = false;

        if (!result.promoting) {
          window.location.assign('/forms');
        } else {
          if (pollTimerRef.current !== null) {
            clearTimeout(pollTimerRef.current);
          }

          pollTimerRef.current = window.setTimeout(() => {
            pollPanel(panel, 0); // Reset retry count on successful poll
          }, pollingDelay);
        }
      })
      .catch(() => {
        isPollingRef.current = false;

        if (retryCount < maxRetries) {
          if (pollTimerRef.current !== null) {
            clearTimeout(pollTimerRef.current);
          }

          pollTimerRef.current = window.setTimeout(() => {
            pollPanel(panel, retryCount + 1);
          }, pollingDelay);
        } else {
          window.location.assign('/forms');
        }
      });
  };

  useEffect(() => {
    // if there are no promoting panels, reset
    if (promotingPanel == null) {
      isPollingRef.current = false;

      if (pollTimerRef.current !== null) {
        clearTimeout(pollTimerRef.current);
        pollTimerRef.current = null;
      }
      return;
    }

    // if there is a promoting panel, poll
    if (promotingPanel != null && !isPollingRef.current) {
      pollPanel(promotingPanel);
    }

    // if unmounting, reset
    return () => {
      if (pollTimerRef.current != null) {
        clearTimeout(pollTimerRef.current);
        pollTimerRef.current = null;
      }
    };
  }, [promotingPanel]);
};

export default usePromotionPolling;
