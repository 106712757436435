import { IAPIActionState } from '../../pages/types';
import type { Template } from '../types';

export const EMONEY_GET_INCOMES: Template = {
  id: 'emoney_get_incomes',
  display: 'Get Incomes',
  configuration: {
    name: { type: 'textfield', label: 'Connector Name' },
    clientId: { type: 'textfield', label: 'Client ID' },
    planId: { type: 'textfield', label: 'Plan ID' }
  },
  defaultState: {
    name: 'EMoney Get Incomes',
    template: 'emoney_get_incomes',
    url: 'https://api-externalbeta2.emaplan.com/public/v2/clients',
    method: 'GET',
    headers: {
      Authorization: 'Bearer {{emoney_token}}',
      apikey: '{{emoney_api_token}}'
    },
    body: '',
    params: {},
    responseStructure: '',
    responseMapping: {},
    testParameters: {}
  },
  loadConfig: (state) => {
    const items = state.url.split('/');

    // if 6 items in the URL then its default state and no clientId or planId stored
    if (items.length === 6) {
      return {
        name: state.name,
        clientId: '',
        planId: ''
      };
    } else {
      return {
        name: state.name,
        clientId: items[6],
        planId: items[8]
      };
    }
  },
  saveConfig: (state, configValues) => {
    const { name, clientId, planId } = configValues;
    const newValues: Partial<IAPIActionState> = {
      name: name,
      url: `https://api-externalbeta2.emaplan.com/public/v2/clients/${clientId}/plans/${planId}/incomes`
    };
    return {
      ...state,
      ...newValues
    };
  }
};
