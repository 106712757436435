import { InlineTooltip } from '../../../components/Core';
import { TrashIcon, UpArrowIcon } from '../../../components/Icons';
import type { FieldProperties } from '../ExtractionCreateModal/types';
import Label from '../../../components/Dialog/Label';
import classNames from 'classnames';
import styles from './styles.module.scss';
import useIntegrations from '../../../components/FormIntegrations/useIntegrations';
import { AI_INTEGRATION_TYPES } from '../../../components/AIIntegrations/types';
import { memo } from 'react';
import { DocumentQuestionGroup } from './DocumentQuestionGroup';
import { CSVQuestionGroup } from './CSVQuestionGroup';

type Props = {
  extractionId?: string;
  index: number;
  group: any;
  fieldProperties: Record<string, FieldProperties>;
  hiddenFieldProperties: Record<string, Partial<FieldProperties>>;
  isLast: boolean;
  file_type: string;
  onUpdate: (
    key: string,
    value: any,
    entityIndex?: any,
    operation?: 'add' | 'remove'
  ) => void;
  onRemove: () => void;
  onMoveUp: () => void;
  onMoveDown: () => void;
};

function QuestionGroup(props: Props) {
  const {
    extractionId,
    index,
    group,
    fieldProperties,
    hiddenFieldProperties,
    isLast,
    file_type,
    onUpdate,
    onRemove,
    onMoveUp,
    onMoveDown
  } = props;

  const integrations = useIntegrations({
    all: true,
    extractionId,
    entityType: 'ai'
  });

  const isEmailIntegActive = !!integrations[AI_INTEGRATION_TYPES.EMAIL_INBOX];
  const label = group.entities.map((entity: any) => entity.name).join(', ');
  return (
    <div
      key={`question-group-${index + 1}`}
      className={classNames(
        styles.questionGroupSection,
        styles.card,
        styles.white
      )}
    >
      <QuestionGroupHeader
        label={label}
        index={index}
        isLast={isLast}
        onMoveUp={onMoveUp}
        onMoveDown={onMoveDown}
        onRemove={onRemove}
      />
      {file_type === 'image' ? (
        <DocumentQuestionGroup
          group={group}
          onUpdate={onUpdate}
          isEmailIntegActive={isEmailIntegActive}
          index={index}
          fieldProperties={fieldProperties}
          hiddenFieldProperties={hiddenFieldProperties}
        />
      ) : (
        <CSVQuestionGroup
          group={group}
          onUpdate={onUpdate}
          index={index}
          fieldProperties={fieldProperties}
          hiddenFieldProperties={hiddenFieldProperties}
        />
      )}
    </div>
  );
}

export default memo(QuestionGroup, (prev, next) => {
  return (
    prev.extractionId === next.extractionId &&
    prev.index === next.index &&
    prev.group === next.group &&
    prev.fieldProperties === next.fieldProperties &&
    prev.hiddenFieldProperties === next.hiddenFieldProperties &&
    prev.isLast === next.isLast &&
    prev.file_type === next.file_type
  );
});

function QuestionGroupHeader({
  label,
  index,
  isLast,
  onMoveUp,
  onMoveDown,
  onRemove
}: any) {
  return (
    <div className={styles.header}>
      <div className={styles.labelContainer}>
        <Label className={styles.label}>{label || `Query ${index + 1}`}</Label>
        <InlineTooltip text='The query will be used by AI to find answer(s) from each document, and the answer(s) will be saved into the field(s)' />
      </div>
      <div className={styles.actionContainer}>
        {index !== 0 && (
          <div
            className={classNames(styles.actionButton, styles.action)}
            onClick={onMoveUp}
          >
            <UpArrowIcon width={16} height={16} className={styles.fill} />
          </div>
        )}
        {!isLast && (
          <div
            className={classNames(styles.actionButton, styles.action)}
            onClick={onMoveDown}
          >
            <UpArrowIcon
              className={classNames(styles.flip, styles.fill)}
              width={16}
              height={16}
            />
          </div>
        )}
        <div
          className={classNames(styles.actionButton, styles.action)}
          onClick={onRemove}
        >
          <TrashIcon width={16} height={16} />
        </div>
      </div>
    </div>
  );
}
