function formatDate(str: any, time = true) {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'June',
    'July',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];
  if (!str) return '';
  const d = new Date(str);
  // @ts-expect-error TS(2345) FIXME: Argument of type 'Date' is not assignable to param... Remove this comment to see the full error message
  if (isNaN(d)) return '';

  if (time) {
    let hour = d.getHours() > 12 ? d.getHours() - 12 : d.getHours();
    if (hour === 0) hour = 12;
    const ampm = d.getHours() >= 12 ? 'PM' : 'AM';
    const mins = d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes();
    return `${
      months[d.getMonth()]
    } ${d.getDate()}, ${d.getFullYear()} at ${hour}:${mins} ${ampm}`;
  } else {
    // Assume all dates without time/timezone are UTC
    return `${
      months[d.getUTCMonth()]
    } ${d.getUTCDate()}, ${d.getUTCFullYear()}`;
  }
}

function formatDateTime(dateTime: any, intlOptions = {}, locales = ['en-US']) {
  return new Intl.DateTimeFormat(locales, intlOptions).format(dateTime);
}
function formatMonthLong(dateTime: any, locales?: any) {
  return formatDateTime(dateTime, { month: 'long' }, locales);
}
function formatMonthAndDay(dateTime: any, locales?: any) {
  return formatDateTime(dateTime, { month: 'long', day: 'numeric' }, locales);
}

function formatNumeric(number: any, intlOptions = {}, locales = ['en-US']) {
  return new Intl.NumberFormat(locales, intlOptions).format(number);
}
function formatDecimal(number: number, decimalDigits = 2, locales = ['en-US']) {
  return new Intl.NumberFormat(locales, {
    minimumFractionDigits: decimalDigits,
    maximumFractionDigits: decimalDigits
  }).format(number);
}
function formatMoneyUSD(number: any, locales?: any) {
  return formatNumeric(number, { style: 'currency', currency: 'USD' }, locales);
}
function formatMoney(number: any, currency = 'USD', locales?: any) {
  return formatNumeric(number, { style: 'currency', currency }, locales);
}

function uniqifyKey(key: any, existing: any, startAtOne = true) {
  if (!(existing instanceof Set)) existing = new Set(existing);

  let i = 1;
  const numMatches = key.match(/\d+$/);
  if (numMatches) {
    // Use existing number if key already ends in a number
    const number = numMatches[0];
    key = key.substring(0, key.length - number.length);
    if (!startAtOne) i = parseInt(number);
  }

  let newKey = `${key}${i}`;
  while (existing.has(newKey)) {
    i += 1;
    newKey = `${key}${i}`;
  }
  return newKey;
}

function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
function toTitleCase(str: string) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}
// Function that converts a string delimited by underscores, dashes or spaces to camel case
const toCamelCase = (str: string) => {
  return str
    .split(/[-_\s]/)
    .map((word, index) => {
      if (index === 0) return word.toLowerCase();
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join('');
};

export {
  formatDate,
  formatDateTime,
  formatMonthAndDay,
  formatMonthLong,
  formatNumeric,
  formatDecimal,
  formatMoneyUSD,
  formatMoney,
  uniqifyKey,
  capitalizeFirstLetter,
  toCamelCase,
  toTitleCase
};
