import {
  CALENDLY_ACTION,
  CONNECT_TO_API,
  NAVIGATE_TO_STEP_ACTION,
  OPEN_URL_ACTION,
  SET_ACTION,
  SET_ERROR_ACTION,
  SMS_CODE_ACTION,
  EMAIL_CODE_ACTION,
  TELESIGN_VOICE_ACTION,
  EMAIL_ACTION,
  EXPRESSION_OPERATORS,
  TELESIGN_SMS_ACTION,
  AI_EXTRACTION_ACTION,
  RUN_INTEGRATION_ACTION
} from './components/RuleAction/constants';

export const validateSetAction = (action: IRuleAction) => {
  const { parameters } = action;
  const [field, exprOrOp] = parameters;

  if (parameters.length !== 2) return false;

  if (field.type !== 'field') return false;
  if (field.value === '') return false;

  if ('operator' in exprOrOp) {
    //expression
    if (exprOrOp.operator && !EXPRESSION_OPERATORS[exprOrOp.operator])
      return false;
    if (!exprOrOp.operands.length) return false;
  } else {
    // operand
    if (
      'type' in exprOrOp &&
      exprOrOp.type !== 'value' &&
      exprOrOp.type !== 'field'
    )
      return false;
  }

  return true;
};

export const validateSetErrorAction = (action: IRuleAction) => {
  const { parameters } = action;
  const [field, value] = parameters;

  if (parameters.length !== 2) return false;

  if (field.type !== 'field') return false;
  if (field.value === '') return false;

  if ('type' in value && value.type !== 'value') return false;

  return true;
};

export const validateOpenURLAction = (action: IRuleAction) => {
  const { parameters } = action;
  const [url, target] = parameters;

  if (parameters.length !== 2) return false;

  if (url.type !== 'value') return false;
  if (url.value === '') return false;

  if ((target as IRuleOperand).type !== 'value') return false;
  if ((target as IRuleOperand).value === '') return false;

  return true;
};

export const validateNavigateToStepAction = (action: IRuleAction) => {
  const { parameters } = action;
  const [stepField] = parameters;

  if (parameters.length !== 1) return false;

  if (stepField.type !== 'value') return false;
  if (stepField.value === '') return false;

  return true;
};

export const validateAPIConnectorAction = (action: IRuleAction) => {
  const { parameters } = action;
  const [connector] = parameters;

  if (parameters.length !== 1) return false;

  if (!connector.value) return false;
  if (!connector.meta.id) return false;
  if (!connector.meta.method) return false;
  if (!connector.meta.url) return false;

  return true;
};

export const validateCalendlyAction = (action: IRuleAction) => {
  const { parameters } = action;
  const [url] = parameters;

  if (parameters.length !== 1) return false;

  if (url.type !== 'value') return false;
  if (url.value === '') return false;

  return true;
};

export const validateOtpAction = (action: IRuleAction) => {
  const { parameters } = action;
  const [field] = parameters;

  if (parameters.length !== 1) return false;

  if (field.type !== 'field') return false;

  return true;
};

export const validateTelesignOtpAction = (action: IRuleAction) => {
  const { parameters } = action;
  const [field] = parameters;

  if (parameters.length !== 1) return false;

  if (field.type !== 'field') return false;

  return true;
};

export const validateEmailAction = (action: IRuleAction) => {
  const { parameters } = action;
  const [emailTemplate] = parameters;

  if (parameters.length !== 1) return false;

  if (emailTemplate.type !== 'value') return false;
  if (emailTemplate.value === '') return false;

  return true;
};

export const validateAIExtractionAction = (action: IRuleAction) => {
  const { parameters } = action;

  const extraction = parameters[0] as IRuleOperand;
  const runAsync = parameters[1] as IRuleOperand;
  const variant = parameters[2] as IRuleOperand;

  if (parameters.length !== 3) return false;

  if (extraction.type !== 'value') return false;
  if (extraction.value === '') return false;

  if (runAsync.type !== 'value') return false;
  if (runAsync.value === '') return false;

  if (variant.type !== 'value') return false;

  return true;
};

export const validateRunIntegrationAction = (action: IRuleAction) => {
  const { parameters } = action;
  const [integrations, _options] = parameters;
  const options = _options as IRuleOperand;

  if (parameters.length !== 2) return false;

  if (integrations.type !== 'value') return false;
  if (integrations.value === '') return false;

  if (options.type !== 'value') return false;
  if (options.value === '') return false;

  let optionsValue;
  try {
    optionsValue = JSON.parse(options.value);
  } catch (err) {
    return false;
  }

  if (!('waitForCompletion' in optionsValue)) return false;
  if (typeof optionsValue.waitForCompletion !== 'boolean') return false;

  return true;
};

export const validateAction = (action: IRuleAction) => {
  if (!action.id) return false;
  if (!action.name) return false;
  if (!action.parameters) return false;

  if (action.name === SET_ACTION) {
    return validateSetAction(action);
  } else if (action.name === SET_ERROR_ACTION) {
    return validateSetErrorAction(action);
  } else if (action.name === OPEN_URL_ACTION) {
    return validateOpenURLAction(action);
  } else if (action.name === NAVIGATE_TO_STEP_ACTION) {
    return validateNavigateToStepAction(action);
  } else if (action.name === CONNECT_TO_API) {
    return validateAPIConnectorAction(action);
  } else if (action.name === CALENDLY_ACTION) {
    return validateCalendlyAction(action);
  } else if ([SMS_CODE_ACTION, EMAIL_CODE_ACTION].includes(action.name)) {
    return validateOtpAction(action);
  } else if (
    [TELESIGN_VOICE_ACTION, TELESIGN_SMS_ACTION].includes(action.name)
  ) {
    return validateTelesignOtpAction(action);
  } else if (action.name === EMAIL_ACTION) {
    return validateEmailAction(action);
  } else if (action.name === AI_EXTRACTION_ACTION) {
    return validateAIExtractionAction(action);
  } else if (action.name === RUN_INTEGRATION_ACTION) {
    return validateRunIntegrationAction(action);
  }

  return false;
};

export const validateActions = (actions: IRuleAction[]) => {
  return actions.every((action) => validateAction(action));
};

export const validateOperand = (operand: IRuleOperand) => {
  if (!operand.id) return false;
  if (!operand.type) return false;
  if (operand.value === undefined) return false;

  return true;
};

export const validateExpression = (expression: IRuleExpression) => {
  if (!expression.id) {
    return false;
  }

  if (
    !expression.operator ||
    ['and', 'or'].indexOf(expression.operator) !== -1
  ) {
    return false;
  }

  if (!expression.operands.length) {
    return false;
  }

  const expressionOperands = expression.operands.filter(
    (operand) => 'operator' in operand
  ) as IRuleExpression[];

  if (!expressionOperands.every(validateExpression)) {
    return false;
  }

  const operands = expression.operands.filter(
    (operand) => 'type' in operand
  ) as IRuleOperand[];

  if (!operands.every(validateOperand)) {
    return false;
  }

  if (operands[0].type !== 'field') {
    return false;
  }

  if (!operands[0].value) {
    return false;
  }

  return true;
};

export const validateCondition = (condition: IRuleCondition) => {
  if (!condition.id) {
    return false;
  }

  if (!condition.operator || ['and', 'or'].indexOf(condition.operator) === -1) {
    return false;
  }

  if (!condition.operands.length) {
    return false;
  }

  const expressionOperands = condition.operands.filter(
    (operand) => ['and', 'or'].indexOf(operand.operator) === -1
  );

  if (!expressionOperands.every(validateExpression)) {
    return false;
  }

  const conditionOperands = condition.operands.filter(
    (operand) => ['and', 'or'].indexOf(operand.operator) !== -1
  ) as IRuleCondition[];

  if (!conditionOperands.every(validateCondition)) {
    return false;
  }

  return true;
};

export const validateClause = (clause: IRuleClause) => {
  if (!clause.id) return false;
  if (!clause.actions.length) return false;
  if (!validateActions(clause.actions)) return false;
  if (clause.when && !validateCondition(clause.when)) return false;

  return true;
};

export const validateBranch = (branch: IRuleBranch) => {
  if (!branch.id) return false;
  if (!branch.clauses.length) return false;
  if (!branch.clauses.every(validateClause)) return false;

  return true;
};

export const validateDSL = (dsl: IRuleDSL) => {
  if (!dsl.branches.length) return false;

  if (!dsl.branches.every(validateBranch)) {
    return false;
  }

  return true;
};

export const getErrors = (dsl: IRuleDSL) => {
  const errors: { id: string; message: string }[] = [];

  if (!dsl.branches.length) {
    errors.push({
      id: 'dsl',
      message: 'The rule must have at least one action.'
    });
  }

  const expressions = dsl.branches.reduce(
    (acc, branch) =>
      acc.concat(
        branch.clauses.reduce(
          (acc, clause) =>
            acc.concat(
              clause.when
                ? clause.when.operands.filter((o) => 'operator' in o)
                : []
            ),
          [] as IRuleExpression[]
        )
      ),
    [] as IRuleExpression[]
  );

  expressions.forEach((expression) => {
    if (!validateExpression(expression)) {
      errors.push({
        id: expression.id,
        message: 'Invalid or incomplete expression.'
      });
    }
  });

  const actions = dsl.branches.reduce(
    (acc, branch) =>
      acc.concat(
        branch.clauses.reduce(
          (acc, clause) => acc.concat(clause.actions),
          [] as IRuleAction[]
        )
      ),
    [] as IRuleAction[]
  );

  actions.forEach((action) => {
    if (!validateAction(action)) {
      errors.push({
        id: action.id,
        message: 'Invalid or incomplete action.'
      });
    }
  });

  const emptyClauses = dsl.branches.reduce(
    (acc, branch) =>
      acc.concat(branch.clauses.filter((clause) => !clause.actions.length)),
    [] as IRuleClause[]
  );

  emptyClauses.forEach((clause) => {
    errors.push({
      id: clause.id,
      message: 'Branches must have at least one action.'
    });
  });

  return errors.reduce((acc, error) => {
    acc[error.id] = {
      id: error.id,
      message: error.message
    };

    return acc;
  }, {} as Record<string, { id: string; message: string }>);
};
