import { useMemo, useState } from 'react';

import { DropdownField, PropertyLabel, TextField } from '../Core';

import { objectApply } from '../../utils/core';
import { defaultServarProps } from '../../utils/step';
import { elementOperation } from '../../utils/themes';
import useFeatheryRedux from '../../redux';

import styles from './styles.module.scss';
import formFieldStyles from './formFieldEditor.module.scss';
import { ChevronDownIcon, LinkIcon, UnlinkIcon } from '../Icons';
import { fieldTypeNameMap } from '../../utils/elements';
import { FieldLinkWarningModal } from '../Modals';
import { useAppSelector } from '../../hooks';
import { Tooltip } from '../Core/Tooltip/Tooltip';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from '../Core/DropdownMenu';
import Row from '../Core/Layout/Row';
import Col from '../Core/Layout/Col';
import { cn } from '../../utils/cn';

const FieldIdToggle = ({
  element,
  handleUpdates,
  viewport,
  mode,
  asset
}: any) => {
  const servar = element.servar ?? {};
  const fieldType = servar.type;

  const {
    formBuilder: { addChangedServar },
    toasts: { addInfoToast }
  } = useFeatheryRedux();
  const allServars = useAppSelector((state) => state.formBuilder.servars);
  const servarUsage = useAppSelector((state) => state.formBuilder.servarUsage);
  const editLinkedAllowed = useAppSelector((state) => {
    const account = state.accounts.account;
    return (
      account.role === 'admin' || account.permission_edit_linked_properties
    );
  });
  const workingSteps = useAppSelector(
    (state) => state.formBuilder.workingSteps
  );
  const activeStepId = useAppSelector(
    (state) => state.formBuilder.activeStepId
  );
  const [showLinkModal, setShowLinkModal] = useState(false);
  const activeStep = workingSteps[activeStepId];

  const stepFields = activeStep.servar_fields;
  const servarSwapOptions: any[] = useMemo(() => {
    if (mode !== 'builder') return [];

    const excludedServars = new Set(
      stepFields.map((field: any) => field.servar.id)
    );
    return Object.values(allServars)
      .filter((s) => {
        return (
          (s as any).type === fieldType &&
          !excludedServars.has((s as any).id) &&
          (fieldType !== 'custom' ||
            servar.metadata.custom_field_key ===
              (s as any).metadata?.custom_field_key)
        );
      })
      .sort((a: any, b: any) => (a.key > b.key ? 1 : -1));
  }, [allServars, fieldType, mode, stepFields]);

  const allowedTypeChanges =
    FeatheryConfig.allowed_field_type_changes[fieldType] ?? [];

  const [editDisabled, setEditDisabled] = useState(true);

  function handlePropChange(propUpdate: any) {
    handleUpdates([elementOperation({ viewport, propUpdate })]);
  }

  function linkToServar(servar: any) {
    // reset and set servar to linked servar
    handleUpdates([
      elementOperation({
        viewport,
        propUpdate: { servar },
        propReset: ['servar']
      })
    ]);
  }

  function handleServarChange(servar: any) {
    handlePropChange({ servar });

    // In the form builder, we need to record that we made changes to this servar
    // So we can show the publish confirmation modal
    if (mode === 'builder') addChangedServar(element.servar.id);
  }

  function swapToNewServar(repeated = false) {
    handleUpdates([
      elementOperation({
        viewport,
        propUpdate: {
          servar: objectApply(
            defaultServarProps({
              type: fieldType,
              fields: Object.values(allServars),
              defaultProperties: {
                ...FeatheryConfig.default_element_properties[fieldType].servar,
                repeated
              }
            }),
            asset?.servar
          )
        },
        propReset: ['servar']
      })
    ]);
  }

  // If we're going to use an existing servar, we need to remove the temporary servar we just made
  // This is to avoid servar.key conflicts
  function swapToExistingServar() {
    const option = servarSwapOptions.find(
      (servarOption) => servarOption.repeated === servar.repeated
    );
    if (option) {
      handleUpdates([
        elementOperation({
          viewport,
          propUpdate: { servar: option },
          propReset: ['servar']
        })
      ]);
      setEditDisabled(true);
    } else {
      addInfoToast('No available field to link to');
    }
  }

  // servarUsage doesn't exist if in theme builder
  const isLinked =
    mode === 'builder' && servarUsage[servar.id]
      ? servarUsage[servar.id].length > 1
      : false;
  let textField = (
    <TextField
      className={cn(
        isLinked && styles.fieldIdInputLinked,
        isLinked && '!border-r-0'
      )}
      placeholder='FieldId'
      value={servar.key || ''}
      onComplete={(val: any) => {
        val = val.trim();
        if (val !== servar.key) handleServarChange({ key: val });
        if (isLinked) setEditDisabled(true);
      }}
      disabled={isLinked && (editDisabled || !editLinkedAllowed)}
      triggerCleanUp
      maxLength={128}
    />
  );
  if (isLinked && editDisabled && editLinkedAllowed)
    textField = (
      <Tooltip content='Double click to edit'>
        <div style={{ position: 'relative' }}>
          {textField}
          <div
            style={{
              position: 'absolute',
              left: 0,
              right: 0,
              bottom: 0,
              top: 0
            }}
            onDoubleClick={() => setEditDisabled(false)}
          />
        </div>
      </Tooltip>
    );

  const elementLabel = fieldTypeNameMap[fieldType];

  return (
    <>
      <Row>
        <FieldLinkWarningModal
          show={showLinkModal}
          setShow={setShowLinkModal}
          onConfirm={() => swapToExistingServar()}
        />
        <Col sm='3'>
          <PropertyLabel label='ID' />
        </Col>
        <Col className={styles.fieldIdColumn}>
          <div className={styles.fieldIdInputContainer}>
            {textField}
            {isLinked && (
              <DropdownMenu>
                <DropdownMenuTrigger className={styles.fieldIdInputChevron}>
                  <ChevronDownIcon width={18} height={18} />
                </DropdownMenuTrigger>
                <DropdownMenuContent
                  align='end'
                  className='overflow-y-scroll max-h-[60vh] w-[195px]'
                >
                  {servarSwapOptions.map((option) => (
                    <DropdownMenuItem
                      onClick={() => {
                        linkToServar(option);
                        setEditDisabled(true);
                      }}
                      key={option.id}
                    >
                      {option.key}
                    </DropdownMenuItem>
                  ))}
                </DropdownMenuContent>
              </DropdownMenu>
            )}
          </div>
          <Tooltip
            key={isLinked.toString()}
            content={
              isLinked ? (
                <>Unlink field</>
              ) : servarSwapOptions.length ? (
                <>
                  Link to an existing field.
                  <br /> Linked fields share the same properties and user data.
                  Styling may vary.
                </>
              ) : (
                <span>
                  No {fieldTypeNameMap[servar.type]} fields to link to
                </span>
              )
            }
          >
            <div
              className={cn(
                styles.fieldIdToggle,
                isLinked && styles.linked,
                !isLinked && !servarSwapOptions.length && styles.disabled
              )}
              onClick={() => {
                if (!isLinked && !servarSwapOptions.length) return;
                if (isLinked) swapToNewServar(servar.repeated);
                else if (servar.has_data) setShowLinkModal(true);
                else swapToExistingServar();
              }}
            >
              {isLinked ? (
                <LinkIcon width={18} height={18} />
              ) : (
                <UnlinkIcon width={18} height={18} />
              )}
            </div>
          </Tooltip>
        </Col>
      </Row>
      <Row>
        <Col sm='3'>
          <PropertyLabel label='Type' />
        </Col>
        <Col>
          {allowedTypeChanges.length === 0 || asset ? (
            <div className={formFieldStyles.labelType}>{elementLabel}</div>
          ) : (
            <DropdownField
              disabled={!editLinkedAllowed && isLinked}
              selected={fieldType}
              options={[fieldType, ...allowedTypeChanges].map((type) => ({
                value: type,
                display: fieldTypeNameMap[type]
              }))}
              onChange={(event: any) => {
                const selected = event.target.value;
                if (fieldType !== selected)
                  handleServarChange({
                    type: selected,
                    ...FeatheryConfig.default_element_properties[selected]
                      .servar
                  });
              }}
              style={{ width: 'auto' }}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default FieldIdToggle;
