import { useHistory, useParams } from 'react-router-dom';
import LogicRuleList, { LogicRule } from '../FormLogicPage/LogicRuleList';
import LogicRuleDetail from '../FormLogicDetailPage/LogicRuleDetail';
import AINavigation from '../../components/NavBar/AINavigation';
import useFeatheryRedux from '../../redux';
import { useCallback, useEffect } from 'react';
import { useAppSelector } from '../../hooks';
import { LogoLoader } from '../../components/Core';
import debounce from 'lodash.debounce';

export default function AILogicPage() {
  const { ruleId, extractionId } =
    useParams<{ ruleId: string; extractionId: string }>();

  const {
    getAIExtractionLogicRule,
    createAIExtractionLogicRule,
    deleteAIExtractionLogicRule,
    editAIExtractionLogicRule
  } = useFeatheryRedux();
  const rules = useAppSelector((state) => state.ai.rules[extractionId] ?? {});

  const history = useHistory();

  useEffect(() => {
    getAIExtractionLogicRule({ extractionId });
  }, []);

  const debouncedOnRuleUpdate = useCallback(
    debounce(
      (rule: LogicRule) =>
        editAIExtractionLogicRule({ extractionId, ruleId: rule.id, ...rule }),
      500
    ),
    [extractionId]
  );

  const onRuleDelete = async (rule: LogicRule, redirect = false) => {
    await deleteAIExtractionLogicRule({ extractionId, ruleId: rule.id });
    if (redirect) history.push(`/ai/${extractionId}/logic`);
  };

  const onRuleCreate = async (newRule: LogicRule, redirect = true) => {
    await createAIExtractionLogicRule({ extractionId, ...newRule });
    // send them to the rule edit screen
    if (redirect) history.push(`/ai/${extractionId}/logic/${newRule.id}?edit`);
  };

  const onRuleSelect = (rule: LogicRule) =>
    history.push(`/ai/${extractionId}/logic/${rule.id}`);

  const onRuleRuntimeOrderChange = (newRules: any[], curRuleId: any) => {
    let index = null;
    newRules.forEach((rule) => {
      if (rule.id === curRuleId) index = rule.index;
    });
    editAIExtractionLogicRule({
      extractionId,
      ruleId: curRuleId,
      index
    });
  };

  let component;
  if (ruleId && !rules[ruleId]) component = <LogoLoader />;
  else if (ruleId) {
    component = (
      <LogicRuleDetail
        steps={{}}
        rules={rules}
        onRuleUpdate={debouncedOnRuleUpdate}
        onRuleDelete={onRuleDelete}
        onBack={() => history.push(`/ai/${extractionId}/logic`)}
      />
    );
  } else {
    component = (
      <LogicRuleList
        onRuleRuntimeOrderChange={onRuleRuntimeOrderChange}
        onRuleUpdate={debouncedOnRuleUpdate}
        onRuleDelete={onRuleDelete}
        onRuleCreate={onRuleCreate}
        onRuleSelect={onRuleSelect}
        steps={{}}
        rules={rules}
      />
    );
  }

  return (
    <>
      <AINavigation activeItem='logic' />
      <div
        style={{
          overflowY: 'scroll',
          height: 'calc(100% - 55px)',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        {component}
      </div>
    </>
  );
}
