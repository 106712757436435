import { DropdownMultiField } from '../../../Core';

type RollbackExtractionsStepProps = {
  thisFormServarIds: string[];
  promoteToServarIds: string[];
  servarKeysById: Record<string, string>;
  setMapFieldData: React.Dispatch<React.SetStateAction<Record<string, string>>>;
  mapFieldData: Record<string, string>;
  promoteKey: string;
  extractions: any[];
  mapExtractionData: Record<string, string>;
  setMapExtractionData: React.Dispatch<
    React.SetStateAction<Record<string, string>>
  >;
};

export function RollbackExtractionsStep(props: RollbackExtractionsStepProps) {
  const { extractions, mapExtractionData, setMapExtractionData } = props;
  return (
    <div
      className='overflow-y-scroll max-h-[calc(100vh-32px-200px)] p-2'
      style={{ maxHeight: 'calc(100vh - 200px)' }}
    >
      <p className='mb-6'>
        (Optional) Choose which extractions to rollback. Their configurations
        will be updated to match the rollback&apos;s fields.
      </p>
      <DropdownMultiField
        selected={Object.keys(mapExtractionData) ?? []}
        options={Object.values(extractions).map((extraction: any) => ({
          value: extraction.id,
          label: extraction.key
        }))}
        onChange={(items: { value: string }[]) => {
          const result: Record<string, string> = {};
          items.forEach(({ value: extractionId }) => {
            result[extractionId] = extractionId;
          });
          setMapExtractionData(result);
        }}
        placeholder='Choose extractions'
      />
    </div>
  );
}
