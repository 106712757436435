import { memo, useMemo, useState } from 'react';

import ElementBlockPanel from './ElementBlockPanel';
import SearchBar from '../../SelectionPanel/SearchBar';
import { objectFilter } from '../../../utils/core';
import ElementListPanel from './ElementListPanel';
import useFeatheryRedux from '../../../redux';
import { BlockViewIcon, ListViewIcon } from '../../Icons';
import { UNDO_TITLES, UNDO_TYPES } from '../../../utils/constants';

import styles from './styles.module.scss';
import { useAppSelector } from '../../../hooks';
import { escapeSpecialChars } from '../../../utils/domOperations';

export function DEFAULT_CATEGORIES(elements: any) {
  return [
    {
      title: 'Layout',
      keys: Object.keys(elements).filter(
        (key) => elements[key].type === 'layout'
      )
    },
    {
      title: 'Basic',
      keys: Object.keys(elements).filter(
        (key) => elements[key].type === 'basic'
      )
    },
    {
      title: 'Fields',
      keys: Object.keys(elements).filter(
        (key) => elements[key].type === 'field'
      )
    }
  ];
}

function filterCategories(categories: any, elements: any) {
  return categories.map((category: any) => {
    return {
      title: category.title,
      keys: category.keys.filter((key: any) => elements[key])
    };
  });
}

function ElementPanel({
  elements = {},

  // Valid modes: 'drag' (step builder) or 'nav' (theme builder)
  mode = 'drag',

  onElementMouseDown = () => {},
  categories,
  handleUpdates,
  context = 'builder',
  deleteAssetFromTheme
}: any) {
  const [search, setSearch] = useState('');

  // The ElementPanel could exist in the theme builder or the flow builder
  // So we have to change which redux module to use accordingly
  const reduxModule = mode === 'drag' ? 'formBuilder' : 'themeBuilder';
  const theme = useAppSelector((state) => state[reduxModule].theme);
  const org = useAppSelector((state) => state.accounts.organization);
  const elementSelectView = useAppSelector(
    (state) => state.accounts.account.element_select_view
  );

  const disabledFormElements = useMemo(() => {
    const disabledElements: Record<string, true> = {};
    org?.disabled_form_elements.forEach(
      (type: string) => (disabledElements[type] = true)
    );
    return disabledElements;
  }, [org]);

  const reduxActions = useFeatheryRedux();
  const { editAccount } = reduxActions;
  const setTheme =
    reduxModule === 'themeBuilder'
      ? reduxActions[reduxModule].setTheme
      : // If in formBuilder, use undo stack for theme changes
        ({ theme: newTheme }: { theme: any }) => {
          reduxActions[reduxModule].addThemeToUndoStack({
            id: theme.id,
            oldValue: theme,
            newValue: newTheme,
            title: UNDO_TITLES.THEME,
            type: UNDO_TYPES.THEME
          });
        };

  const isBlock = elementSelectView === 'block';
  const Panel = isBlock ? ElementBlockPanel : ElementListPanel;

  const results = Object.entries(elements)
    .filter(
      ([, entry]: any[]) =>
        entry.label
          .toLowerCase()
          .search(escapeSpecialChars(search).toLowerCase()) > -1
    )
    .map(([key]: any[]) => key);

  const filteredElements: Record<string, any> = objectFilter(
    elements,
    ({ key }) => results.includes(key) && !disabledFormElements[key]
  );
  const filteredCategories = categories
    ? filterCategories(categories, filteredElements)
    : DEFAULT_CATEGORIES(filteredElements);

  return (
    <>
      <div className={styles.header}>
        <SearchBar
          searchValue={search}
          onSearch={setSearch}
          searchCount={results.length}
        />
        <div
          className={styles.panelViewToggle}
          onClick={() => {
            const newView = isBlock ? 'list' : 'block';
            newView !== elementSelectView &&
              editAccount({
                element_select_view: newView
              });
          }}
        >
          {isBlock ? <ListViewIcon /> : <BlockViewIcon />}
        </div>
      </div>
      <Panel
        elements={filteredElements}
        mode={mode}
        theme={theme}
        setTheme={setTheme}
        handleUpdates={handleUpdates}
        onElementMouseDown={onElementMouseDown}
        categories={filteredCategories}
        context={context}
        deleteAssetFromTheme={deleteAssetFromTheme}
      />
    </>
  );
}

export default memo(ElementPanel);
